import convertToBytes, { units } from "./convertToBytes";

/**
 * @param {number} value 
 * @param {"bytes" | "kb" | "mb" | "gb" | "tb"} unit 
 */
function formatBytes(value, unit) {
    let bytes = convertToBytes(value, unit);
    let newUnit = "bytes";

    for (let i = 0; i < units.length; i++) {
        const newBytes = bytes / 1000;
        if (newBytes <= 1) {
            break;
        }

        bytes = newBytes;
        newUnit = units[i + 1] || "tb";
    }

    if (newUnit === "bytes") {
        newUnit = "b";
    }

    return `${bytes.toFixed(2)} ${newUnit.toUpperCase()}`.replace(/\./g, ",");
}

export default formatBytes;