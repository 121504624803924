import React, { useState, useEffect } from 'react';
import firebase from '@firebase-config';
import i18n, { format } from '@i18n';
import { store, services } from '@redux';
import { useSelector, connect } from 'react-redux';
import { ExecutionFlow, Task, ExecutedForm, Signing } from '@taugor/taugor-bpmn-models';
import {
    Typography,
    Button,
} from '@material-ui/core';
import Api from '@api';
import Icon from '@custom/Icon';
import { Input as ZeroInput, store as zerocodeStore } from 'zerocode';

const oTaskService = new Task().getService(firebase, store, 'expiredView');
const oExecutedFormService = new ExecutedForm().getService(firebase, store, 'taskExpiredView');


const TaskExpired = ({
    taskId,
    task,
    executionFlow,
    authStore,
    absenseStore,
    taskStore,
}) => {
    const [sendMail, setSendMail] = useState(false);
    const appcenterStore = zerocodeStore.getState().appcenter;

    useEffect(() => {
        if (!taskId) return;
        services.spinner.show();
        oTaskService
            .get(taskId)
            .finally(services.spinner.hide);
    }, [taskId]);

    useEffect(() => {
        if (!taskStore.current || !taskStore.current.uid) return;
        services.spinner.show();
        oExecutedFormService
            .filter([
                [
                    ['action.uid', '==', taskStore.current.action.uid],
                    ['executionFlow', '==', taskStore.current.executionFlow],
                    ['company', '==', authStore.company.uid],
                ],
            ])
            .list()
            .finally(services.spinner.hide);
    }, [taskStore.current]);

    if (taskStore.current.status && taskStore.current.status !== 'completed' && taskStore.current.agent !== authStore.user.uid) {
        let isAbsent = false;
        absenseStore.list.map((absense) => {
            let today = new Date();
            if (absense.dateStart.toDate() <= today && absense.dateEnd.toDate() >= today) {
                isAbsent = true;
            }
        });
    }

    const renderExecutor = () => (
        <div className="task-form-main">
            <h3>{i18n('task.form.isExpired')}</h3>
            <div>
                <div style={{ color: '#666', marginBottom: 10 }}>
                    <Button
                        onClick={async () => {
                            executionFlow.workflowObject.experation.isExpired = false
                            executionFlow.workflowObject.experation.doesExpire = false
                            services.spinner.show();
                            await Api.executionFlow.updateExecutionFlow({
                                executionFlow: {
                                    workflowObject: {
                                        experation: {
                                            isExpired: false,
                                            doesExpire: false,
                                        }
                                    },
                                    uid: executionFlow.uid
                                }
                            })
                                .finally(services.spinner.hide);
                        }}
                        style={{ padding: 2 }}
                    >
                        Restaurar fluxo<Icon icon="faRedo" style={{ fontSize: 18 }} />
                    </Button>
                </div>

            </div>

        </div>
    );
    const renderSimpleUser = () => (
        <div className="task-form-main">
            <div>
                <h3>{i18n('task.form.expired')}</h3>
                <div style={{ marginBottom: 20 }}>
                    {sendMail ?
                        <div>
                            {i18n('task.form.sended')}
                        </div> :
                        <div>
                            <Button
                                style={{ marginBottom: 20 }}
                                className="simple-filled-button"
                                onClick={async () => {
                                    services.spinner.show();
                                    const { data } = await Api.mail.mailWrite(taskStore.current)
                                        .finally(services.spinner.hide);
                                    if (data.sucess == 'enviado') {
                                        setSendMail(true)
                                    }
                                }}
                            >
                                {i18n('task.form.buttonResponsible')}
                            </Button>
                        </div>}
                </div>
            </div>
        </div>
    );

    const renderContent = () => {
        return appcenterStore?.user.uid === task?.action.agent ? renderSimpleUser() : renderExecutor();
    };
    return (
        <div>
            <header className="form-panel-header">
                <div>
                    <Typography variant="h4">Expirado</Typography>
                </div>
                <div
                    className="header-button"
                >
                    <Icon icon="faTimes" />
                </div>
            </header>
            <div style={{ padding: 20 }}>{renderContent()}</div>
        </div>
    );
};

export default connect(({ auth, absenseStore, expiredView, signingFormView, taskFormExecutedForm }) => ({
    authStore: auth,
    absenseStore: absenseStore,
    taskStore: expiredView,
    signingStore: signingFormView,
    executedFormStore: taskFormExecutedForm,
}))(TaskExpired);