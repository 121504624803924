import React, { useState, useEffect } from 'react';
import i18n, { format } from '@i18n';
import '@assets/sass/social.share.scss';

const SocialShareMedia = ({
	onClick,
	onChange,
	url,
	title,
	image,
	desc,
	app_id,
	redirect_url,
	via,
	hash_tags,
	provider,
	language,
	user_id,
	category,
	phone_number,
	email_address,
	cc_email_address,
	bcc_email_address,
}) => {
	const [links, setLinks] = useState({});

	const getOrderedByPopularity = () => {
		return [
			'email',
			'gmail',
			'facebook',
			'reddit',
			'whatsapp',
			'twitter',
			'linkedin',
			'skype',
			'tumblr',
			'pinterest',
			'blogger',
			'google.bookmarks',
			'livejournal',
			'evernote',
			'add.this',
			'getpocket',
			'hacker.news',
			'buffer',
			'flipboard',
			'instapaper',
			'surfingbird.ru',
			'flattr',
			'diaspora',
			'qzone',
			'vk',
			'weibo',
			'ok.ru',
			'douban',
			'xing',
			'renren',
			'threema',
			'sms',
			'line.me',
			'telegram.me',
			'yahoo',
		];
	};

	const getListWithLinks = ({
		url,
		title,
		image,
		desc,
		app_id,
		redirect_url,
		via,
		hash_tags,
		provider,
		language,
		user_id,
		category,
		phone_number,
		email_address,
		cc_email_address,
		bcc_email_address,
	}) => {
		url = url ? encodeURIComponent(url) : '';
		title = title ? encodeURIComponent(title) : '';
		image = image ? encodeURIComponent(image) : '';
		desc = desc ? encodeURIComponent(desc) : '';
		app_id = app_id ? encodeURIComponent(app_id) : '';
		redirect_url = redirect_url ? encodeURIComponent(redirect_url) : '';
		via = via ? encodeURIComponent(via) : '';
		hash_tags = hash_tags ? encodeURIComponent(hash_tags) : '';
		provider = provider ? encodeURIComponent(provider) : '';
		language = language ? encodeURIComponent(language) : '';
		user_id = user_id ? encodeURIComponent(user_id) : '';
		category = category ? encodeURIComponent(category) : '';
		phone_number = phone_number ? encodeURIComponent(phone_number) : '';
		email_address = email_address ? encodeURIComponent(email_address) : '';
		cc_email_address = cc_email_address ? encodeURIComponent(cc_email_address) : '';
		bcc_email_address = bcc_email_address ? encodeURIComponent(bcc_email_address) : '';

		var text = title;

		if (desc) {
			text += '%20%3A%20'; // This is just this, " : "
			text += desc;
		}

		return {
			'add.this': 'http://www.addthis.com/bookmark.php?url=' + url,
			blogger: 'https://www.blogger.com/blog-this.g?u=' + url + '&n=' + title + '&t=' + desc,
			buffer: 'https://buffer.com/add?text=' + text + '&url=' + url,
			diaspora: 'https://share.diasporafoundation.org/?title=' + title + '&url=' + url,
			douban: 'http://www.douban.com/recommend/?url=' + url + '&title=' + text,
			email: 'mailto:' + email_address + '?subject=' + title + '&body=' + desc,
			evernote: 'https://www.evernote.com/clip.action?url=' + url + '&title=' + text,
			getpocket: 'https://getpocket.com/edit?url=' + url,
			facebook: 'http://www.facebook.com/sharer.php?u=' + url,
			flattr:
				'https://flattr.com/submit/auto?user_id=' +
				user_id +
				'&url=' +
				url +
				'&title=' +
				title +
				'&description=' +
				text +
				'&language=' +
				language +
				'&tags=' +
				hash_tags +
				'&hidden=HIDDEN&category=' +
				category,
			flipboard: 'https://share.flipboard.com/bookmarklet/popout?v=2&title=' + text + '&url=' + url,
			gmail:
				'https://mail.google.com/mail/?view=cm&to=' +
				email_address +
				'&su=' +
				title +
				'&body=' +
				url +
				'&bcc=' +
				bcc_email_address +
				'&cc=' +
				cc_email_address,
			'google.bookmarks':
				'https://www.google.com/bookmarks/mark?op=edit&bkmk=' +
				url +
				'&title=' +
				title +
				'&annotation=' +
				text +
				'&labels=' +
				hash_tags +
				'',
			instapaper: 'http://www.instapaper.com/edit?url=' + url + '&title=' + title + '&description=' + desc,
			'line.me': 'https://lineit.line.me/share/ui?url=' + url + '&text=' + text,
			linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=' + url,
			livejournal: 'http://www.livejournal.com/update.bml?subject=' + text + '&event=' + url,
			'hacker.news': 'https://news.ycombinator.com/submitlink?u=' + url + '&t=' + title,
			'ok.ru': 'https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=' + url,
			pinterest: 'http://pinterest.com/pin/create/button/?url=' + url,
			qzone: 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + url,
			reddit: 'https://reddit.com/submit?url=' + url + '&title=' + title,
			renren:
				'http://widget.renren.com/dialog/share?resourceUrl=' +
				url +
				'&srcUrl=' +
				url +
				'&title=' +
				text +
				'&description=' +
				desc,
			skype: 'https://web.skype.com/share?url=' + url + '&text=' + text,
			sms: 'sms:' + phone_number + '?body=' + text,
			'telegram.me': 'https://t.me/share/url?url=' + url + '&text=' + text + '&to=' + phone_number,
			threema: 'threema://compose?text=' + text + '&id=' + user_id,
			tumblr:
				'https://www.tumblr.com/widgets/share/tool?canonicalUrl=' +
				url +
				'&title=' +
				title +
				'&caption=' +
				desc +
				'&tags=' +
				hash_tags,
			twitter: 'https://twitter.com/intent/tweet?url=' + url + '&text=' + text + '&via=' + via + '&hashtags=' + hash_tags,
			vk: 'http://vk.com/share.php?url=' + url + '&title=' + title + '&comment=' + desc,
			weibo: 'http://service.weibo.com/share/share.php?url=' + url + '&appkey=&title=' + title + '&pic=&ralateUid=',
			whatsapp: 'https://api.whatsapp.com/send?text=' + text + '%20' + url,
			xing: 'https://www.xing.com/spi/shares/new?url=' + url,
			yahoo: 'http://compose.mail.yahoo.com/?to=' + email_address + '&subject=' + title + '&body=' + text,
		};
	};

	const socialmedia = getOrderedByPopularity();

	onClick = onClick || (() => () => {});

	const onBrandClick = (media) => (e) => {
		let link = getListWithLinks({})[media];
		onClick(media, getListWithLinks);
	};

	return (
		<div className="social-shares">
			{socialmedia.map((media, i) => {
				return (
					<div className="social-share-brand" onClick={onBrandClick(media)} key={i}>
						<div className="brand-name">{i18n(`social.brands.${media}`)}</div>
						<div className="brand-icon" tooltip={i18n(`social.brands.${media}`)}>
							<img src={`/assets/images/social-logo-icons-opaque-background/${media}.png`} loading="lazy" />
						</div>
					</div>
				);
			})}
		</div>
	);
};
export default SocialShareMedia;
