import React, { useState, useEffect } from 'react';
import i18n, { format } from '@i18n';
import { Typography, TextField, MenuItem } from '@material-ui/core';
import { services } from '@redux';
import { connect } from 'react-redux';
import firebase, { workfluxFirestore } from '@firebase-config';
import TaskCard from './executionFlow/TaskCard';
import { Icon } from 'zerocode';
import { toDate, store as zerocodeStore } from 'zerocode';
import { kanbanStatus } from '@custom/common.js';

const taskCollection = workfluxFirestore.collection('task');
const executionFlowCollection = workfluxFirestore.collection('executionFlow');
const userKanbanConfigCollection = workfluxFirestore.collection('userKanbanConfig');

let currentScope = {
	user: '',
	company: '',
};
let statusSnapshots = {};

let debounceQuery;
const KanbanBasic = ({ auth }) => {
	const [state, setState] = useState({});
	const [user, setUser] = useState({});
	const [companySnapshots, setCompanySnapshots] = useState({});
	const [statusConfig, setStatusConfig] = useState({});
	const [columnOrder, setColumnOrder] = useState({});
	const [currentUser, setCurrentUser] = useState({});
	const [currentGroup, setCurrenGroup] = useState({});
	const [executionsFlows, setExecutionsFlows] = useState([]);
	let appcenterStore = zerocodeStore.getState().appcenter;

	useEffect(() => {
		currentScope = {
			user: '',
			company: '',
		};
		setCurrentUser(auth.user);
		getStatusConfig();
	}, []);

	useEffect(() => {
		if (!auth.isAuthenticated || !auth.user || !auth.user.uid || !auth.company || !auth.company.uid) {
			currentScope = {
				user: '',
				company: '',
			};
			return;
		}
		if (currentScope.user == auth.user.uid && currentScope.company == auth.company.uid) return;
		currentScope.user = auth.user.uid;
		currentScope.company = auth.company.uid;
		// setCurrentUser(auth.user);
		// getStatusConfig();
	}, [auth.company, auth.user, auth.isAuthenticated]);

	useEffect(() => {
		getExecutionFlowInProgress();
	}, [statusConfig, currentUser]);

	useEffect(() => {
		if (!Object.values(statusConfig).length) return;

		//('WILL CREATE KANBAN COLUMNS SNAPS');
		clearTimeout(debounceQuery);

		debounceQuery = setTimeout(() => {
			kanbanStatus.map((status) => {
				let id = status.id;
				//('KANBAN COLUMN', status.id);
				if (!statusConfig[id] || statusConfig[id].closed) {
					return () => { };
				}
				//('WILL QUERY');
				let query = taskCollection;
				query = query.where('deleted', '==', false);
				query = query.where('action.agent', '==', currentUser.uid);
				query = query.where('status', 'in', ['review', 'pending', 'completed']);
				query = query.where('action.kanban.basic.status', '==', status.id);
				query = query.limit(500);
				if (typeof statusSnapshots[status.id] == 'function') {
					statusSnapshots[status.id]();
				}
				statusSnapshots[status.id] = query.onSnapshot(
					(snapshot) => {
						companySnapshots[status.id] = snapshot.docs.filter((doc) => {
							const task = doc.data();
							return executionsFlows.indexOf(task.executionFlow) > -1;
						});
						setState({});
						services.spinner.hide();
					},
					(e) => {
						//('ERROR', e);
						services.spinner.hide();
					}
				);
				services.spinner.hide();
			});
		}, 400);
	}, [executionsFlows]);

	const getExecutionFlowInProgress = async () => {
		let ef;
		const data = await executionFlowCollection
			.where('deleted', '==', false)
			.where('company', '==', auth.user.currentCompany.uid)
			.where('completed', 'not-in', ['canceled', 'archived'])
			// .where('currentPhase', '!=', '')
			// .where('completed', '!=', 'archived')
			.get();
		ef = data.docs.map((d) => d.data());
		ef = ef.filter((e) => e.currentPhase != '');
		ef = ef.map((e) => e.uid);
		setExecutionsFlows(ef);
	};

	const getStatusConfig = () => {
		return userKanbanConfigCollection.doc(auth.user.uid).onSnapshot((doc) => {
			let defaultStatus = {};
			kanbanStatus.map((s) => {
				defaultStatus[s.id] = {
					id: s.id,
					closed: false,
					order: '',
				};
			});
			setStatusConfig(doc.data() || defaultStatus);
		});
	};
	const updateStatusConfig = (id, prop, value) => (e) => {
		if (!statusConfig[id]) {
			statusConfig[id] = {
				id: id,
				closed: false,
				order: '',
			};
		}
		statusConfig[id][prop] = value;
		userKanbanConfigCollection.doc(auth.user.uid).set(
			{
				...statusConfig,
				[id]: {
					[prop]: value,
				},
			},
			{ merge: true }
		);
	};

	return (
		<div style={{ height: '100%' }}>
			<div style={{ padding: 15 }}>
				<div>
					<div className="columns">
						<div style={{ marginRight: 20 }}>
							<Typography gutterBottom variant="h5" component="h2">
								{i18n("workflow.kanban.title")}
							</Typography>
							<h5>{i18n("workflow.kanban.subTitle")}</h5>
						</div>
						{auth.user.companyadmin && (
							<TextField
								variant="filled"
								style={{ width: 150, marginRight: 10 }}
								label={i18n("groupsOptional")}
								value={currentGroup}
								onChange={(e) => {
									setCurrenGroup(e.target.value);
									services.spinner.show();
								}}
								InputLabelProps={{ shrink: true }}
								select
							>
								<MenuItem value={''}>{i18n("groups")}</MenuItem>
								{appcenterStore.groups.map((group) => {
									return (
										<MenuItem key={group.uid} value={group}>
											{group.name}
										</MenuItem>
									);
								})}
							</TextField>
						)}
						{auth.user.companyadmin && (
							<TextField
								variant="filled"
								style={{ width: 150 }}
								label={i18n("user")}
								value={currentUser}
								onChange={(e) => {
									setCurrentUser(e.target.value);
									services.spinner.show();
								}}
								InputLabelProps={{ shrink: true }}
								select
							>
								<MenuItem key={auth.user.uid} value={auth.user}>
									{i18n("appcommon.menutop.myTasks")}
								</MenuItem>
								{appcenterStore.users.map((user) => {
									if (currentGroup && currentGroup.uid) {
										if (user.groups.indexOf(currentGroup.uid) == -1) return;
									}
									if (!user.active) return;
									if (user.active.indexOf(auth.user.currentCompany.uid) == -1) return;
									return (
										<MenuItem key={user.uid} value={user}>
											{user.firstName} {user.lastName}
										</MenuItem>
									);
								})}
							</TextField>
						)}
					</div>
				</div>
			</div>
			<div className="columns kanban">
				{kanbanStatus.map((status, i) => {
					let config = {};
					let id = status.id;
					if (statusConfig && statusConfig[id]) {
						config = statusConfig[id];
					}

					let totalBusinessValue = 0;
					let totalCostEstimated = 0;
					let totalEffortEstimated = 0;
					let totalPriority = 0;

					if (!!companySnapshots[status.id]) {
						companySnapshots[status.id].map((doc) => {
							let data = doc.data();
							doc.__data = data;
							totalBusinessValue += parseFloat(data.action.businessValue || 0);
							totalCostEstimated += parseFloat(data.action.estimatedCost || 0);
							totalEffortEstimated += parseFloat(data.action.estimatedEffort || 0);
							totalPriority += parseFloat(data.action.priority || 0);
						});
					}

					return (
						<div
							key={i}
							className={`kanban-column droppable ${status.id} ${config.closed ? 'closed' : ''}`}
							onMouseEnter={(e) => {
								e.currentTarget.classList.add('hover');
							}}
							onMouseLeave={(e) => {
								e.currentTarget.classList.remove('hover');
							}}
							data-status={status.id}
						>
							<div className="kanban-header">
								<div className="kanban-title">
									<div className="text">
										{i18n(status.name)} (
										{companySnapshots[status.id] ? companySnapshots[status.id].length : '0'})
									</div>
									<div className="icon" onClick={updateStatusConfig(status.id, 'closed', !config.closed)}>
										<Icon icon="faCaretLeft" />
									</div>
								</div>
							</div>
							<div className="header-info">
								<div className="info-title">{i18n("workflow.kanban.card.step.estimates")}</div>
								<div
									className="info-totalBusinessValue"
									onClick={updateStatusConfig(
										status.id,
										'order',
										config.order == 'data.action.businessValue'
											? 'data.createdAt'
											: 'data.action.businessValue'
									)}
								>
									{config.order == 'data.action.businessValue' && (
										<div>
											<Icon icon="faChevronRight" />
										</div>
									)}
									<div>{i18n("workflow.form.actions.businessValue")}</div>
									{totalBusinessValue}
								</div>
								<div
									className="info-totalCostEstimated"
									onClick={updateStatusConfig(
										status.id,
										'order',
										config.order == 'data.action.estimatedCost'
											? 'data.createdAt'
											: 'data.action.estimatedCost'
									)}
								>
									{config.order == 'data.action.estimatedCost' && (
										<div>
											<Icon icon="faChevronRight" />
										</div>
									)}
									<div>{i18n("workflow.kanban.card.step.cost")}</div>
									{format.currency(totalCostEstimated)}
								</div>
								<div
									className="info-totalEffortEstimated"
									onClick={updateStatusConfig(
										status.id,
										'order',
										config.order == 'data.action.estimatedEffort'
											? 'data.createdAt'
											: 'data.action.estimatedEffort'
									)}
								>
									{config.order == 'data.action.estimatedEffort' && (
										<div>
											<Icon icon="faChevronRight" />
										</div>
									)}
									<div>{i18n("workflow.kanban.card.step.effortInHours")}</div>
									{format.number(totalEffortEstimated)}
								</div>
								<div
									className="info-totalEffortEstimated"
									onClick={updateStatusConfig(
										status.id,
										'order',
										config.order == 'data.action.priority' ? 'data.createdAt' : 'data.action.priority'
									)}
								>
									{config.order == 'data.action.priority' && (
										<div>
											<Icon icon="faChevronRight" />
										</div>
									)}
									<div>{i18n("workflow.kanban.card.step.priorityValue")}</div>
									{format.number(totalPriority)}
								</div>
							</div>
							<div className="items">
								{!!companySnapshots[status.id] &&
									companySnapshots[status.id]
										.map((doc) => {
											let data = doc.data();
											data.createdAt = toDate();
											return { data, doc };
										})
										.sortBy([config.order ? config.order : 'data.createdAt'])
										.map((doc) => {
											return (
												<TaskCard
													isFinalPhase={kanbanStatus.length - 1 == i}
													key={doc.doc.id}
													parentStatus={status.id}
													auth={auth}
													doc={doc.doc}
												/>
											);
										})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default connect(({ auth }) => ({ auth }))(KanbanBasic);
