import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@custom/Icon';
import i18n from '@i18n';
import { sortByUrgency } from '@custom/common';

const ExecutionFlowBox = ({ icon, title, list, className, renderItem }) => {
  return (
    <div className={className}>
      <h3>
        <Icon icon={icon} />
        {i18n(title)} ({list.length})
      </h3>
      <div className="items">
        {list.sort(sortByUrgency).map(renderItem)}
      </div>
    </div>
  );
};

ExecutionFlowBox.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
};

export default ExecutionFlowBox;