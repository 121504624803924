import React from 'react';
import {
	Button
} from '@material-ui/core';
import { convertTimestampDateAndHours } from '../../../../utils/dates';
import { useSelector } from 'react-redux';
import { history } from "@redux";
import styles from './news.module.css';

export function RenderCardNews({ news, categoryName, handleEdit }) {
	const auth = useSelector((s) => s.auth);

	const date = convertTimestampDateAndHours({ timestamp: news.createdAt });
	const category = categoryName();

	return (
		<section className={styles.card}>
			<section className={styles.areaBanner}>
				{news.bannerUrl ? (<img src={news.bannerUrl} alt='Banner da notícia' />) : (
					<p>Essa notícia não possue capa</p>
				)}
			</section>
			<section className={styles.areaText}>
				<section className={styles.header}>
					<h1>{category}</h1>
					<section>
						<p>{date.date}</p>
						<p>ás {date.hours}</p>
					</section>
				</section>
				<h2>{news.title}</h2>
				<section>
					<p>Resumo:</p>
					<p dangerouslySetInnerHTML={{ __html: news.summary }} />
				</section>
			</section>
			<section className={styles.areaButtons}>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					onClick={() => history.push(`/news/${news.id}`)}
				>
					+ Detalhes
				</Button>
				{auth.user.companyadmin &&
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						onClick={() => handleEdit({ newsSelected: news })}
					>
						Editar
					</Button>
				}
			</section>
		</section>
	);
}
