import React from 'react';
import { useSelector } from 'react-redux';
import { services, history } from '@redux';
// @ts-ignore
import { AuthPage } from 'zerocode';

const Authentication = ({ match }) => {
	// @ts-ignore
	const { auth } = useSelector((state) => state);

	return (
		<AuthPage
			app="workflux"
			urlProps={match}
			auth={auth}
			loginWithEmail={services.auth.login}
			loginWithSSO={services.auth.sso}
			loginWithCertificate={services.auth.loginWithCertificate}
			logout={services.auth.logout}
			hideSpinner={services.spinner.hide}
			showSpinner={services.spinner.show}
			showMessage={services.message.show}
			hideMessage={services.message.hide}
			goToPage={history.push}
		/>
	);
};

export default Authentication;
