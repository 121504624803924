import styles from './detail.module.css'
import React, { useEffect, useState } from 'react';
import { workfluxFirestore } from '@firebase-config';
import { convertTimestampDateAndHours } from '../../utils/dates';
import { history } from "@redux";
import {
  Button,
} from '@material-ui/core';
import { LoadingCircle } from '../../components/interface/loadingCircle';
import Icon from '../../@custom/Icon';

export function DetailCalendar({ match }) {

  const { id } = match.params
  const [calendar, setCalendar] = useState({});
  const calendarCollection = workfluxFirestore.collection("calendar");

  const [loading, setLoading] = useState(false);
  const data = calendar.createdAt && convertTimestampDateAndHours({ timestamp: calendar?.createdAt });

  useEffect(() => {

    async function getCalendar() {
      setLoading(true)
      try {
        const response = await calendarCollection.doc(id).get();

        const data = response.data();
        const dataCalendar = {
          uid: data.uid,
          title: data.title,
          text: data.text,
          company: data.company,
          createdBy: data.createdBy,
          user: data.user,
          createdAt: data.createdAt
        }

        setCalendar(dataCalendar)
      } catch (error) { }
      setLoading(false)
    }
    getCalendar();

  }, [])

  if (loading) {
    return (
      <LoadingCircle />
    )
  }

  return (
    <section className={styles.container}>

      <section className={styles.content}>
        <section style={{
          display: "flex",
          alignItems: "center",
          gap: 16
        }}>
          <h3><Icon icon="faCalendar" /> Agenda</h3>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => history.push("/calendar")}
          >
            Voltar
          </Button>
        </section>
        <section className={styles.header}>
          <section style={{
            display: "flex",
            gap: 16
          }}>

            <h1>{calendar.title}</h1>
          </section>
        </section>
        <section>
          <p>Texto:</p>
          <p dangerouslySetInnerHTML={{ __html: calendar?.text }} />
        </section>
        <section className={styles.footer}>
          <p>Criado por: {calendar?.user?.name}</p>
          <p>Em: {data?.date} ás {data?.hours}</p>
        </section>
      </section>
    </section>
  )
}