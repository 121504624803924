import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from '@redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Scripts } from 'zerocode';
import AppCommon from './AppCommon';
import '@assets/sass/main.scss';
// import 'zerocode/dist/index.css';

const theme = createMuiTheme({
	palette: {
		primary: { main: '#039be5' },
		secondary: { main: '#fdd835' },
		error: { main: '#f44336' },
		warning: { main: '#FF8800' },
	},
	status: {
		danger: 'orange',
	},
});

const App = () => {
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<ThemeProvider theme={theme}>
					<Scripts app="workflux">
						
						<AppCommon />
					</Scripts>
				</ThemeProvider>
			</ConnectedRouter>
		</Provider>
	);
};

export default App;
