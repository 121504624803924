import React, { useState, useEffect, useRef } from 'react';
import { Input, toDate } from 'zerocode';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import i18n, { format } from '@i18n';

import Icon from '../../@custom/Icon';
import { LinearProgressWithLabel } from '../../utils/linearProgressWithLabel';
import firebase, { workfluxFirestore } from '@firebase-config';
import { factory } from 'zerocode';
import { getFieldListFromString } from '@custom/common';
import Parser from 'react-html-parser';

const taskCollection = workfluxFirestore.collection('task');

let saveDebounce;
const TaskCard = ({ auth, doc, parentStatus, isFinalPhase, teste }) => {
	const [state, setState] = useState({});
	const [configs, setConfigs] = useState({ actionName: '', checklistOpen: false });
	const [checklist, setChecklist] = useState(false);
	const [discussions, setDiscussions] = useState([]);
	const [task, setTask] = useState({ workflowObject: {}, action: { agentName: '', type: {}, kanban: { basic: {} } } });
	const [isSaving, setIsSaving] = useState(false);
	const [keys, setKeys] = useState([]);
	const [values, setValues] = useState([]);
	const [options, setOptions] = useState([]);
	const [formName, setFormName] = useState('');
	const cardRef = useRef(null);
	useEffect(() => {
		let t = doc.data();
		if (!t.action.estimatedCost) {
			t.action.estimatedCost = 0;
		}
		if (!t.action.estimatedEffort) {
			t.action.estimatedEffort = 0;
		}
		setTask(t);
		let checklistDoc = taskCollection.doc(t.uid).collection('checklist').doc(t.uid);
		checklistDoc.get().then((d) => {
			let data = {
				actions: [],
			};
			if (d.exists) {
				data = d.data();
			}
			setChecklist(data);
		});
	}, []);

	useEffect(() => {
		let t = doc.data();
		return taskCollection
			.doc(t.uid)
			.collection('discussion')
			.where('deleted', '==', false)
			.onSnapshot((snapshot) => {
				setDiscussions(snapshot.docs.map((d) => d.data()));
			});
	}, []);

	useEffect(() => {
		if (task.action.hasFormReference) {
			task.workflowObject.phases &&
				task.workflowObject.phases.map((phase) => {
					if (phase.actions) {
						phase.actions.map((action) => {
							if (action.executedForm == undefined) return;
							if (action.uid === task.action.formReference.action) {
								setFormName(action.formTitle);

								const filterKeysInOrder = getFieldListFromString(action);

								let optionsTable = [];
								let keysInOrder = [];
								let valuesInOrder = [];

								filterKeysInOrder.forEach((key) => {
									if (key.type === 'table') {
										optionsTable.push(key.options);
									}
								});

								filterKeysInOrder.forEach((key) => {
									keysInOrder.push(key.name);
									valuesInOrder.push(action.executedForm.fields[key.name]);
								});

								setKeys(keysInOrder);
								setValues(valuesInOrder);
								setOptions(optionsTable);
							}
						});
					}
				});
		}
	}, [task]);

	const draggable = factory.draggable({
		element: cardRef.current,
		onDragEnd: (e, clones, droppable) => {
			if (!droppable || !droppable.dataset.status) {
				return;
			}
			task.action.kanban.basic.status = droppable.dataset.status;
			setState({});

			let doc = taskCollection.doc(task.uid);
			doc.update({
				'action.kanban.basic.status': droppable.dataset.status,
				'action.kanban.basic.statusDate': new Date().toISOString(),
			});
		},
	});

	const saveTask = () => {
		clearTimeout(saveDebounce);
		saveDebounce = setTimeout(async () => {
			setIsSaving(true);
			let doc = taskCollection.doc(task.uid);

			await doc.update({
				'action.estimatedCost': task.action.estimatedCost || 0,
				'action.estimatedEffort': task.action.estimatedEffort || 0,
				'action.estimatedDeliveryDate': task.action.estimatedDeliveryDate || '',
			});
			await doc
				.collection('history')
				.doc()
				.set({
					user: auth.user.uid,
					date: new Date(),
					estimatedCost: task.action.estimatedCost || 0,
					estimatedEffort: task.action.estimatedEffort || 0,
					estimatedDeliveryDate: task.action.estimatedDeliveryDate || '',
					checklist,
				});
			await doc.collection('checklist').doc(task.uid).set(checklist);
			setIsSaving(false);
		}, 500);
	};

	const addChecklistAction = () => {
		let c = checklist;
		c.actions.push({
			name: configs.actionName,
			custom: true,
			finished: false,
		});
		configs.actionName = '';
		setChecklist({ ...c });
		saveTask();
	};

	const addComment = () => {
		if (!configs.comment) return;
		let doc = taskCollection.doc(task.uid);
		let comment = doc.collection('discussion').doc();
		comment.set({
			uid: comment.id,
			deleted: false,
			date: new Date().toISOString(),
			user: {
				uid: auth.user.uid,
				name: auth.user.firstName + ' ' + auth.user.lastName,
				email: auth.user.email,
			},
			comment: configs.comment,
		});
		configs.comment = '';
		setState({});
	};

	const removeComment = (id) => (e) => {
		let doc = taskCollection.doc(task.uid);
		doc.collection('discussion')
			.doc(id)
			.update({ deleted: true, deletedBy: auth.user.uid, deletedAt: new Date().toISOString() });
	};

	let progress = 100;
	if (checklist && checklist.actions.length > 0) {
		progress = (checklist.actions.filter((a) => a.finished).length * 100) / checklist.actions.length;
	}

	let estimatedDeliveryDate = task.action.estimatedDeliveryDate;
	let isDueDate = false;
	if (estimatedDeliveryDate && parentStatus !== 'done') {
		estimatedDeliveryDate = new Date(estimatedDeliveryDate + ' 23:59:59');
		if (new Date() >= estimatedDeliveryDate) {
			isDueDate = true;
		}
	}

	const convertValuesInTable = (valuesField) => {
		return (
			<div className="info-field-">
				<table className="table-sheet-view">
					<tr className="row headers">
						{options[0].map((col, a) => {
							return (
								<th key={a} className="col">
									{col}
								</th>
							);
						})}
					</tr>

					{valuesField.map((value, i) => {
						return (
							<tr className="row" key={i}>
								{options[0].map((col, a) => {
									return (
										<td style={{ border: '1px solid #abcbe8' }} key={a} className="col">
											{value[col]}
										</td>
									);
								})}
							</tr>
						);
					})}
				</table>
			</div>
		);
	};

	/* convertValues(mockValues) */

	return (
		<div
			className={`
				type-${task.action.type.type}
				${task.expired ? 'expired' : ''}
				${isDueDate ? 'due-date' : ''}
				${isSaving ? 'saving' : ''}
				task-card
			`}
			onMouseDown={parentStatus ? draggable.onMouseDown : () => { }}
			ref={cardRef}
		>
			<div className="local-spinner">
				<Icon icon="faCircleNotch" />
			</div>
			<header style={{ display: 'flex', justifyContent: 'space-between' }}>
				{task.action.instructions || task.action.formTitle}
			</header>

			{!!checklist && checklist.actions.length > 0 && (
				<div className="progress-bar">
					<LinearProgressWithLabel value={progress} />
				</div>
			)}
			<div
				className="navigation a"
				onMouseDown={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
				onClick={(e) => {
					window.open(`/executionFlow/${task.executionFlow}/task/approval/${task.uid}`, '_blank');
				}}
			>
				<Icon icon="faExternalLinkSquareAlt" />
			</div>
			{isDueDate && (
				<div className="due-date-task">
					<Icon icon="faExclamationTriangle" />
				</div>
			)}
			<div
				className="container"
				onMouseDown={(e) => {
					e.stopPropagation();
				}}
			>
				<div className="task-discussion">
					<div
						className="task-discussion-header"
						onClick={(e) => {
							configs.description = !configs.description;
							setConfigs({ ...configs });
						}}
					>
						<div>{i18n('executionFlow.form.description')}</div>
						<Icon icon={configs.description ? 'faCaretDown' : 'faCaretRight'} />
					</div>
					{configs.description && (
						<div className="task-priority">
							<h5>{task.action.description}</h5>
						</div>
					)}
				</div>
				<div className="task-info">
					<div className="info-column">
						<div
							className="info-title"
							onClick={(e) => {
								configs.infoOpened = !configs.infoOpened;
								setConfigs({ ...configs });
							}}
						>
							<div>{i18n("executionFlow.form.generalInformation")}</div>
							<Icon icon={configs.infoOpened ? 'faCaretDown' : 'faCaretRight'} />
						</div>
						{configs.infoOpened && (
							<div>
								<div className="task-priority">
									<div>{i18n("flow")}</div>
									{task.workflowObject.description}
								</div>
								<div className="task-priority">
									<div>{i18n("workflow.form.actions.priority")}</div>
									{task.action.priority || 0}
								</div>
								<div className="task-businessvalue">
									<div>{i18n("workflow.form.actions.businessValue")}</div>
									{task.action.businessValue || 0}
								</div>
								{!!task.action.kanban.basic.statusDate && (
									<div className="task-businessvalue">
										<div>{i18n("dateStatus")}</div>
										{format.datetime(toDate(task.action.kanban.basic.statusDate))}
									</div>
								)}
							</div>
						)}
					</div>
					<div>
						<div className="info-column estimates">
							<div
								className="estimates-title"
								onClick={(e) => {
									configs.estimatesOpened = !configs.estimatesOpened;
									setConfigs({ ...configs });
								}}
							>
								<div>{i18n("workflow.kanban.card.step.estimates")}</div>
								<Icon icon={configs.estimatesOpened ? 'faCaretDown' : 'faCaretRight'} />
							</div>
							{configs.estimatesOpened && (
								<div>
									<div className="task-cost">
										<div>{i18n("workflow.kanban.card.step.cost")}</div>
										<Input
											value={task.action.estimatedCost}
											type="formatted-number"
											isNumericString={true}
											onMouseDown={(e) => {
												e.stopPropagation();
											}}
											onChange={(e) => {
												task.action.estimatedCost = e.target.floatValue;
												saveTask();
												setState({});
											}}
										/>
										{/* {format.currency(task.action.estimatedCost || 0)} */}
									</div>
									<div className="task-time">
										<div>{i18n("workflow.kanban.card.step.effortInHours")}</div>
										<Input
											value={task.action.estimatedEffort}
											type="formatted-number"
											prefix={''}
											suffix={' horas'}
											onMouseDown={(e) => {
												e.stopPropagation();
											}}
											onChange={(e) => {
												task.action.estimatedEffort = e.target.floatValue;
												saveTask();
												setState({});
											}}
										/>
									</div>
									<div className="task-time">
										<div>{i18n("workflow.kanban.card.step.deliveryForecast")}</div>
										<Input
											value={task.action.estimatedDeliveryDate}
											type="date"
											onMouseDown={(e) => {
												e.stopPropagation();
											}}
											onChange={(e) => {
												let value = e.target.value;
												if (typeof value === 'date') {
													value = value.toISOString();
												}
												task.action.estimatedDeliveryDate = value;
												saveTask();
												setState({});
											}}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="task-checklist">
					<div
						className="task-checklist-header"
						onClick={(e) => {
							configs.checklistOpen = !configs.checklistOpen;
							setConfigs({ ...configs });
						}}
					>
						<div>Checklist</div>
						<Icon icon={configs.checklistOpen ? 'faCaretDown' : 'faCaretRight'} />
					</div>
					{configs.checklistOpen && (
						<div className="task-checklist-input">
							<Input
								label={i18n("workflow.kanban.card.step.nameItemAdd")}
								value={configs.actionName}
								onChange={(e) => {
									configs.actionName = e.target.value;
									setConfigs({ ...configs });
								}}
								onEnterPress={addChecklistAction}
							/>
							<div className="add-button" onClick={addChecklistAction}>
								<Icon icon="faPlus" />
							</div>
						</div>
					)}
					{!!checklist && configs.checklistOpen && (
						<div className="task-checklist-actions">
							{checklist.actions.map((action, i) => {
								return (
									<div className="checklist-action" key={i}>
										<FormControlLabel
											style={{ width: 'calc(100% - 0px)' }}
											label={action.name}
											className="checkbox-control"
											disabled={task.agent !== auth.user.uid}
											control={
												<Checkbox
													checked={action.finished}
													disabled={task.agent !== auth.user.uid}
													onChange={(e) => {
														action.finished = e.target.checked;
														saveTask();
														setState({});
													}}
												/>
											}
										/>
										{action.custom && (
											<div
												className="checklist-remove-action"
												onClick={(e) => {
													checklist.actions.splice(i, 1);
													setChecklist({ ...checklist });
													saveTask();
												}}
											>
												<Icon icon="faTrash" />
											</div>
										)}
									</div>
								);
							})}
						</div>
					)}
				</div>
				<div className="task-discussion">
					<div
						className="task-discussion-header"
						onClick={(e) => {
							configs.discussionOpened = !configs.discussionOpened;
							setConfigs({ ...configs });
						}}
					>
						<div>{i18n("workflow.kanban.card.step.discussion")}</div>
						<Icon icon={configs.discussionOpened ? 'faCaretDown' : 'faCaretRight'} />
					</div>
					{configs.discussionOpened && (
						<div className="task-discussion-input">
							<Input
								label={i18n("workflow.kanban.card.step.nameItemAdd")}
								value={configs.comment}
								multiline={true}
								onChange={(e) => {
									configs.comment = e.target.value;
									setConfigs({ ...configs });
								}}
								onEnterPress={addComment}
							/>
							<div className="add-button" onClick={addComment}>
								<Icon icon="faPlus" />
							</div>
						</div>
					)}
					{configs.discussionOpened && (
						<div className="discussion-content">
							{discussions.map((discussion) => {
								return (
									<div className="discussion">
										<div className="user">
											<div className="avatar">{discussion.user.name[0].toUpperCase()}</div>
											<div>
												<div className="name">{discussion.user.name}</div>
												<div className="email">{discussion.user.email}</div>
												<div className="date">{format.datetime(toDate(discussion.date))}</div>
											</div>
										</div>
										<div className="content">{discussion.comment}</div>
										{discussion.user.uid === auth.user.uid && (
											<div className="trash" onClick={removeComment(discussion.uid)}>
												<Icon icon="faTrash" />
											</div>
										)}
									</div>
								);
							})}
						</div>
					)}
				</div>
				<div className="task-responsible">
					<div className="avatar">{task.action.agentName[0]}</div>
					<div>
						{task.action.agentName}
						<div className="task-email">{task.action.agentEmail}</div>
					</div>
				</div>
				{task.action.hasFormReference && (
					<div>
						{keys && (
							<div style={{ border: 'thin solid #ddd', padding: 8, borderRadius: 8, marginTop: 8 }}>
								<p>Formulário Referenciado: {formName}</p>
								<div style={{ display: 'column' }}>
									{keys.map((k, i) => {
										return (
											<p
												style={{
													fontSize: 12,
													margin: 0,
													padding: '8px 4px',
													borderBottom: 'thin solid #ddd',
													borderRadius: '',
												}}
											>
												{Array.isArray(values[i])
													? convertValuesInTable(values[i])
													: `${k} : ${values[i]}`}
											</p>
										);
									})}
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default TaskCard;
