/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import firebase, { workfluxFirestore } from '@firebase-config';
import i18n, { format } from '@i18n';
import { store, services, history } from '@redux';
import { useSelector } from 'react-redux';
import { ExecutionFlow, ExecutedApproval, ExecutedDocument, ExecutedForm } from '@taugor/taugor-bpmn-models';
import { Button, TextField } from '@material-ui/core';
import Icon from '@custom/Icon';
import TaskApproval from './task.approval';
import TaskDocumentInput from './task.documentInput';
import TaskFormInput from './task.formInput';
import Expired from './task.expired';
import Unauthorized from '../Unauthorized';
import PhaseComments from '@custom/PhaseComments';

import icpbrasillogo from '@assets/images/icpbrasillogo.jpeg';
import Api from '../../Api';
import { IconButton } from '@mui/material';
import Message from '@custom/Message';
import ShareDocument from '@custom/ShareDocument';

import '@assets/sass/executionFlow.scss';
import ShareExecutionFlow from '../../@custom/ShareExecutionFlow';

const HISTORY_TYPE = {
	EXECUTION_START: 'execution.start',
	EXECUTION_CANCELED: 'execution.canceled',
	EXECUTION_END: 'execution.end',
	EXECUTION_VIEW: 'execution.view',
	EXECUTION_FORWARDED: 'execution.forwarded',
	APPROVAL_DONE: 'executed.approval',
	REPROVAL_DONE: 'executed.reproval',
	REPROVAL_FORBIDS_FORWARDING: 'executed.reproval.forbids.forwarding',
	DOCUMENT_UPLOADED: 'document.uploaded',
	FORM_FILLED: 'form.filled',
	TASK_EXPIRED: 'task.expired',
	TASK_OWNER_CHANGED: 'task.owner.changed',
	TASK_EXTERNAL_SHARE: 'external.share',
	COMPLEMENTARY_TASK: 'task.complementary.created',
	PHASE_JUMPED: 'execution.phase.jumped',

};

const oExecutionFlowService = new ExecutionFlow().getService(firebase, store, 'executionFlowView');
const oTaskService = workfluxFirestore.collection('task');
const executionFlowCollection = workfluxFirestore.collection("executionFlow");
const executedQueryUidCollection = workfluxFirestore.collection('executedQueryUid');
const oExecutedApprovalService = new ExecutedApproval().getService(firebase, store, 'executionFlowExecutedApprovalView');
const oExecutedDocumentService = new ExecutedDocument().getService(firebase, store, 'executionFlowExecutedDocumentView');
const oExecutedFormService = new ExecutedForm().getService(firebase, store, 'executionFlowExecutedFormView');

const ExecutionFlowView = ({ match, noTaskView, closeButton }) => {
	const auth = useSelector((s) => s.auth);
	const executionFlowStore = useSelector((s) => s.executionFlowView);
	const executedApprovalStore = useSelector((s) => s.executionFlowExecutedApprovalView);
	const executedDocumentStore = useSelector((s) => s.executionFlowExecutedDocumentView);
	const executedFormStore = useSelector((s) => s.executionFlowExecutedFormView);
	const [mobileFormVisible, setMobileFormVisible] = useState(!!match.params.task);
	const [executionFlow, setExecutionFlow] = useState(executionFlowStore.current);
	const [documentCount, setDocumentCount] = useState(0);
	const [cpfData, setCpfData] = useState({});
	const [showCommentsModal, setShowCommentsModal] = useState();
	const [commentModalProps, setCommentModalProps] = useState({});

	const unauthorized = executionFlow?.company !== auth.company.uid;
	const workflow = executionFlow?.workflowObject;
	const viewGroups = workflow?.viewGroups || [];
	const viewGroupUids = viewGroups.map(group => group.uid);
	const userGroupuids = auth.user.groups.map(group => group.uid);
	const participants = executionFlow?.participants || [];
	const isExecutionsAdmin = auth.user.companyadmin || auth.user.permissions.includes('workflux.executions.admin');
	const isExecutionsViewer = auth.user.companyadmin || auth.user.permissions.includes('workflux.executions.viewer');
	const canViewFlow = !!(
		(isExecutionsAdmin || isExecutionsViewer) &&
		match.params.id &&
		!unauthorized &&
		!!(
			auth.user.companyadmin ||
			auth.user.uid === executionFlow?.createdBy ||
			participants.includes(auth.user.uid) ||
			userGroupuids.some(groupUid => viewGroupUids.includes(groupUid))
		)
	);

	const selectedCommentsPhase = workflow?.phases.find(phase => phase.uid === commentModalProps.phaseUid);

	const getExecutor = async (uid) => {
		if (uid) {
			if (uid == 'system-automation') {
				return (executionFlow.executor = 'Automação do sistema');
			}
			const { data } = await Api.getUser(uid);
			executionFlow.executor = data.firstName + ' ' + data.lastName;
		}
	};

	useEffect(() => {
		setMobileFormVisible(!!match.params.task);
	}, [match.params.task]);

	useEffect(() => {
		setExecutionFlow(executionFlowStore.current);
	}, [executionFlowStore.current]);

	useEffect(() => {
		const executionFlowUid = match.params.id;
		if (!executionFlowUid || !auth || !auth.user) return;

		services.spinner.show();

		const removeSnapshot = executionFlowCollection.doc(executionFlowUid).onSnapshot(doc => {
			const flow = doc.data();
			if (!flow?.uid) return;

			setExecutionFlow({ ...flow });

			oExecutedApprovalService
				.filter([
					[
						['executionFlow', '==', flow.uid],
						['company', '==', auth.company.uid],
					],
				])
				.list(false);
			oExecutedDocumentService
				.filter([
					[
						['executionFlow', '==', flow.uid],
						['company', '==', auth.company.uid],
					],
				])
				.list(false);
			oExecutedFormService
				.filter([
					[
						['executionFlow', '==', flow.uid],
						['company', '==', auth.company.uid],
					],
				])
				.list(false);

			services.spinner.hide();
		});

		return () => removeSnapshot();
	}, [match.params.id]);

	useEffect(() => {
		if (!canViewFlow || !auth.user?.uid || !executionFlow?.uid) return;

		const fullName = `${auth.user.firstName} ${auth.user.lastName}`.replace(/\s+/g, " ").trim();

		const history = [
			...executionFlow.history,
			{
				type: HISTORY_TYPE.EXECUTION_VIEW,
				user: {
					uid: auth.user.uid,
					name: fullName,
					email: auth.user.email
				},
				date: new Date(),
			}
		];

		executionFlowCollection.doc(executionFlow.uid).update({ history });
	}, [canViewFlow, auth.user, executionFlow?.uid]);

	useEffect(() => {
		if (!executionFlow) return;
		getExecutor(executionFlow.createdBy);
		getQueryUid();
	}, [executionFlow]);

	const getQueryUid = async () => {
		if (!!executionFlow.uid) {
			const querySnapshot = await executedQueryUidCollection.where('uid', '==', executionFlow.uid).get();
			const dados = querySnapshot.docs;
			const listUsers = [];
			dados.forEach((doc) => {
				listUsers.push({
					...doc.data(),
					key: doc.id,
				});
			});
			setCpfData(listUsers[0]);
		}
	};

	if (!canViewFlow) {
		return <Unauthorized />;
	}

	if (!executionFlow || !executionFlow.uid) return <div></div>;
	let canceled = executionFlow.completed === 'canceled';

	const createGroupedExecutedActions = (store) => {
		let list = {};
		store.list
			.sort((a, b) => {
				if (a.createdAt.toDate() > b.createdAt.toDate()) return -1;
				if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
				return 0;
			})
			.map((item) => {
				if (!list[item.action.uid]) {
					list[item.action.uid] = {
						current: item,
						versions: [],
					};
				} else {
					list[item.action.uid].versions.push(item);
				}
			});
		return Object.values(list);
	};

	const handleOpenDocument = (document) => async () => {
		console.log("document.current", document.current);

		services.spinner.show();

		Api.executionFlow.document
			.open(document.current.uid)
			.then(result => result.data)
			.then(url => {
				return window.open(url.url);
			})
			.catch(err => console.error("Error downloading document", err))
			.finally(services.spinner.hide);
	};

	const handleSendDocument = (document) => () => {
		services.message.show(
			"Compartilhar documento",
			<ShareDocument document={document.current} />
		);
	};

	const handleShareExecutionFlow = () => {
		services.message.show(
			"Encaminhar",
			<ShareExecutionFlow executionFlow={executionFlow} />
		);

	};

	const renderVersions = (item) => {
		return item.versions.map((version, v) => (
			<div key={v} className="simple-list-item with-columns executed-item-version-box" style={{ padding: '2px 10px' }}>
				<div className={'document-version-descriptor'}>
					{version.action.isMultiple && (
						<div>
							<div className="line bold">{version.action.instructions}</div>
							{!!version.action.documentInputDescription && (
								<div className="line bold">{version.action.documentInputDescription}</div>
							)}
							<div className="line">{version.action.agentName}</div>
							<div className="line">{format.datetime(version.createdAt.toDate())}</div>
							{renderDigitalSigningInfo(version)}
						</div>
					)}
					{!version.action.isMultiple && (
						<div>
							{i18n('executionFlow.form.documents.version')}
							{item.versions.length - v}
						</div>
					)}
				</div>

				<div className="line" style={{ flex: 1 }}>
					{format.datetime(version.createdAt.toDate())}
				</div>
				{version.action.type.type === 'approval' && (
					<div className="line" style={{ flex: 1 }}>
						{i18n(`executionFlow.form.${version.approvedAt ? 'approvedTitle' : 'reprovedTitle'}`)}
					</div>
				)}
				{version.action.type.type === 'documentInput' && (
					<Button
						onClick={() => {
							window.open(version.location);
						}}
						style={{ padding: 2 }}
					>
						<Icon icon="faDownload" style={{ fontSize: 18 }} />
					</Button>
				)}
				{!!version.documents && !!version.documents.length && (
					<Button
						onClick={() => {
							window.open(version.documents[0].url);
						}}
						style={{ padding: 2 }}
					>
						<Icon icon="faFileDownload" style={{ fontSize: 18 }} />
					</Button>
				)}
			</div>
		));
	};

	const renderDigitalSigningInfo = (doc) => {
		return (
			!!doc.action.isSigningRequired && (
				<div
					className="line"
					style={{
						fontSize: 12,
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div>{i18n('executionFlow.form.digitallySignedDocument')}</div>
					<div style={{ flex: 1 }}></div>
					<img
						src={icpbrasillogo}
						style={{
							marginLeft: 10,
							height: 40,
							borderRadius: 10,
						}}
					/>
				</div>
			)
		);
	};
	const renderDocuments = () => {
		if (!executionFlow.documents.length) {
			if (documentCount != 0) {
				setDocumentCount(0);
			}
			return <div></div>;
		}
		let list = createGroupedExecutedActions(executedDocumentStore);
		let count = 0;

		for (let item of list) {
			count += item.versions.length + 1;
		}

		if (count != documentCount && list.length) {
			setDocumentCount(count);
		}

		return (
			<div className='executed-item-box documents'>
				{list.map((document, i) => (
					<div key={i}>
						<div key={i} className="simple-list-item with-columns">
							<div style={{ marginRight: 10, flex: 1 }}>
								<div className="line bold">{document.current.action.instructions}</div>
								{!!document.current.action.documentInputDescription && (
									<div className="line bold">{document.current.action.documentInputDescription}</div>
								)}
								<div className="line">{document.current.action.agentName}</div>
								<div className="line">{format.datetime(document.current.createdAt.toDate())}</div>
								{renderDigitalSigningInfo(document.current)}
							</div>
							<Button
								onClick={handleOpenDocument(document)}
							>
								<Icon icon="faDownload" style={{ fontSize: 18 }} />
							</Button>
							<Button
								onClick={handleSendDocument(document)}
							>
								<Icon icon="faPaperPlane" style={{ fontSize: 18 }} />
							</Button>
						</div>
						{renderVersions(document)}
					</div>
				))}
			</div>
		);
	};
	const renderForms = () => {
		if (!executionFlow.forms.length) {
			return <div>{i18n('executionFlow.form.noFormsAttached')}</div>;
		}
		let list = createGroupedExecutedActions(executedFormStore);
		return (
			<div className={'executed-item-box forms'}>
				{list.map((form, i) => {
					return (
						<div key={i}>
							<div className="simple-list-item with-columns">
								<div style={{ flex: 1 }}>
									<div className="line">{form.current.action.formTitle}</div>
									<div className="line">{form.current.action.agentName}</div>
									<div className="line">{format.datetime(form.current.createdAt.toDate())}</div>
									{renderDigitalSigningInfo(form.current)}
								</div>
								{!!form.current.documents && !!form.current.documents.length && (
									<div>
										<Button
											onClick={() => {
												window.open(form.current.documents[0].url);
											}}
										>
											<Icon icon="faFileDownload" style={{ fontSize: 18 }} />
										</Button>
									</div>
								)}
							</div>
							{renderVersions(form)}
						</div>
					);
				})}
			</div>
		);
	};
	const renderCpf = () => {
		if (cpfData.history === undefined) {
			return <div>{i18n('executionFlow.form.noCpfAttached')}</div>;
		}
		return (
			<div className={'executed-item-box cpf'}>
				{cpfData.history.map((form, i) => {
					if (form.acessName === 'HistoricalBasicData') {
						return (
							<div key={i}>
								<div className="simple-list-item with-columns">
									<div style={{ flex: 1 }}>
										<div className="line">Nome:{form.response.CurrentName}</div>
										<div className="line">Idade: {form.response.Age}</div>
										<div className="line">CPF: {form.response.TaxIdNumber}</div>
										<div className="line">{format.datetime(form.date.toDate())}</div>
									</div>
								</div>
							</div>
						);
					}

					if (form.acessName === 'RelatedPeople') {
						const relatives = (form.response.PersonalRelationships || []).filter(
							(person) => person.RelationshipLevel === 'DIRECT'
						);

						return relatives.map((relative) => (
							<div key={relative.RelatedEntityName}>
								<div className="simple-list-item with-columns">
									<div style={{ flex: 1 }}>
										<div className="line">Nome: {relative.RelatedEntityName}</div>
										<div className="line">
											Relação: {i18n(relative.RelationshipType?.toLowerCase() || 'Sem registro')}
										</div>
										<div className="line">CPF: {relative.RelatedEntityTaxIdNumber}</div>
										<div className="line">{format.datetime(new Date(relative.LastUpdateDate))}</div>
									</div>
								</div>
							</div>
						));
					}

					if (form.acessName === 'ProfessionData') {
						let ProfessionData = {
							CompanyName: 'Sem registro',
							Sector: 'Sem registro',
							CompanyIdNumber: 'Sem registro',
							CompanyName: 'Sem registro',
							Level: 'Sem registro',
							Status: 'Sem registro',
							StartDate: 'Sem registro',
							EndDate: 'Sem registro',
						};
						let checker = false;

						(form.response?.Professions || [])
							.sort((a, b) => {
								if (!a.EndDate || !b.EndDate) return 0;
								if (a.EndDate > b.EndDate) return -1;
								if (a.EndDate < b.EndDate) return 1;

								return 0;
							})
							.map((jobs, j) => {
								if (jobs.EndDate == '0001-01-01T00:00:00Z') {
									ProfessionData = {
										CompanyName: jobs.CompanyName,
										Sector: jobs.Sector,
										CompanyIdNumber: jobs.CompanyIdNumber,
										CompanyName: jobs.CompanyName,
										Level: jobs.Level,
										Status: jobs.Status,
										StartDate: jobs.StartDate,
										EndDate: jobs.EndDate,
									};
									checker = true;
								} else if (!checker) {
									ProfessionData = {
										CompanyName: jobs.CompanyName,
										Sector: jobs.Sector,
										CompanyIdNumber: jobs.CompanyIdNumber,
										CompanyName: jobs.CompanyName,
										Level: jobs.Level,
										Status: jobs.Status,
										StartDate: jobs.StartDate,
										EndDate: jobs.EndDate,
									};
									checker = true;
								}
							});
						return (
							<div key={i}>
								<div className="simple-list-item with-columns">
									<div style={{ flex: 1 }}>
										<div className="line">Setor: {ProfessionData.Sector}</div>
										<div className="line">CNPJ: {ProfessionData.CompanyIdNumber}</div>
										<div className="line">Nome da empresa: {ProfessionData.CompanyName}</div>
										<div className="line">Nivel: {ProfessionData.Level}</div>
										<div className="line">Estado: {ProfessionData.Status}</div>
										<div className="line">Data de início: {ProfessionData.StartDate}</div>
										<div className="line">Data de saída: {form.response.EndDate}</div>
										<div className="line">Estado: {ProfessionData.Status}</div>
										<div className="line">CPF: {form.cpf}</div>
										<div className="line">{format.datetime(form.date.toDate())}</div>
									</div>
								</div>
							</div>
						);
					}
					if (form.acessName === 'Scholarship') {
						return (
							<div key={i}>
								<div className="simple-list-item with-columns">
									<div style={{ flex: 1 }}>
										<div className="line">
											Instituição: {form.response.ScholarshipHistory[0].Institution || 'Sem informação'}
										</div>
										<div className="line">
											Área de Especialização:{' '}
											{form.response.ScholarshipHistory[0].SpecializationArea || 'Sem informação'}
										</div>
										<div className="line">
											Graduação:{' '}
											{form.response.ScholarshipHistory[0].AcademicTrainingType || 'Sem informação'}
										</div>
										<div className="line">Nivel escolar: {form.response.ScholarshipLevel}</div>
										<div className="line">CPF: {form.cpf}</div>
										<div className="line">{format.datetime(form.date.toDate())}</div>
									</div>
								</div>
							</div>
						);
					}

					return <></>;
				})}
			</div>
		);
	};
	const renderApprovals = () => {
		if (!executionFlow.approvals.length) {
			return <div>{i18n('executionFlow.form.noApprovalsAttached')}</div>;
		}
		let list = createGroupedExecutedActions(executedApprovalStore);
		return (
			<div className={'executed-item-box approvals'}>
				{list.map((approval, i) => {
					return (
						<div key={i}>
							<div className="simple-list-item with-columns">
								<div style={{ flex: 1 }}>
									<div className="line">{history.instructions}</div>
									<div className="line bold">
										{i18n(
											`executionFlow.form.${approval.current.approvedAt ? 'approvedTitle' : 'reprovedTitle'
											}`
										)}
									</div>
									<div className="line">{approval.current.action.agentName}</div>
									<div className="line">
										{approval.current.approvedAt
											? approval.current.approvalReason
											: approval.current.reprovalReason}
									</div>
									<div className="line">
										{format.datetime((approval.current.approvedAt || approval.current.reprovedAt).toDate())}
									</div>
									{renderDigitalSigningInfo(approval.current)}
								</div>
								{!!approval.current.documents && !!approval.current.documents.length && (
									<div>
										<Button
											onClick={() => {
												window.open(approval.current.documents[0].url);
											}}
										>
											<Icon icon="faFileDownload" style={{ fontSize: 18 }} />
										</Button>
									</div>
								)}
							</div>
							{renderVersions(approval)}
						</div>
					);
				})}
			</div>
		);
	};
	const renderHistory = () => {
		if (!executionFlow.history.length) {
			return <div>{i18n('executionFlow.form.noHistoryYet')}</div>;
		}

		return (
			<div className={'executed-item-box history'}>
				{executionFlow.history
					.sort((a, b) => {
						if (!a.date.toDate) {
							a.date = {
								toDate: () => new Date(),
							};
						}
						if (!b.date.toDate) {
							b.date = {
								toDate: () => new Date(),
							};
						}
						if (a.date.toDate() > b.date.toDate()) return 1;
						if (a.date.toDate() < b.date.toDate()) return -1;
						return 0;
					})
					.map((history, i) => {
						switch (history.type) {
							case HISTORY_TYPE.PHASE_JUMPED:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line break">{history.phaseName}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.TASK_EXTERNAL_SHARE:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line break">{history.media}</div>
										<div className="line break">{history.url}</div>
										<div className="line">{history.agentName}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.TASK_OWNER_CHANGED:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line">{history.taskDescription}</div>
										<div className="line">
											{history.oldAgent.agentName}
											{' -> '}
											{history.newAgent.agentName}
										</div>
										<div className="line">Modificado por: {history.userName}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.TASK_EXPIRED:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line">{history.agentName}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.EXECUTION_CANCELED:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.EXECUTION_START:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line">{history.agentName}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.EXECUTION_END:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.APPROVAL_DONE:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line">{history.instructions}</div>
										<div className="line">{history.agentName}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.REPROVAL_DONE:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line">{history.instructions}</div>
										<div className="line">{history.agentName}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.REPROVAL_FORBIDS_FORWARDING:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line">{history.instructions}</div>
										<div className="line">{history.agentName}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.COMPLEMENTARY_TASK:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line">{history.agentName}</div>
										<div className="line">
											{i18n('workflow.form.actionTypes.' + history.actionType + '.name')}
										</div>

										<div className="line">{history.description}</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);

							case HISTORY_TYPE.DOCUMENT_UPLOADED:
								return (
									<div key={i} className="simple-list-item with-columns">
										<div style={{ marginRight: 10, flex: 1 }}>
											<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
											<div className="line">{history.instructions}</div>
											<div className="line">{history.agentName}</div>
											{history.local && (
												<div className="line break">
													{history.local.city}, {history.local.region}, {history.local.country_code}
													({history.local.latitude}º O {" "} {history.local.longitude}º S)
												</div>
											)}
											<div className="line">{format.datetime(history.date.toDate())}</div>
										</div>
										<Button
											onClick={() => {
												window.open(history.location);
											}}
										>
											<Icon icon="faFileDownload" style={{ fontSize: 18 }} />
										</Button>
									</div>
								);
							case HISTORY_TYPE.FORM_FILLED:
								return (
									<div key={i} className="simple-list-item with-columns">
										<div style={{ marginRight: 10, flex: 1 }}>
											<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
											<div className="line">{history.formTitle}</div>
											<div className="line">{history.agentName}</div>
											{history.local && (
												<div className="line break">
													{history.local.city}, {history.local.region}, {history.local.country_code}
													({history.local.latitude}º O {" "} {history.local.longitude}º S)
												</div>
											)}
											<div className="line">{format.datetime(history.date.toDate())}</div>
										</div>
									</div>
								);
							case HISTORY_TYPE.EXECUTION_VIEW:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line break">{history.user.name} ({history.user.email})</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							case HISTORY_TYPE.EXECUTION_FORWARDED:
								return (
									<div key={i} className="simple-list-item">
										<div className="line bold">{i18n('executionFlow.form.history.' + history.type)}</div>
										<div className="line break">{`${executionFlow.managementProtocolCode}-ENC-${history.code}`}</div>
										<div className="line break">{history.user.name} ({history.user.email})</div>
										{history.local && (
											<div className="line break">
												{history.local.city}, {history.local.region}, {history.local.country_code}
												({history.local.latitude}º O {" "} {history.local.longitude}º S)
											</div>
										)}
										<div className="line">{format.datetime(history.date.toDate())}</div>
									</div>
								);
							default:
								return <></>;
						}
					})}
			</div>
		);
	};

	const renderTaskForm = () => {
		if (!match.params.task) return <div></div>;
		if (executionFlow.workflowObject.experation?.isExpired == true) {
			return (
				<Expired
					setMobileFormVisible={setMobileFormVisible}
					taskId={match.params.task}
					executionFlow={executionFlow}
				/>
			);
		}

		switch (match.params.type) {
			case 'approval':
				return <TaskApproval setMobileFormVisible={setMobileFormVisible} taskId={match.params.task} />;
			case 'documentInput':
				return <TaskDocumentInput setMobileFormVisible={setMobileFormVisible} taskId={match.params.task} />;
			case 'formInput':
				return <TaskFormInput setMobileFormVisible={setMobileFormVisible} taskId={match.params.task} />;
		}
	};

	let currentPhaseFound = false;
	const cancel = () => {
		executionFlow.cancelMessage = '';
		services.message.show(
			i18n('cancel.executionFlow'),
			<div>
				<div style={{ paddingBotton: 10, marginBottom: 10 }}>{i18n('confirm.action')}</div>
				<div>
					<TextField
						variant="outlined"
						style={{ width: '100%', marginBottom: 10 }}
						multiline
						id="campo-obrigatorio-cancel"
						// error
						helperText=" "
						rows={4}
						label={i18n('executionFlow.view.cancel')}
						onChange={(e) => {
							executionFlow.cancelMessage = e.target.value;
						}}
						onEnterPress={(e) => {
							executionFlow.cancelMessage = e.target.value;
						}}
					/>
				</div>
				<div className="buttons">
					<simple-button
						save
						onClick={(e) => {
							if (!executionFlow.cancelMessage) {
								document.getElementById('campo-obrigatorio-cancel-helper-text').innerHTML = 'Campo Obrigatório';
								document.getElementById('campo-obrigatorio-cancel-helper-text').style.color = 'red';
							} else {
								services.message.hide();
								services.spinner.show();
								Api.executionFlow
									.cancel({
										id: executionFlow.uid,
										cancelMessage: executionFlow.cancelMessage,
									})
									.then(services.spinner.hide)
									.finally(services.message.hide);
							}
						}}
					>
						{i18n("confirm")}
					</simple-button>
					<simple-button clear onClick={services.message.hide}>
						{i18n("cancel")}
					</simple-button>
				</div>
			</div>
		);
	};

	const toggleHistory = (e) => {
		let panel = document.getElementById(match.params.task ? 'list-panel' : 'custom-form-panel');
		let history = document.getElementById('micro-panel');
		let toggler = document.getElementById('history-toggler');

		if (!history) return;
		if (panel.classList.contains('history-visible')) {
			panel.classList.remove('history-visible');
			history.classList.remove('visible');
			toggler.classList.remove('visible');
		} else {
			panel.classList.add('history-visible');
			history.classList.add('visible');
			toggler.classList.add('visible');
		}
	};

	const generatePdfDossier = async () => {
		services.spinner.show();
		try {
			const result = executionFlow.documents;

			for (const document of result) {
				await Api.indexia.convertedPDFtoPNG(document.executedDocument);
			}

			const { data } = await Api.executionFlow.createDossier(executionFlow);
			window.open(data, '_blank');
		} catch (error) {
			console.log(error);
			services.toast.error('Um erro ocorreu ao gerar o dossiê');
		} finally {
			services.spinner.hide();
		}
	};

	const completeExecutionFlowManually = async () => {
		await executionFlowCollection.doc(executionFlow.uid).update({
			completed: new Date(),
			currentPhase: '',
			history: [...executionFlow.history, {
				type: HISTORY_TYPE.EXECUTION_END,
				date: new Date()
			}]
		});
	};

	const handleOpenPhaseComments = (phase) => () => {
		setShowCommentsModal(true);
		setCommentModalProps({
			phaseUid: phase.uid
		});
	};

	const renderListPanel = () => (
		<div id={match.params.task ? 'list-panel' : 'custom-form-panel'}>
			<div className="info-field">
				<div className="info-field-header">
					<h3>
						{i18n('executionFlow.form.title')}
						{!canceled && (executionFlow.agent === auth.user.uid || auth.user.companyadmin) && (
							<div className="columns cancel-flow">
								<div className="simple-button" onClick={cancel}>
									<Icon icon="faBan" />
									{i18n("cancel")}
								</div>
							</div>
						)}

						{executionFlow.completed === 'not-completed' ? (
							<>
								<Button variant="contained" onClick={handleShareExecutionFlow}>Encaminhar fluxo</Button>
								{executionFlow.workflowObject.completeManually && !executionFlow.currentPhase && (
									<Button variant="contained" onClick={completeExecutionFlowManually}>Finalizar fluxo</Button>
								)}
							</>
						) : (
							<Button variant="contained" onClick={generatePdfDossier}>Gerar Dossiê</Button>
						)}
					</h3>

					{canceled && (
						<div className="content-title canceled">
							<Icon icon="faBan" />
							<span>{i18n('executionFlow.form.flowCanceled')}</span>
						</div>
					)}
					{closeButton && (
						<Button
							variant="contained"
							onClick={() => {
								closeButton();
							}}
						>
							<Icon icon="faTimes" />
							{i18n("toClose")}
						</Button>
					)}
				</div>
				<label className="content-title">
					<Icon icon="faSitemap" />
					{executionFlow?.workflowObject.document.title}
				</label>
				<div className="content-subtitle">
					<div className="info">
						<span>{i18n("flowUid")}:</span>
						{executionFlow?.uid}
					</div>
					<div className="info">
						<span>{i18n('executionFLow.form.createdAt')}:</span>
						{format.datetime(executionFlow?.createdAt.toDate())}
					</div>
					<div className="info">
						<span>{i18n('companyArea.form.screenTitle')}:</span>
						{executionFlow?.workflowObject.divisionObject.name}
					</div>
					<div className="info">
						<span>{i18n("flowDescription")}:</span>
						{executionFlow?.workflowObject.description}
					</div>
					<div className="info">
						<span>{i18n('executionFlow.form.agent')}:</span>
						{executionFlow?.agentObject?.firstName} {executionFlow?.agentObject.lastName}
					</div>
					<div className="info">
						<span>{i18n("runBy")}:</span>
						{executionFlow?.executor}
					</div>
					<div className="info">
						<span>{i18n("companyArea.form.item.managementProtocol")}:</span>
						{executionFlow?.managementProtocolCode}
					</div>
					<div className="info">
						<span>{i18n("companyArea.form.item.automaticCompletion")}:</span>
						{!workflow?.completeManually ? "Sim" : "Não"}
					</div>
					<div className="info">
						<span>{i18n("companyArea.form.item.isUrgent")}:</span>
						{workflow?.urgent ? "Sim" : "Não"}
					</div>
					{viewGroups.length > 0 && (
						<div className="info">
							<span>{i18n("companyArea.form.item.viewGroups")}:</span>
							{viewGroups.map(group => group.name).join(", ")}
						</div>
					)}
					{executionFlow?.workflowObject?.experation?.doesExpire && (
						<div style={{ color: '#666', marginBottom: 10 }}>
							{i18n('task.form.taskWillExpire', {
								date: format.date(
									new Date(executionFlow?.workflowObject?.experation?.expirationDateString + ' 12:00')
								),
							})}
						</div>
					)}
				</div>
			</div>
			<div className="phases-content info-field">
				<label className="content-title">
					<Icon icon="faStream" />
					<div className="main">{i18n('executionFlow.form.currentPhase')}</div>
					<div className="chip-status chip-status-none">{i18n("notDistributed")}</div>
					<div className="chip-status chip-status-completed">{i18n("executionFlow.form.task.status.completed")}</div>
					<div className="chip-status chip-status-pending">{i18n("executionFlow.form.task.status.pending")}</div>
					<div className="chip-status chip-status-review">{i18n("executionFlow.form.task.status.review")}</div>
					<div className="chip-status chip-status-waiting-review">{i18n("awaitingReturn")}</div>
				</label>
				<div className="phases">
					{executionFlow.workflowObject.phases
						.filter((m) => !m.deleted)
						.map((phase, i) => {
							if (!currentPhaseFound) {
								currentPhaseFound = phase.uid === executionFlow.currentPhase;
							}
							return (
								<div
									key={i}
									className={`phase ${!currentPhaseFound ? 'past-phase' : ''} 
										${phase.uid === executionFlow.currentPhase ? 'current' : ''}
										${phase.completed && !currentPhaseFound ? 'phase-completed' : ''}`}
								>
									{phase.completed && !currentPhaseFound && (
										<div className="completed-icon">
											<Icon icon="faCheckCircle" />
										</div>
									)}
									{phase.uid === executionFlow.currentPhase && (
										<div className="current-icon">
											<Icon icon="faArrowAltCircleDown" />
										</div>
									)}
									<div className="title">{phase.name}</div>
									{phase.actions.map((action, a) => {
										if (action.deleted) return <div key={a}></div>;
										if (executionFlow.workflowObject.experation?.isExpired)
											action.type.type = 'expired';
										return (
											<div
												key={a}
												className={`action 
													${action.type.type}
													status-${action.status || (!!action.agent ? 'pending' : 'none')}
													${action.task ? 'clickable' : ''}
													${action.complementary ? 'complementary' : ''}
												`}
												onClick={() => {
													if (!action.task) return;
													setMobileFormVisible(true);
													if (history.location.pathname === '/workflow/list') {
														window.open(
															`/executionFlow/${executionFlow.uid}/task/${action.type.type}/${action.task}`
														);
													} else {
														history.push(
															`/executionFlow/${executionFlow.uid}/task/${action.type.type}/${action.task}`
														);
													}
												}}
											>
												<div className={'title'}>{action.instructions || action.formTitle}</div>
											</div>
										);
									})}

									{workflow?.allowComments && (
										<div className='comments'>
											<IconButton onClick={handleOpenPhaseComments(phase)}>
												<Icon icon="faComments" />
											</IconButton>
										</div>
									)}
								</div>
							);
						})}
				</div>
			</div>

			{cpfData && (
				<div className="info-field">
					<label className="content-title">
						<Icon icon="faIdCard" />
						{i18n('executionFlow.form.cpf')}
					</label>
					{renderCpf()}
				</div>
			)}

			<div className="info-field">
				<label className="content-title">
					<Icon icon="faFolderOpen" />
					{i18n('executionFlow.form.documents')}
				</label>
				<div className="content-subtitle">
					<div className="info">
						<span>{i18n('executionFlow.form.documentLength')}:</span>
						{documentCount}
					</div>
				</div>

				{renderDocuments()}
			</div>
			<div className="info-field">
				<label className="content-title">
					<Icon icon="faFileAlt" />
					{i18n('executionFlow.form.forms')}
				</label>
				{renderForms()}
			</div>
			<div className="info-field">
				<label className="content-title">
					<Icon icon="faClipboardCheck" />
					{i18n('executionFlow.form.approvals')}
				</label>
				{renderApprovals()}
			</div>
		</div>
	);

	if (noTaskView) {
		return renderListPanel();
	}

	return (
		<content className="executionflow">
			<div className="entity-management">
				<div className="history-toggler" id="history-toggler" onClick={toggleHistory}>
					<Icon icon="faChevronLeft" />
				</div>
				<div id="micro-panel">
					<div className="info-field">
						<label className="content-title">
							<Icon icon="faHistory" />
							{i18n('executionFlow.form.history')}
						</label>
						{renderHistory()}
					</div>
				</div>
				{renderListPanel()}
				<div id="form-panel" className={`${mobileFormVisible ? 'visible' : ''}`}>
					{renderTaskForm()}
				</div>
			</div>
			<Message
				visible={showCommentsModal}
				hide={() => setShowCommentsModal(false)}
				title="Comentários"
				description={
					<PhaseComments
						executionFlow={executionFlow}
						phase={selectedCommentsPhase}
					/>
				}
			/>
		</content>
	);
};

export default ExecutionFlowView;
