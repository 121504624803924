import { Auth, Company } from '@taugor/taugor-appcenter-sdk-js';
import spinner from './spinner';
const SET_GROUP_LIST = 'COMPANY_GROUPS_SET_GROUP_LIST';

Auth.token.current();
const service = (dispatch) => ({
	getGroups: ({ company }) => {
		//('HERE');
		new Company().groups.list({ company }).then((r) => {
			// //('GROUPS FOUND FOR', company, r);
			dispatch({
				type: SET_GROUP_LIST,
				list: r,
			});
		});
	},
	clearGroups: () => {
		dispatch({
			type: SET_GROUP_LIST,
			list: [],
		});
	},
});

const INIT_STATE = {
	groups: [],
};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SET_GROUP_LIST:
			return {
				...state,
				groups: action.list,
			};

		default:
			return { ...state };
	}
};
export default { reducer, service };
