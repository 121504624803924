import React, { useEffect, useState } from 'react';
import firebase, { workfluxFirestore } from '@firebase-config';
import { connect } from 'react-redux';
import { services } from '@redux';
import { workfluxMainFirebase as admin } from 'zerocode';
import Unauthorized from './Unauthorized';
import Input from '@custom/Input';
import md5 from '@custom/md5';
import TaskApproval from './executionFlow/task.approval';
import TaskDocumentInput from './executionFlow/task.documentInput';
import TaskFormInput from './executionFlow/task.formInput';
import '@assets/sass/external.scss';

const taskCollection = workfluxFirestore.collection('task');
const externalUserCollection = workfluxFirestore.collection('externalUser');

const ExternalAction = ({ auth, match }) => {
	const [mobileFormVisible, setMobileFormVisible] = useState(false);
	const [task, setTask] = useState({});
	const [user, setUser] = useState({ email: '', name: '', password: '', uid: '' });
	const [showName, setShowName] = useState(false);

	useEffect(() => {
		firebase.auth().onAuthStateChanged(function (u) {
			//('TEST', u);
			if (!u || !match.params.token) {
				return;
			}

			// taskCollection = workfluxFirestore.collection('task');
			taskCollection
				.where('action.externalShareToken', '==', match.params.token)
				.where('deleted', '==', false)
				// .where('status', 'in', ['pending', 'review'])
				.get()
				.then((snapshot) => {
					//('HERE', snapshot.docs);
					if (!snapshot.docs.length) {
						setTask({});
						return;
					}
					setTask(snapshot.docs[0].data());
				});
		});
	}, []);

	if (!match.params.type || !match.params.token) {
		return <Unauthorized />;
	}

	const onChangeUser = (prop) => (e) => {
		setUser({
			...user,
			[prop]: e.target.value,
		});
	};

	const login = () => {
		if (!user.email || !user.password) return;
		try {
			externalUserCollection
				.where('email', '==', user.email.toLowerCase().trim())
				.get()
				.then((snapshot) => {
					if (!snapshot.docs.length) {
						setShowName(true);
						return;
					}
					let pass = md5(user.password);
					let data = snapshot.docs[0].data();
					if (pass === data.password) {
						setUser(data);
						services.auth.setExternalHeader(JSON.stringify({ ...user, password: '', currentCompany: task.company }));
						firebase.auth().signInWithEmailAndPassword(user.email, user.password);
					} else {
						services.message.show(
							'Autenticação Falhou',
							'Já existe uma conta com este email, mas está senha não está correta. Por favor, verifique.'
						);
					}
				});
		} catch (error) {
			console.log('E', error);
		}
	};

	const createAccount = () => {
		let hasError = false;
		let doc = externalUserCollection.doc();
		user.uid = doc.id;
		let originalPassword = user.password;
		user.password = md5(user.password);

		services.spinner.show();
		firebase
			.auth()
			.createUserWithEmailAndPassword(user.email, originalPassword)
			.catch((e) => {
				hasError = true;
				console.log('e create count', e);
				services.spinner.hide();

				services.message.show('Cadastro Falhou', e.message);

				return;
			})
			.then((r) => {
				if (!hasError) {
					doc.set(user).then((r) => {
						setUser({ ...user });
						services.auth.setExternalHeader(JSON.stringify({ ...user, password: '', currentCompany: task.company }));

						services.spinner.hide();
					});
				}
			});
	};

	const renderTaskForm = () => {
		if (!task.uid) return <div>Sem task</div>;
		switch (match.params.type) {
			case 'approval':
				return <TaskApproval setMobileFormVisible={setMobileFormVisible} taskId={task.uid} external={true} />;
				break;
			case 'documentInput':
				return <TaskDocumentInput setMobileFormVisible={setMobileFormVisible} taskId={task.uid} external={true} />;
				break;
			case 'formInput':
				return <TaskFormInput setMobileFormVisible={setMobileFormVisible} taskId={task.uid} external={true} />;
				break;
		}
	};

	if (!user.uid) {
		return (
			<content className="external-action">
				<div className="auth-login">
					<div className="form">
						<div className="message">
							Precisamos de seu email e uma senha para acesso a ação compartilhada com você.
							<br />
							Caso já possua uma conta, pode utilizar seu email e senha normalmente
						</div>
						<Input
							disabled={!!showName}
							value={user.email}
							onChange={onChangeUser('email')}
							label="Email"
							mode="outlined"
						/>
						<Input value={user.password} onChange={onChangeUser('password')} label="Senha" mode="outlined " />
						{showName && <div className="message">Informe seu nome, por favor</div>}
						{showName && (
							<Input value={user.name} onChange={onChangeUser('name')} label="Nome" mode="outlined top-label" />
						)}
						<div className="buttons">
							{!showName && (
								<simple-button save className="login" onClick={login}>
									Entrar
								</simple-button>
							)}
							{showName && (
								<simple-button className="login" onClick={createAccount}>
									Criar Conta
								</simple-button>
							)}
						</div>
					</div>
				</div>
			</content>
		);
	}
	return (
		<content className="external-action">
			<div className="space-panel"></div>
			<div className="main-panel">{renderTaskForm()}</div>
			<div className="space-panel"></div>
		</content>
	);
};
export default connect(({ }) => ({}))(ExternalAction);
