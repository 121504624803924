import React from 'react';
import i18n from '@i18n';
import Icon from '@custom/Icon';
import InputMask from 'react-input-mask';

const Input = ({
	label,
	value,
	onChange,
	onEnterPress,
	type,
	mode,
	containerStyle,
	inputStyle,
	labelStyle,
	leftIcon,
	rightIcon,
	leftIconClick,
	rightIconClick,
	message,
	mask,
	maskPlaceholder,
	alwaysShowMask,
	beforeMaskedStateChange,
	multiline,
	disabled,
	max,
	min,
}) => {
	let finalLabel = i18n(label) || label;

	return (
		<div
			className={`form-control-input ${mode} 
						type-${type}
						${multiline ? 'multiline' : ''}
							${leftIcon ? 'has-left-icon' : ''} 
							${rightIcon ? 'has-right-icon' : ''}`}
			style={containerStyle}
		>
			<div className="content">
				{!!leftIcon && (
					<div className="left-icon icon" onClick={leftIconClick}>
						<Icon icon={leftIcon} />
					</div>
				)}
				{!!mask && (
					<InputMask
						disabled={!!disabled}
						mask={mask}
						maskPlaceholder={maskPlaceholder}
						alwaysShowMask={alwaysShowMask}
						beforeMaskedStateChange={beforeMaskedStateChange}
						value={value}
						onChange={onChange}
						placeholder=" "
						style={inputStyle}
					/>
				)}
				{!mask && !multiline && (
					<input
						max={max}
						min={min}
						disabled={!!disabled}
						value={value}
						onChange={onChange}
						placeholder=" "
						style={inputStyle}
						type={type}
						onKeyPress={(e) => {
							let code = e.key || e.code || e.keyCode || e.which || e.charCode;
							if (code == 13 || code === 'Enter') {
								if (typeof onEnterPress === 'function') onEnterPress(e);
							}
						}}
					/>
				)}
				{multiline && (
					<textarea
						disabled={!!disabled}
						placeholder=" "
						style={inputStyle}
						value={value}
						onChange={onChange}
					></textarea>
				)}
				<label style={labelStyle}>{finalLabel}</label>
				{!!rightIcon &&
					(rightIcon instanceof Array ? (
						rightIcon.map((ri, rii) => {
							return (
								<div key={rii} className={`right-icon icon icon-${rii}`} onClick={rightIconClick[rii]}>
									<Icon icon={ri} />
								</div>
							);
						})
					) : (
						<div className="right-icon icon" onClick={rightIconClick}>
							<Icon icon={rightIcon} />
						</div>
					))}
			</div>
			{!!message && <div className="message">{message}</div>}
		</div>
	);
};

export default Input;
