const SHOW_MESSAGE = 'MESSAGE_SHOW';
const HIDE_MESSAGE = 'MESSAGE_HIDE';

const service = (dispatch) => ({
	show: (title, description, buttons, onHide) => {
		dispatch({
			type: SHOW_MESSAGE,
			title,
			description,
			buttons,
			hide: onHide
		});
	},
	hide: () => {
		dispatch({
			type: HIDE_MESSAGE,
		});
	},
});

const INIT_STATE = {
	visible: false,
	title: '',
	description: '',
	buttons: [],
};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SHOW_MESSAGE:
			return {
				...state,
				visible: true,
				title: action.title,
				description: action.description,
				buttons: action.buttons,
				hide: action.hide
			};

		case HIDE_MESSAGE:
			return { ...state, visible: false };

		default:
			return { ...state };
	}
};

export default { reducer, service };
