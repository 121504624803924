import React, { useState, useEffect } from 'react';
// import firebase from '@firebase-config';
import i18n from '@i18n';
import { store as zerocodeStore } from 'zerocode';

import { TextField, List, ListItem, ListItemText, ListItemIcon, MenuItem, Button } from '@material-ui/core';

import { getFieldListFromString } from '@custom/common';

import { services } from '@redux';

import Icon from '@custom/Icon';

export const operationHandler = {
	isDraggingAction: false,
};

export const creatorGroup = {
	uid: 'process-creator-group',
	name: i18n("flowCreatorActivity"),
	description: '',
};
export const responsibleGroup = {
	uid: 'process-responsible-group',
	name: i18n("activityOfThePersonResponsibleForTheFlow"),
	description: '',
};
export const specificGroup = {
	uid: 'process-specific-group',
	name: i18n("selectSpecificResponsible"),
	description: '',
	group: '',
};

export const phaseGroup = {
	uid: 'process-specific-group-user',
	name: i18n("selectGroupResponsibleforPhase"),
	description: '',
	group: '',
};

function RenderGroup(group, i, newArray, selectGroup, state, setGroup) {
	const [isVisible, setIsVisible] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	return (
		<>
			<ListItem key={i} className="group-list-item-2" onClick={() => setIsVisible(!isVisible)}>
				<ListItemText primary={group.name} />
				<ListItemIcon className="group-list-icon">
					<Icon icon={isVisible ? 'faChevronDown' : 'faChevronUp'} />
				</ListItemIcon>
			</ListItem>
			<div id={`field-${i}`} className={isVisible ? '' + 'all group-list-item-3' : 'invisible-group'}>
				<TextField
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					label="Digite o nome do Membro"
					style={isVisible ? {} : { diplay: 'none' }}
				/>
			</div>
			{group.members
				.filter((member) => {
					let memberName = newArray.find((name) => member === name.uid) || {
						firstName: 'Nome não Informado',
						lastName: '',
					};

					let fullName = `${memberName.firstName} ${memberName.lastName}`;

					return fullName.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1;
				})
				.map((member, id) => {
					let newGroupMember = group.members.find((v) => {
						return v === member;
					});

					let fullName = newArray.find((name) => member === name.uid);

					let newGroupOneMember = fullName
						? { ...group, members: [newGroupMember], agent: member, agentObject: fullName }
						: {};
					return (
						<div id={`member-${i}`} className={isVisible ? '' + 'all' : 'invisible-group'}>
							{fullName && (
								<ListItem
									key={id}
									className="group-list-item-3"
									onClick={selectGroup(newGroupOneMember, fullName)}
								>
									<ListItemText primary={`${fullName.firstName} ${fullName.lastName}`} />
									<ListItemIcon className="group-list-icon">
										<Icon icon="faChevronRight" />
									</ListItemIcon>
								</ListItem>
							)}
						</div>
					);
				})}
		</>
	);
}

function RenderPhase(workflow, currentPhase, inputUserGroup, phase, i) {
	const [isVisible, setIsVisible] = useState(false);

	const index = workflow.phases.findIndex(object => {
		return object.uid === currentPhase.uid;
	});

	if (phase.uid === currentPhase.uid) {
		if (i == 0) {
			return <div className="group-list-item-2"><b>{i18n('workflow.form.actions.noPreviousPhase')}</b></div>
		}
	}

	if (index > i) {
		return phase.actions.map((action, a) => {
			let fields = getFieldListFromString(action)
			if (action.type.type !== 'formInput') return;
			if (action.deleted) return;
			return (
				<>
					<ListItem key={a} className="group-list-item-2" onClick={() => setIsVisible(!isVisible)}>
						<ListItemText primary={phase.name + " " + action.formTitle}
						/>
						<ListItemIcon className="group-list-icon">
							<Icon icon={isVisible ? 'faChevronDown' : 'faChevronUp'} />
						</ListItemIcon>
					</ListItem>
					<div id={`member-${i}`} className={isVisible ? '' + 'all' : 'invisible-group'}
						style={isVisible ? {} : { diplay: 'none' }}
					>
						{fields.map((fieldConfig, b) => {
							let type = fieldConfig.type;
							if (type === 'user' || type === 'systemUser') {
								const c = fields.findIndex(object => {
									return object.name === fieldConfig.name;
								});
								return (
									<div>
										<ListItem
											className="group-list-item-3"
											onClick={inputUserGroup(phase.name, i18n('workflow.form.actions.userAction'), action, c, "user")}
										>
											<ListItemText primary={`${fieldConfig.name}`}
											/>
											<ListItemIcon className="group-list-icon">
												<Icon icon="faChevronRight" />
											</ListItemIcon>
										</ListItem>
									</div>
								);
							}
							if (type === 'group' || type === 'systemGroup') {
								const c = fields.findIndex(object => {
									return object.name === fieldConfig.name;
								});
								return (
									<div>
										<ListItem
											className="group-list-item-3"
											onClick={inputUserGroup(phase.name, i18n('workflow.form.actions.groupAction'), action, c, "group")}
										>
											<ListItemText primary={`${fieldConfig.name}`} />
											<ListItemIcon className="group-list-icon">
												<Icon icon="faChevronRight" />
											</ListItemIcon>
										</ListItem>
									</div>
								);
							}
						})}
					</div>
				</>
			);
		}
		);
	}
	// }
	// }
	// 		})}
	// 	</div>
	// );
}

function Groups({ selectGroup, company, hideResponsible, newArray, appcenterStore, hideCreator, hideSpecific, workflow, currentPhase, hidePhase, inputUserGroup }) {
	// const [ groupState, setGroupState ] = useState({});
	// inputUserGroup('lala')
	// function setGroup(uid, state){
	// 	let newState = groupState;

	// 	newState[uid] = state;

	// 	setGroupState(newState);
	// }

	// useEffect(() => {
	// 	let groupStateTemplate = {}

	// 	for (let group of appcenterStore.groups){
	// 		groupStateTemplate[group.uid] = {
	// 			searchTerm: '',
	// 			isVisible: false
	// 		}
	// 	}

	// 	setGroupState(groupStateTemplate);
	// }, [])
	return (
		<div>
			<div>
				<List className="group-list">
					{!hideCreator && (
						<ListItem className="group-list-item process-creator-group" onClick={selectGroup(creatorGroup)}>
							<ListItemText primary={creatorGroup.name} />
							<ListItemIcon className="group-list-icon">
								<Icon icon="faChevronRight" />
							</ListItemIcon>
						</ListItem>
					)}
					{!hideResponsible && (
						<ListItem className="group-list-item process-creator-group" onClick={selectGroup(responsibleGroup)}>
							<ListItemText primary={responsibleGroup.name} />
							<ListItemIcon className="group-list-icon">
								<Icon icon="faChevronRight" />
							</ListItemIcon>
						</ListItem>
					)}
					{!hideSpecific && (
						<ListItem
							className="group-list-item process-creator-group"
							onClick={(e) => {
								// e.stopPropagation();
								let id = document.getElementById('specific-group');
								//(id, 'id');
								if (id.classList.contains('visible-group')) {
									id.classList.remove('visible-group');
									id.classList.add('invisible-group');
								} else {
									id.classList.add('visible-group');
									id.classList.remove('invisible-group');
								}
							}}
						>
							<ListItemText primary={specificGroup.name} />
							<ListItemIcon className="group-list-icon">
								<Icon icon="faChevronRight" />
							</ListItemIcon>
						</ListItem>
					)}
					<div id="specific-group" className="invisible-group">
						{appcenterStore.groups.map((group, i) => RenderGroup(group, i, newArray, selectGroup))}
					</div>

					{!hideResponsible && (
						<div>
							<ListItem
								className="group-list-item process-creator-group"
								onClick={(e) => {
									// e.stopPropagation();
									let id = document.getElementById('specific-phase');
									//(id, 'id');
									if (id.classList.contains('visible-group')) {
										id.classList.remove('visible-group');
										id.classList.add('invisible-group');
									} else {
										id.classList.add('visible-group');
										id.classList.remove('invisible-group');
									}
								}}
							>
								<ListItemText primary={phaseGroup.name} />

								<ListItemIcon className="group-list-icon">
									<Icon icon="faChevronRight" />
								</ListItemIcon>
							</ListItem>
							<div id="specific-phase" className="invisible-group">
								{workflow.phases.map((phase, i) => RenderPhase(workflow, currentPhase, inputUserGroup, phase, i))}
							</div>
						</div>
					)}

					{appcenterStore.groups.map((group, i) => {
						return (
							<ListItem key={i} className="group-list-item" onClick={selectGroup(group)}>
								<ListItemText primary={group.name} />
								<ListItemIcon className="group-list-icon">
									<Icon icon="faChevronRight" />
								</ListItemIcon>
							</ListItem>
						);
					})}
				</List>
			</div>
		</div>
	);
}

export const showGroupSelectionWindow =
	(selectGroup, company, hideResponsible, newArray, workflow, currentPhase, inputUserGroup, appcenterStore, hideCreator, hideSpecific, hidePhase) => () => {
		// showGroupSelectionWindow(selectGroup, companyStore, false, newArray, workflow, currentPhase, inputUserGroup)
		let idSpecific = document.getElementById('specific-group');
		let appcenterStore = zerocodeStore.getState().appcenter;
		// for(var i = 0; i < workflow.phases.length; i++){
		// 	workflow.phases[i]["id"]=i+1
		// }
		// const remainingArr = workflow.phases.filter(data => data.uid != currentPhase.uid);




		if (idSpecific) {
			idSpecific.classList.remove('visible-group');
			idSpecific.classList.add('invisible-group');
		}

		let id2 = document.querySelectorAll('.all');

		if (id2.length !== 0) {
			appcenterStore.groups.map((group) => {
				for (let a = 0; a < group.members.length; a++) {
					id2[a].classList.remove('visible-group');
					id2[a].classList.add('invisible-group');
				}
			});
		}

		services.message.show(
			i18n('workflow.form.actions.selectGroupLabel'),
			<Groups
				selectGroup={selectGroup}
				company={company}
				hideResponsible={hideResponsible}
				hideCreator={hideCreator}
				hideSpecific={hideSpecific}
				newArray={newArray}
				appcenterStore={appcenterStore}
				// remainingArr = {remainingArr}
				workflow={workflow}
				currentPhase={currentPhase}
				hidePhase={hidePhase}
				inputUserGroup={inputUserGroup}
			/>
		);
	};
