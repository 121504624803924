import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

export function LoadingCircle() {
  return (
    <main style={{
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <CircularProgress />
    </main>

  )
}