import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import Thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducers, services } from './modules';

const history = createBrowserHistory();
reducers.router = connectRouter(history);

let initialState = {};
const createReducers = (asyncReducers) => {
	return combineReducers({
		...reducers,
		...asyncReducers
	});
};

const store = createStore(
	createReducers(),
	initialState,
	compose(applyMiddleware(Thunk, routerMiddleware(history)))
);

store.asyncReducers = {};

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
store.injectReducer = (key, asyncReducer) => {
	store.asyncReducers[key] = asyncReducer;
	store.replaceReducer(createReducers(store.asyncReducers));
};

// if (module.hot) {
// 	// Enable Webpack hot module replacement for reducers
// 	module.hot.accept("./reducers/index", () => {
// 		const nextRootReducer = require("./modules/index");
// 		store.replaceReducer(nextRootReducer);
// 	});
// }

Object.keys(services).map((k) => {
	services[k] = services[k](store.dispatch);
});

export { services, store, history };
