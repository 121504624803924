import React, { useEffect, useState } from 'react';
import i18n from '@i18n';
import Action from './Action';
import Input from '@custom/Input';

import { generateUid } from '@custom/common';

import { Button, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';

import {
	WorkflowActionType,
	WorkflowReactionType,
	WorkflowPhaseAction as WorkflowPhaseActionShape,
	WorkflowPhaseReaction as WorkflowPhaseReactionShape,
} from '@taugor/taugor-bpmn-models';

import { services } from '@redux';
import Icon from '@custom/Icon';

import { operationHandler, creatorGroup } from './functions';

const Phase = ({
	isFirst,
	phase,
	updateWorkflow,
	companyStore,
	addPhase,
	removePhase,
	workflow,
	isMappingAdmin,
	incrementHistory,
	auth,
	saveWorkflow,
}) => {
	const [state, setState] = useState({});
	const updateName = (e) => {
		phase.name = e.target.value;
		setState({});
		incrementHistory();
		// updateWorkflow();
	};

	if (phase.toDelete) {
		let el = document.getElementById(phase.uid);
		if (el) {
			el.classList.add('will-delete');
		}
	}

	const onActionPropChange = (base, index) => (prop) => (e) => {
		switch (e.target.type) {
			case 'checkbox':
			case 'radio':
				phase[base][index][prop] = e.target.checked;
				break;

			default:
				phase[base][index][prop] = e.target.value;
				//(phase, 'phase');
				break;
		}
		updateWorkflow();
	};
	const onMouseOver = () => {
		const main = document.getElementById(phase.uid);
		if (!main) return;
		main.classList.add('hover');
		if (operationHandler.isDraggingAction) {
			main.classList.add('hover-droppable');
		}
	};
	const onMouseOut = () => {
		const main = document.getElementById(phase.uid);
		if (!main) return;
		main.classList.remove('hover');
		main.classList.remove('hover-droppable');
	};
	let selected = 0;
	const actionList = phase.actions.map((action, i) => {
		if (action.deleted) return null;
		if (action.selected) selected++;
		return (
			<Action
				operatorType={'user'}
				action={action}
				onActionPropChange={onActionPropChange('actions', i)}
				key={action.uid}
				workflow={workflow}
				companyStore={companyStore}
				updateWorkflow={updateWorkflow}
				phaseActionsList={[]}
				isMappingAdmin={isMappingAdmin}
				phase={phase}
				currentPhase={phase}
				auth={auth}
				incrementHistory={incrementHistory}
			/>
		);
	});
	const unlinkActions = () => {
		workflow.phases.map((phase, a) => {
			phase.actions.map((action, i) => {
				if (!action.selected) return;
				action.selected = false;
				delete action.linkedBy;
				delete action.linkColor;
			});
		});
		updateWorkflow();
	};
	const linkActions = () => {
		const link = () => {
			let uid = new Date().getTime().toString(32);
			let color = 'hsl(' + Math.ceil(Math.random() * 360) + ', ' + randomInteger(50, 100) + '%, 50%)';
			let group = null;
			workflow.phases.find((phase, a) => {
				if (group) return phase;
				phase.actions.find((action, i) => {
					if (group) return action;
					if (!action.selected) return;
					if (action.group && action.group.uid) {
						group = action.group;
					}
				});
			});
			workflow.phases.map((phase, a) => {
				phase.actions.map((action, i) => {
					if (!action.selected) return;
					action.linkedBy = uid;
					action.selected = false;
					action.linkColor = color;
					action.group = group;
				});
			});
			updateWorkflow();
			services.message.hide();
		};
		services.message.show(
			'Vincular Ações',
			<div>
				<div style={{ marginBottom: 10 }}>
					Ao <b>Vincular Ações</b> você fará com que as ações selecionadas sejam necessariamente passadas para a mesma
					pessoa ao invés de serem distribuídas segundo o balanceamento de carga
				</div>
				<div style={{ marginBottom: 10 }}>
					Ao selecionar uma ou mais ações que já estavam vinculadas, você modificará o vínculo daquelas ações para o
					novo vínculo que será criado
				</div>
				<div style={{ marginBottom: 10 }}>Confirma esta ação?</div>
				<div className="buttons">
					<simple-button save onClick={link}>
						Confirmar
					</simple-button>
					<simple-button clear onClick={services.message.hide}>
						{i18n("cancel")}
					</simple-button>
				</div>
			</div>
		);
	};
	const togglePhaseOpen = () => {
		incrementHistory();
		phase.closed = !phase.closed;
		setState({});
	};

	const movePhaseToLeft = async () => {
		const indexSelectedPhase = workflow.phases.indexOf(phase);
		if (indexSelectedPhase === 0) {
			return;
		} else {
			const previousPhase = workflow.phases[indexSelectedPhase - 1];
			workflow.phases[indexSelectedPhase] = previousPhase;
			workflow.phases[indexSelectedPhase - 1] = phase;
			saveWorkflow();
		}
		return;
	};
	const movePhaseToRight = async () => {
		const indexSelectedPhase = workflow.phases.indexOf(phase);
		if (indexSelectedPhase === workflow.phases.length - 1) {
			return;
		} else {
			const nextPhase = workflow.phases[indexSelectedPhase + 1];
			workflow.phases[indexSelectedPhase] = nextPhase;
			workflow.phases[indexSelectedPhase + 1] = phase;
			saveWorkflow();
		}
		return;
	};

	const actionNotDeleted = phase.actions.filter((action) => !action.deleted)

	let fieldsAction;

	for (const action of actionNotDeleted) {
		fieldsAction = action.formFields;
	}



	return [
		<div className="add-inter-phase" onClick={addPhase}>
			<Icon icon="faPlus" />
		</div>,
		<section
			className={`phase no-selectable ${phase.closed ? 'closed' : ''}`}
			id={phase.uid}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
		>
			<div className="phase-name">
				<Input
					label={'workflow.form.phaseName'}
					rightIcon={isMappingAdmin ? ['faCaretLeft', 'faTrash'] : ''}
					rightIconClick={isMappingAdmin ? [togglePhaseOpen, removePhase] : () => { }}
					value={phase.name}
					onChange={updateName}
					disabled={!isMappingAdmin || phase.closed}
				/>
			</div>
			<div className="phase-controls">
				{false && isFirst && (
					<FormControlLabel
						label={i18n('workflow.form.phase.isOpening')}
						className="checkbox-control phase-opening"
						disabled={!isMappingAdmin}
						control={
							<Checkbox
								checked={phase.isOpening}
								onChange={(e) => {
									phase.isOpening = e.target.checked;
									phase.actions.map((a) => {
										a.group = JSON.parse(JSON.stringify(creatorGroup));
										delete a.linkedBy;
										delete a.linkedByColor;
									});
									setState({});
								}}
							/>
						}
					/>
				)}
				{!selected && isMappingAdmin && (
					<>
						<Tooltip title="Mover fase para a esquerda">
							<Button
								variant="contained"
								color="primary"
								elevation={0}
								className="phase-button"
								onClick={movePhaseToLeft}
								startIcon={<Icon icon="faChevronLeft" size={'1x'} />}
							></Button>
						</Tooltip>
						<Tooltip title="Mover fase para a direita">
							<Button
								variant="contained"
								color="primary"
								elevation={0}
								className="phase-button"
								onClick={movePhaseToRight}
								startIcon={<Icon icon="faChevronRight" size={'1x'} />}
							></Button>
						</Tooltip>
					</>
				)}
				{!selected && isMappingAdmin && (
					<Button
						variant="contained"
						color="primary"
						elevation={0}
						className="phase-button"
						onClick={toggleOptionsMenu}
						startIcon={<Icon icon="faPlus" size={'1x'} />}
					>
						<span>{i18n('workflow.form.addActionButton')}</span>
						<div className="options-menu action-types">
							<div className="option action-type approval" onClick={addAction(phase, 'approval', updateWorkflow)}>
								{i18n('workflow.form.actionTypes.approval.name')}
							</div>
							<div
								className="option action-type document-input"
								onClick={addAction(phase, 'documentInput', updateWorkflow)}
							>
								{i18n('workflow.form.actionTypes.documentInput.name')}
							</div>
							<div
								className="option action-type form-input"
								onClick={addAction(phase, 'formInput', updateWorkflow)}
							>
								{i18n('workflow.form.actionTypes.formInput.name')}
							</div>
						</div>
					</Button>
				)}
				{!selected && isMappingAdmin && (
					<Button
						variant="contained"
						color="primary"
						elevation={0}
						className="phase-button"
						onClick={toggleOptionsMenu}
						startIcon={<Icon icon="faPlus" size={'1x'} />}
					>
						<span>{i18n('workflow.form.addReactionButton')}</span>
						<div className="options-menu">
							{auth.user.companyadmin && (
								<div className="option " onClick={addReaction(phase, 'dokia', updateWorkflow)}>
									{i18n('workflow.form.reactionTypes.dokia.name')}
								</div>
							)}
							<div className="option " onClick={addReaction(phase, 'newWorkflow', updateWorkflow)}>
								{i18n('workflow.form.reactionTypes.new.workflow')}
							</div>
							<div className="option " onClick={addReaction(phase, 'phaseJump', updateWorkflow)}>
								{i18n('workflow.form.reactionTypes.phaseJump.name')}
							</div>
							<div className="option " onClick={addReaction(phase, 'documentOutput', updateWorkflow)}>
								{i18n('workflow.form.reactionTypes.documentOutput.name')}
							</div>
							{auth.user.companyadmin && (
								<div className="option " onClick={addReaction(phase, 'start-collectame-process', updateWorkflow)}>
									{i18n('workflow.form.reactionTypes.start-collectame-process.name')}
								</div>
							)}
							{auth.user.companyadmin &&
								fieldsAction &&
								!fieldsAction.includes('@table') && (
									<div className="option" onClick={addReaction(phase, 'generate-tdoc', updateWorkflow)}>
										{i18n('workflow.form.reactionTypes.generate-tdoc.name')}
									</div>
								)}

							<div className="option " onClick={addReaction(phase, 'notificationEmail', updateWorkflow)}>
								{i18n('workflow.form.reactionTypes.notificationEmail.name')}
							</div>
							<div className="option " onClick={addReaction(phase, 'queryCpf', updateWorkflow)}>
								{i18n('workflow.form.reactionTypes.queryCpf.name')}
							</div>
						</div>
					</Button>
				)}
				{!!selected && (
					<Button
						variant="contained"
						color="primary"
						className="phase-button phase-green-button"
						onClick={linkActions}
						startIcon={<Icon icon="faLink" size={'1x'} />}
					>
						<span>Vincular</span>
					</Button>
				)}
				{!!selected && (
					<Button
						variant="contained"
						color="primary"
						className="phase-button phase-remove-button"
						onClick={unlinkActions}
						startIcon={<Icon icon="faLink" size={'1x'} />}
					>
						<span>Desvincular</span>
					</Button>
				)}
			</div>
			<div className="phase-content-list">
				<div className="phase-actions-list">{actionList}</div>
				<div className="phase-reactions-list">
					<div className="reactions-title">
						{i18n("reactions")}
						<div className="line"></div>
					</div>
					{phase.reactions.map((action, i) => {
						if (action.deleted) return null;
						return (
							<Action
								operatorType={'system'}
								action={action}
								onActionPropChange={onActionPropChange('reactions', i)}
								key={action.uid}
								workflow={workflow}
								companyStore={companyStore}
								updateWorkflow={updateWorkflow}
								phaseActionsList={phase.actions}
								isMappingAdmin={isMappingAdmin}
								currentPhase={phase}
								incrementHistory={incrementHistory}
							/>
						);
					})}
				</div>
			</div>
		</section>,
	];
};

const addAction = (phase, type, updateWorkflow) => () => {
	let action = new WorkflowPhaseActionShape({
		instructions: '',
		isApprovalRequired: true,
		onReprovalGoesBack: false,
		uid: 'action-' + generateUid(),
		group: {},
		type: new WorkflowActionType({
			name: i18n(`workflow.form.actionTypes.${type}.name`),
			type,
		}).$toPlainObject(),
	}).$toPlainObject();
	action.kanban = {
		basic: {
			status: 'todo',
		},
	};
	phase.actions.push(action);
	updateWorkflow();
};
const addReaction = (phase, type, updateWorkflow) => () => {
	phase.reactions.push(
		new WorkflowPhaseReactionShape({
			uid: 'reaction-' + generateUid(),
			createApprovalDocument: true,
			requireApprovalDocumentSign: true,
			createDocumentFromForm: false,
			originForm: '',
			emailsToNotify: '',
			type: new WorkflowReactionType({
				name: i18n(`workflow.form.reactionTypes.${type}.name`),
				type,
			}).$toPlainObject(),
		}).$toPlainObject()
	);
	updateWorkflow();
};

function randomInteger(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

const toggleOptionsMenu = (e) => {
	[].slice.call(document.body.getElementsByClassName('options-menu-visible')).forEach((el) => {
		if (el === e.target) return;
		el.classList.remove('options-menu-visible');
	});
	e.target.classList.toggle('options-menu-visible');
};
export default Phase;
