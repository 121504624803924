import React, { useState, useEffect, useCallback } from 'react';
import i18n from '@i18n';
import ExecutionFlowBox from '../components/ExecutionFlowBox';
import { Autocomplete, store as zerocodeStore } from 'zerocode';
import { connect } from 'react-redux';
import { renderExecutionFlowMenuItem, renderTaskMenuItem } from '@custom/common';
import '@assets/sass/dashboard.scss';
import Chart from 'react-apexcharts';
import Icon from '@custom/Icon';
import WorkflowActionCard, { workflowCollection, checkLicenses } from '../@custom/WorkflowActionCard';

let debounceDonut;

const Dashboard = ({ auth, executionFlowStore, executionFlowStoreMine, taskStore, companyAreaStore, recurrenceStore }) => {
	const [workflows, setWorkflows] = useState([]);
	const [donutA, setDonutA] = useState({
		chartOptions: {
			labels: [],
		},
		options: {
			labels: [],
		},
		series: [],
	});
	const [hasLicensesMappings, setHasLicensesMappings] = useState(false);
	const [loadingHasLicenses, setLoadingHasLicenses] = useState(false);
	const [maxMappingsLicensesAvailable, setMaxMappingsLicensesAvailable] = useState(false);
	const appcenterStore = zerocodeStore.getState().appcenter;

	const checkNecessaryLicenses = () => {
		checkLicenses((result) => {
			setHasLicensesMappings(result.hasEnoughMappingsLicense);
			setMaxMappingsLicensesAvailable(result.mappingsLicensesAvailable);

			setLoadingHasLicenses(true);
		});
	};

	const debounce = (func, wait) => {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				func.apply(this, args);
			}, wait);
		};
	};

	const updateDonutData = useCallback(debounce(() => {
		let workflows = {};
		taskStore.list.forEach((task) => {
			if (!workflows[task.workflow]) {
				workflows[task.workflow] = {
					count: 0,
					label: task.workflowObject.document.title,
				};
			}
			workflows[task.workflow].count++;
		});

		const labels = Object.values(workflows).map((o) => o.label);
		const series = Object.values(workflows).map((o) => o.count);

		setDonutA((prevDonutA) => ({
			...prevDonutA,
			chartOptions: {
				...prevDonutA.chartOptions,
				labels: [...labels],
			},
			options: {
				...prevDonutA.options,
				labels: [...labels],
			},
			series: [...series],
		}));
	}, 500), [taskStore.list]);

	const fetchWorkflows = useCallback(debounce(async () => {
		try {
			const snapshot = await workflowCollection
				.where('company', '==', auth.company.uid)
				.where('deleted', '==', false)
				.get();

			const filteredWorkflows = snapshot.docs
				.map((doc) => doc.data())
				.filter((workflow) => filterWorkflows(workflow))
				.sort((a, b) => {
					const divisionCompare = a.divisionObject.name.localeCompare(b.divisionObject.name);
					if (divisionCompare !== 0) return divisionCompare;
					return a.document.title.localeCompare(b.document.title);
				});

			setWorkflows(filteredWorkflows);
		} catch (error) {
			console.error('Error fetching workflows:', error);
		}
	}, 400), [auth, companyAreaStore, workflowCollection]);

	const filterWorkflows = (workflow) => {
		if (!workflow.division) return false;
		if (auth.user.companyadmin) return true;

		const companyArea = companyAreaStore.list.find((area) => area.uid === workflow.division);
		if (!companyArea) return false;

		return companyArea.groups.some((group) =>
			auth.user.groups.some((userGroup) => userGroup.uid === group.uid)
		);
	};

	useEffect(() => {
		if (auth.isAuthenticated) {
			fetchWorkflows();
		}
	}, [auth.isAuthenticated]);

	useEffect(() => {
		updateDonutData();
	}, [taskStore.list]);

	useEffect(() => {
		checkNecessaryLicenses();
	}, [appcenterStore.licenses]);

	return (
		<content>
			<div className="workflow-search-container">
				<Autocomplete
					preventCloseOnBlur
					className="workflow-search"
					items={workflows}
					label={i18n("workflow.dashboard.labelSearchProcess")}
					leftIcon='faSearch'
					getComparisonText={(option) => {
						return option.document.title + ' ' + option.divisionObject.name + ' ' + option.document.description || '';
					}}
					renderItem={(workflow) => [
						<WorkflowActionCard
							displayDivision={true}
							auth={auth}
							workflow={workflow}
							companyAreaStore={companyAreaStore}
							recurrenceStore={recurrenceStore}
							hasLicensesMappings={hasLicensesMappings}
							maxMappingsLicensesAvailable={maxMappingsLicensesAvailable}
						/>,
					]}
				/>
			</div>

			<header>
				<h3>
					<Icon icon="faChartPie" />
					Dashboard
				</h3>
			</header>

			<div className="columns graphic-line">
				<ExecutionFlowBox
					className="column tasks"
					icon="faTasks"
					title='workflow.dashboard.titleMyActivities'
					list={taskStore.list}
					renderItem={renderTaskMenuItem}
				/>
				<div className="column">
					{!!donutA.options.labels.length && (
						<Chart
							style={{ height: 300, width: 300 }}
							options={donutA.options}
							series={donutA.series}
							type="donut"
							width={'400px'}
							height={'500px'}
						/>
					)}
				</div>
			</div>
			<div className="dashboard-grid">
				<ExecutionFlowBox
					className="box"
					icon="faSitemap"
					title='appcommon.menutop.executionFlowsICreated'
					list={executionFlowStoreMine.list}
					renderItem={renderExecutionFlowMenuItem}
				/>
				<ExecutionFlowBox
					className="box"
					icon="faSitemap"
					title='appcommon.menutop.executionFlowsImResponsible'
					list={executionFlowStore.list}
					renderItem={renderExecutionFlowMenuItem}
				/>
			</div>
		</content>
	);
};

export default connect(({ auth, executionFlow, myExecutionFlowStore, task, companyAreaStore, recurrenceStore }) => ({
	auth,
	executionFlowStore: executionFlow,
	executionFlowStoreMine: myExecutionFlowStore,
	taskStore: task,
	companyAreaStore,
	recurrenceStore,
}))(Dashboard);
