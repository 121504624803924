import React from 'react';
import { Button } from '@material-ui/core';
import { services } from '@redux';
import Icon from '@custom/Icon';
const Message = ({ title, description, visible, buttons, hide }) => {
	const internalHide = (e) => {
		if (!hide) {
			services.message.hide();
			e.target.classList.remove('visible');
		} else {
			hide();
		}
	};
	return (
		<div className={`floating-message ${visible ? 'visible' : ''}`} onClick={internalHide}>
			<div className="content" onClick={(e) => e.stopPropagation()}>
				<div className="header">
					<div className="title">{title}</div>
					<div className="close" onClick={internalHide}>
						<Icon icon="faTimesCircle" />
					</div>
				</div>
				<div className="description">{description}</div>
				<div className="buttons">
					{(buttons || []).map((button, i) => {
						return (
							<simple-button variant="contained" onClick={button.onClick} key={i}>
								{button.text}
							</simple-button>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Message;
