import styles from './calendar.module.css';
import React, { useEffect, useState } from "react";
import { Modal } from "../../components/interface/containerModal";
import { workfluxFirestore } from '@firebase-config';
import { useSelector } from 'react-redux';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { LoadingCircle } from '../../components/interface/loadingCircle';
import Icon from '../../@custom/Icon';
import { FormCalendar } from './modules/form';
import { RenderCardCalendar } from './modules/renderCalendar';

export function Calendar() {
  const auth = useSelector((s) => s.auth);
  const calendarCollection = workfluxFirestore.collection("calendar");

  const [renderModalAddCalendar, setRenderModalAddCalendar] = useState(false);
  const [renderModalEditCalendar, setRenderModalEditCalendar] = useState(false);

  const [listCalendar, setListCalendar] = useState([]);
  const [loading, setLoading] = useState(false);


  const [filterTitle, setFilterTitle] = useState("");

  const [dataCalendarSelectedEdit, setDateCalendarSelectedEdit] = useState(null);

  async function getCalendar() {
    setLoading(true)
    try {
      return await calendarCollection
        .where("company", "==", auth.company.uid)
        .where("createdBy", "==", auth.user.uid)
        .where("delete", "==", false)
        .onSnapshot((docs) => {
          const getListCalendar = [];
          docs.forEach((snap) => {
            const data = snap.data();
            getListCalendar.push({
              id: snap.id,
              company: data.company,
              createdAt: data.createdAt,
              createdBy: data.createdBy,
              initialDate: data.initialDate,
              endDate: data.endDate,
              text: data.text,
              title: data.title,
              user: data.user,
              delete: data.delete
            })
          })
          setListCalendar(getListCalendar);
          setLoading(false)
        });
    } catch (error) {
      // error ao fazer a busca
    }
    setLoading(false);
  }

  useEffect(() => {
    getCalendar();
  }, [])

  const filterListCalendar = listCalendar.filter((item) => {
    const titleMatch = item.title.toLowerCase().includes(filterTitle.toLowerCase());
    return titleMatch;
  })

  const handleEdit = ({ calendarSelected }) => {
    setDateCalendarSelectedEdit(calendarSelected)
    setRenderModalEditCalendar(true);
  }

  return (
    <main className={styles.containerCalendar}>
      <section className={styles.headerCalendar}>
        <section>
          <h3><Icon icon="faCalendar" /> Agenda</h3>
        </section>
        <section className={styles.headerFilter}>
          <TextField
            size="small"
            variant="outlined"
            label="Titulo"
            style={{ width: '100%' }}
            value={filterTitle}
            onChange={(event) => setFilterTitle(event.target.value)}
          />
          {auth.user.companyadmin && (
            <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setRenderModalAddCalendar(true)}
                style={{
                  width: "100%",
                }}
              >
                Adicionar Evento
              </Button>
            </>
          )}
        </section>
      </section>
      {loading ? <LoadingCircle /> : (
        <>
          {listCalendar.length === 0 ?
            (<section >
              <h3>A agenda está vazia</h3>
            </section>)
            :
            (<section className={styles.containerCardCalendar}>
              {filterListCalendar.map((calendar) => (
                <RenderCardCalendar
                  calendar={calendar}
                  key={calendar?.id}
                  handleEdit={handleEdit}
                />
              ))}
            </section>)
          }
          {
            renderModalAddCalendar && (
              <Modal setRenderModal={setRenderModalAddCalendar}>
                <FormCalendar
                  closeModal={() => setRenderModalAddCalendar(false)}
                  titleForm={"Adicione um evento a agenda"}
                  action={null}
                  dataCalendar={null}
                />
              </Modal>
            )
          }
          {
            renderModalEditCalendar && (
              <Modal setRenderModal={setRenderModalEditCalendar}>
                <FormCalendar
                  closeModal={() => setRenderModalEditCalendar(false)}
                  titleForm={"Edite seu evento"}
                  action={"edit"}
                  dataCalendar={dataCalendarSelectedEdit}
                />
              </Modal>
            )
          }
        </>
      )}
    </main >
  )
}