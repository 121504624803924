import axios from 'axios';

const isLocal = window.location.href.indexOf('localhost') > -1;

export default axios.create({
	baseURL: isLocal
		? 'http://localhost:5005/bpmn-taugor/us-central1/webApi/api/v1/'
		: 'https://us-central1-bpmn-taugor.cloudfunctions.net/webApi/api/v1',
	timeout: 120000,
});

export const customer_api = axios.create({
	baseURL: isLocal
		? 'http://localhost:5001/taugor-appcenter/us-central1/customer_api'
		: 'https://us-central1-taugor-appcenter.cloudfunctions.net/customer_api',
	timeout: 120000,
});

export const indexia_api = axios.create({
	baseURL: 
		'https://us-central1-indexia-web.cloudfunctions.net/customer_api',
	timeout: 120000,
});


