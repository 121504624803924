import styles from './detail.module.css'
import React, { useEffect, useState } from 'react';
import { workfluxFirestore } from '@firebase-config';
import { convertTimestampDateAndHours } from '../../utils/dates';
import { history } from "@redux";
import {
  Button,
} from '@material-ui/core';
import { LoadingCircle } from '../../components/interface/loadingCircle';
import Icon from '../../@custom/Icon';

export function DetailNews({ match }) {

  const { id } = match.params
  const [news, setNews] = useState({});
  const newsCollection = workfluxFirestore.collection("news");
  const categoryCollection = workfluxFirestore.collection("categoryNews");

  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("")
  const data = news.createdAt && convertTimestampDateAndHours({ timestamp: news?.createdAt });

  useEffect(() => {

    async function getNews() {
      setLoading(true)
      try {
        const response = await newsCollection.doc(id).get();

        const data = response.data();
        const dataNews = {
          uid: data.uid,
          title: data.title,
          summary: data.summary,
          text: data.text,
          bannerUrl: data.bannerUrl,
          category: data.category,
          company: data.company,
          createdBy: data.createdBy,
          user: data.user,
          createdAt: data.createdAt
        }

        const category = await categoryCollection.doc(data.category).get();
        setCategoryName(category.data().category);
        setNews(dataNews)
      } catch (error) { }
      setLoading(false)
    }
    getNews();

  }, [])

  if (loading) {
    return (
      <LoadingCircle />
    )
  }

  return (
    <section className={styles.container}>

      <section className={styles.content}>
        <section style={{
          display: "flex",
          alignItems: "center",
          gap: 16
        }}>
          <h3><Icon icon="faNewspaper" /> Notícias</h3>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => history.push("/news")}
          >
            Voltar
          </Button>
        </section>
        <section className={styles.header}>
          <section style={{
            display: "flex",
            gap: 16
          }}>

            <h1>{news.title}</h1>
          </section>
          <h3>{categoryName}</h3>
        </section>
        <img src={news?.bannerUrl} alt="Banner da notícia" />
        <section>
          <p>Resumo:</p>
          <p dangerouslySetInnerHTML={{ __html: news?.summary }} />
        </section>
        <section>
          <p>Texto:</p>
          <p dangerouslySetInnerHTML={{ __html: news?.text }} />
        </section>
        <section className={styles.footer}>
          <p>Criado por: {news?.user?.name}</p>
          <p>Em: {data?.date} ás {data?.hours}</p>
        </section>
      </section>
    </section>
  )
}