/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Api from '../../Api';
import {
	store as zerocodeStore,
	dokia as dokiaApi,
	autodocs as autodocsApi,
	collectame as collectameApi,
	collectameSignatoryType,
	collectameSignatureType,
} from 'zerocode';
import { showGroupSelectionWindow, operationHandler } from './functions';
import { Workflow } from '@taugor/taugor-bpmn-models';
import firebase from '@firebase-config';
import Input from '@custom/Input';
import {
	TextField,
	Checkbox,
	FormControl,
	FormControlLabel,
	Button,
	InputAdornment,
	Tooltip,
	InputLabel,
	Select,
	MenuItem,
	FormGroup,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import i18n from '@i18n';

import Icon from '@custom/Icon';
import { store, services } from '@redux';
import { generateUid, getFieldListFromString } from '@custom/common';
import { smallUid } from 'zerocode/src/@api/common';

const oWorkflow = new Workflow();
const oWorkflowService = oWorkflow.getService(firebase, store);

let newArray = [];
let array = [];

let groupStateTemplate = {};

const Action = ({
	action,
	operatorType,
	onActionPropChange,
	companyStore,
	workflow,
	updateWorkflow,
	phaseActionsList,
	isMappingAdmin,
	currentPhase,
	incrementHistory,
	auth,
	companyAreaStore,
}) => {
	const [state, updateState] = useState({});
	const [configs, setConfigs] = useState({ actionName: '', checklistOpen: false });
	const [localSendEmail, setLocalSendEmail] = useState(action.sendMail !== '' ? action.sendMail : true);
	const [reprovalGoesBack, setReprovalGoesBack] = useState(false);
	const [reprovalCancel, setReprovalCancel] = useState(false);
	const [localsendWhatsApp, setLocalsendWhatsApp] = useState(
		action.sendWhatsApp == true || action.sendWhatsApp == false ? action.sendWhatsApp : false
	);
	const [workflows, setWorkflows] = useState([]);
	const [workspaces, setWorkspaces] = useState([]);
	const [organizationUnits, setOrganizationUnits] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [classifications, setClassifications] = useState([]);
	const [workspace, setWorkspace] = useState('');
	const [organizationUnit, setOrganizationUnit] = useState('');
	const [company, setCompany] = useState('');
	const [safeBoxes, setSafeBoxes] = useState([]);
	const [safeBox, setSafeBox] = useState('');
	const [templates, setTemplates] = useState([]);
	const [template, setTemplate] = useState('');
	const [tdocsTemplates, setTdocsTemplates] = useState([]);
	const [t, setT] = useState([]);

	const [emailsToNotifyFildsLocal, setEmailsToNotifyFildsLocal] = useState([]);
	const [tdocsTemplate, setTdocsTemplate] = useState('');

	const [preventEditingDokiaInfo, setPreventEditingDokiaInfo] = useState(true);
	const [signatories, updateSignatories] = useState(action.signatories instanceof Array ? action.signatories : []);
	const [sendToDok, setSendToDok] = useState(action.sendToDok ? true : false);
	const [titleDoc, setTitleDoc] = useState(action.titleDoc || '');
	const [dateDoc, setDateDoc] = useState(action.dateDoc || '');
	const [fixedData, setFixedData] = useState(action.fixedData ? true : false);
	const [groupState, setGroupState] = useState(groupStateTemplate);

	const [dokiaLists, setDokiaLists] = useState({
		organizations: [],
		companies: [],
		subsidiaries: [],
		classifications: [],
	});

	const [dokiaLists2, setDokiaLists2] = useState({
		organizations: [],
		companies: [],
		subsidiaries: [],
		classifications: [],
	});

	const [dokiaInfo, updateDokiaInfo] = useState(
		action.dokiaInfo && action.type.type === 'start-collectame-process'
			? action.dokiaInfo
			: {
				organization: '',
				company: '',
				subsidiary: '',
				classification: [],
			}
	);

	const [dokiaInfo2, updateDokiaInfo2] = useState({
		organization: '',
		company: '',
		subsidiary: '',
		classification: [],
	});

	const appcenterStore = zerocodeStore.getState().appcenter;

	for (let group of appcenterStore.groups) {
		groupStateTemplate[group.uid] = {
			term: '',
			visible: false,
		};
	}

	const [isClassificationEmpty, setIsClassificationEmpty] = useState(false);
	const [errorText, setErrorText] = useState('');

	useEffect(() => {
		setIsClassificationEmpty(dokiaInfo.classification.length === 0);
	}, [dokiaInfo.classification]);

	useEffect(() => {
		setErrorText(isClassificationEmpty ? 'Este campo é obrigatório' : '');
	}, [isClassificationEmpty]);

	useEffect(() => {
		if (!Object.keys(groupState).length) {
			setGroupState(groupStateTemplate);
		}
	}, [groupState]);

	useEffect(() => {
		if (!companyAreaStore.list || companyAreaStore.list[0] == undefined) return;
		oWorkflowService
			.filter([
				[
					['division', '==', action.newWorkflow || '--none--'],
					['company', '==', companyAreaStore.list[0].company],
					['deleted', '==', false],
				],
			])
			.list(false, false)
			.then((r) => {
				setWorkflows(r);
			})
			.finally(services.spinner.hide);
	}, [action.newWorkflow]);

	useEffect(() => {
		if (!companyAreaStore.list && !workflow.company) return;
		if (action.newWorkflow !== undefined) {
			oWorkflowService
				.filter([
					[
						['division', '==', action.newWorkflow || '--none--'],
						['company', '==', workflow.company],
						['deleted', '==', false],
					],
				])
				.list(false, false)
				.then((r) => {
					setWorkflows(r);
				});
		}
	}, []);

	const setState = (state) => {
		incrementHistory();
		updateState(state);
	};

	const onLocalChangeProp = (prop) => (e) => {
		action[prop] = ['checkbox', 'radiobutton'].indexOf(e.target.type) > -1 ? e.target.checked : e.target.value;

		if (prop === 'onReprovalGoesBack' || prop === 'onReprovalCancel') {
			action.isApprovalRequired = true;
		}

		if (prop === 'onReprovalGoesBack') {
			action.onReprovalCancel = false;
			setReprovalGoesBack(e.target.checked);
			setReprovalCancel(false);
		}

		if (prop === 'onReprovalCancel') {
			action.onReprovalGoesBack = false;
			setReprovalCancel(e.target.checked);
			setReprovalGoesBack(false);
		}

		if (prop === 'sendMail') {
			setLocalSendEmail(!localSendEmail);
		}
		if (prop === 'sendWhatsApp') {
			setLocalsendWhatsApp(!localsendWhatsApp);
		}

		action.sendMail = localSendEmail;
		action.sendWhatsApp = localsendWhatsApp;

		updateState({});
		incrementHistory(true);
	};

	const onTdocsTemplateChange = (template) => {
		action.template = template.uid;
		action.fields = [];
		action.sections = [];
		template.sections.map((section) => {
			action.sections.push({
				...section,
			});
		});
		template.fields.map((field) => {
			action.fields.push({
				...field,
			});
		});
		setTdocsTemplate(template);
	};

	const setSignatories = (signatories) => {
		let s = [...signatories];
		updateSignatories(s);
		action.signatories = s;
	};

	const createSignatory = ({ }) => {
		signatories.push({
			fixed: true,
			form: '',
			name: '',
			email: '',
			phone: '',
			formName: { form: '', field: '' },
			formEmail: { form: '', field: '' },
			formPhone: { form: '', field: '' },
			signatoryType: '',
			signatureType: '',
			validateSMS: false,
			linkSMS: false,
			requireSelfie: false,
			requireVideo: false,
			uid: smallUid(),
		});
		setSignatories([...signatories]);
	};

	const onSafeBoxChange = (safeBox) => {
		action.safeBox = safeBox.uid;
		setSafeBox(safeBox);
	};

	const onTitleDocChange = (title) => {
		action.titleDoc = title;
		setTitleDoc(title);
	};

	const onDateDocChange = (date) => {
		action.dateDoc = date;
		setDateDoc(date);
	};

	const onTemplateChange = (template) => {
		action.template = template.uid;
		action.signatories = [];
		template.signatories.map((signatory) => {
			action.signatories.push({
				fixed: true,
				form: '',
				name: signatory.name,
				email: signatory.email,
				phone: '',
				formName: { form: '', field: '' },
				formEmail: { form: '', field: '' },
				formPhone: { form: '', field: '' },
				signatoryType: signatory.signLike,
				signatureType: signatory.signType,
				validateSMS: false,
				linkSMS: false,
				requireSelfie: false,
				requireVideo: false,
				uid: smallUid(),
			});
		});
		setTemplate(template);
		setSignatories(action.signatories);
	};

	const setDokiaInfo = (info) => {
		if (preventEditingDokiaInfo) return;
		updateDokiaInfo(info);
		action.dokiaInfo = {
			organization: {
				uid: info.organization ? info.organization.uid || '' : '',
				code: info.organization ? info.organization.code || '' : '',
				title: info.organization ? info.organization.title || '' : '',
			},
			company: {
				uid: info.company ? info.company.uid || '' : '',
				code: info.company ? info.company.code || '' : '',
				title: info.company ? info.company.title || '' : '',
			},
			subsidiary: {
				uid: info.subsidiary ? info.subsidiary.uid || '' : '',
				code: info.subsidiary ? info.subsidiary.code || '' : '',
				title: info.subsidiary ? info.subsidiary.title || '' : '',
			},
			classification: info.classification
				? info.classification.map((c) => {
					return {
						uid: c.uid || '',
						code: c.code || '',
						title: c.title || '',
					};
				})
				: [],
		};
	};

	const addChecklistAction = () => {
		let checklist = action.checklist;
		if (!checklist) {
			checklist = {
				actions: [],
			};
		}
		checklist.actions.push({
			name: configs.actionName,
			custom: false,
			finished: false,
		});
		configs.actionName = '';
		action.checklist = checklist;
		setState({});
	};

	const toggle = (e) => {
		if (e.metaKey || e.ctrlKey || e.shiftKey) return;
		let toggler = document.getElementById(`action-${action.uid}-toggler`);
		let content = document.getElementById(`action-${action.uid}-content`);
		toggler.classList.toggle('opened');
		content.classList.toggle('opened');
	};

	if (!action.ged17Config) {
		action.ged17Config = {
			workspace: '',
			organizationUnit: '',
			company: '',
			classification: '',
		};
	}
	useEffect(() => {
		if (action.type.type !== 'generate-tdoc') {
			return;
		}

		autodocsApi.template.list().then((list) => {
			setTdocsTemplates(list);
			list.map((item) => {
				if (item.uid == action.template) {
					setTdocsTemplate(item);
				}
			});
		});
	}, []);

	useEffect(() => {
		if (!action.sendWhatsApp) {
			action.sendWhatsApp = localsendWhatsApp;
		}
		setEmailsToNotifyFildsLocal(action.emailsToNotifyFilds);
	}, []);

	useEffect(() => {
		if (action.type.type !== 'start-collectame-process') {
			return;
		}
		if (action.signatories) {
			setSignatories(action.signatories);
		}

		collectameApi.template.list().then((list) => {
			setTemplates(list);
			list.map((item) => {
				if (item.uid == action.template) {
					setTemplate(item);
				}
			});
		});
		collectameApi.safeBox.list().then((list) => {
			setSafeBoxes(list);
			list.map((item) => {
				if (item.uid == action.safeBox) {
					setSafeBox(item);
				}
			});
		});
	}, []);

	useEffect(() => {
		if (action.type.type !== 'dokia' && action.type.type !== 'start-collectame-process') {
			return;
		} else {
		}
		if (action.dokiaInfo && action.type.type !== 'start-collectame-process') {
			setPreventEditingDokiaInfo(true);
			return;
		} else {
			setPreventEditingDokiaInfo(false);
		}
		dokiaApi.oi.organization.list().then((list) => {
			setDokiaLists({
				...dokiaLists,
				organizations: list,
			});
		});
	}, []);

	useEffect(() => {
		if (action.type.type === 'start-collectame-process') {
			if (!action.dokiaInfo) {
				action.dokiaInfo = {
					organization: '',
					company: '',
					subsidiary: '',
					classification: [],
				};
				return;
			}
			if (!!dokiaInfo2.organization.uid) {
				dokiaApi.oi.company.list(dokiaInfo2.organization.uid).then((list) => {
					setDokiaLists2({
						...dokiaLists2,
						companies: list,
					});
				});
			}
			if (!!action.dokiaInfo.organization.uid) {
				dokiaApi.oi.company.list(action.dokiaInfo.organization.uid).then((list) => {
					setDokiaLists2({
						...dokiaLists2,
						companies: list,
					});
				});
			}
			if (!!action.dokiaInfo.company.uid) {
				dokiaApi.oi.subsidiary.list(action.dokiaInfo.company.uid).then((list) => {
					setDokiaLists2({
						...dokiaLists2,
						subsidiaries: list,
					});
				});
			}
			if (!!action.dokiaInfo.subsidiary.uid) {
				dokiaApi.oi.classification.list(action.dokiaInfo.subsidiary.uid).then((list) => {
					setDokiaLists2({
						...dokiaLists2,
						classifications: list,
					});
				});
			}
		}
	}, [action.dokiaInfo]);

	useEffect(() => {
		if (preventEditingDokiaInfo) return;
		if (!dokiaInfo.organization) {
			setDokiaInfo({
				company: '',
				subsidiary: '',
				classification: '',
			});
			setDokiaLists({
				companies: [],
				subsidiaries: [],
				classifications: [],
			});
			return;
		}

		dokiaApi.oi.company.list(dokiaInfo.organization.uid).then((list) => {
			setDokiaLists({
				...dokiaLists,
				companies: list,
			});
		});
		dokiaInfo.classification = [];
	}, [dokiaInfo.organization]);

	useEffect(() => {
		if (preventEditingDokiaInfo) return;
		if (!dokiaInfo.company) {
			setDokiaInfo({
				subsidiary: '',
				classification: '',
			});
			setDokiaLists({
				subsidiaries: [],
				classifications: [],
			});
			return;
		}

		dokiaApi.oi.subsidiary.list(dokiaInfo.company.uid).then((list) => {
			setDokiaLists({
				...dokiaLists,
				subsidiaries: list,
			});
		});
		dokiaInfo.classification = [];
	}, [dokiaInfo.company]);

	useEffect(() => {
		if (preventEditingDokiaInfo) return;
		if (!dokiaInfo.subsidiary) {
			setDokiaInfo({
				classification: '',
			});
			setDokiaLists({
				classifications: [],
			});
			return;
		}

		dokiaApi.oi.classification.list(dokiaInfo.subsidiary.uid).then((list) => {
			setDokiaLists({
				...dokiaLists,
				classifications: list,
			});
		});
		dokiaInfo.classification = [];
	}, [dokiaInfo.subsidiary]);

	/* useEffect(() => {
		action.onReprovalGoesBack = reprovalGoesBack;
		action.onReprovalCancel = reprovalCancel;
	}, [reprovalGoesBack, reprovalCancel]); */

	useEffect(() => {
		if (preventEditingDokiaInfo) return;
		if (!dokiaInfo.classification || !dokiaInfo.classification.length) {
			return;
		}
		let last = dokiaInfo.classification[dokiaInfo.classification.length - 1];
		if (!last || !last.uid) {
			last = dokiaInfo.subsidiary;
			dokiaInfo.classification = [];
		}
		setT([...t, last]);
		if (dokiaInfo.classification.length <= t.length) {
			setT([]);
			dokiaInfo.classification = [];
			last = dokiaInfo.subsidiary;
		}
		dokiaApi.oi.classification.list(last.uid).then((list) => {
			setDokiaLists({
				...dokiaLists,
				classifications: list,
			});
		});
	}, [dokiaInfo.classification]);

	useEffect(() => {
		updateSignatories(prev => prev.sort((a, b) => {
			if (a.signatureType === b.signatureType) return 0;
			if (a.signatureType === "icp-brasil") return 1;
			return -1;
		}))
	}, [signatories]);

	if (action.deleted) return <></>;

	appcenterStore.groups.map((group) => {
		group.members.map((member) => {
			if (array.indexOf(member) === -1) {
				array.push(member);
			}
		});
	});

	appcenterStore.groups.map((group) => {
		appcenterStore.users.map((member) => {
			if (group.members.indexOf(member.uid) == -1) return;
			let i = newArray.findIndex((x) => x.uid == member.uid);
			if (i <= -1) {
				newArray.push({
					uid: member.uid,
					firstName: member.firstName,
					lastName: member.lastName,
					email: member.email,
				});
			}
		});
	});
	const customFormMail = () => {
		let componente = <h5>{i18n('workflow.form.actions.customFormMail.clean')}</h5>;
		let arrayComponent = [];
		const a = workflow.phases.findIndex((object) => {
			return object.uid === currentPhase.uid;
		});
		workflow.phases.map((object, i) => {
			if (i <= a) {
				object.actions.map((action, b) => {
					let fields = getFieldListFromString(action);
					if (action.type.type !== 'formInput') return;
					if (action.deleted) return;
					fields.map((fieldConfig, c) => {
						let type = fieldConfig.type;
						if (type === 'email') {
							let pushComponent = {
								name: fieldConfig.name,
								action: action.uid,
								uid: 'process-input-email',
								field: c,
							};
							arrayComponent.push(pushComponent);
						}
					});
				});
			}
		});
		if (arrayComponent.length !== 0) {
			return (
				<>
					<Autocomplete
						multiple
						clearOnBlur
						openOnFocus
						selectOnFocus
						handleHomeEndKeys
						disableClearable
						variant="filled"
						// value={action.emailsToNotifyFild}
						value={emailsToNotifyFildsLocal}
						// className="organization"
						getOptionLabel={(option) => {
							return option.name || '';
						}}
						options={arrayComponent}
						style={{ width: '100%', marginTop: 10 }}
						renderOption={(option) => <div>{option.name}</div>}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Email do formulário"
								placeholder="Email do formulário"
								variant="outlined"
							// className="autocomplete"
							/>
						)}
						onChange={(e, value) => {
							action.emailsToNotifyFilds = value;
							setEmailsToNotifyFildsLocal(value);
						}}
					/>
				</>
			);
		} else {
			action.emailsToNotifyFilds = [];
		}
		return componente;
	};

	const customFormCPF = () => {
		let componente = <h5>{i18n('workflow.form.actions.customFormCpf.clean')}</h5>;
		let arrayComponent = [];
		const genaratorOptionCpf = [
			{ name: 'Dados Profissionais', Datasets: 'occupation_data', acessName: 'ProfessionData' },
			{ name: 'Histórico Escolar e Acadêmico', Datasets: 'university_student_data', acessName: 'Scholarship' },
			{ name: 'Familiares', Datasets: 'related_people', acessName: 'RelatedPeople' },
			// { name: 'docs2', Datasets: "historical_basic_data", acessName: "HistoricalBasicData",},
			// { name: 'docs4', Datasets: "circles_legal_representatives", acessName:,},
			// { name: 'docs5', Datasets: "circles_first_level_owners", acessName:,}
		];

		const a = workflow.phases.findIndex((object) => {
			return object.uid === currentPhase.uid;
		});
		workflow.phases.map((object, i) => {
			if (i <= a) {
				object.actions.map((action, b) => {
					let fields = getFieldListFromString(action);
					if (action.type.type !== 'formInput') return;
					if (action.deleted) return;
					fields.map((fieldConfig, c) => {
						let type = fieldConfig.type;
						if (type === 'cpf') {
							let pushComponent = {
								name: fieldConfig.name,
								action: action.uid,
								uid: 'process-input-cpf',
								field: c,
							};
							arrayComponent.push(pushComponent);
						}
					});
				});
			}
		});
		if (arrayComponent.length !== 0) {
			return (
				<>
					<Autocomplete
						// disablePortal
						variant="filled"
						getOptionLabel={(option) => {
							return option.name || '';
						}}
						options={genaratorOptionCpf}
						value={action.genaratorOptionCpf}
						sx={{ width: 300 }}
						renderInput={(params) => <TextField variant="filled" {...params} label={i18n("categories")} />}
						onChange={(e, value) => {
							action.genaratorOptionCpf = value;
							// onLocalChangeProp('newWorkflowInputText')
						}}
					/>
					<Autocomplete
						// multiple
						clearOnBlur
						openOnFocus
						selectOnFocus
						handleHomeEndKeys
						disableClearable
						variant="filled"
						value={action.queryCpf}
						getOptionLabel={(option) => {
							return option.name || '';
						}}
						options={arrayComponent}
						style={{ width: '100%', marginTop: 10 }}
						renderOption={(option) => <div>{option.name}</div>}
						renderInput={(params) => (
							<TextField {...params} label={i18n("cpfForm")} placeholder={i18n("cpfForm")} variant="outlined" />
						)}
						onChange={(e, value) => {
							action.queryCpf = value;
						}}
					/>
				</>
			);
		} else {
			action.queryCpf = [];
		}
		return componente;
	};

	const customFormInputNewWorkflow = () => {
		let componente = <h5>{i18n('workflow.form.actions.newWorkflowInputTitle.clean')}</h5>;
		let arrayComponent = [];
		const a = workflow.phases.findIndex((object) => {
			return object.uid === currentPhase.uid;
		});
		workflow.phases.map((object, i) => {
			if (i <= a) {
				object.actions.map((action, b) => {
					let fields = getFieldListFromString(action);
					if (action.type.type !== 'formInput') return;
					if (action.deleted) return;
					fields.map((fieldConfig, c) => {
						let type = fieldConfig.type;
						if (type === 'textarea') {
							let pushComponent = {
								name: fieldConfig.name,
								action: action.uid,
								uid: 'process-input-text',
								field: c,
							};
							arrayComponent.push(pushComponent);
						}
					});
				});
			}
		});
		if (arrayComponent.length !== 0) {
			return (
				<>
					<Autocomplete
						clearOnBlur
						openOnFocus
						selectOnFocus
						handleHomeEndKeys
						disableClearable
						variant="filled"
						value={action.newWorkflowInputText}
						className="organization"
						getOptionLabel={(option) => {
							return option.name || '';
						}}
						options={arrayComponent}
						style={{ width: '100%', marginTop: 2, marginBottom: 2 }}
						renderOption={(option) => <div>{option.name}</div>}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Titulo do novo fluxo que vem a partir de um formulário"
								placeholder="Titulo do novo fluxo que vem a partir de um formulário"
								variant="outlined"
							/>
						)}
						onChange={(e, value) => {
							action.newWorkflowInputText = value;
							// onLocalChangeProp('newWorkflowInputText')
						}}
					/>
				</>
			);
		} else {
			action.newWorkflowInputText = [];
		}
		return componente;
	};

	const authGed = () => {
		services.spinner.show();

		Api.ged17
			.authenticate(action.ged17User, action.ged17Password, action.ged17UrlGed)
			.then(getWorkspaces)
			.finally(services.spinner.hide);
	};
	const reauthGed = () => {
		setWorkspaces([]);
	};
	const getWorkspaces = () => {
		services.spinner.show();
		setWorkspace('');
		setOrganizationUnit('');
		Api.ged17
			.getWorkspaces()
			.then((r) => {
				setWorkspaces(r);
			})
			.finally(services.spinner.hide);
	};
	const getOrganizationUnits = (workspace) => {
		setWorkspace(workspace);
		setOrganizationUnit('');
		services.spinner.show();
		Api.ged17
			.getOrganizationUnits(workspace.id)
			.then((r) => {
				setOrganizationUnits(r);
			})
			.finally(services.spinner.hide);
	};
	const getCompanies = (organizationUnit) => {
		setOrganizationUnit(organizationUnit);
		services.spinner.show();
		Api.ged17
			.getCompanies(organizationUnit.id)
			.then((r) => {
				setCompanies(r);
			})
			.finally(services.spinner.hide);
	};
	const getClassifications = (company) => {
		setCompany(company);
		services.spinner.show();
		Api.ged17
			.getClassifications(company.id)
			.then((r) => {
				let classifications = {};
				r.sort((a, b) => {
					if (a.identifier > b.identifier) return 1;
					if (a.identifier < b.identifier) return -1;
					return 0;
				}).map((item) => {
					let parts = item.identifier.split('.');
					let object = classifications;

					parts.map((part, i) => {
						if (!object[part]) {
							object[part] = {
								...item,
								children: {},
							};
						}
						object = object[part].children;
					});
				});

				let classificationsArray = [];
				Object.values(classifications).map((c) => {
					classificationsArray.push(c);
					if (Object.values(c.children).length) {
						Object.values(c.children).map((cc) => {
							classificationsArray.push(cc);
						});
					}
				});

				setClassifications(classificationsArray);
			})
			.finally(services.spinner.hide);
	};

	let actionContent = <div></div>;
	let tdocsReactions = [];
	let forms = [];
	workflow.phases.forEach((phase, i) => {
		phase.actions.forEach((action, a) => {
			if (action.type.type == 'formInput' && action.deleted !== true) {
				forms.push({ name: action.formTitle, action: action.uid, phase: phase.uid, index: i });
			}
		});
		phase.reactions.forEach((r) => {
			if (r.type.type === 'generate-tdoc' && r.deleted !== true) {
				tdocsReactions.push({
					name: phase.name,
					index: i,
					phaseUid: phase.uid,
					reactionUid: r.uid,
					contractName: r.contractName?.form,
				});
			}
			// if (r.type.type === 'start-collectame-process' && r.deleted !== true) {
			// 	yopenReactions.push({ name: phase.name, index: i, phaseUid: phase.uid, reactionUid: r.uid });
			// }
		});
	});
	switch (action.type.type) {
		case 'approval':
			actionContent = (
				<div>
					<TextField
						variant="filled"
						style={{ width: '100%', marginBottom: 10 }}
						multiline={true}
						value={action.instructions}
						label={i18n('workflow.form.actions.instructions')}
						onChange={onLocalChangeProp('instructions')}
						disabled={!isMappingAdmin}
						InputProps={{
							endAdornment: (
								<Tooltip
									title={
										<div style={{ fontSize: 14 }}>
											{i18n('workflow.form.actions.instructions.explanation')}
										</div>
									}
								>
									<InputAdornment position="end">
										<Icon icon="faQuestionCircle" size="lg" />
									</InputAdornment>
								</Tooltip>
							),
						}}
					/>
					<div>
						<FormControlLabel
							label={i18n('workflow.form.actions.isApprovalRequired')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox
									checked={action.isApprovalRequired}
									onChange={onLocalChangeProp('isApprovalRequired')}
								// value={true}
								/>
							}
						/>
						<FormControlLabel
							label={i18n('workflow.form.actions.onReprovalGoesBack')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox
									checked={action.onReprovalGoesBack}
									onChange={onLocalChangeProp('onReprovalGoesBack')}
								/>
							}
						/>
						<FormControlLabel
							label={i18n('workflow.form.actions.onReprovalCancel')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox checked={action.onReprovalCancel} onChange={onLocalChangeProp('onReprovalCancel')} />
							}
						/>
					</div>
				</div>
			);
			break;
		case 'documentInput':
			actionContent = (
				<div>
					<TextField
						variant="filled"
						style={{ width: '100%' }}
						className="textfield-input-white-label"
						multiline={true}
						value={action.instructions}
						label={i18n('workflow.form.actions.expectedDocument')}
						onChange={onLocalChangeProp('instructions')}
						disabled={!isMappingAdmin}
						InputProps={{
							endAdornment: (
								<Tooltip
									title={
										<div style={{ fontSize: 14 }}>
											{i18n('workflow.form.actions.expectedDocument.explanation')}
										</div>
									}
								>
									<InputAdornment position="end">
										<Icon icon="faQuestionCircle" size="lg" />
									</InputAdornment>
								</Tooltip>
							),
						}}
					/>
					<FormControlLabel
						label={i18n('workflow.form.actions.isSigningRequired')}
						className="checkbox-control"
						disabled={!isMappingAdmin}
						control={
							<Checkbox checked={action.isSigningRequired} onChange={onLocalChangeProp('isSigningRequired')} />
						}
					/>
					<FormControlLabel
						label={i18n('workflow.form.actions.isMultiple')}
						className="checkbox-control"
						disabled={!isMappingAdmin}
						control={<Checkbox checked={action.isMultiple} onChange={onLocalChangeProp('isMultiple')} />}
					/>
				</div>
			);
			break;
		case 'formInput':
			actionContent = (
				<div className="form-input-content">
					<div>
						<TextField
							variant="filled"
							style={{ width: '100%' }}
							label={i18n('workflow.form.actions.formTitle')}
							value={action.formTitle}
							onChange={onLocalChangeProp('formTitle')}
							InputLabelProps={{ shrink: true }}
							disabled={!isMappingAdmin}
							InputProps={{
								endAdornment: (
									<Tooltip
										title={
											<div style={{ fontSize: 14 }}>
												{i18n('workflow.form.actions.formTitle.explanation')}
											</div>
										}
									>
										<InputAdornment position="end">
											<Icon icon="faQuestionCircle" size="lg" />
										</InputAdornment>
									</Tooltip>
								),
							}}
						/>
					</div>
					<TextField
						variant="filled"
						style={{ width: '100%' }}
						multiline={true}
						value={action.formFields}
						label={i18n('workflow.form.formInputType.formFieldsLabel')}
						onChange={onLocalChangeProp('formFields')}
						disabled={!isMappingAdmin}
						inputProps={{
							style: { whiteSpace: 'nowrap', overflow: 'auto', minHeight: 130 },
						}}
						InputProps={{
							endAdornment: (
								<Tooltip
									placement="left"
									title={
										<div
											style={{
												fontSize: 14,
											}}
										>
											Para cada campo desejado, adicione uma linha de texto neste campo. <br />
											É possível utilizar tipos diferentes de campos, colocando um @ no final do campo mais
											o tipo desejado Exemplo: Data de Nascimento@date. <br />
											<br />
											Tipos de campo:
											<br />
											Checkbox@checkbox
											<br />
											Opções de Seleção@select(opcao A\opcao B\opcao C\opcao ETC)
											<br />
											Opções de Tabela@table(Coluna A\Coluna B\Coluna ETC)
											<br />
											CPF@cpf
											<br />
											CNPJ@cnpj
											<br />
											Máscara@mask#999-aa-aaa9-99-XYZ-99
											<br />
											Data e Hora@datetime-local
											<br />
											Texto Livre@textarea
											<br />
											Texto Rico@richtext
											<br />
											Usuário@user
											<br />
											Grupo@group
											<br />
											Data@date
											<br />
											Hora@time
											<br />
											Números@number
											<br />
											Email@email
											<br />
											Telefone@tel
											<br />
											Competência@month
											<br />
											Site@url
											<br />
											Cor@color
											<br />
											<br />
											Campos de máscara:
											<br />
											Onde precisa de números utilize 9<br />
											Onde precisa de letras utilize a<br />
											onde pode ser alphanumérico utilize *<br />
											<br />
											<br />
											Quebras de linha automáticas não influenciam.
											<br />
										</div>
									}
								>
									<InputAdornment position="end">
										<Icon icon="faQuestionCircle" size="lg" />
									</InputAdornment>
								</Tooltip>
							),
						}}
					/>
					{!!(action.formFields || '').length && (
						<div>
							<div
								className="form-example-title"
								onClick={() => {
									// toggleVisibleId(`action-${action.uid}-form-input-example`)();
									// toggleVisibleId(`action-${action.uid}-form-input-example-caret`)();
									services.message.show(
										action.formTitle,
										<div id={`action-${action.uid}-form-input-example`} className="form-example-content">
											{(action.formFields || '')
												.split(/(\;|\n)/g)
												.filter((s) => s.replace(/\;/g, '').trim())
												.map((fieldconfig, i) => {
													let field, type, s, options;
													if (fieldconfig.indexOf('@') > -1) {
														s = fieldconfig.split('@');
														field = s[0];
														type = s[1];
													} else {
														field = fieldconfig;
														type = 'string';
													}
													if (type.indexOf('(') > -1) {
														s = type.split('(');
														type = s[0];
														if (type === 'select') {
															options = s[1].replace(')', '').split('\\');
															return (
																<div key={i}>
																	<FormControl
																		style={{ width: '100%', marginBottom: 10 }}
																		variant="outlined"
																	>
																		<InputLabel>{field}</InputLabel>
																		<Select value={0}>
																			{options.map((option, a) => {
																				return (
																					<MenuItem value={a} key={a}>
																						{option}
																					</MenuItem>
																				);
																			})}
																		</Select>
																	</FormControl>
																</div>
															);
														}
													}
													if (type === 'checkbox') {
														return (
															<div key={i}>
																<FormControl style={{ width: '100%' }} variant="filled">
																	<FormControlLabel
																		label={field}
																		className="checkbox-control"
																		control={<Checkbox />}
																	/>
																</FormControl>
															</div>
														);
													}
													if (type == 'table') {
														options = s[1].replace(')', '').split('\\');
														return (
															<div style={{ marginTop: 10 }}>
																<div className="form-field-label">{field}</div>
																<table className="table-sheet">
																	<tr className="row headers">
																		<th className="col actions">
																			<div
																				onClick={(e) => {
																					let o = {};
																				}}
																			>
																				<Icon on icon="faPlus" />
																			</div>
																		</th>

																		{options.map(function (value, label) {
																			return (
																				<th className="col" key={label}>
																					{value}
																				</th>
																			);
																		})}
																	</tr>
																	<tr className="row">
																		<td className="col actions">
																			<div
																				onClick={(e) => {
																					// formValues[field].splice(a, 1);
																					// setFormValue({ ...formValues });
																				}}
																			>
																				<Icon icon="faTrash" />
																			</div>
																		</td>
																		{options.map((option, oi) => {
																			return (
																				<td className="col" key={oi}>
																					<Input />
																				</td>
																			);
																		})}
																	</tr>
																</table>
															</div>
														);
													}
													if (type === 'title') {
														return (
															<div key={i}>
																<h4>{field}</h4>
															</div>
														);
													}
													if (type === 'subtitle') {
														return (
															<div key={i}>
																<h5>{field}</h5>
															</div>
														);
													}
													if (type === 'group' || type === 'systemGroup') {
														return (
															<Autocomplete
																label={field}
																disablePortal
																multiple
																options={appcenterStore.groups}
																variant="outlined"
																getOptionLabel={(option) => option.name}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		variant="standard"
																		label={field}
																		placeholder="Escolha o grupo"
																	/>
																)}
																onChange={(e, value) => {
																	if (value.length === 0) {
																		workflow.executorGroups = [];
																		return;
																	} else {
																		value.forEach((group) => {
																			workflow.executorGroups.push({
																				name: group.name,
																				uid: group.uid,
																			});
																		});
																	}
																}}
															/>
														);
													}
													if (type === 'user' || type === 'systemUser') {
														return (
															<Autocomplete
																label={field}
																disablePortal
																multiple
																options={appcenterStore.users}
																variant="outlined"
																// options={appcenterStore.groups.map((option)=>option.name)}
																getOptionLabel={(option) =>
																	option.firstName + ' ' + option.lastName
																}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		variant="standard"
																		label={field}
																		placeholder="Escolha o usuário"
																	/>
																)}
																onChange={(e, value) => {
																	if (value.length === 0) {
																		workflow.executorGroups = [];
																		return;
																	} else {
																		value.forEach((group) => {
																			workflow.executorGroups.push({
																				name: group.firstName,
																				uid: group.uid,
																			});
																		});
																	}
																}}
															/>
														);
													}
													return (
														<div key={i}>
															<Input
																mode="top-label outlined "
																label={field}
																type={type}
																multiline={type === 'textarea'}
															/>
														</div>
													);
												})}
										</div>
									);
								}}
							>
								<div className={'action-toggler'} id={`action-${action.uid}-form-input-example-caret`}>
									<Icon icon="faCaretDown" size={'lg'} />
								</div>
								<span>{i18n('workflow.form.formInputType.formExampleTitle')}</span>
							</div>
						</div>
					)}
				</div>
			);
			break;
		case 'notificationEmail':
			actionContent = (
				<div style={{ marginTop: 10 }}>
					<TextField
						variant="filled"
						style={{ width: '100%' }}
						label={i18n('workflow.form.actions.emailsToNotify')}
						InputLabelProps={{ shrink: true }}
						value={action.emailsToNotify}
						disabled={!isMappingAdmin}
						onChange={onLocalChangeProp('emailsToNotify')}
						InputProps={{
							endAdornment: (
								<Tooltip
									title={
										<div>
											<p>
												Separe os e-mails com <strong>;</strong>
											</p>
										</div>
									}
								>
									<InputAdornment position="end">
										<Icon icon="faQuestionCircle" size="lg" />
									</InputAdornment>
								</Tooltip>
							),
						}}
					/>

					<FormControlLabel
						label={i18n('workflow.form.actions.customMail')}
						className="checkbox-control"
						disabled={!isMappingAdmin}
						control={<Checkbox checked={action.hasCustomMail} onChange={onLocalChangeProp('hasCustomMail')} />}
					/>
					{!!action.hasCustomMail && (
						<div>
							<TextField
								variant="outlined"
								style={{ width: '100%' }}
								label={i18n('workflow.form.actions.customMail.subject')}
								InputLabelProps={{ shrink: true }}
								value={action.customMailSubject || ''}
								disabled={!isMappingAdmin}
								onChange={onLocalChangeProp('customMailSubject')}
							/>
							<TextField
								multiline
								variant="outlined"
								style={{ width: '100%', marginTop: 8 }}
								label={i18n('workflow.form.actions.customMail.content')}
								InputLabelProps={{ shrink: true }}
								value={action.customMailContent || ''}
								disabled={!isMappingAdmin}
								onChange={onLocalChangeProp('customMailContent')}
								InputProps={{
									endAdornment: (
										<Tooltip
											title={
												<div style={{ fontSize: 14 }}>
													<p>Utilize {'<br>'} para gerar uma quebra de linha</p>
													<p>
														Para títulos utilize: {'<h4>'} Exemplo: {'</h4>'}
													</p>
												</div>
											}
										>
											<InputAdornment position="end">
												<Icon icon="faQuestionCircle" size="lg" />
											</InputAdornment>
										</Tooltip>
									),
								}}
							/>
						</div>
					)}
					<FormControlLabel
						label={i18n('workflow.form.actions.customFormMail')}
						className="checkbox-control"
						disabled={!isMappingAdmin}
						control={
							<Checkbox checked={action.hasCustomFormMail} onChange={onLocalChangeProp('hasCustomFormMail')} />
						}
					/>
					{!!action.hasCustomFormMail && <div>{customFormMail()}</div>}
				</div>
			);
			break;
		case 'queryCpf':
			actionContent = <div style={{ marginTop: 10 }}>{customFormCPF()}</div>;
			break;
		case 'ged17':
			actionContent = (
				<div style={{ marginTop: 10 }}>
					<TextField
						variant="filled"
						style={{ width: '100%' }}
						type="Documento a ser enviado"
						label={i18n('workflow.form.actions.originDocument')}
						InputLabelProps={{ shrink: true }}
						value={action.originDocument}
						disabled={!isMappingAdmin}
						onChange={onLocalChangeProp('originDocument')}
						select
					>
						{phaseActionsList.map((action, a) => {
							if (action.type.type !== 'documentInput') return;
							if (action.deleted) return;
							return (
								<MenuItem value={action.uid} key={a}>
									{action.instructions}
								</MenuItem>
							);
						})}
					</TextField>
					<TextField
						variant="filled"
						style={{ width: '100%' }}
						type="URL do ambiente"
						label={i18n('workflow.form.actions.ged17UrlGed')}
						InputLabelProps={{ shrink: true }}
						value={action.ged17UrlGed}
						disabled={!isMappingAdmin}
						onChange={onLocalChangeProp('ged17UrlGed')}
					/>
					<TextField
						variant="filled"
						style={{ width: '100%' }}
						label={i18n('workflow.form.actions.ged17User')}
						InputLabelProps={{ shrink: true }}
						value={action.ged17User}
						disabled={!isMappingAdmin}
						onChange={onLocalChangeProp('ged17User')}
					/>
					<TextField
						variant="filled"
						style={{ width: '100%' }}
						type="password"
						label={i18n('workflow.form.actions.ged17Password')}
						InputLabelProps={{ shrink: true }}
						disabled={!isMappingAdmin}
						value={action.ged17Password}
						onChange={onLocalChangeProp('ged17Password')}
					/>

					<div className="columns">
						<simple-button onClick={authGed}>Entrar</simple-button>
					</div>
					{!!action.ged17Config.workspace && (
						<div className="ged-17-info">
							<div className="info">{action.ged17Config.workspace.title}</div>
							<div className="info">{action.ged17Config.organizationUnit.title}</div>
							<div className="info">{action.ged17Config.company.title}</div>
							<div className="info">{action.ged17Config.classification.title}</div>
						</div>
					)}
					{!action.ged17Config.workspace && (
						<div className="ged-17-info">
							{!!workspaces.length && (
								<FormControl variant="filled" style={{ width: '100%', marginTop: 15 }}>
									<Autocomplete
										disabled={!isMappingAdmin}
										options={workspaces}
										// filterOptions={(options, state) => {
										// 	return options.filter((item) => {});
										// }}
										getOptionLabel={(workspace) => workspace.title || ''}
										renderInput={(params) => <TextField {...params} label={'Workspace'} variant="outlined" />}
										renderValue={(o) => {
											return <div>{o.title}</div>;
										}}
										onChange={(e, newValue) => {
											//(newValue);
											getOrganizationUnits(newValue);
										}}
									/>
								</FormControl>
							)}
							{!!organizationUnits.length && (
								<FormControl variant="filled" style={{ width: '100%', marginTop: 15 }}>
									<Autocomplete
										disabled={!isMappingAdmin}
										options={organizationUnits}
										// filterOptions={(options, state) => {
										// 	return options.filter((item) => {});
										// }}
										getOptionLabel={(organizationUnit) => organizationUnit.title || ''}
										renderInput={(params) => (
											<TextField {...params} label={'Organizações'} variant="outlined" />
										)}
										renderValue={(o) => {
											return <div>{o.title}</div>;
										}}
										onChange={(e, newValue) => {
											//(newValue);
											getCompanies(newValue);
										}}
									/>
								</FormControl>
							)}
							{!!companies.length && (
								<FormControl variant="filled" style={{ width: '100%', marginTop: 15 }}>
									<Autocomplete
										disabled={!isMappingAdmin}
										options={companies}
										// filterOptions={(options, state) => {
										// 	return options.filter((item) => {});
										// }}
										getOptionLabel={(company) => `${company.identifier} - ${company.title}`}
										renderInput={(params) => <TextField {...params} label={i18n("workflow.form.actions.ged17Company")} variant="outlined" />}
										renderValue={(o) => {
											return <div>{o.title}</div>;
										}}
										onChange={(e, newValue) => {
											//(newValue);
											getClassifications(newValue);
										}}
									/>
								</FormControl>
							)}
							{!!classifications.length && (
								<FormControl variant="filled" style={{ width: '100%', marginTop: 15 }}>
									<Autocomplete
										disabled={!isMappingAdmin}
										options={classifications}
										getOptionLabel={(classification) =>
											`${classification.identifier} - ${classification.title}`
										}
										renderInput={(params) => (
											<TextField {...params} label={'Classificação'} variant="outlined" />
										)}
										renderOption={(o) => {
											return (
												<div style={{ width: '100%', borderBottom: '1px solid #ddd', fontSize: 12 }}>
													{o.identifier}-{o.title}
												</div>
											);
										}}
										onChange={(e, newValue) => {
											action.ged17Config = {
												workspace,
												organizationUnit,
												company,
												classification: newValue,
											};
										}}
									/>
								</FormControl>
							)}
						</div>
					)}
				</div>
			);
			break;
		case 'documentOutput':
			if (typeof action.createApprovalDocument === 'string') action.createApprovalDocument = true;
			if (typeof action.requireApprovalDocumentSign === 'string') action.requireApprovalDocumentSign = true;
			if (typeof action.createDocumentFromForm === 'string') action.createDocumentFromForm = false;
			actionContent = (
				<div style={{ marginTop: 10 }}>
					<FormControlLabel
						label={i18n('workflow.form.actions.createApprovalDocument')}
						className="checkbox-control"
						disabled={!isMappingAdmin}
						control={
							<Checkbox
								checked={action.createApprovalDocument}
								onChange={(e) => {
									action.createApprovalDocument = e.target.checked;
									action.createDocumentFromForm = false;
									action.originForm = '';
									updateWorkflow();
								}}
							/>
						}
					/>
					{!!action.createApprovalDocument && (
						<div>
							<div>
								<FormControl style={{ width: '100%' }}>
									<InputLabel id={`action-${action.uid}-select-approval`}>
										{i18n('workflow.form.actionTypes.approval.name')}
									</InputLabel>
									<Select
										labelId={`action-${action.uid}-select-approval`}
										value={action.originApproval}
										disabled={!isMappingAdmin}
										onChange={onLocalChangeProp('originApproval')}
									>
										{phaseActionsList.map((action, a) => {
											if (action.type.type !== 'approval') return;
											if (action.deleted) return;
											return (
												<MenuItem value={action.uid} key={a}>
													{action.instructions}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</div>
							<FormControlLabel
								label={i18n('workflow.form.actions.isSigningRequired')}
								className="checkbox-control"
								disabled={!isMappingAdmin}
								control={
									<Checkbox
										checked={action.requireApprovalDocumentSign}
										onChange={onLocalChangeProp('requireApprovalDocumentSign')}
									/>
								}
							/>
						</div>
					)}
					<FormControlLabel
						label={i18n('workflow.form.actions.createDocumentFromForm')}
						className="checkbox-control"
						disabled={!isMappingAdmin}
						control={
							<Checkbox
								checked={action.createDocumentFromForm}
								onChange={(e) => {
									action.createDocumentFromForm = e.target.checked;
									action.createApprovalDocument = false;
									action.requireApprovalDocumentSign = false;
									updateWorkflow();
								}}
							/>
						}
					/>
					{!!action.createDocumentFromForm && (
						<div>
							<FormControl style={{ width: '100%' }}>
								<InputLabel id={`action-${action.uid}-select-approval`}>
									{i18n('workflow.form.actionTypes.formInput.name')}
								</InputLabel>
								<Select
									labelId={`action-${action.uid}-select-approval`}
									value={action.originForm}
									onChange={onLocalChangeProp('originForm')}
								>
									{phaseActionsList.map((action, a) => {
										if (action.type.type !== 'formInput') return;
										if (action.deleted) return;
										return (
											<MenuItem value={action.uid} key={a}>
												{action.formTitle}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControlLabel
								label={i18n('workflow.form.actions.isSigningRequired')}
								className="checkbox-control"
								disabled={!isMappingAdmin}
								control={
									<Checkbox
										checked={action.requireApprovalDocumentSign}
										onChange={onLocalChangeProp('requireApprovalDocumentSign')}
									/>
								}
							/>
						</div>
					)}
				</div>
			);
			break;
		case 'phaseJump':
			actionContent = (
				<div style={{ marginTop: 10 }}>
					<div>
						<TextField
							variant="filled"
							style={{ width: '100%', marginBottom: 10 }}
							label={i18n('workflow.form.actions.phaseSelection')}
							InputLabelProps={{ shrink: true }}
							value={action.jumpPhase}
							onChange={onLocalChangeProp('jumpPhase')}
							disabled={!isMappingAdmin}
							select
						>
							{workflow.phases.map((phase, i) => {
								if (phase.deleted) return;
								return (
									<MenuItem key={phase.uid} value={phase.uid}>
										{phase.name}
									</MenuItem>
								);
							})}
						</TextField>
					</div>
					<div>
						<div className="info">Selecione uma opção</div>
						<FormControlLabel
							label={i18n('workflow.form.actions.phasejump.onreproval')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox
									checked={action.jumpPhaseOnReproval}
									onChange={(e) => {
										action.jumpPhaseOnReproval = true;
										action.jumpPhaseOnApproval = false;
										action.jumpPhaseWhenFormFields = false;
										updateWorkflow();
									}}
								/>
							}
						/>
						<FormControlLabel
							label={i18n('workflow.form.actions.phasejump.onapproval')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox
									checked={action.jumpPhaseOnApproval}
									onChange={(e) => {
										action.jumpPhaseOnReproval = false;
										action.jumpPhaseOnApproval = true;
										action.jumpPhaseWhenFormFields = false;
										updateWorkflow();
									}}
								/>
							}
						/>
						<FormControlLabel
							label={i18n('workflow.form.actions.phasejump.whenformfields')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox
									checked={action.jumpPhaseWhenFormFields}
									onChange={(e) => {
										action.jumpPhaseOnReproval = false;
										action.jumpPhaseOnApproval = false;
										action.jumpPhaseWhenFormFields = true;
										updateWorkflow();
									}}
								/>
							}
						/>
						{action.jumpPhaseWhenFormFields && (
							<div>
								<TextField
									variant="filled"
									style={{ width: '100%' }}
									label={i18n('workflow.form.actionTypes.formInput.name')}
									value={action.originForm}
									disabled={!isMappingAdmin}
									onChange={onLocalChangeProp('originForm')}
									InputLabelProps={{ shrink: true }}
									select
								>
									{phaseActionsList.map((action, a) => {
										if (action.type.type !== 'formInput') return;
										if (action.deleted) return;
										return (
											<MenuItem value={action.uid} key={a}>
												{action.formTitle}
											</MenuItem>
										);
									})}
								</TextField>
								<div className="phase-jump-form-config">
									<div className="form-title">
										{i18n('workflow.form.actions.phaseSelection.formFieldsConfig')}
									</div>
									<div className="field-conditional-configuration">
										{getFieldListFromString(phaseActionsList.find((a) => action.originForm === a.uid)).map(
											(field, fieldIndex) => {
												if (!action.jumpPhaseFormFieldsConfig) {
													action.jumpPhaseFormFieldsConfig = { conditions: [] };
												}
												if (!action.jumpPhaseFormFieldsConfig.conditions[fieldIndex]) {
													action.jumpPhaseFormFieldsConfig.conditions[fieldIndex] = {
														operator: '',
														value: '',
														field: field.name,
													};
												}
												let config = action.jumpPhaseFormFieldsConfig;
												return (
													<div className="field-configuration">
														<div className="field-title">{field.name}</div>
														<div className="columns">
															<div className="operators column">
																<TextField
																	style={{ width: '90%' }}
																	InputLabelProps={{ shrink: true }}
																	label={i18n(
																		'workflow.form.actions.phaseSelection.formFieldsConfig.operators'
																	)}
																	value={config.conditions[fieldIndex].operator}
																	onChange={(e) => {
																		config.conditions[fieldIndex].operator = e.target.value;
																		updateWorkflow();
																	}}
																	select
																>
																	<MenuItem value="equal">Igual</MenuItem>
																	<MenuItem value="different">Diferente</MenuItem>
																	<MenuItem value="higherThan">Maior que</MenuItem>
																	<MenuItem value="lessThan">Menor que</MenuItem>
																</TextField>
															</div>
															<div className="value column">
																<TextField
																	style={{ width: '90%' }}
																	InputLabelProps={{ shrink: true }}
																	label={i18n(
																		'workflow.form.actions.phaseSelection.formFieldsConfig.value'
																	)}
																	value={config.conditions[fieldIndex].value}
																	onChange={(e) => {
																		config.conditions[fieldIndex].value = e.target.value;
																		updateWorkflow();
																	}}
																	select={field.type === 'select' || field.type == 'checkbox'}
																>
																	{(field.options || []).map((option) => {
																		if (field.type === 'checkbox') {
																			return (
																				<MenuItem value={option}>
																					{option ? 'Marcado' : 'Desmarcado'}
																				</MenuItem>
																			);
																		}
																		return <MenuItem value={option}>{option}</MenuItem>;
																	})}
																</TextField>
															</div>
														</div>
													</div>
												);
											}
										)}
									</div>
								</div>
							</div>
						)}
						{(action.jumpPhaseOnReproval || action.jumpPhaseOnApproval) && (
							<div>
								<TextField
									variant="filled"
									style={{ width: '100%' }}
									label={i18n('workflow.form.actionTypes.approval.name')}
									value={action.originApproval}
									disabled={!isMappingAdmin}
									onChange={onLocalChangeProp('originApproval')}
									InputLabelProps={{ shrink: true }}
									select
								>
									{phaseActionsList.map((action, a) => {
										if (action.type.type !== 'approval') return;
										if (action.deleted) return;
										return (
											<MenuItem value={action.uid} key={a}>
												{action.instructions}
											</MenuItem>
										);
									})}
								</TextField>
							</div>
						)}
					</div>
				</div>
			);

			break;
		case 'newWorkflow':
			actionContent = (
				<div style={{ marginTop: 10 }}>
					<div>
						<TextField
							closeOnSelect
							variant="filled"
							style={{ width: '100%', marginBottom: 10 }}
							label={i18n('workflow.form.actions.workareaSelection')}
							InputLabelProps={{ shrink: true }}
							value={action.newWorkflow}
							onChange={onLocalChangeProp('newWorkflow')}
							disabled={!isMappingAdmin}
							select
						>
							{companyAreaStore.list
								.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})
								.map((list, i) => {
									if (list.deleted) return;
									return (
										<MenuItem key={list.uid} value={list.uid}>
											{list.name}
										</MenuItem>
									);
								})}
						</TextField>
						{action.newWorkflow !== undefined && (
							<FormControl style={{ width: '100%', marginBottom: 10 }}>
								<InputLabel>{i18n('workflow.form.actions.workflowSelection')}</InputLabel>
								<Select
									closeOnSelect
									variant="filled"
									style={{ width: '100%', marginBottom: 10 }}
									label={i18n('workflow.form.actions.workflowSelection')}
									defaultValue={action.newWorkflowUid}
									onChange={onLocalChangeProp('newWorkflowUid')}
								>
									{workflows
										.sort((a, b) => {
											if (a.document.title > b.document.title) return 1;
											if (a.document.title < b.document.title) return -1;
											return 0;
										})
										.map((list, i) => {
											if (list.deleted) return;
											let phasesWithProblem = list.phases
												.filter((a) => !a.deleted)
												.find((phase) => {
													let actions = phase.actions.filter((action) => !action.deleted);
													if (!actions.length) {
														return phase;
													}
													let foundProblem = false;
													actions.map((action) => {
														if (!action.group || !action.group.uid) {
															foundProblem = true;
														}
													});
													if (foundProblem) return phase;
												});
											if (phasesWithProblem) return;
											if (!list.group || !list.group.uid) return;
											if (!list.phases.filter((a) => !a.deleted).length) return;
											return (
												<MenuItem key={i} value={list.uid}>
													{list.document.title}
												</MenuItem>
											);
										})}
								</Select>
							</FormControl>
						)}
						<TextField
							closeOnSelect
							variant="filled"
							style={{ width: '100%', marginBottom: 10 }}
							label={i18n('workflow.form.actions.newWorkflowFixTitle')}
							InputLabelProps={{ shrink: true }}
							value={action.newWorkflowFixText}
							onChange={onLocalChangeProp('newWorkflowFixText')}
							disabled={!isMappingAdmin}
						></TextField>
						{customFormInputNewWorkflow()}
					</div>
					<div>
						<div className="info">Selecione uma opção</div>
						<FormControlLabel
							label={i18n('workflow.form.actions.phasejump.onreproval')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox
									checked={action.newWorkflowOnReproval}
									onChange={(e) => {
										action.newWorkflowOnReproval = true;
										action.newWorkflowOnApproval = false;
										action.newWorkflowWhenFormFields = false;
										updateWorkflow();
									}}
								/>
							}
						/>
						<FormControlLabel
							label={i18n('workflow.form.actions.phasejump.onapproval')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox
									checked={action.newWorkflowOnApproval}
									onChange={(e) => {
										action.newWorkflowOnReproval = false;
										action.newWorkflowOnApproval = true;
										action.newWorkflowWhenFormFields = false;
										updateWorkflow();
									}}
								/>
							}
						/>
						<FormControlLabel
							label={i18n('workflow.form.actions.phasejump.whenformfields')}
							className="checkbox-control"
							disabled={!isMappingAdmin}
							control={
								<Checkbox
									checked={action.newWorkflowWhenFormFields}
									onChange={(e) => {
										action.newWorkflowOnReproval = false;
										action.newWorkflowOnApproval = false;
										action.newWorkflowWhenFormFields = true;
										updateWorkflow();
									}}
								/>
							}
						/>
						{action.newWorkflowWhenFormFields && (
							<div>
								<TextField
									variant="filled"
									style={{ width: '100%' }}
									label={i18n('workflow.form.actionTypes.formInput.name')}
									value={action.originFormNewWorkflow}
									disabled={!isMappingAdmin}
									onChange={onLocalChangeProp('originFormNewWorkflow')}
									InputLabelProps={{ shrink: true }}
									select
								>
									{phaseActionsList.map((action, a) => {
										if (action.type.type !== 'formInput') return;
										if (action.deleted) return;
										return (
											<MenuItem value={action.uid} key={a}>
												{action.formTitle}
											</MenuItem>
										);
									})}
								</TextField>
								<div className="phase-jump-form-config">
									<div className="form-title">
										{i18n('workflow.form.actions.phaseSelection.formFieldsConfig')}
									</div>
									<div className="field-conditional-configuration">
										{getFieldListFromString(
											phaseActionsList.find((a) => action.originFormNewWorkflow === a.uid)
										).map((field, fieldIndex) => {
											if (!action.newWorkflowFormFieldsConfig) {
												action.newWorkflowFormFieldsConfig = { conditions: [] };
											}
											if (!action.newWorkflowFormFieldsConfig.conditions[fieldIndex]) {
												action.newWorkflowFormFieldsConfig.conditions[fieldIndex] = {
													operator: '',
													value: '',
													field: field.name,
												};
											}
											let config = action.newWorkflowFormFieldsConfig;
											return (
												<div className="field-configuration">
													<div className="field-title">{field.name}</div>
													<div className="columns">
														<div className="operators column">
															<TextField
																style={{ width: '90%' }}
																InputLabelProps={{ shrink: true }}
																label={i18n(
																	'workflow.form.actions.phaseSelection.formFieldsConfig.operators'
																)}
																value={config.conditions[fieldIndex].operatorNewWorkflow}
																onChange={(e) => {
																	config.conditions[fieldIndex].operatorNewWorkflow =
																		e.target.value;
																	updateWorkflow();
																}}
																select
															>
																<MenuItem value="equal">Igual</MenuItem>
																<MenuItem value="different">Diferente</MenuItem>
																<MenuItem value="higherThan">Maior que</MenuItem>
																<MenuItem value="lessThan">Menor que</MenuItem>
															</TextField>
														</div>
														<div className="value column">
															<TextField
																style={{ width: '90%' }}
																InputLabelProps={{ shrink: true }}
																label={i18n(
																	'workflow.form.actions.phaseSelection.formFieldsConfig.value'
																)}
																value={config.conditions[fieldIndex].valueNewWorkflow}
																onChange={(e) => {
																	config.conditions[fieldIndex].valueNewWorkflow =
																		e.target.value;
																	updateWorkflow();
																}}
																select={field.type === 'select' || field.type == 'checkbox'}
															>
																{(field.options || []).map((option) => {
																	if (field.type === 'checkbox') {
																		return (
																			<MenuItem value={option}>
																				{option ? 'Marcado' : 'Desmarcado'}
																			</MenuItem>
																		);
																	}
																	return <MenuItem value={option}>{option}</MenuItem>;
																})}
															</TextField>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						)}
						{(action.newWorkflowOnReproval || action.newWorkflowOnApproval) && (
							<div>
								<TextField
									variant="filled"
									style={{ width: '100%' }}
									label={i18n('workflow.form.actionTypes.approval.name')}
									value={action.originApproval}
									disabled={!isMappingAdmin}
									onChange={onLocalChangeProp('originApproval')}
									InputLabelProps={{ shrink: true }}
									select
								>
									{phaseActionsList.map((action, a) => {
										if (action.type.type !== 'approval') return;
										if (action.deleted) return;
										return (
											<MenuItem value={action.uid} key={a}>
												{action.instructions}
											</MenuItem>
										);
									})}
								</TextField>
							</div>
						)}
					</div>
				</div>
			);

			break;
		case 'dokia':
			actionContent = (
				<div style={{ marginTop: 10 }}>
					<div>
						<div>
							<TextField
								variant="filled"
								style={{ width: '100%' }}
								type="Documento a ser enviado"
								label={i18n('workflow.form.actions.originDocument')}
								InputLabelProps={{ shrink: true }}
								value={action.originDocument}
								disabled={!isMappingAdmin}
								onChange={onLocalChangeProp('originDocument')}
								select
							>
								{phaseActionsList.map((action, a) => {
									if (action.type.type !== 'documentInput') return;
									if (action.deleted) return;
									return (
										<MenuItem value={action.uid} key={a}>
											{action.instructions}
										</MenuItem>
									);
								})}
							</TextField>
						</div>
						{preventEditingDokiaInfo && action.dokiaInfo && (
							<div>
								<div className="info">
									{action.dokiaInfo.organization.code} - {action.dokiaInfo.organization.title}
								</div>
								<div className="info">
									{action.dokiaInfo.company.code} - {action.dokiaInfo.company.title}
								</div>
								<div className="info">
									{action.dokiaInfo.subsidiary.code} - {action.dokiaInfo.subsidiary.title}
								</div>
								{action.dokiaInfo.classification.map((c) => (
									<div className="info">
										{c.code} - {c.title}
									</div>
								))}
							</div>
						)}
						{!preventEditingDokiaInfo && (
							<div>
								<div>
									<TextField
										select
										variant="filled"
										style={{ width: '100%' }}
										label="Organização"
										onChange={(e) => {
											setDokiaInfo({ ...dokiaInfo, organization: e.target.value });
										}}
									>
										{(dokiaLists.organizations || []).map((o) => {
											return (
												<MenuItem key={o.uid} value={o}>
													{o.code} - {o.title}
												</MenuItem>
											);
										})}
									</TextField>
								</div>
								<div>
									<TextField
										select
										variant="filled"
										style={{ width: '100%' }}
										label={i18n("workflow.form.actions.ged17Company")}
										onChange={(e) => {
											setDokiaInfo({ ...dokiaInfo, company: e.target.value });
										}}
									>
										{(dokiaLists.companies || []).map((o) => {
											return (
												<MenuItem key={o.uid} value={o}>
													{o.code} - {o.title}
												</MenuItem>
											);
										})}
									</TextField>
								</div>
								<div>
									<TextField
										select
										variant="filled"
										style={{ width: '100%' }}
										label="Filial"
										onChange={(e) => {
											setDokiaInfo({ ...dokiaInfo, subsidiary: e.target.value });
										}}
									>
										{(dokiaLists.subsidiaries || []).map((o) => {
											return (
												<MenuItem key={o.uid} value={o}>
													{o.code} - {o.title}
												</MenuItem>
											);
										})}
									</TextField>
								</div>

								<div>
									<Autocomplete
										multiple
										clearOnBlur
										openOnFocus
										selectOnFocus
										handleHomeEndKeys
										disableClearable
										// disableCloseOnSelect
										variant="filled"
										style={{ width: '100%', marginTop: 7 }}
										className="classification"
										options={dokiaLists.classifications}
										getOptionLabel={(option) => {
											return option.title || '';
										}}
										renderOption={(option) => (
											<div>
												{option.code} - {option.title}
											</div>
										)}
										// renderTags={(value, getTagProps) =>
										// 	value.map((option, index) => {
										// 		let chipProps = getTagProps({ index });
										// 		chipProps.__onDelete = chipProps.onDelete;
										// 		chipProps.deleteIcon =
										// 			index < value.length - 1 ? (
										// 				<></>
										// 			) : (
										// 				<div className="icon delete">
										// 					<Icon icon="faTimesCircle" />
										// 				</div>
										// 			);

										// 		chipProps.onDelete = (e) => {
										// 			if (index < value.length - 1) return;
										// 			chipProps.__onDelete(e);
										// 		};
										// 		return (
										// 			<Chip
										// 				variant="default"
										// 				classes={['classification']}
										// 				icon={
										// 					<div className="icon">
										// 						<Icon icon="faFolder" />
										// 					</div>
										// 				}
										// 				{...chipProps}
										// 				label={`${option.code} - ${option.title}`}
										// 			/>
										// 		);
										// 	})
										// }
										renderInput={(params) => (
											<TextField
												{...params}
												// variant="standard"
												label={'Classificação'}
												placeholder={'Classificação'}
												variant="outlined"
												className="autocomplete"
												error={isClassificationEmpty}
												helperText={errorText}
											/>
										)}
										onChange={(e, value) => {
											dokiaInfo.classification = value;
											setDokiaInfo({
												...dokiaInfo,
											});
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			);

			break;
		case 'start-collectame-process':
			if (action.dokiaInfo) {
			}
			actionContent = (
				<div>
					<div>
						<TextField
							variant="filled"
							style={{ width: '100%' }}
							label={i18n('workflow.form.actions.originDocument')}
							InputLabelProps={{ shrink: true }}
							value={action.originDocument}
							disabled={!isMappingAdmin}
							onChange={onLocalChangeProp('originDocument')}
							select
						>
							{phaseActionsList.map((action, a) => {
								if (action.type.type !== 'documentInput') return;
								if (action.deleted) return;
								return (
									<MenuItem value={action.uid} key={a}>
										{action.instructions}
									</MenuItem>
								);
							})}
							{/* {tdocsReactions.map((r, i) => {
								if (r.index <= workflow.phases.indexOf(currentPhase)) {
									return (
										<MenuItem
											key={i}
											value={`tdoc-document/${r.contractName}`}
										>{`${r.name} - Documento Gerado pelo EditFy`}</MenuItem>
									);
								}
							})} */}
						</TextField>
					</div>
					<div>
						<TextField
							variant="filled"
							style={{ width: '100%' }}
							label={'Template'}
							InputLabelProps={{ shrink: true }}
							value={template}
							disabled={!isMappingAdmin}
							onChange={(e) => {
								onTemplateChange(e.target.value);
							}}
							select
						>
							{templates.map((template) => {
								return (
									<MenuItem value={template} key={template.uid}>
										{template.description}
									</MenuItem>
								);
							})}
						</TextField>
					</div>
					<div>
						<TextField
							variant="filled"
							style={{ width: '100%' }}
							label={'Cofre'}
							InputLabelProps={{ shrink: true }}
							value={safeBox}
							disabled={!isMappingAdmin}
							onChange={(e) => {
								onSafeBoxChange(e.target.value);
							}}
							select
						>
							{safeBoxes.map((safebox) => (
								<MenuItem value={safebox} key={safebox.uid}>
									{safebox.name} - {safebox.description}
								</MenuItem>
							))}
						</TextField>
					</div>

					<div>
						{(signatories || []).map((signatory, i) => (
							<div className={`signatory ${signatory.opened ? 'opened' : ''}`} key={signatory.uid}>
								<div
									className="signatory-header"
									onClick={(e) => {
										signatory.opened = !signatory.opened;
										setState({});
									}}
								>
									<div className="caret">
										<Icon icon={`${signatory.opened ? 'faCaretDown' : 'faCaretRight'}`} />
									</div>
									<div className="text">{signatory.name || 'Signatário'}</div>
									<div
										className="icon"
										onClick={(e) => {
											signatories.splice(i, 1);
											setSignatories(signatories);
										}}
									>
										<Icon icon="faTimes" />
									</div>
								</div>
								<div className="content">
									<FormControlLabel
										label={'Signatário Fixo'}
										className="checkbox-control"
										control={
											<Checkbox
												checked={signatory.fixed}
												onChange={(e) => {
													signatory.fixed = e.target.checked;
													setSignatories(signatories);
												}}
											/>
										}
									/>
									{!signatory.fixed && (
										<div>
											<FormFieldOption
												action={action}
												getFieldListFromString={getFieldListFromString}
												isMappingAdmin={isMappingAdmin}
												workflow={workflow}
												currentPhase={currentPhase}
												title="Nome"
												value={signatory.formName}
												onChange={(value) => {
													signatory.formName = value;
													setSignatories(signatories);
												}}
											/>
											<FormFieldOption
												action={action}
												getFieldListFromString={getFieldListFromString}
												isMappingAdmin={isMappingAdmin}
												workflow={workflow}
												currentPhase={currentPhase}
												title="Email"
												value={signatory.formEmail}
												onChange={(value) => {
													signatory.formEmail = value;
													setSignatories(signatories);
												}}
											/>
											<FormFieldOption
												action={action}
												getFieldListFromString={getFieldListFromString}
												isMappingAdmin={isMappingAdmin}
												workflow={workflow}
												currentPhase={currentPhase}
												title="Telefone"
												value={signatory.formPhone}
												onChange={(value) => {
													signatory.formPhone = value;
													setSignatories(signatories);
												}}
											/>
										</div>
									)}
									{signatory.fixed && (
										<div>
											<Input
												mode="outlined top-label"
												label="Nome"
												value={signatory.name}
												onChange={(e) => {
													signatory.name = e.target.value;
													setSignatories(signatories);
												}}
											/>
											<Input
												mode="outlined top-label"
												label="Email"
												value={signatory.email}
												onChange={(e) => {
													signatory.email = e.target.value;
													setSignatories(signatories);
												}}
											/>
											<Input
												mode="outlined top-label"
												label="Telefone"
												mask="(99) 99999-9999"
												type="text"
												value={signatory.phone}
												onChange={(e) => {
													signatory.phone = e.target.value.replace(/\D+/g, '');
													setSignatories(signatories);
												}}
											/>
										</div>
									)}
									<div>
										<TextField
											variant="filled"
											style={{ width: '100%' }}
											label="Tipo de Assinatura"
											select
											value={signatory.signatureType}
											onChange={(e) => {
												signatory.signatureType = e.target.value;
												setSignatories(signatories);
											}}
										>
											{Object.keys(collectameSignatureType).map((key) => {
												return (
													<MenuItem key={key} value={key}>
														{collectameSignatureType[key]}
													</MenuItem>
												);
											})}
										</TextField>
									</div>
									<div>
										<TextField
											variant="filled"
											style={{ width: '100%' }}
											label="Assinar Como"
											select
											value={signatory.signatoryType}
											onChange={(e) => {
												signatory.signatoryType = e.target.value;
												setSignatories(signatories);
											}}
										>
											{Object.keys(collectameSignatoryType).map((key) => {
												return (
													<MenuItem key={key} value={key}>
														{collectameSignatoryType[key]}
													</MenuItem>
												);
											})}
										</TextField>
									</div>

									<FormControlLabel
										label={'Validar SMS'}
										className="checkbox-control"
										control={
											<Checkbox
												checked={signatory.validateSMS}
												onChange={(e) => {
													signatory.validateSMS = e.target.checked;
													setSignatories(signatories);
												}}
											/>
										}
									/>
									<FormControlLabel
										label={'Enviar Link via SMS'}
										className="checkbox-control"
										control={
											<Checkbox
												checked={signatory.linkSMS}
												onChange={(e) => {
													signatory.linkSMS = e.target.checked;
													setSignatories(signatories);
												}}
											/>
										}
									/>
									<FormControlLabel
										label={'Solicitar Selfie'}
										className="checkbox-control"
										control={
											<Checkbox
												checked={signatory.requireSelfie}
												onChange={(e) => {
													signatory.requireSelfie = e.target.checked;
													setSignatories(signatories);
												}}
											/>
										}
									/>
									<FormControlLabel
										label={'Solicitar Vídeo'}
										className="checkbox-control"
										control={
											<Checkbox
												checked={signatory.requireVideo}
												onChange={(e) => {
													signatory.requireVideo = e.target.checked;
													setSignatories(signatories);
												}}
											/>
										}
									/>
								</div>
							</div>
						))}
					</div>
					<div>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={sendToDok}
										onChange={(e) => {
											action.sendToDok = e.target.checked;
											setSendToDok(e.target.checked);
										}}
									/>
								}
								label="Enviar documento assinado ao Dok+"
							/>
						</FormGroup>
						{sendToDok && (
							<div>
								<div>
									<Autocomplete
										clearOnBlur
										openOnFocus
										selectOnFocus
										handleHomeEndKeys
										// disableCloseOnSelect
										disableClearable
										variant="filled"
										value={action.dokiaInfo.organization}
										className="organization"
										getOptionLabel={(option) => {
											return option.title || '';
										}}
										options={dokiaLists.organizations}
										style={{ width: '100%', marginTop: 10 }}
										renderOption={(option) => (
											<div>
												{option.code} - {option.title}
											</div>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Organização"
												placeholder="Organização"
												variant="outlined"
												className="autocomplete"
											/>
										)}
										onChange={(e, value) => {
											dokiaInfo.organization = value;
											setDokiaInfo({
												...dokiaInfo,
											});
										}}
									/>
								</div>
								<div>
									<Autocomplete
										clearOnBlur
										openOnFocus
										selectOnFocus
										handleHomeEndKeys
										// disableCloseOnSelect
										disableClearable
										variant="filled"
										style={{ width: '100%', marginTop: 4 }}
										value={dokiaInfo.company}
										options={dokiaLists.companies}
										getOptionLabel={(option) => {
											return option.title || '';
										}}
										renderOption={(option) => (
											<div>
												{option.code} - {option.title}
											</div>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												label={i18n("workflow.form.actions.ged17Company")}
												placeholder={i18n("workflow.form.actions.ged17Company")}
												variant="outlined"
												className="autocomplete"
											/>
										)}
										onChange={(e, value) => {
											dokiaInfo.company = value;
											setDokiaInfo({
												...dokiaInfo,
											});
										}}
									/>
								</div>
								<div>
									<Autocomplete
										clearOnBlur
										openOnFocus
										selectOnFocus
										handleHomeEndKeys
										// disableCloseOnSelect
										disableClearable
										variant="filled"
										style={{ width: '100%', marginTop: 4 }}
										className="subsidiary"
										value={dokiaInfo.subsidiary}
										options={dokiaLists.subsidiaries}
										getOptionLabel={(option) => {
											return option.title || '';
										}}
										renderOption={(option) => (
											<div>
												{option.code} - {option.title}
											</div>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												// variant="standard"
												label={'Filial'}
												placeholder={'Filial'}
												variant="outlined"
												className="autocomplete"
											/>
										)}
										onChange={(e, value) => {
											dokiaInfo.subsidiary = value;
											setDokiaInfo({
												...dokiaInfo,
											});
										}}
									/>
								</div>
								<div>
									<Autocomplete
										multiple
										clearOnBlur
										openOnFocus
										selectOnFocus
										handleHomeEndKeys
										// disableCloseOnSelect
										disableClearable
										variant="filled"
										style={{ width: '100%', marginTop: 4 }}
										className="classification"
										value={dokiaInfo.classification}
										options={dokiaLists.classifications}
										getOptionLabel={(option) => {
											return option.title || '';
										}}
										renderOption={(option) => (
											<div>
												{option.code} - {option.title}
											</div>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												// variant="standard"
												label={'Classificação'}
												placeholder={'Classificação'}
												variant="outlined"
												className="autocomplete"
											/>
										)}
										onChange={(e, value) => {
											dokiaInfo.classification = value;
											setDokiaInfo({
												...dokiaInfo,
											});
										}}
									/>
									{/* {action.dokiaInfo.organization.uid !== '' ? (<>
										<Autocomplete
											clearOnBlur
											openOnFocus
											selectOnFocus
											handleHomeEndKeys
											variant="filled"
											value={action.dokiaInfo.company}
											className="Empresa"
											getOptionLabel={(option) => {
												return option.title || '';
											}}
											options={dokiaLists2.companies}
											style={{ width: '100%', marginTop: 10 }}
											renderOption={(option) => (
												<div>
													{option.code} - {option.title}
												</div>
											)}
											renderInput={(params) =>
												<TextField
													{...params}
													label="Empresa"
													placeholder='Empresa'
													variant="outlined"
													className="autocomplete"
												/>
											}
											onChange={(e, value) => {
												const compan = {
													uid: value ? value.uid : "",
													code: value ? value.code : "",
													title: value ? value.title : "",
												}
												action.dokiaInfo = { ...action.dokiaInfo, company: compan, subsidiary: '', classification: [] }
												updateDokiaInfo2({ ...dokiaInfo2, company: compan, subsidiary: '', classification: [] })
											}}
										/>
									</>) : (<></>)}
									{!!action.dokiaInfo.company.uid ? (<>
										<Autocomplete
											clearOnBlur
											openOnFocus
											selectOnFocus
											handleHomeEndKeys
											variant="filled"
											value={action.dokiaInfo.subsidiary}
											className="Compania"
											getOptionLabel={(option) => {
												return option.title || '';
											}}
											options={dokiaLists2.subsidiaries}
											style={{ width: '100%', marginTop: 10 }}
											renderOption={(option) => (
												<div>
													{option.code} - {option.title}
												</div>
											)}
											renderInput={(params) =>
												<TextField
													{...params}
													label="Compania"
													placeholder='Compania'
													variant="outlined"
													className="autocomplete"
												/>
											}
											onChange={(e, value) => {
												const subsidiar = {
													uid: value ? value.uid : "",
													code: value ? value.code : "",
													title: value ? value.title : "",
												}
												action.dokiaInfo = { ...action.dokiaInfo, subsidiary: subsidiar, classification: [] }
												updateDokiaInfo2({ ...dokiaInfo2, subsidiary: subsidiar, classification: [] })
											}}
										/>
									</>) : (<></>)}
									{!!action.dokiaInfo.subsidiary.uid ? (<>
										<Autocomplete
											multiple
											clearOnBlur
											openOnFocus
											selectOnFocus
											handleHomeEndKeys
											variant="filled"
											defaultValue={action.dokiaInfo.classification}
											value={action.dokiaInfo.classification}
											className="classification"
											getOptionLabel={(option) => {
												return option.title || '';
											}}
											options={dokiaLists2.classifications}
											style={{ width: '100%', marginTop: 10 }}
											renderOption={(option) => (
												<div>
													{option.code} - {option.title}
												</div>
											)}
											renderInput={(params) =>
												<TextField
													{...params}
													label="Classificação"
													placeholder='Classificação'
													variant="outlined"
													className="autocomplete"
												/>
											}
											onChange={(e, value) => {
												action.dokiaInfo = { ...action.dokiaInfo, classification: value }
												updateDokiaInfo2({ ...dokiaInfo2, classification: value })
											}}
										/>
									</>) : (<></>)} */}
								</div>
								<div>
									<FormControlLabel
										label={'Valor Fixo'}
										className="checkbox-control"
										control={
											<Checkbox
												checked={fixedData}
												onChange={(e) => {
													action.fixedData = e.target.checked;
													setFixedData(e.target.checked);
													setTitleDoc('');
													setDateDoc('');
												}}
											/>
										}
									/>
									{!fixedData ? (
										<>
											<div>
												<FormFieldOption
													action={action}
													getFieldListFromString={getFieldListFromString}
													isMappingAdmin={isMappingAdmin}
													workflow={workflow}
													currentPhase={currentPhase}
													title="Titulo do Documento"
													value={action.titleDoc}
													onChange={(value) => {
														action.titleDoc = value;
														setTitleDoc(value);
													}}
												/>
											</div>
											<div>
												<FormFieldOption
													action={action}
													getFieldListFromString={getFieldListFromString}
													isMappingAdmin={isMappingAdmin}
													workflow={workflow}
													currentPhase={currentPhase}
													title="Data do Documento"
													value={action.dateDoc}
													onChange={(value) => {
														action.dateDoc = value;
														setDateDoc(value);
													}}
												/>
											</div>
										</>
									) : (
										<>
											<div>
												<TextField
													variant="outlined"
													style={{ width: '100%', marginBottom: 10, marginTop: 10 }}
													value={titleDoc}
													label="Título do Documento"
													onChange={(e) => {
														onTitleDocChange(e.target.value);
													}}
												/>
											</div>
											<div>
												<TextField
													variant="outlined"
													style={{ width: '100%', marginBottom: 10 }}
													label="Data do Documento"
													value={dateDoc}
													onChange={(e) => {
														// setDateDoc(e.target.value);
														onDateDocChange(e.target.value);
													}}
													type="date"
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</div>
										</>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			);
			break;
		case 'generate-tdoc':
			actionContent = (
				<div>
					<div>
						<TextField
							variant="filled"
							style={{ width: '100%' }}
							label={'Template'}
							InputLabelProps={{ shrink: true }}
							value={tdocsTemplate}
							disabled={!isMappingAdmin}
							onChange={(e) => {
								onTdocsTemplateChange(e.target.value);
							}}
							select
						>
							{tdocsTemplates.map((template) => {
								return (
									<MenuItem value={template} key={template.uid}>
										{template.name}
									</MenuItem>
								);
							})}
						</TextField>
					</div>
					{/* <div>
						<FormFieldOption
							action={action}
							getFieldListFromString={getFieldListFromString}
							isMappingAdmin={isMappingAdmin}
							workflow={workflow}
							currentPhase={currentPhase}
							title="Nome do Documento"
							value={action.contractName}
							onChange={(value) => {
								action.contractName = value;
								setState({});
							}}
						/>
					</div> */}
					{/* <div>
							<simple-button onClick={createSignatory}>
								<Icon icon="faPlus" />
								Signatário
							</simple-button>
						</div> */}
					<div>
						{(action.sections || []).map((section) => {
							return (
								<div className={`signatory ${section.opened ? 'opened' : ''}`} key={section.id}>
									<div
										className="signatory-header"
										onClick={(e) => {
											section.opened = !section.opened;
											setState({});
										}}
									>
										<div className="caret">
											<Icon icon={`${section.opened ? 'faCaretDown' : 'faCaretRight'}`} />
										</div>
										<div className="text">{section.name}</div>
									</div>
									<div className="content">
										{(action.fields || []).map((field, i) => {
											if (field.sectionId !== section.id) return;
											if (action.fixed === undefined) {
												action.fixed = false;
											}
											return (
												<div className={`signatory ${field.opened ? 'opened' : ''}`} key={field.id}>
													<div
														className="signatory-header"
														onClick={(e) => {
															field.opened = !field.opened;
															setState({});
														}}
													>
														<div className="caret">
															<Icon icon={`${field.opened ? 'faCaretDown' : 'faCaretRight'}`} />
														</div>
														<div className="text">{field.name || 'Campo'}</div>
													</div>
													<div className="content">
														<FormControlLabel
															label={'Valor Fixo'}
															className="checkbox-control"
															control={
																<Checkbox
																	checked={field.fixed}
																	onChange={(e) => {
																		field.fixed = e.target.checked;
																		setState({});
																	}}
																/>
															}
														/>
														{!field.fixed && (
															<div>
																<FormFieldOption
																	action={action}
																	getFieldListFromString={getFieldListFromString}
																	isMappingAdmin={isMappingAdmin}
																	workflow={workflow}
																	currentPhase={currentPhase}
																	title="Valor do Campo"
																	value={field.form}
																	onChange={(value) => {
																		field.form = value;
																	}}
																/>
															</div>
														)}
														{!!field.fixed && (
															<div>
																<Input
																	mode="outlined top-label"
																	label={field.name}
																	type="text"
																	value={field.value}
																	onChange={(e) => {
																		field.value = e.target.value;
																		setState({});
																	}}
																/>
															</div>
														)}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			);
			break;
	}

	const selectGroup = (group, user) => () => {
		//(group, 'group');
		services.message.hide();
		if (action.linkedBy) {
			workflow.phases.map((phase, a) => {
				phase.actions.map((oAction, i) => {
					if (oAction.deleted) return;
					if (action.linkedBy === oAction.linkedBy) {
						oAction.group = group;
					}
				});
			});
			// //(workflow, 'workflow');
		}
		onLocalChangeProp('group')({ target: { value: group } });
	};

	const inputUserGroup = (group, sentence, action, c, select) => () => {
		//(group, 'group');

		const namedGroup = {
			name: sentence + group,
			action: action.uid,
			uid: 'process-input-user',
			field: c,
		};

		if (select == 'group') {
			namedGroup.uid = 'process-input-group';
		}

		services.message.hide();
		if (action.linkedBy) {
			workflow.phases.map((phase, a) => {
				phase.actions.map((oAction, i) => {
					if (oAction.deleted) return;
					if (action.linkedBy === oAction.linkedBy) {
						oAction.group = { name: sentence + group };
						// console.log("aqui", oAction.group);
					}
				});
			});
			// //(workflow, 'workflow');
		}
		onLocalChangeProp('group')({ target: { value: namedGroup } });
	};

	const remove = () => {
		// onLocalChangeProp('deleted')({ target: { value: true } });
		action.deleted = true;
		setState({});
	};
	const toggleSelect = function (e) {
		onActionPropChange('selected')({ target: { value: !action.selected } });
	};
	const getMainEl = () => {
		return document.getElementById(`action-${action.uid}-main`);
	};
	const mouseDown = (e) => {
		if (operatorType === 'system') return;
		let main = getMainEl();
		if (!main || !e) return;

		if ((e.metaKey || e.ctrlKey) && !e.shiftKey) {
			e.preventDefault();
			e.stopPropagation();
			toggleSelect();
			return;
		}

		if (!e.shiftKey) return;
		state.newAction = JSON.parse(JSON.stringify(action));
		if (!e.ctrlKey && !e.metaKey) {
			state.newAction.uid = 'action-' + generateUid();
		} else {
			action.deleted = true;
			setState({ newAction: state.newAction });
		}

		document.body.classList.add('dragging-action');
		let draggable = main.cloneNode(true);
		main.parentNode.appendChild(draggable);
		draggable.classList.add('dragging');
		let left = e.clientX - 30;
		let top = e.clientY - 30;
		draggable.style.transform = `translate3d(${left}px,${top}px,0)`;
		document.body.addEventListener('mousemove', onMouseMove);
		document.body.addEventListener('mouseup', onMouseUp);
		operationHandler.isDraggingAction = true;
	};
	const onMouseMove = function (e) {
		let main = getMainEl();
		if (!main) return;
		let draggables = main.parentNode.getElementsByClassName('dragging');
		if (!draggables.length) {
			document.body.classList.remove('dragging-action');
			return;
		}
		document.body.classList.add('dragging-action');
		let draggable = draggables[0];
		let left = e.clientX - 30;
		let top = e.clientY - 30;
		draggable.style.transform = `translate3d(${left}px,${top}px,0)`;
	};
	const onMouseUp = function (e) {
		document.body.classList.remove('dragging-action');
		let main = getMainEl();
		if (!main) {
			action.deleted = false;
			setState({});
			return;
		}
		let draggables = main.parentNode.getElementsByClassName('dragging');
		if (!draggables.length) {
			action.deleted = false;
			setState({});
			return;
		}
		let draggable = draggables[0];
		main.parentNode.removeChild(draggable);

		let phaseElements = document.getElementsByClassName('hover-droppable');

		operationHandler.isDraggingAction = false;
		document.body.removeEventListener('mousemove', onMouseMove);
		document.body.removeEventListener('mouseup', onMouseUp);

		if (phaseElements.length) {
			let phase = workflow.phases.find((phase) => {
				return phase.uid === phaseElements[0].id ? phase : false;
			});
			if (phase) {
				if (action.deleted) {
					currentPhase.actions.map((a, ai) => {
						if (a.uid === action.uid) {
							currentPhase.actions.splice(ai, 1);
						}
					});
				}
				phase.actions.push(state.newAction);
				updateWorkflow();
			} else {
				action.deleted = false;
				setState({});
			}
		} else {
			action.deleted = false;
			setState({});
		}
	};
	const onMouseOver = function (e) {
		let main = getMainEl();
		if (!main) return;
		if (action.linkedBy) {
			workflow.phases.map((phase, a) => {
				phase.actions.map((oAction, i) => {
					if (oAction.deleted) return;
					const element = document.getElementById(`action-${oAction.uid}-main`);

					if (element) {
						if (action.linkedBy === oAction.linkedBy && element) {
							element.classList.add('hover-linked-friend');
						} else {
							element.classList.remove('hover-linked-friend');
						}
					}
				});
			});
		}
	};
	const onMouseOut = function (e) {
		const main = getMainEl();
		if (!main) return;
		workflow.phases.map((phase, a) => {
			phase.actions.map((oAction, i) => {
				if (oAction.deleted) return;
				const element = document.getElementById(`action-${oAction.uid}-main`);
				if (element) {
					element.classList.remove('hover-linked-friend');
				}
			});
		});
	};

	return (
		<div
			className={`phase-action 
			${action.type.type} 
			${action.selected ? 'selected' : ''}
			${action.linkedBy ? 'linked' : ''}
			`}
			data-linked-by={action.linkedBy}
			data-linked-color={action.linkColor}
			id={`action-${action.uid}-main`}
			onMouseDown={isMappingAdmin ? mouseDown : () => { }}
			onMouseOver={isMappingAdmin ? onMouseOver : () => { }}
			onMouseOut={isMappingAdmin ? onMouseOut : () => { }}
			onMouseLeave={isMappingAdmin ? onMouseOut : () => { }}
		>
			<div className="phase-action-type">
				{!!action.linkedBy && (
					<div
						className="action-link-circle"
						style={{
							backgroundColor: action.linkedBy ? action.linkColor : 'transparent',
						}}
					>
						<Icon icon="faLink" size={'lg'} />
					</div>
				)}
				<header>
					<div className={'action-toggler opened'} id={`action-${action.uid}-toggler`} onClick={toggle}>
						<Icon icon="faCaretDown" size={'lg'} />
					</div>

					<div className="phase-action-type-name" onClick={toggle}>
						{action.type.name}

						{(operatorType === 'user' || operatorType === 'systemUser') && (
							<div
								className={`group-info 
									${action.group && action.group.uid ? action.group.uid : ''}
							`}
							>
								{action.group && action.group.name
									? action.group.agentObject
										? `${action.group.agentObject.firstName} ${action.group.agentObject.lastName}`
										: action.group.name
									: i18n('workflow.form.actions.noGroupSelected')}
							</div>
						)}
					</div>
					{!action.selected &&
						(operatorType === 'user' || operatorType === 'systemUser') &&
						isMappingAdmin &&
						!currentPhase.isOpening && (
							<Button className="action-button">
								<span
									className={`action-groups ${action.group && action.group.name ? '' : 'warning'}`}
									id={`action-${action.uid}-groups`}
									onClick={showGroupSelectionWindow(
										selectGroup,
										companyStore,
										false,
										newArray,
										workflow,
										currentPhase,
										inputUserGroup
									)}
								>
									<Icon icon="faUsers" size={'lg'} />
								</span>
							</Button>
						)}
					<Button className="action-button" onClick={() => {
						if(workflow.actionBanner === action.uid){
							workflow.actionBanner = null
						}else{
							workflow.actionBanner = action.uid
						}
						setState({})
					}}>
						<span className={`action-delete ${workflow?.actionBanner === action.uid &&"success"}`} id={`action-${action.uid}-delete`}>
							<Icon icon="faFile" size={'lg'} />
						</span>
					</Button>
					{!action.selected && isMappingAdmin && (
						<Button className="action-button" onClick={remove}>
							<span className={'action-delete'} id={`action-${action.uid}-delete`}>
								<Icon icon="faTrash" size={'lg'} />
							</span>
						</Button>
					)}
					{action.selected && isMappingAdmin && (
						<Button className="action-button" onClick={toggleSelect}>
							<span className={'action-check'}>
								<Icon icon="faCheckCircle" size={'lg'} />
							</span>
						</Button>
					)}
				</header>
				<div className="action-content opened" id={`action-${action.uid}-content`}>
					<div className="action-form">
						{actionContent}
						{!!action.group && action.group.uid && (
							<div style={{ marginTop: 5 }}>
								<TextField
									variant="filled"
									style={{ width: '100%' }}
									label={i18n('workflow.form.actions.estimatedCost')}
									InputLabelProps={{ shrink: true }}
									value={action.estimatedCost}
									type={'number'}
									onChange={onLocalChangeProp('estimatedCost')}
									disabled={!isMappingAdmin}
									InputProps={{
										endAdornment: (
											<Tooltip
												title={
													<div style={{ fontSize: 14 }}>
														{i18n('workflow.form.actions.estimatedCost.explanation')}
													</div>
												}
											>
												<InputAdornment position="end">
													<Icon icon="faQuestionCircle" size="lg" />
												</InputAdornment>
											</Tooltip>
										),
									}}
								/>
								<TextField
									variant="filled"
									style={{ width: '100%' }}
									label={i18n('workflow.form.actions.estimatedEffort')}
									InputLabelProps={{ shrink: true }}
									value={action.estimatedEffort}
									type={'number'}
									onChange={onLocalChangeProp('estimatedEffort')}
									disabled={!isMappingAdmin}
									InputProps={{
										endAdornment: (
											<Tooltip
												title={
													<div style={{ fontSize: 14 }}>
														{i18n('workflow.form.actions.estimatedEffort.explanation')}
													</div>
												}
											>
												<InputAdornment position="end">
													<Icon icon="faQuestionCircle" size="lg" />
												</InputAdornment>
											</Tooltip>
										),
									}}
								/>
								<TextField
									variant="filled"
									style={{ width: '100%' }}
									label={i18n('workflow.form.actions.businessValue')}
									InputLabelProps={{ shrink: true }}
									value={action.businessValue}
									type={'number'}
									onChange={onLocalChangeProp('businessValue')}
									disabled={!isMappingAdmin}
									InputProps={{
										endAdornment: (
											<Tooltip
												title={
													<div style={{ fontSize: 14 }}>
														{i18n('workflow.form.actions.businessValue.explanation')}
													</div>
												}
											>
												<InputAdornment position="end">
													<Icon icon="faQuestionCircle" size="lg" />
												</InputAdornment>
											</Tooltip>
										),
									}}
								/>
								<TextField
									variant="filled"
									style={{ width: '100%', marginBottom: 10 }}
									label={i18n('workflow.form.actions.priority')}
									InputLabelProps={{ shrink: true }}
									value={action.priority}
									type={'number'}
									onChange={onLocalChangeProp('priority')}
									disabled={!isMappingAdmin}
									InputProps={{
										endAdornment: (
											<Tooltip
												title={
													<div style={{ fontSize: 14 }}>
														{i18n('workflow.form.actions.priority.explanation')}
													</div>
												}
											>
												<InputAdornment position="end">
													<Icon icon="faQuestionCircle" size="lg" />
												</InputAdornment>
											</Tooltip>
										),
									}}
								/>
								<FormControlLabel
									label={i18n('workflow.form.actions.preventDistribution')}
									className="checkbox-control"
									disabled={!isMappingAdmin}
									control={
										<Checkbox
											checked={action.preventDistribution}
											onChange={onLocalChangeProp('preventDistribution')}
										/>
									}
								/>
								<FormControlLabel
									label={i18n('workflow.form.actions.visibleOnKanban')}
									className="checkbox-control"
									disabled={!isMappingAdmin}
									control={
										<Checkbox
											checked={action.visibleOnKanban}
											onChange={onLocalChangeProp('visibleOnKanban')}
										/>
									}
								/>
								<FormControlLabel
									label={i18n('workflow.form.actions.canDelegate')}
									className="checkbox-control"
									disabled={!isMappingAdmin}
									control={
										<Checkbox checked={action.canDelegate} onChange={onLocalChangeProp('canDelegate')} />
									}
								/>
								<FormControlLabel
									label={i18n('workflow.form.actions.sendMail')}
									className="checkbox-control"
									disabled={!isMappingAdmin}
									control={<Checkbox checked={localSendEmail} onChange={onLocalChangeProp('sendMail')} />}
								/>
								<FormControlLabel
									label={i18n('workflow.form.actions.whats')}
									className="checkbox-control"
									disabled={!isMappingAdmin}
									control={
										<Checkbox checked={localsendWhatsApp} onChange={onLocalChangeProp('sendWhatsApp')} />
									}
								/>
								<FormControlLabel
									label={i18n('workflow.form.actions.doesExpire')}
									className="checkbox-control"
									disabled={!isMappingAdmin}
									control={<Checkbox checked={action.doesExpire} onChange={onLocalChangeProp('doesExpire')} />}
								/>
								{!!action.doesExpire && (
									<div>
										<TextField
											variant="filled"
											style={{ width: '100%' }}
											label={i18n('workflow.form.actions.daysToExpiration')}
											InputLabelProps={{ shrink: true }}
											value={action.daysToExpiration}
											type={'number'}
											disabled={!isMappingAdmin}
											onChange={onLocalChangeProp('daysToExpiration')}
										/>
										<TextField
											variant="filled"
											style={{ width: '100%' }}
											label={i18n('workflow.form.actions.daysToFirstWarning')}
											InputLabelProps={{ shrink: true }}
											value={action.daysToFirstWarning}
											type={'number'}
											disabled={!isMappingAdmin}
											onChange={onLocalChangeProp('daysToFirstWarning')}
										/>
										<TextField
											variant="filled"
											style={{ width: '100%' }}
											label={i18n('workflow.form.actions.daysToLastWarning')}
											InputLabelProps={{ shrink: true }}
											value={action.daysToLastWarning}
											type={'number'}
											disabled={!isMappingAdmin}
											onChange={onLocalChangeProp('daysToLastWarning')}
										/>
										<FormControl style={{ width: '100%' }}>
											<InputLabel id={`action-${action.uid}-select-expirationConsequence`}>
												{i18n('workflow.form.actions.expirationConsequence')}
											</InputLabel>
											<Select
												labelId={`action-${action.uid}-select-expirationConsequence`}
												disabled={!isMappingAdmin}
												value={action.expirationConsequence}
												onChange={onLocalChangeProp('expirationConsequence')}
											>
												<MenuItem value="ignore">
													{i18n('workflow.form.actions.expirationConsequence.ignore')}
												</MenuItem>
												<MenuItem value="go-previous-phase">
													{i18n('workflow.form.actions.expirationConsequence.back')}
												</MenuItem>
												<MenuItem value="cancel-execution-flow">
													{i18n('workflow.form.actions.expirationConsequence.cancel')}
												</MenuItem>
											</Select>
										</FormControl>
									</div>
								)}

								{action.type.type === 'approval' && (
									<FormControlLabel
										label={i18n('workflow.form.actions.formReference')}
										className="checkbox-control"
										disabled={!isMappingAdmin}
										control={
											<Checkbox
												checked={action.hasFormReference}
												onChange={onLocalChangeProp('hasFormReference')}
											/>
										}
									/>
								)}

								{!!action.hasFormReference && (
									<div>
										<Autocomplete
											clearOnBlur
											openOnFocus
											selectOnFocus
											handleHomeEndKeys
											disabled={!isMappingAdmin}
											options={forms.filter((form) => form.index <= workflow.phases.indexOf(currentPhase))}
											value={action?.formReference}
											getOptionLabel={(form) => form.name || ''}
											renderInput={(params) => (
												<TextField {...params} label={'Formulários'} variant="outlined" />
											)}
											renderValue={(o) => {
												return <div>{o.name}</div>;
											}}
											onChange={(e, newValue) => {
												action.formReference = newValue;
											}}
											style={{ width: '100%', marginTop: 4 }}
										/>
									</div>
								)}

								<div className="task-checklist">
									<div
										className="task-checklist-header"
										onClick={(e) => {
											configs.checklistOpen = !configs.checklistOpen;
											setConfigs({ ...configs });
										}}
									>
										<div>Checklist</div>
										<Icon icon={configs.checklistOpen ? 'faCaretDown' : 'faCaretRight'} />
									</div>
									{configs.checklistOpen && (
										<div className="task-checklist-input">
											<Input
												label="Nome do item para adicionar"
												value={configs.actionName}
												onChange={(e) => {
													configs.actionName = e.target.value;
													setConfigs({ ...configs });
												}}
												onEnterPress={addChecklistAction}
											/>
											<div className="add-button" onClick={addChecklistAction}>
												<Icon icon="faPlus" />
											</div>
										</div>
									)}
									{!!action.checklist && configs.checklistOpen && (
										<div className="task-checklist-actions">
											{action.checklist.actions.map((actionLocal, i) => {
												return (
													<div className="checklist-action" key={i}>
														{/* <div>
															<Icon icon="faCaretRight" />
														</div> */}
														<div className="checklist-action-name">{actionLocal.name}</div>
														<div
															className="checklist-remove-action"
															onClick={(e) => {
																action.checklist.actions.splice(i, 1);
																setState({});
															}}
														>
															<Icon icon="faTrash" />
														</div>
													</div>
												);
											})}
										</div>
									)}
								</div>
							</div>
						)}
					</div>
					{action.uid.slice(0, 6) == 'action' && (
						<div
							style={{
								display: 'flex',
								marginTop: 2,
								marginBottom: 10,
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<TextField
								variant="filled"
								style={{
									width: '92%',
									fontSize: 10,
								}}
								label={i18n('workflow.form.actions.formTitle.description')}
								value={action.description}
								onChange={onLocalChangeProp('description')}
								InputLabelProps={{ shrink: true }}
								disabled={!isMappingAdmin}
								InputProps={{
									endAdornment: (
										<Tooltip
											title={
												<div style={{ fontSize: 14 }}>
													{i18n('workflow.form.actions.formTitle.description.explanation')}
												</div>
											}
										>
											<InputAdornment position="end">
												<Icon icon="faQuestionCircle" size="lg" />
											</InputAdornment>
										</Tooltip>
									),
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const FormFieldOption = ({ onChange, isMappingAdmin, getFieldListFromString, currentPhase, workflow, title, value }) => {
	const [form, setForm] = useState('');
	const [field, setField] = useState('');

	useEffect(() => {
		if (!value) return;
		let form;
		workflow.phases.find((phase) => {
			let a = phase.actions.find((action, a) => {
				if (action.uid === value.form) {
					form = action;
					return action;
				}
			});
			if (a) return phase;
		});
		setForm(form);
		setField(value.field + '');
	}, []);

	let foundPhase = false;

	return (
		<div className="form-field-option">
			<div className="form-title">{title}</div>
			<TextField
				variant="filled"
				style={{ width: '100%' }}
				label={i18n('workflow.form.actionTypes.formInput.name')}
				value={form}
				disabled={!isMappingAdmin}
				onChange={(e) => {
					setForm(e.target.value);
					onChange({
						form: (e.target.value || {}).uid,
						field: '',
					});
				}}
				InputLabelProps={{ shrink: true }}
				select
			>
				{workflow.phases.map((phase) => {
					if (foundPhase) return;
					if (phase.uid === currentPhase.uid) {
						foundPhase = true;
					}
					return phase.actions.map((action, a) => {
						if (action.type.type !== 'formInput') return;
						if (action.deleted) return;
						return (
							<MenuItem value={action} key={a}>
								<b>{phase.name}</b>
								<div>{action.formTitle}</div>
							</MenuItem>
						);
					});
				})}
			</TextField>
			<div className="phase-jump-form-config">
				<div className="field-conditional-configuration">
					<TextField
						variant="filled"
						style={{ width: '100%' }}
						InputLabelProps={{ shrink: true }}
						label={'Campo do formulário'}
						value={field}
						onChange={(e) => {
							setField(e.target.value);
							onChange({
								form: form.uid,
								field: e.target.value + '',
							});
						}}
						select
					>
						{getFieldListFromString(form)
							.filter((field) => field.type !== 'table')
							.map((field, fieldIndex) => {
								return <MenuItem value={fieldIndex + ''}>{field.name}</MenuItem>;
							})}
					</TextField>
				</div>
			</div>
		</div>
	);
};

export default connect(({ auth, companyAreaStore }) => ({ auth, companyAreaStore }))(Action);
// export default Action;
