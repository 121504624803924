import styles from './form.module.css';
import React, { useState } from "react";

import i18n from "@i18n";
import Input from '@custom/Input';
import { useSelector } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers';

import { workfluxFirestore } from '@firebase-config';

import {
  Button
} from '@material-ui/core';
import EastIcon from '@mui/icons-material/East';
import QuillRichTextEditor from '@custom/QuillRichTextEditor';

export function FormCalendar({ closeModal, titleForm, action, dataCalendar }) {
  const auth = useSelector((s) => s.auth);
  const calendarCollection = workfluxFirestore.collection('calendar');

  const [loading, setLoading] = useState(false);
  
  const [error, setError] = useState(null);
  const [calendar, setCalendar] = useState({
    title: action === "edit" ? dataCalendar.title : "",
    text: action === "edit" ? dataCalendar.text : "",
    initialDate: action === "edit" ? dataCalendar.initialDate.toDate() : null,
    endDate: action === "edit" ? dataCalendar.endDate.toDate() : null,
  })
  const [errors, setErrors] = useState({
    errorTitle: false,
    errorText: false,
  })

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'minDate':
      case 'conflict': {
        return 'Por favor selecione um outro periodo';
      }
      default: {
        return '';
      }
    }
  }, [error]);
  
  const todayDate = new Date();

  const {title, text, initialDate, endDate } = calendar;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!auth.user.companyadmin) {
      closeModal();
      return;
    }

    const { docs } = await calendarCollection
      .where("company", "==", auth.company.uid)
      .where("createdBy", "==", auth.user.uid)
      .where("initialDate", ">=", initialDate)
      .get()
    
    if (docs.length) {
      for (const doc of docs) {
        const data = doc.data();
        if (data.endDate.toDate() > initialDate) {
          setError("conflict");
          return
        }
        if (data.endDate.toDate() <= endDate) {
          setError("conflict");
          return
        }
      }
    }
    
    setLoading(true);

    const listErros = {
      errorTitle: false,
      errorText: false,
    }
    if (title === "") {
      listErros.errorTitle = true
    }
    if (text === "") {
      listErros.errorText = true
    }

    if (listErros.errorTitle || listErros.errorText) {
      setErrors({
        errorTitle: listErros.errorTitle,
        errorText: listErros.errorText,
      })
      setLoading(false);

      return;
    }

    action === "edit" ? await editCalendar({ actionDelet: false }) : await addCalendar();

    setLoading(false);

    setCalendar({
      title: "",
      text: "",
      initialDate: "",
      endDate: ""
    })
    closeModal();
  }

  const editCalendar = async ({ actionDelet }) => {
    const refDoc = calendarCollection.doc(dataCalendar.id);
    try {
      await refDoc.update({
        title,
        text,
        initialDate,
        endDate,
        delete: actionDelet
      });
    } catch (error) {
    }
  }

  const handleDelete = async () => {
    await editCalendar({ actionDelet: true })
    closeModal()
  }

  const addCalendar = async () => {
    try {
      const refDoc = calendarCollection.doc();

      await refDoc.set({
        uid: refDoc.id,
        title,
        text,
        createdBy: auth.user.uid,
        initialDate,
        endDate,
        user: {
          uid: auth.user.uid,
          name: `${auth.user.firstName} ${auth.user.lastName}`,
          email: auth.user.email
        },
        company: auth.company.uid,
        createdAt: new Date(),
        delete: false
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = ({ prop, value }) => {

    if (action === "edit" && prop === "text") {
      const div = document.createElement("div");
      div.innerHTML = value;
      value = div.innerText.replace(/\s+/g, " ").trim();
    }

    setCalendar({ ...calendar, [prop]: value })
  }

  return (
    <section className={styles.containerForm}>
      <h1>{titleForm}</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Input
          leftIcon="faTextHeight"
          mode="label-from-left"
          label={i18n("title")}
          value={title}
          onChange={(e) => handleChange({ prop: "title", value: e.target.value })}
          message={errors.errorTitle && i18n("enterATitle")}
        />
        <section style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Início"
              onChange={(e) => handleChange({ prop: "initialDate", value: e })}
              ampm={false}
              minDateTime={todayDate}
              value={initialDate}
              onError={(newError) => setError(newError)}
              slotProps={{
                textField: {
                  helperText: errorMessage,
                },
              }}
            />
          </LocalizationProvider>
          <EastIcon />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Fim"
              onChange={(e) => handleChange({ prop: "endDate", value: e})}
              ampm={false}
              minDateTime={calendar.initialDate ? calendar.initialDate : todayDate}
              value={endDate}
              onError={(newError) => setError(newError)}
              slotProps={{
                textField: {
                  helperText: errorMessage,
                },
              }}
            />
          </LocalizationProvider>
        </section>
        <section className={styles.ritch}
          style={{
            borderColor: errors.errorText ? "red" : "",
          }}
        >
          <p>{i18n("text")}</p>
          <QuillRichTextEditor
            preserveWhitespace
            onChange={(e) => handleChange({ prop: "text", value: e })}
            value={text}
            placeholder="Clique duas vezes sobre algum texto para usar o editor"
            toolbar={[
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'image'],

              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],

              ['clean']
            ]}
          />
        </section>
        <section className={styles.areaButtons}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={closeModal}
          >
            {i18n("cancel")}
          </Button>
          {action === "edit" && (
            <Button
              type="button"
              variant="contained"
              style={{
                background: "#F44336",
                color: "#FFF"
              }}
              disabled={loading}
              onClick={handleDelete}
            >
              Excluir
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {action === "edit" ? "Editar" : "Enviar"}
          </Button>

        </section>
      </form>
    </section>
  )
}