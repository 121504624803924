import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { services } from '@redux';
import Icon from '@custom/Icon';
import Message from '@custom/Message';
import i18n, { format } from '@i18n';
import { history, store } from '@redux';
import { MenuItem, Tooltip } from '@material-ui/core';

const getValueFromPath = (object, path) => {
	let props = path.split('.');
	let propertyPath = props.slice(0, props.length - 1);
	let lastProp = props.slice(props.length - 1, props.length)[0];

	let oItem = object;
	propertyPath.map((prop) => {
		oItem = oItem[prop];
	});
	return oItem[lastProp];
};

Array.prototype.sortBy = function (keys, splitKeyChar = '~') {
	return this.sort((i1, i2) => {
		const sortStr1 = keys.reduce((str, key) => str + splitKeyChar + getValueFromPath(i1, key), '');
		const sortStr2 = keys.reduce((str, key) => str + splitKeyChar + getValueFromPath(i2, key), '');
		return sortStr1.localeCompare(sortStr2);
	});
};

export const kanbanStatus = [
	{ id: 'todo', name: 'kanba.status.todo' },
	{ id: 'inprogress', name: 'kanba.status.inprogress' },
	{ id: 'done', name: 'kanba.status.done' },
];

export const renderExecutionFlowMenuItem = (execution, i, onClick) => {
	const isUrgent = !!execution.workflowObject.urgent;
	return (
		<MenuItem
			key={execution.uid}
			className={isUrgent ? "urgent" : ""}
			style={{ borderBottom: '1px solid #ddd' }}
			onClick={() => {
				if (typeof onClick === 'function') onClick();
				history.push(`/executionFlow/view/${execution.uid}`);
			}}
		>
			{/* <Tooltip title={execution.workflowObject.description} placement="left"> */}
			<div>
				<div style={{ whiteSpace: 'normal' }}>{execution.workflowObject.document.title}</div>
				<div style={{ fontSize: 12, marginBottom: 5, marginTop: 2 }}>{execution.workflowObject.description}</div>
				<div style={{ fontSize: 10, color: '#666' }}>
					<b>{i18n('appcommon.menutop.executionFlows.id')} </b>
					{execution.uid}
				</div>
				<div style={{ fontSize: 12, color: '#444' }}>{format.datetime(execution.createdAt.toDate())}</div>
				<div style={{ fontSize: 12, color: '#444' }}>
					Responsável: {execution.agentObject.firstName + ' ' + execution.agentObject.lastName}
				</div>
			</div>
			{/* </Tooltip> */}
		</MenuItem>
	);
};

export const sortByUrgency = (a, b) => {
	const aIsUrgent = !!a.workflowObject.urgent;
	const bIsUrgent = !!b.workflowObject.urgent;
	return aIsUrgent === bIsUrgent ? 0 : aIsUrgent ? -1 : 1;
};

export const filterByViewPermission = (user) => (executionFlow) => {
	const workflow = executionFlow.workflowObject;
	const viewGroups = workflow.viewGroups || [];
	const viewGroupUids = viewGroups.map(group => group.uid);
	const userGroupuids = user.groups.map(group => typeof group === "string" ? group : group.uid);
	const participants = executionFlow.participants || [];
	return !!(
		user.companyadmin ||
		executionFlow.createdBy === user.uid ||
		participants.includes(user.uid) ||
		userGroupuids.some(groupUid => viewGroupUids.includes(groupUid))
	);
};

export const renderTaskMenuItem = (task, i, onClick) => {
	const isUrgent = !!task.workflowObject.urgent;
	return (
		<MenuItem
			key={task.uid}
			className={`${task.action.type.type}${isUrgent ? " urgent" : ""}`}
			onClick={() => {
				if (typeof onClick === 'function') onClick();
				history.push(`/executionFlow/${task.executionFlow}/task/${task.action.type.type}/${task.uid}`);
			}}
			style={{ borderBottom: '1px solid #ddd' }}
		>
			{task.status === 'review' && <Icon icon='faFlag' style={{ color: '#666', marginRight: 15 }} />}
			<div>
				<div style={{ display: 'block' }}>{task.action.type.name}</div>
				<div style={{ fontSize: 12 }}>{task.workflowObject.description}</div>

				{task.status === 'review' && (
					<div
						style={{
							fontSize: 12,
							color: '#666',
							display: 'block',
						}}
					>
						{i18n('appcommon.menutop.task.reviewTask')}
					</div>
				)}
				<div style={{ fontSize: 12 }}>{task.workflowObject.document.title}</div>
				<div style={{ fontSize: 10, color: '#666' }}>
					<b>{i18n('appcommon.menutop.task.executionFlow')} </b>
					{task.executionFlow}
				</div>
				<div style={{ fontSize: 12 }}>{format.datetime(task.createdAt.toDate())}</div>
			</div>
		</MenuItem>
	);
};

export const generateUid = () => {
	return Math.ceil(Math.random() * 1000000).toString(32) + '' + new Date().getTime().toString(32);
};

export const onMainChangeProp = (object, setObject, props, checkbox) => (e) => {
	let value = typeof e === 'object' ? (checkbox ? e.target.checked : e.target.value) : e;
	let propertyPath = [];
	if (props instanceof Array) {
		props = props;
	} else {
		props = [props];
	}
	propertyPath = props.slice(0, props.length - 1);
	let lastProp = props.slice(props.length - 1, props.length)[0];

	let oItem = object;
	propertyPath.map((prop) => {
		oItem = oItem[prop];
	});

	oItem[lastProp] = value;
	if (object instanceof Array) {
		setObject([...object]);
		// return object
	} else {
		setObject({ ...object });
		// return object
	}
	return value;
};

export const getFieldListFromString = (action) => {
	if (!action) return [];
	return (action.formFields || '')
		.split(/(\n)/g)
		.filter((s) => s.replace(/\;/g, '').trim())
		.map((fieldconfig, i) => {
			let field, type, s, options, mask;
			if (fieldconfig.indexOf('@') > -1) {
				s = fieldconfig.split('@');
				field = s[0];
				type = s[1];
			} else {
				field = fieldconfig;
				type = 'string';
			}
			if (type.indexOf('(') > -1) {
				s = type.split('(');
				type = s[0];
				if (['select', 'table'].indexOf(type) > -1) {
					options = s[1].replace(')', '').split('\\');
				}
			}

			if (type === 'checkbox') {
				options = [true, false];
			}

			if (type.indexOf('mask#') > -1) {
				s = type.split('mask#');
				type = '';
				mask = s[1];
			}
			if (type === 'cpf') {
				mask = '999.999.999-99';
				type = 'cpf';
			}
			if (type === 'cnpj') {
				mask = '99.999.999/9999-99';
				type = 'cnpj';
			}
			return { name: field, type, mask, options };
		});
};
