import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from '@i18n';
import firebase, { workfluxFirestore } from '@firebase-config';
import { store as zerocodeStore, Message } from 'zerocode';
import WorkflowStartup from '../screens/workflow/WorkflowStartup';
import Api from '@api';
import { CompanyArea, Workflow, ExecutedApproval, ExecutedDocument, ExecutedForm, Recurrence } from '@taugor/taugor-bpmn-models';
import Icon from '@custom/Icon';
import Input from '@custom/Input';
import { generateUid } from '@custom/common';
import { store, history, services } from '@redux';
import { FormControl, Select, MenuItem, InputLabel, FormControlLabel, Checkbox } from '@material-ui/core';
import { createPortal } from 'react-dom';
import { mainFirebaseNewton as mainFirebase } from 'zerocode';

export const oCompanyArea = new CompanyArea();
export const oWorkflow = new Workflow();
// console.log(oWorkflow)
// export const oWorkflowHistory = new WorkflowHistory();
export const oRecurrence = new Recurrence();
export const oExecutedApproval = new ExecutedApproval();
export const oExecutedDocument = new ExecutedDocument();
export const oExecutedForm = new ExecutedForm();

export const oCompanyAreaService = oCompanyArea.getService(firebase);
export const oWorkflowService = oWorkflow.getService(firebase, store);
// export const oWorkflowServiceHistory = oWorkflowHistory.getService(firebase, store);
export const oRecurrenceService = oRecurrence.getService(firebase, store, 'recurrenceStore');

const firestore = workfluxFirestore;
export const workflowCollection = firestore.collection(oWorkflow.getModelName());
export const workflowHistoryCollection = firestore.collection('workflowHistory');
export const companyAreaCollection = firestore.collection(oCompanyArea.getModelName());
export const recurrenceCollection = firestore.collection(oRecurrence.getModelName());
export const executedApprovalCollection = firestore.collection(oExecutedApproval.getModelName());
export const executedDocumentCollection = firestore.collection(oExecutedDocument.getModelName());
export const executedFormCollection = firestore.collection(oExecutedForm.getModelName());

const FieldValue = mainFirebase.firestore.FieldValue;

const saveRecurrence = () => { };

const updateGroups = async (workflow) => {
	services.spinner.show();
	await Api.updateGroups(workflow.uid);
	services.spinner.hide();
};

const confirmStartExecutionFlow = (workflow) => () => {
	updateGroups(workflow)
	services.message.show('Por favor, informe uma descrição para esta execução', <WorkflowStartup workflow={workflow} />);
};

export const checkLicenses = (callback) => {
	const appcenterStore = zerocodeStore.getState().appcenter;

	let mappingsLicensesAvailable = 0;

	let foundMappingsLicense = false;

	appcenterStore.licenses.map((license) => {
		if (license.type === 'workflux-mappings') {
			foundMappingsLicense = true;
			if (license.amount === "unlimited") {
				mappingsLicensesAvailable += Infinity - parseInt(license.used || 0);
			}
			else {
				mappingsLicensesAvailable += parseInt(license.amount || 0) - parseInt(license.used || 0);
			}
		}
	});

	let hasEnoughMappingsLicense = foundMappingsLicense ? mappingsLicensesAvailable : true;

	if (typeof callback == 'function') {
		callback({ hasEnoughMappingsLicense, mappingsLicensesAvailable });
	}
};

const consumeMappingsLicence = (amount, auth) => {
	Api.company.licenses
		.consume(auth.company.uid, 'workflux_mappings', amount, auth.user)
		.then((result) => {
			// Licença consumida
			checkLicenses();
		})
		.catch((e) => {
			//('Erro ao consumir licença:', e);
		});
};

export const removeWorkflow = (auth, checkLicenses, workflow, hideMessage) => (e) => {
	services.message.hide();
	services.spinner.show();
	workflow.deleted = true;
	return Promise.all([
		workflowCollection.doc(workflow.uid).update({
			deleted: true,
			deletedBy: auth.user,
			deletedAt: new Date(),
		}),
		workflowHistoryCollection
			.doc(workflow.uid)
			.set(
				{
					company: workflow.company,
					uid: workflow.uid,
					division: workflow.division,
					name: workflow.document.title,
					history: FieldValue.arrayUnion({
						date: new Date(),
						user: auth.user.uid,
						userName: auth.user.firstName + ' ' + auth.user.lastName,
						type: "workflow.history.removed",
					}),
				},
				{ merge: true }
			),
		recurrenceCollection
			.where('workflow', '==', workflow.uid)

			.get()
			.then((snapshot) => {
				if (!snapshot.docs.length) return Promise.resolve();
				return Promise.all(
					snapshot.docs.map((item) => {
						return recurrenceCollection.doc(item.id).update({
							deleted: true,
							deletedBy: auth.user,
							deletedAt: new Date(),
						});
					})
				);
			}),
		executedApprovalCollection
			.where('workflow', '==', workflow.uid)

			.get()
			.then((snapshot) => {
				if (!snapshot.docs.length) return Promise.resolve();
				return Promise.all(
					snapshot.docs.map((item) => {
						executedApprovalCollection.doc(item.id).update({
							deleted: true,
							deletedBy: auth.user,
							deletedAt: new Date(),
						});
					})
				);
			}),
		executedDocumentCollection
			.where('workflow', '==', workflow.uid)

			.get()
			.then((snapshot) => {
				if (!snapshot.docs.length) return Promise.resolve();
				return Promise.all(
					snapshot.docs.map((item) => {
						executedDocumentCollection.doc(item.id).update({
							deleted: true,
							deletedBy: auth.user,
							deletedAt: new Date(),
						});
					})
				);
			}),
		executedFormCollection
			.where('workflow', '==', workflow.uid)

			.get()
			.then((snapshot) => {
				if (!snapshot.docs.length) return Promise.resolve();
				return Promise.all(
					snapshot.docs.map((item) => {
						executedFormCollection.doc(item.id).update({
							deleted: true,
							deletedBy: auth.user,
							deletedAt: new Date(),
						});
					})
				);
			}),
	]).then((r) => {
		consumeMappingsLicence(-1, auth, checkLicenses);
		if (!hideMessage) {
			services.message.show('Excluído com sucesso');
			services.spinner.hide();
		}
	});
};

const WorkflowActionCard = ({
	auth,
	workflow,
	companyAreaStore,
	recurrenceStore,
	hasLicensesMappings,
	maxMappingsLicensesAvailable,
	displayDivision = false,
}) => {
	const [state, setState] = useState({});
	const [showRecurrenceWindow, setShowRecurrenceWindow] = useState(false);
	const [showCopyWindow, setShowCopyWindow] = useState(false);

	const isCompanyAreaAdmin = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.companyarea.admin') > -1;
	const isCompanyAreaViewer = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.companyarea.viewer') > -1;
	const isMappingAdmin = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.mapping.admin') > -1;
	const isMappingViewer = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.mapping.viewer') > -1;

	if (workflow.deleted || (!isMappingAdmin && !isMappingViewer)) return <></>;

	let phasesWithProblem = workflow.phases
		.filter((a) => !a.deleted)
		.find((phase) => {
			let actions = phase.actions.filter((action) => !action.deleted);

			if (!actions.length) {
				return phase;
			}
			let foundProblem = false;
			actions.map((action) => {
				if (!action.group || !action.group.uid) {
					foundProblem = true;
				}
			});
			if (foundProblem) return phase;
		});
	if (!workflow.group || !workflow.group.uid) {
		phasesWithProblem = true;
	}
	if (!workflow.phases.filter((a) => !a.deleted).length) {
		phasesWithProblem = true;
	}

	if (workflow.executorGroups && workflow?.executorGroups.length) {
		let groupUser = auth.user.groups.map((g) => g.uid);
		let uidExecutorGroups = workflow.executorGroups.map(eg => eg.uid)

		let acceptGroups = groupUser.filter((group) => {
			// console.log(group)
			return uidExecutorGroups.includes(group);
		});
		// console.log(acceptGroups)
		workflow.canExecute = acceptGroups.length > 0 ? true : false;
	} else {
		workflow.canExecute = true;
	}

	const startWorkflowCopy = ({ companyAreaStore, maxMappingsLicensesAvailable, workflow }) => {
		// console.log("aqui")
		let sizeAreaSelected = 0;
		sizeAreaSelected = companyAreaStore.list.filter((area) => {
			return area.selected;
		});
		if (sizeAreaSelected.length > maxMappingsLicensesAvailable) {
			return services.message.show(`Você possui apenas ${maxMappingsLicensesAvailable} licença(s) disponível(is).`);
		}

		let oPromise = Promise.resolve();
		companyAreaStore.list.map((companyArea) => {
			if (!companyArea.selected) return;
			companyArea.selected = false;
			oPromise = oPromise.then((r) => {
				let newWorkflow = Object.assign(workflow);
				newWorkflow.uid = generateUid();
				newWorkflow.division = companyArea.uid;
				newWorkflow.divisionObject = {
					name: companyArea.name,
					uid: companyArea.uid,
					groups: companyArea.groups,
				};
				delete newWorkflow.divisionObject.$$index;
				return oWorkflowService.save(newWorkflow);
			});
		});
		oPromise.then((r) => {
			consumeMappingsLicence(sizeAreaSelected.length, auth, checkLicenses);
			services.message.show('Workflows copiados com Sucesso');
		});
	};

	return [
		<div className="workflow-action-card">
			<div className="content">
				{displayDivision && <div className="document-title">{workflow.divisionObject.name}</div>}
				<div className="document-title">{workflow.document.title}</div>
				<div className="document-description">
					<span
						style={{
							textOverflow: 'ellipsis',
							height: 20,
							overflow: 'hidden',
						}}
					>
						{workflow.document.description.substr(0, 100)}
						{workflow.document.description.length > 100 ? '...' : ''}
					</span>
				</div>
				{phasesWithProblem && <div className="problematic-mapping-message">{i18n('incompleteMapping')}</div>}
				<div className="columns">
					{!phasesWithProblem && isMappingAdmin && (
						<div
							className={'execute-button' + (!hasLicensesMappings ? ' disabled' : '')}
							onClick={() => setShowCopyWindow(true)}
						>
							<Icon icon="faCopy" />
							<div>{i18n("copy")}</div>
						</div>
					)}
					{!phasesWithProblem && workflow.canExecute && (
						<div className={'execute-button'} onClick={confirmStartExecutionFlow(workflow)}>
							<Icon icon="faPlay" />
							<div>{i18n("toExecute")}</div>
						</div>
					)}
					{!phasesWithProblem && isMappingAdmin && (
						<div className={'execute-button'} onClick={() => setShowRecurrenceWindow(true)}>
							<Icon icon="faPlus" />
							<div>{i18n("recurrence")}</div>
						</div>
					)}
					{isMappingAdmin && (
						<div
							className={'execute-button'}
							onClick={(e) => {
								services.message.show(
									'Exclusão de mapeamento',
									<div>
										<div>
											{i18n("youWillNotBeAbleToUndoThisAction")}
											<br />
											{i18n("thisActionDoesNoteImpactFlowsAready")}
											<br />
											{i18n("comfirmDeleteMapping")}

										</div>
										<div className="buttons">
											<simple-button
												clear
												onClick={(e) => {
													removeWorkflow(
														auth,
														checkLicenses,
														workflow
													)(e).then((r) => {
														setState({});
													});
												}}
											>
												{i18n("delete")}
											</simple-button>
											<simple-button cancel onClick={services.message.hide}>
												{i18n("cancel")}
											</simple-button>
										</div>
									</div>
								);
							}}
						>
							<Icon icon="faTrash" />
							<div>{i18n("delete")}</div>
						</div>
					)}
				</div>
			</div>
			{isMappingAdmin && (
				<div
					className="edit-button"
					onClick={() => {
						updateGroups(workflow)
						history.push('/workflow/form/' + workflow.uid);
					}}
				>
					<Icon icon="faChevronRight" />
				</div>
			)}
		</div>,
		showCopyWindow &&
		createPortal(
			<WorkflowCopyWindow
				workflow={workflow}
				companyAreaStore={companyAreaStore}
				maxMappingsLicensesAvailable={maxMappingsLicensesAvailable}
				visible={showCopyWindow}
				setVisible={setShowCopyWindow}
				startWorkflowCopy={startWorkflowCopy}
			/>,
			document.body
		),
		showRecurrenceWindow &&
		createPortal(
			<RecurrenceWindow
				auth={auth}
				workflow={workflow}
				recurrenceStore={recurrenceStore}
				setVisible={setShowRecurrenceWindow}
				visible={showRecurrenceWindow}
			/>,
			document.body
		),
	];
};

export const WorkflowCopyWindow = ({
	visible,
	setVisible,
	maxMappingsLicensesAvailable,
	companyAreaStore,
	workflow,
	startWorkflowCopy,
}) => {
	const [state, setState] = useState({});
	return (
		<Message
			visible={visible}
			hide={() => {
				setVisible(false);
			}}
			title={`Copiar fluxo para Áreas de Negócio`}
			description={
				<div>
					<div>{!!workflow.document && workflow.document.title}</div>
					<div>Selecione as Áreas de negócio para onde deseja duplicar o mapeamento</div>
					{companyAreaStore.list
						.sort((a, b) => {
							if (a.name > b.name) return 1;
							if (a.name < b.name) return -1;
							return 0;
						})
						.map((item, i) => {
							return (
								<div key={i}>
									<div className="columns with-padding">
										<div className="with-padding">
											<FormControlLabel
												label={item.name}
												className="checkbox-control"
												control={
													<Checkbox
														checked={item.selected}
														onChange={(e) => {
															item.selected = e.target.checked;
															setState({});
														}}
													/>
												}
											/>
										</div>
									</div>
								</div>
							);
						})}
					<div className="buttons">
						<simple-button
							save
							onClick={(e) => startWorkflowCopy({ companyAreaStore, maxMappingsLicensesAvailable, workflow })}
						>
							Copiar
						</simple-button>
						<simple-button cancel onClick={(e) => setVisible(false)}>
							{i18n("cancel")}
						</simple-button>
					</div>
				</div>
			}
		/>
	);
};

const recurrence_types = {
	daily: 'Diariamente',
	everySpecificHour: 'Diariamente - Horário específico',
	hourly: 'A cada hora',
	weekly: 'Semanalmente',
	monthly: 'Mensalmente',
	everyXHours: 'A cada X horas',
	everySpecificDay: 'Dia específico',
	everySpecificMonth: 'Mês específico',
};

export const RecurrenceWindow = ({ auth, workflow, visible, setVisible, recurrenceStore }) => {
	const [recurrence, setRecurrence] = useState({
		company: '',
		workflow: '',
		workflowObject: '',
		executionHistory: '',
		rule: '',
		daily: '',
		hourly: '',
		weekly: '',
		monthly: '',
		everyXHours: '',
		everySpecificDay: '',
		everySpecificMonth: '',
	});

	const isMappingAdmin = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.mapping.admin') > -1;
	const isMappingViewer = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.mapping.viewer') > -1;

	const getRecurrences = () => {
		//('WORKFLOW RECURRENCE', workflow);
		oRecurrenceService
			.filter([
				[
					['workflow', '==', workflow.uid || '---none---'],
					['deleted', '==', false],
				],
			])
			.list();
	};

	useEffect(() => {
		getRecurrences();
	}, [workflow.uid]);

	const addRecurrence = () => {
		let requiresRule =
			recurrence.type === 'everySpecificHour' ||
			recurrence.type === 'everySpecificDay' ||
			recurrence.type === 'everySpecificMonth';

		if (!recurrence.type || (requiresRule && !recurrence.rule)) {
			services.message.show('Por favor preencha corretamente');
			return;
		}
		if (!requiresRule) {
			recurrence.rule = '';
		}
		recurrence.company = auth.user.currentCompany.uid;
		recurrence.workflow = workflow.uid;
		recurrence.active = false;
		recurrence.lastExecution = '';
		recurrence.executionHistory = [
			{
				uid: auth.user.uid,
				name: auth.user.firstName + ' ' + auth.user.lastName,
				email: auth.user.email,
				type: 'created',
			},
		];

		//('recurrence', recurrence);

		oRecurrenceService.save(recurrence).then((r) => {
			recurrence.uid = '';
			recurrence.rule = '';
			recurrence.type = '';
		});
	};

	const toggleRecurrenceActive = (recurrence) => () => {
		recurrence.executionHistory.push({
			uid: auth.user.uid,
			name: auth.user.firstName + ' ' + auth.user.lastName,
			active: !recurrence.active,
			type: 'active-change',
		});
		oRecurrenceService.patch(recurrence.uid, {
			executionHistory: recurrence.executionHistory,
			active: !recurrence.active,
		});
	};

	const removeRecurrence = (recurrence) => () => {
		services.message.show('Remoção de Recorrência', 'Confirma a remoção da recorrência?', [
			{
				text: i18n('confirm'),
				onClick: async () => {
					services.message.hide();
					await recurrenceCollection
						.doc(recurrence.uid)
						.update({ deleted: true, deletedBy: auth.user.uid, deletedAt: new Date() });
				},
			},
			{
				text: i18n('cancel'),
				onClick: services.message.hide,
			},
		]);
	};

	const onRecurrencePropChange = (prop) => (e) => {
		setRecurrence({
			...recurrence,
			[prop]: e.target.value,
		});
	};
	return (
		<Message
			visible={visible}
			hide={() => {
				setVisible(false);
			}}
			title={`Recorrências`}
			description={
				<div>
					<h3>{workflow.document.title}</h3>
					<div className="recurrence-form">
						{isMappingAdmin && (
							<div className="" id="recurrence-form-content">
								<div className="columns">
									<FormControl>
										<InputLabel>Tipo de Recorrência</InputLabel>
										<Select value={recurrence.type} onChange={onRecurrencePropChange('type')}>
											<MenuItem value={'daily'}>Diariamente</MenuItem>
											<MenuItem value={'everySpecificHour'}>Diariamente - Hora específica</MenuItem>
											<MenuItem value={'hourly'}>A cada hora</MenuItem>
											<MenuItem value={'everyXHours'}>A cada X horas</MenuItem>
											<MenuItem value={'weekly'}>Semanalmente</MenuItem>
											<MenuItem value={'monthly'}>Mensalmente</MenuItem>
											<MenuItem value={'everySpecificDay'}>Dia específico</MenuItem>
											<MenuItem value={'everySpecificMonth'}>Mês específico</MenuItem>
										</Select>
									</FormControl>
									<div>
										{recurrence.type === 'everyXHours' && (
											<Input
												mode="specific top-label"
												label="Intervalo de horas"
												value={recurrence.rule}
												onChange={onRecurrencePropChange('rule')}
												type="number"
												mask="99"
											/>
										)}
										{recurrence.type === 'everySpecificHour' && (
											<Input
												mode="specific top-label"
												label="Hora específica"
												value={recurrence.rule}
												onChange={onRecurrencePropChange('rule')}
												type="number"
												mask="99"
											/>
										)}
										{recurrence.type === 'everySpecificDay' && (
											<Input
												mode="specific top-label"
												label="Dia específico"
												value={recurrence.rule}
												onChange={onRecurrencePropChange('rule')}
												type="number"
												mask="99"
											/>
										)}
										{recurrence.type === 'everySpecificMonth' && (
											<Input
												mode="specific top-label"
												label="Mês específico"
												value={recurrence.rule}
												onChange={onRecurrencePropChange('rule')}
												type="number"
												mask="99"
											/>
										)}
									</div>
									<div className="add-recurrence" onClick={addRecurrence}>
										<Icon icon="faPlus" />
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="recurrence-list">
						{recurrenceStore.list.map((recurrence, i) => {
							return (
								<div
									key={i}
									className={`recurrence-list-item
									${recurrence.active && 'active'}
									`}
								>
									<div className="recurrence-type">{recurrence_types[recurrence.type]}</div>
									<div className="recurrence-rule">
										{recurrence.type === 'everySpecificDay' && 'dia '}
										{recurrence.type === 'everySpecificMonth' && 'mês '}
										{recurrence.rule}
										{recurrence.type === 'everySpecificHour' && ' horas'}
									</div>
									<div style={{ flex: 1 }}></div>
									{isMappingAdmin && (
										<div className="recurrence-action delete" onClick={removeRecurrence(recurrence)}>
											<Icon icon="faTrash" />
										</div>
									)}
									{isMappingAdmin && (
										<div className="recurrence-action" onClick={toggleRecurrenceActive(recurrence)}>
											{recurrence.active && <Icon icon="faPause" />}
											{!recurrence.active && <Icon icon="faPlay" />}
										</div>
									)}
								</div>
							);
						})}
					</div>
					<div className="buttons">
						{/* <Button onClick={saveRecurrence}>Confirmar</Button>
				<Button onClick={services.message.hide}>Cancelar</Button> */}
					</div>
				</div>
			}
		/>
	);
};

export default connect(({ auth, companyAreaStore, recurrenceStore }) => ({ auth, companyAreaStore, recurrenceStore }))(
	WorkflowActionCard
);
