import styles from './news.module.css';
import React, { useEffect, useState } from "react";
import { Modal } from "../../components/interface/containerModal";
import { workfluxFirestore } from '@firebase-config';
import { useSelector } from 'react-redux';
import {
  Button,
  TextField,
  MenuItem
} from '@material-ui/core';
import { RenderCardNews } from './modules/renderNews';
import { AddCategory } from './modules/addCategory';
import { LoadingCircle } from '../../components/interface/loadingCircle';
import Icon from '../../@custom/Icon';
import { FormNews } from './modules/form';
import { Categories } from './modules/categories';

export function News() {
  const auth = useSelector((s) => s.auth);
  const newsCollection = workfluxFirestore.collection("news");
  const categoryCollection = workfluxFirestore.collection("categoryNews")

  const [renderModalAddNews, setRenderModalAddNews] = useState(false);
  const [renderModalEditNews, setRenderModalEditNews] = useState(false);
  const [renderModalAddCategory, setRenderModalAddCategory] = useState(false);
  const [renderModalCategories, setRenderModalCategories] = useState(false);
  const [renderModalEditCategory, setRenderModalEditCategory] = useState(false);

  const [listNews, setListNews] = useState([]);
  const [listCategoryNews, setListCategoryNews] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterCategory, setFilterCategory] = useState("");
  const [filterTitle, setFilterTitle] = useState("");

  const [dataNewsSelectedEdit, setDateNewsSelectedEdit] = useState(null);
  const [categorySelectedEdit, setCategorySelectedEdit] = useState(null);

  useEffect(() => {
    setLoading(true)
    const removeSnapshot = newsCollection
      .where("company", "==", auth.company.uid)
      .where("delete", "==", false)
      // .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const getListNews = [];
        docs.forEach((snap) => {
          const data = snap.data();
          getListNews.push({
            id: snap.id,
            bannerUrl: data.bannerUrl,
            category: data.category,
            company: data.company,
            createdAt: data.createdAt,
            createdBy: data.createdBy,
            summary: data.summary,
            text: data.text,
            title: data.title,
            user: data.user,
            delete: data.delete
          })
        })
        setListNews(getListNews);
        setLoading(false)
      });
    return () => {
      removeSnapshot()
    }
  }, [])

  useEffect(() => {

    const removeSnapshot = categoryCollection.where("company", "==", auth.company.uid).onSnapshot((docs) => {
      const getListCategory = [];

      docs.forEach((snap) => {
        const data = snap.data();
        getListCategory.push({
          id: snap.id,
          category: data.category,
          createdAt: data.createdAt,
          company: data.company,
          createdBy: data.createdBy,
          delete: data.delete,
          uid: data.uid
        })
      })
      setListCategoryNews(getListCategory)
    })

    return () => {
      removeSnapshot();
    }
  }, [])

  const filterListNews = listNews.filter((item) => {
    const titleMatch = item.title.toLowerCase().includes(filterTitle.toLowerCase());
    const categoryMatch = !filterCategory || item.category === filterCategory;

    return titleMatch && categoryMatch;
  })

  const handleCategoryNews = (e) => {
    const categoryNews = listCategoryNews.filter((item) => item.id === e);
    const nameCategory = categoryNews[0]?.category

    return nameCategory
  }

  const handleEdit = ({ newsSelected }) => {
    setDateNewsSelectedEdit(newsSelected)
    setRenderModalEditNews(true);
  }

  const handleEditCategory = ({ category }) => {
    setCategorySelectedEdit(category);
    setRenderModalEditCategory(true)
  }

  return (
    <main className={styles.containerNews}>
      <section className={styles.headerNews}>
        <section>
          <h3><Icon icon="faNewspaper" /> Portal de Notícias</h3>
        </section>
        <section className={styles.headerFilter}>
          <TextField
            size="small"
            variant="outlined"
            label="Titulo"
            style={{ width: '100%', zIndex: 0 }}
            value={filterTitle}
            onChange={(event) => setFilterTitle(event.target.value)}
          />
          <TextField
            select
            size="small"
            variant="outlined"
            label="Categoria"
            style={{ width: '100%', zIndex: 0 }}
            value={filterCategory}
            onClick={(event) => setFilterCategory(event.target.value)}
          >

            <MenuItem value={""}>Categoria</MenuItem>
            {listCategoryNews.map((category) => (
              <MenuItem
                value={category.id}
                key={category.id}>
                {category.delete ?
                  <p>{category.category} <span style={{
                    fontSize: 12
                  }}>[deletada]</span></p>
                  :
                  category.category
                }</MenuItem>
            ))}
          </TextField>
          {auth.user.companyadmin && (
            <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setRenderModalAddNews(true)}
                style={{
                  width: "100%",
                }}
              >
                Adicionar Notícia
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setRenderModalCategories(true)}
                style={{
                  width: "100%",

                }}
              >
                Categorias
              </Button>
            </>
          )}
        </section>
      </section>
      {loading ? <LoadingCircle /> : (
        <>
          {listNews.length === 0 ?
            (<section >
              <h3>Até o momento não foi criado nenhuma  notícias</h3>
            </section>)
            :
            (<section className={styles.containerCardNews}>
              {filterListNews.map((news) => (
                <RenderCardNews
                  news={news}
                  key={news?.id}
                  categoryName={() => handleCategoryNews(news?.category)}
                  handleEdit={handleEdit}
                />
              )
              )}
            </section>)
          }

          {
            renderModalAddNews && (
              <Modal setRenderModal={setRenderModalAddNews}>
                <FormNews
                  closeModal={() => setRenderModalAddNews(false)}
                  addCategory={() => setRenderModalAddCategory(true)}
                  listCategory={listCategoryNews}
                  titleForm={"Crie uma Notícia"}
                  action={null}
                  dataNews={null}
                />
              </Modal>
            )
          }
          {
            renderModalEditNews && (
              <Modal setRenderModal={setRenderModalEditNews}>
                <FormNews
                  closeModal={() => setRenderModalEditNews(false)}
                  addCategory={() => setRenderModalAddCategory(true)}
                  listCategory={listCategoryNews}
                  titleForm={"Edite sua Notícia"}
                  action={"edit"}
                  dataNews={dataNewsSelectedEdit}
                />
              </Modal>
            )
          }
          {
            renderModalCategories && (
              <Modal setRenderModal={setRenderModalCategories}>
                <Categories
                  closeModal={() => setRenderModalCategories(false)}
                  listCategory={listCategoryNews}
                  handleEdit={handleEditCategory}
                  addCategory={() => setRenderModalAddCategory(true)}
                />
              </Modal>
            )
          }
          {
            renderModalAddCategory && (
              <Modal setRenderModal={setRenderModalAddCategory}>
                <AddCategory
                  closeModal={() => setRenderModalAddCategory(false)}
                  listCategory={listCategoryNews}
                  categorySelected={false}
                />
              </Modal>
            )
          }
          {
            renderModalEditCategory && (
              <Modal setRenderModal={setRenderModalEditCategory}>
                <AddCategory
                  closeModal={() => setRenderModalEditCategory(false)}
                  listCategory={listCategoryNews}
                  categorySelected={categorySelectedEdit}
                />
              </Modal>
            )
          }

        </>
      )}
    </main >

  )
}