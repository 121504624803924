import styles from "./addCategory.module.css";
import React, { useState } from "react";

import {
  Button,
} from '@material-ui/core';
import Input from '@custom/Input';
import i18n from "@i18n";
import { useSelector } from 'react-redux';

import { workfluxFirestore } from '@firebase-config';

export function AddCategory({ closeModal, listCategory, categorySelected }) {
  const auth = useSelector((s) => s.auth);
  const categoryCollection = workfluxFirestore.collection("categoryNews");

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(categorySelected ? categorySelected.category : "");
  const [errorTitle, setErrorTitle] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    if (title === "") {
      setErrorTitle(true);
      return;
    }

    categorySelected ? await editNews() : await addNews();

    closeModal();
    setErrorTitle(false);
    setTitle("");
    setLoading(false)
  }

  const addNews = async () => {
    try {
      const refDoc = categoryCollection.doc();

      const response = await refDoc.set({
        uid: refDoc.id,
        category: title,
        createdBy: auth.user.uid,
        company: auth.company.uid,
        createdAt: new Date(),
        delete: false
      });
    } catch (error) {
      console.log(error);
    }
  }

  const editNews = async () => {
    try {
      const refDoc = categoryCollection.doc(categorySelected.id);

      const response = await refDoc.update({
        category: title
      });

    } catch (error) {

    }
  }

  return (
    <section style={{ marginTop: 16 }}>
      <form onSubmit={handleSubmit}>
        <Input
          styles={{
            marginTop: 16
          }}
          leftIcon="faTextHeight"
          mode="label-from-left"
          label={i18n("category")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          message={errorTitle && i18n("enterATitle")}
        />
        <section className={styles.areaButtons}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={closeModal}
          >
            Voltar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {categorySelected ? "Editar" : "Cadastrar"}
          </Button>
        </section>
      </form>
    </section>
  )
}