import { services, history } from '../@redux';

setTimeout(() => {
    const originalLog = console.log;

    console.log = function () {
        const [obj] = arguments;

        if (['auth/user-token-expired', 'permission-denied'].includes(obj?.code)) {
            services.auth.logout();
            history.push('/auth');
        }

        originalLog.apply(console, arguments);
    };
}, 0);
