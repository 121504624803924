import React, { useEffect, useState } from 'react';
import Api from '@api';
import { connect } from 'react-redux';
import firebase, { workfluxFirestore } from '@firebase-config';
import i18n, { format } from '@i18n';
import Parser from 'react-html-parser';
import LacunaWebPKI from 'web-pki';
import TaskComplementary from './task.complementary.js';
import TaskOwnerAbsent from './task.owner.absent.js';
import TaskShare from './task.share.js';
import TaskCard from './TaskCard.js';
import Table from '@custom/Table';
import { Input as ZeroInput, store as zerocodeStore } from 'zerocode';
import { LinearProgressWithLabel } from '../../utils/linearProgressWithLabel';
import {
	services,
	store
} from '@redux';
import {
	ExecutedForm,
	Signing,
	Task
} from '@taugor/taugor-bpmn-models';
import {
	getFieldListFromString,
	onMainChangeProp
} from '@custom/common';
import Icon from '@custom/Icon';
import Input from '@custom/Input';
import QuillRichTextEditor from '@custom/QuillRichTextEditor';
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	Typography
} from '@material-ui/core';

const oSigningService = new Signing().getService(firebase, store, 'signingFormView');
const oTaskService = new Task().getService(firebase, store, 'taskView');
const oExecutedFormService = new ExecutedForm().getService(firebase, store, 'taskFormExecutedForm');
const taskCollection = workfluxFirestore.collection('task');

var pki = new LacunaWebPKI();
let isSigning = false;

const TaskApproval = ({
	taskId,
	task,
	setMobileFormVisible,
	external,
	authStore,
	absenseStore,
	taskStore,
	signingStore,
	executedFormStore,
	onSave,
}) => {
	const [, setReactions] = useState([]);
	const [, setPkiStarted] = useState([]);
	const [formValues, setFormValue] = useState({});
	const [discussions, setDiscussions] = useState([]);
	const [signingRequired, setSigningRequired] = useState(false);
	const [pkiCertificates, setPkiCertificates] = useState([]);
	const [selectedCert, setSelectedCert] = useState('');
	const [hasHsmLicense, setHasHsmLicense] = useState(false);
	const [checklist, setChecklist] = useState(false);
	const appcenterStore = zerocodeStore.getState().appcenter;

	useEffect(() => {
		if (appcenterStore.licenses) {
			const hasHsmLicense = appcenterStore.licenses.some((license) => license.type === 'workflux-hsm');
			setHasHsmLicense(hasHsmLicense);
		}
	}, [appcenterStore.licenses])

	useEffect(() => {
		if (!taskId) return;
		return taskCollection
			.doc(taskId)
			.collection('discussion')
			.where('deleted', '==', false)
			.onSnapshot((snapshot) => {
				setDiscussions(snapshot.docs.map((d) => d.data()));
			});
	}, [, taskStore.current.uid]);

	useEffect(() => {
		pki.init(onWebPkiReady);
	}, []);

	useEffect(() => {
		if (signingStore.current.status === 'pending-sign' && !isSigning) {
			isSigning = true;
			pki.signWithRestPki({
				token: signingStore.current.token,
				thumbprint: signingStore.current.certificateThumbprint,
			}).success(function (token) {
				oSigningService
					.patch(signingStore.current.uid, {
						token,
						status: 'done',
					})
					.then((r) => {
						isSigning = false;
					});
			});
		}
	}, [signingStore.current, signingStore.current.status]);

	function onWebPkiReady() {
		setPkiStarted(true);
		let c = [];
		pki.listCertificates().success(function (certs) {
			for (var i = 0; i < certs.length; i++) {
				var cert = certs[i];
				c.push(cert);
			}
			setPkiCertificates(c);
		});
	}
	const onTaskGet = (task) => {
		if (!task || !task.uid) return;
		let r = [];
		task.workflowObject.phases.map((phase) => {
			phase.reactions.map((reaction) => {
				if (reaction.originForm === task.action.uid) {
					r.push(reaction);
					if (reaction.type.type === 'documentOutput' && reaction.requireApprovalDocumentSign) {
						setSigningRequired(true);
					}
				}
			});
		});
		setReactions(r);
		//('HERE AT ON TASK GET', task);
	};
	useEffect(() => {
		if (taskId || !task) return;
		taskStore.current = task;
		onTaskGet(task);
	}, [task]);

	useEffect(() => {
		if (!taskId) return;
		let checklistDoc = taskCollection.doc(taskId).collection('checklist').doc(taskId);
		checklistDoc.get().then((d) => {
			let data = {
				actions: [],
			};
			if (d.exists) {
				data = d.data();
			}
			setChecklist(data.actions);
		});
	}, [, taskStore.current.uid]);

	useEffect(() => {
		if (!taskId) return;
		services.spinner.show();
		oTaskService
			.get(taskId)
			.then((task) => {
				onTaskGet(task);
			})
			.finally(services.spinner.hide);
	}, [taskId]);

	useEffect(() => {
		if (!taskStore.current || !taskStore.current.uid) return;
		services.spinner.show();
		oExecutedFormService
			.filter([
				[
					['action.uid', '==', taskStore.current.action.uid],
					['executionFlow', '==', taskStore.current.executionFlow],
					['company', '==', authStore.company.uid],
				],
			])
			.list()
			.finally(services.spinner.hide);
	}, [taskStore.current]);

	let progress = 100;
	if (checklist && checklist.length > 0) {
		progress = (checklist.filter((a) => a.finished).length * 100) / checklist.length;
	}

	if (!taskStore.current || !taskStore.current.uid) return <div></div>;
	if (taskStore.current.status && taskStore.current.status !== 'completed' && taskStore.current.agent !== authStore.user.uid) {
		let isAbsent = false;
		absenseStore.list.map((absense) => {
			let today = new Date();
			if (absense.dateStart.toDate() <= today && absense.dateEnd.toDate() >= today) {
				isAbsent = true;
			}
		});
		if (isAbsent) {
			return <TaskOwnerAbsent task={taskStore.current} />;
		}
	}

	const onChange = (prop) => {
		let fn = onMainChangeProp(formValues, setFormValue, prop);
		return fn;
	};

	const onChange2 = (prop) => (e) => {
		setFormValue({
			...formValues,
			[prop]: e.target.value,
		});
	};

	const saveMessage = () => {
		services.spinner.hide();
		services.message.show(
			'Tarefa concluída com sucesso',
			'',
			[],
			() => window.location.reload()
		);
	};

	const sendLinkHsm = async (e, task) => {
		e.preventDefault();
		e.stopPropagation();

		let phone = ''

		await new Promise((resolve) => {
			services.message.show(
				'Enviar link de assinatura por HSM',
				<div>
					<div
						style={{
							marginBottom: 16,
						}}
					>
						Digite o número do celular:
					</div>
					<Input
						mode="outlined"
						type="text"
						label={'Número de telefone'}
						mask="(99) 99999-9999"
						onChange={(e) => phone = e.target.value}
					/>
				</div>,
				[
					{
						text: 'confirm',
						onClick: (e) => resolve(),
					},
					{
						text: 'cancel',
						onClick: (e) => resolve(),
					},
				],
				resolve
			)
		});

		services.message.hide();

		services.spinner.show();

		phone = `55${phone.replace(/[^0-9]/g, '')}`;

		const data = {
			to: phone,
			namespace: '86ae2fa8_6186_4b8c_81af_8d7b71b3a2d0',
			elementName: 'voc_recebeu_uma_tarefa_real',
			components: [
				{
					type: 'body',
					parameters: [
						{
							type: 'text',
							text: task.action.agentName,
						},
						{
							type: 'text',
							text: `${task.workflowObject.document.title || ''} - ${task.action.type.name || ''}`.trim(),
						},
						{
							type: 'text',
							text: task.action.formTitle,
						},
						{
							type: 'text',
							text: `https://workflux.digital//executionFlow/${taskStore.current.executionFlow}/task/${taskStore.current.action.type.type}/${taskStore.current.uid}`,
						}
					],
				},
			],
		};

		try {
			await Api.hsm.sendHsm(data);

			services.spinner.hide();
			services.message.show('Link enviado com sucesso!', `O link foi enviado para o número +${phone}.`);
		} catch (err) {
			console.log(err);

			services.spinner.hide();
			services.message.show('Ocorreu um erro ao enviar o link.', `Confira se o número +${phone} está certo e entre em contato com o suporte.`);
		}
	}

	const save = () => {
		if (signingRequired && !selectedCert) {
			services.message.show(i18n('task.form.reproval.needsSigning'));
			return;
		}

		services.spinner.show();
		if (signingRequired) {
			oSigningService.save({ status: 'pending', certificateThumbprint: selectedCert }).then((r) => {
				Api.executionFlow.task
					.formInput({
						task: taskStore.current,
						form: formValues,
						signingUid: r.uid,
					})
					.finally(saveMessage);
				oSigningService.get(r.uid);
			});
		} else {
			Api.executionFlow.task.formInput({
				task: taskStore.current,
				form: formValues,
			})
				.finally(saveMessage);
		}
	};


	const renderInfoContent = () => {
		return (
			<div>
				{taskStore.current.status === 'review' ? (
					<div>
						{taskStore.current.action.agent !== authStore.user.uid && (
							<div style={{ marginBottom: 20 }}>
								{i18n('task.form.responsible')}: {taskStore.current.action.agentName}
							</div>
						)}
						<h4>{i18n('task.form.inReview')}</h4>
					</div>
				) : (
					<div>
						<div style={{ marginBottom: 20 }}>
							{i18n('task.form.responsible')}: {taskStore.current.action.agentName}
						</div>
						<div style={{ color: '#666', marginBottom: 10 }}>{i18n('task.form.instructions')}</div>
						<h3>{taskStore.current.action.formTitle || i18n('task.form.noTitleForm')}</h3>
					</div>
				)}
				{!external && (
					<div className="absent-panel">
						<TaskOwnerAbsent task={taskStore.current} hideTexts={true} />
					</div>
				)}
				{taskStore.current.expirationDateString && (
					<div style={{ color: '#666', marginBottom: 10 }}>
						{i18n('task.form.taskWillExpire', {
							date: format.date(new Date(taskStore.current.expirationDateString + ' 12:00')),
						})}
					</div>
				)}

				{taskStore.current.action.complementary && (
					<div className="complementary-task-warning">{i18n('executionFlow.form.complementaryTask')}</div>
				)}
				{taskStore.current.status === 'review' && taskStore.current.action.agent === authStore.user.uid && (
					<div style={{ marginBottom: 20 }}>
						<Button
							style={{ marginBottom: 20 }}
							className="simple-filled-button"
							onClick={() => {
								services.spinner.show();
								Api.executionFlow.task
									.confirmReview({ task: taskStore.current.uid })
									.finally(services.spinner.hide);
							}}
						>
							{i18n('task.form.inReview.useCurrentInfo')}
						</Button>
						{renderFormContent()}
					</div>
				)}

				{!!checklist && checklist.length > 0 && (
					<div className="progress-bar">
						<p>{i18n("activityChecklist")}:</p>
						<LinearProgressWithLabel value={progress} />
					</div>
				)}

				{!!checklist && (
					<div className="task-checklist-actions" style={{ marginBottom: 15 }}>
						{checklist.map((action, i) => {
							return (
								<div className="checklist-action" style={{ color: '#666', marginBottom: 5 }} key={i}>
									{action.finished ? (<><Icon icon="faCheck" /> </>) : (<><Icon icon="faTimes" /> </>)}
									{action.name}
								</div>
							);
						})}
					</div>
				)}
				{!!discussions[0] && (
					<div className="discussion-content">
						<p className="title">{i18n("workflow.kanban.card.step.discussion")}:</p>
						{discussions.map((discussion) => {
							return (
								<div className="discussion">
									<div className="name" >{discussion.user.name}:</div>
									<div className="content" >{discussion.comment}</div>
								</div>
							);
						})}
					</div>
				)}

				{executedFormStore.list
					.sort((a, b) => {
						if (a.createdAt.toDate() > b.createdAt.toDate()) return -1;
						if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
						return 0;
					})
					.map((executedForm, i) => {
						let fields = getFieldListFromString(executedForm.action);
						//('fields', fields);
						let lastFieldType = '';
						let listNumber = 0;
						return (
							<div key={i}>
								<div className="info-field-" style={{ marginBottom: 15 }}>
									<label style={{ color: '#666' }}>{i18n('task.formInput.form.filledAt')}</label>
									<div>{format.datetime(executedForm.createdAt.toDate())}</div>
								</div>
								<div style={{ borderBottom: '1px solid #ddd', marginBottom: 30 }}>
									{fields.map((fieldConfig, i) => {
										let type = fieldConfig.type;
										let options = fieldConfig.options;
										let mask = fieldConfig.mask;
										let field = fieldConfig.name;

										if (executedForm.fields[field] === undefined) {
											executedForm.fields[field] = '';
										}
										let fieldValue = executedForm.fields[field];
										if (type === 'link') {
											lastFieldType = type;
											listNumber = 0;
											return (
												<div>
													<a href={(options || '').join('')}>{field}</a>
												</div>
											);
										}
										if (type === 'title') {
											lastFieldType = type;
											listNumber = 0;
											return <h3>{field}</h3>;
										}
										if (type === 'subtitle') {
											lastFieldType = type;
											listNumber = 0;
											return <h4>{field}</h4>;
										}
										if (type === 'listNumber') {
											lastFieldType = type;
											if (lastFieldType === 'listNumber') {
												listNumber++;
											}
											return (
												<div className="list-number-item">
													<div className="number">{listNumber}</div>
													<div className="value">{field}</div>
												</div>
											);
										}
										if (type === 'listBullet') {
											lastFieldType = type;
											listNumber = 0;

											return (
												<div className="list-number-item">
													<div className="bullet">
														<Icon icon="faCircle" />
													</div>
													<div className="value">{field}</div>
												</div>
											);
										}
										if (type === 'richtext') {
											lastFieldType = type;
											listNumber = 0;

											return (
												<div key={i} className="info-field form">
													<label>{field}</label>
													<div
														dangerouslySetInnerHTML={{ __html: fieldValue }}
														style={{ whiteSpace: 'pre-wrap' }}
													/>
												</div>
											);
										}

										if (type.indexOf('table') > -1) {
											fieldValue = (
												<table className="table-sheet-view">
													<tr className="row headers">
														{options.map((col, a) => {
															return (
																<th key={a} className="col">
																	{col}
																</th>
															);
														})}
													</tr>

													{fieldValue.map((values, i) => {
														return (
															<tr className="row" key={i}>
																{options.map((col, a) => {
																	return (
																		<td key={a} className="col">
																			{Parser(values[col])}
																		</td>
																	);
																})}
															</tr>
														);
													})}
												</table>
											);
										} else {
											if (type === 'checkbox') {
												if (fieldValue === true) {
													fieldValue = <Icon icon="faCheck" />;
												} else {
													fieldValue = <Icon icon="faTimes" />;
												}
											}
											if (type === 'color') {
												fieldValue = (
													<div
														style={{
															width: 30,
															height: 30,
															background: fieldValue,
														}}
													></div>
												);
											}
										}

										return (
											<div key={i} className="info-field form">
												<label>{field}</label>
												<div style={{ whiteSpace: 'pre-wrap' }}>{fieldValue}</div>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
			</div>
		);
	};
	const renderSignatureForm = () => {
		if (!signingRequired) return;
		return (
			<div style={{ marginTop: 10 }}>
				<div style={{ marginBottom: 10 }}>{i18n("thisApprovalRequiresSignature")}</div>
				<div style={{ marginBottom: 10, fontSize: 14 }}>{i18n("pleaseSelectYourCertificate")}</div>
				<div>
					<FormControl style={{ width: '100%' }} variant="filled">
						<InputLabel>{i18n("yourCertificates")}</InputLabel>
						<Select value={null} onChange={(e) => setSelectedCert(e.target.value)}>
							<MenuItem value={''}></MenuItem>
							{pkiCertificates.map((cert, i) => {
								return (
									<MenuItem
										value={cert.thumbprint}
										key={i}
										style={{
											borderBottom: '1px solid #ddd',
										}}
									>
										<div>
											<div style={{ fontSize: 12, fontWeight: 'bold' }}>
												{cert.pkiBrazil.certificateType} - {cert.subjectName}
											</div>
											<div style={{ fontSize: 12 }}>{cert.issuerName}</div>
											{cert.validityEnd < new Date() && (
												<div style={{ fontSize: 12, fontWeight: 'bold' }}>Expirado</div>
											)}
										</div>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
			</div>
		);
	};

	const buildFormFields = () => {
		let fields = getFieldListFromString(taskStore.current.action);
		console.log("fields", fields);
		let lastFieldType = '';
		let listNumber = 0;
		return fields.map((fieldConfig, i) => {
			let type = fieldConfig.type;
			let options = fieldConfig.options;
			let mask = fieldConfig.mask;
			let field = fieldConfig.name;

			if (formValues[field] === undefined) {
				formValues[field] = '';
			}
			if (type === 'link') {
				lastFieldType = type;
				listNumber = 0;
				return (
					<div>
						<a href={(options || '').join('')}>{field}</a>
					</div>
				);
			}
			if (type === 'title') {
				lastFieldType = type;
				listNumber = 0;
				return <h3>{field}</h3>;
			}
			if (type === 'subtitle') {
				lastFieldType = type;
				listNumber = 0;
				return <h4>{field}</h4>;
			}
			if (type === 'user' || type === 'systemUser') {
				lastFieldType = type;
				listNumber = 0;
				return (
					<div className="form-input-content" key={i}>
						<FormControl style={{ width: '100%' }} variant="filled">
							<InputLabel>{field}</InputLabel>
							<Select value={formValues[field]}
								onChange={onChange2(field)}
							>
								{appcenterStore.users.map((user) => {
									let active = (user.active || '').indexOf(user.companies[0]) > -1;
									if (!active) return;
									return (
										<MenuItem value={`${user.firstName}; ${user.lastName}; ${user.email}; ${user.uid}`}>
											<div>
												<div>{user.firstName + ' ' + user.lastName}</div>
												<div>{user.user}</div>
											</div>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
				);
			}
			if (type === 'group' || type === 'systemGroup') {
				lastFieldType = type;
				listNumber = 0;

				return (
					<div className="form-input-content" key={i}>
						<FormControl style={{ width: '100%' }} variant="filled">
							<InputLabel>{field}</InputLabel>
							<Select
								value={formValues[field]}
								onChange={onChange2(field)}
							>
								{appcenterStore.groups.map((group) => {
									return (
										<MenuItem value={`${group.uid} ${group.name}`}>
											<div>{group.name}</div>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
				);
			}
			if (type === 'listNumber') {
				lastFieldType = type;
				if (lastFieldType === 'listNumber') {
					listNumber++;
				}
				return (
					<div className="list-number-item">
						<div className="number">{listNumber}</div>
						<div className="value">{field}</div>
					</div>
				);
			}
			if (type === 'listBullet') {
				lastFieldType = type;
				listNumber = 0;
				return (
					<div className="list-number-item">
						<div className="bullet">
							<Icon icon="faCircle" />
						</div>
						<div className="value">{field}</div>
					</div>
				);
			}
			if (type === 'table') {
				if (!formValues[field]) {
					formValues[field] = [];
				}
				lastFieldType = type;
				listNumber = 0;
				return (
					<Table
						field={field}
						formValues={formValues}
						setFormValue={setFormValue}
						options={options}
					/>
				);
			}
			if (type === 'select') {
				lastFieldType = type;
				listNumber = 0;
				return (
					<div className="form-input-content" key={i}>
						<FormControl style={{ width: '100%' }} variant="filled">
							<InputLabel>{field}</InputLabel>
							<Select
								value={formValues[field]}
								onChange={onChange(field)}
							>
								{options.map((option, a) => {
									return (
										<MenuItem value={option} key={a}>
											{option}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
				);
			}
			if (type === 'checkbox') {
				lastFieldType = type;
				listNumber = 0;
				return (
					<div className="form-input-content" key={i}>
						<FormControl style={{ width: '100%' }} variant="filled">
							<FormControlLabel
								label={field}
								className="checkbox-control"
								control={
									<Checkbox
										checked={formValues[field]}
										onChange={(e) => {
											// onChange(field)
											setFormValue({
												...formValues,
												[field]: e.target.checked,
											});
										}}
									/>
								}
							/>
						</FormControl>
					</div>
				);
			}
			if (type === 'currency') {
				type = 'formatted-number';
			}
			if (type === 'richtext') {
				lastFieldType = type;
				listNumber = 0;
				return (
					<label>
						{field}
						<QuillRichTextEditor
							theme="snow"
							onChange={onChange(field)}
							value={formValues[field]}
							placeholder="Digite aqui..."
						/>
					</label>
				);
			}

			lastFieldType = type;
			listNumber = 0;

			const label = field + (type === 'email'
				? '. Caso for colocar mais de um email separar por ";"'
				: ''
			);
			return (
				<div className="form-input-content" key={i}>
					<ZeroInput
						alwaysShowMask
						mode="top-label outlined"
						label={label}
						type={type}
						mask={mask}
						multiline={type === 'textarea'}
						value={formValues[field]}
						onChange={onChange(field)}
					/>
				</div>
			);
		});
	};

	const teste = executedFormStore.list
		.sort((a, b) => {
			if (a.createdAt.toDate() > b.createdAt.toDate()) return -1;
			if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
			return 0;
		})
		.map((executedForm) => {
			getFieldListFromString(executedForm.action);
		});

	const renderFormContent = () => (
		<div className="task-form-main">
			<TaskCard
				teste={teste}
				isFinalPhase={true}
				key={taskStore.current.uid}
				parentStatus={''}
				auth={authStore}
				doc={{
					id: taskStore.current.uid,
					data: () => taskStore.current,
				}}
			/>
			{taskStore.current.expirationDateString && (
				<div style={{ color: '#666', marginBottom: 10 }}>
					{i18n('task.form.taskWillExpire', {
						date: format.date(new Date(taskStore.current.expirationDateString + ' 12:00')),
					})}
				</div>
			)}
			{!external && taskStore.current.uid !== 'startup' && (
				<div>
					<TaskComplementary task={taskStore.current} />
					<TaskShare task={taskStore.current} />
				</div>
			)}
			{!external && taskStore.current.uid !== 'startup' && (
				<div style={{ marginBottom: 15 }}>
					<TaskOwnerAbsent task={taskStore.current} hideTexts={true} />
				</div>
			)}
			<div className="free">
				{!!(taskStore.current.action.formFields || '').length && <div>{buildFormFields()}</div>}
			</div>
			<div>{renderSignatureForm()}</div>
			{taskStore.current.uid !== 'startup' && (
				<div style={{ marginTop: 20 }}>
					<Button className="simple-filled-button" onClick={save}>
						{i18n('task.formInput.form.save')}
					</Button>
				</div>
			)}
		</div>
	);

	const renderContent = () => {
		if (!external && taskStore.current.action.agent !== authStore.user.uid) {
			return renderInfoContent();
		}
		return taskStore.current.status !== 'pending' ? renderInfoContent() : renderFormContent();
	};

	return (
		<content>
			<header className="form-panel-header">
				<div>
					{/* Preenchimento de Formulário / Aprovação / Envio de Documento */}
					<Typography variant="h4">{i18n("task.formInput.type")}</Typography>
					<Typography style={{ marginTop: 10 }}>
						{i18n('workflow.form.title')}
						{': '}
						{taskStore.current.workflowObject.document.title}
					</Typography>
					<div style={{ display: 'flex', justifyContent: 'space-around' }}>
						<simple-button
							style={{
								border: '2px solid #3c3',
								color: '#3c3',
								textDecoration: 'none',
								padding: 6,
								borderRadius: 4,
								fontSize: 12,
								backgroundColor: 'transparent',
								width: 'fit-content',
							}}
							onClick={() => {
								window.open(`https://api.whatsapp.com/send?text=
								A tarefa ${taskStore.current.action.instructions || taskStore.current.action.formTitle
									} do fluxo https://workflux.digital//executionFlow/${taskStore.current.executionFlow}/task/${taskStore.current.action.type.type
									}/${taskStore.current.uid}
								`);
							}}
						>
							<Icon icon="faWhatsapp" />
							{i18n("submitTask")}
						</simple-button>

						{hasHsmLicense && <simple-button
							style={{
								border: '2px solid #3c3',
								color: '#3c3',
								textDecoration: 'none',
								padding: 6,
								borderRadius: 4,
								fontSize: 12,
								backgroundColor: 'transparent',
								width: 'fit-content',
							}}
							onClick={(e) => {
								// console.log(taskStore.current)
								sendLinkHsm(e, taskStore.current)
							}}
						>
							<Icon icon="faWhatsapp" />
							{i18n("submitTask")} HSM
						</simple-button>}
					</div>
				</div>
				<div
					className="header-button"
					onClick={() => {
						if (typeof setMobileFormVisible === 'function') setMobileFormVisible(false);
					}}
				>
					<Icon icon="faTimes" />
				</div>
			</header>
			<div style={{ padding: 20 }}>{renderContent()}</div>
		</content>
	);
};

export default connect(({ auth, absenseStore, taskView, signingFormView, taskFormExecutedForm }) => ({
	authStore: auth,
	absenseStore: absenseStore,
	taskStore: taskView,
	signingStore: signingFormView,
	executedFormStore: taskFormExecutedForm,
}))(TaskApproval);
