import React, { useState } from 'react';
import i18n from '@i18n';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import { services } from '@redux';

const Unauthorized = () => {
	return (
		<content>
			<header>
				<Typography gutterBottom variant="h5" component="h2">
					{i18n('oops.unauthorized')}
				</Typography>
			</header>
		</content>
	);
};

export default Unauthorized;
