"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateLicense = exports.getLicenses = exports.getLeftAmountLicense = exports.filterAppLicenses = void 0;
var _store = require("../../@api/store");
var getLicenses = function getLicenses() {
  var appcenterStore = _store.store.getState().appcenter;
  return appcenterStore.licenses || [];
};
exports.getLicenses = getLicenses;
var filterValidLicenses = function filterValidLicenses(licenseType, app) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    returnAllAmounts = _ref.returnAllAmounts;
  var licenses = getLicenses();
  if (!(licenses !== null && licenses !== void 0 && licenses.length)) return [];
  var filtered = licenses.filter(function (l) {
    return (!!app ? l.app === app : true) && (
    // filter by app
    !licenseType || l.type === licenseType) &&
    // get any license or filter by type
    ['paid', 'free'].includes(l.status) && (
    // is paid or is free
    !!l.singleUse || l.used < l.amount || returnAllAmounts);
  } // if has use limit
  );

  return filtered;
};
var getLeftAmountLicense = function getLeftAmountLicense(licenseType) {
  var app = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var licenses = filterValidLicenses(licenseType, app, {
    returnAllAmounts: true
  });
  var isValid = false;
  var unlimited = false;
  var amount = 0;
  var used = 0;
  licenses.forEach(function (license) {
    if (amount === 'unlimited') {
      unlimited = true;
      isValid = true;
    } else {
      amount += license.amount;
      used += license.used;
    }
  });
  var left = unlimited ? 'unlimited' : amount - used;
  if (licenses.length) {
    if (!isValid) {
      left = amount - used;
      isValid = left > 0 && !!licenses.length;
    }
  }
  return {
    amount: amount,
    used: used,
    left: left,
    isValid: isValid
  };
};
exports.getLeftAmountLicense = getLeftAmountLicense;
var validateLicense = function validateLicense(licenseType) {
  var app = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var check = function check(license) {
    return !!filterValidLicenses(license, app);
  };
  if (!getLicenses().length) return 'loading';
  if (typeof licenseType === 'string') return check(licenseType);
  return !!licenseType.map(check).filter(function (l) {
    return !!l;
  }).length;
};
exports.validateLicense = validateLicense;
var filterAppLicenses = function filterAppLicenses(app) {
  return getLicenses().filter(function (license) {
    return license.app === app;
  });
};
exports.filterAppLicenses = filterAppLicenses;