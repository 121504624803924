import React, {useEffect, useState, useCallback, useRef} from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';


import {
  GridToolbarContainer,
  GridActionsCellItem,
  DataGrid,
  GridRowModes,
  ptBR
} from "@mui/x-data-grid";

import {
  randomId
} from '@mui/x-data-grid-generator';
import { toolbarClasses } from '@mui/material';

function EditToolbar(props) {
  const { setRows, setRowModesModel, tableId } = props;

  const handleAddNewRow = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };
  
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.ctrlKey && e.key === "Enter") handleAddNewRow();
    }
    document.getElementById(tableId).addEventListener('keydown', onKeyDown);
    return () => document.getElementById(tableId).removeEventListener('keydown', onKeyDown);
  }, [])


  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleAddNewRow}/>
    </GridToolbarContainer>
  );
}

export default function Table (props) {
	  const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});

    const tableId = props.field.split(' ').join('-').toLowerCase();
   
    useEffect(() => {
      setRows(
        props.formValues[props.field].map((item, key) => {
          return {
            id: key,
            ...item,
          }
        }
      ));
    }, []);

    const handleRowEditStart = ( params, event ) => {
      event.defaultMuiPrevented = true;
    };
  
    const handleEditClick = (id) => () => {
      setRowModesModel({ 
        ...rowModesModel, 
        [id]: { 
          mode: GridRowModes.Edit 
        } 
      });
    };
  
    const handleSaveClick = (id) => () => {
      setRowModesModel({ 
        ...rowModesModel, 
        [id]: { 
          mode: GridRowModes.View 
        } 
      });
    };
  
    const handleDeleteClick = (id) => () => {
      const rowsAux = rows.filter((row) => row.id !== id);
      setRows(rowsAux);
      props.setFormValue({
        ...props.formValues,
        [props.field]: rowsAux,
      });
    };
  
    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { 
          mode: GridRowModes.View, 
          ignoreModifications: true 
        }
      });
  
      const editedRow = rows.find((row) => row.id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };
  
    const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      const rowsAux = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
      setRows(rowsAux);
      props.setFormValue({
        ...props.formValues,
        [props.field]: rowsAux,
      });
      return updatedRow;
    };

    const col = props.options.map((option) => {
      return {
        field: option,
        headerName: option,
        width: 150,
        editable: true,
      }
    });

    const columns = [
      ...col,
      {
        field: "actions",
        type: "actions",
        headerName: "AÇÕES",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            ];
          }
      
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          ];
        }
      }
    ];

    return (
      <div id={ tableId } style={{ marginTop: 10 }} >
        <div className="form-field-label">
          {props.field}
          <Tooltip title='Tecle Ctrl + Enter para salvar e adicionar uma nova linha ou apenas Enter para salvar.'>
            <InfoIcon/>
          </Tooltip>  
        </div>
        <Box
          sx={{
            height: 500,
            width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        > 
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { 
                setRows, 
                setRowModesModel,
                tableId
              },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </div>
    );
}