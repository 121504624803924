/* eslint-disable default-case */
import React, { useState, useEffect, useMemo } from 'react';
import firebase, { workfluxFirestore } from '@firebase-config';
import i18n, { format, toDate } from '@i18n';
import { ExecutionFlow } from '@taugor/taugor-bpmn-models';
import Api from '../../../Api';
import Icon from '@custom/Icon';
import { sortByUrgency, filterByViewPermission } from '@custom/common';
import { store, services } from '@redux';
import View from '../view';
import Unauthorized from '../../Unauthorized';
import { Tabs } from 'zerocode';
import Input from '@custom/Input';
import { useSelector, connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { formatDate } from '../../../utils/dates';
import getChunks from '../../../utils/getChunks';

const oExecutionFlowService = new ExecutionFlow().getService(firebase, store, 'executionFlowListStore');
const executionFlowCollection = workfluxFirestore.collection('executionFlow');
const initialDate = '2023-02-01';
const finalDate = '2023-02-10';

const ExecutionFlowList = ({ companyAreaStore, auth, executionFlowListStore, executionFlowStore }) => {
	let currentUid;
	if (executionFlowStore && executionFlowStore.current) {
		currentUid = executionFlowStore.current.uid;
	}

	const [state, setState] = useState({});
	const [button, setButton] = useState(true);
	const [currentTab, setCurrentTab] = useState('created');
	const [executionFlowSelectedUid, setExecutionFlowSelectedUid] = useState(currentUid);
	const [search, setSearch] = useState('');
	const [searchTime, setSearchTime] = useState('today');
	const [searchPeriod, setSearchPeriod] = useState({
		initialDate,
		finalDate,
	});
	const [maxDate, setMaxDate] = useState(formatDate(new Date()));
	const [minDate, setMinDate] = useState(formatDate({ date: new Date(initialDate) }));
	const [queries, setQueries] = useState([]);
	const [filters, setFilters] = useState({
		inExecution: true,
		admin: false,
		finished: false,
		canceled: false,
		archived: false,
		myTasks: false,
		byOldest: true,
	});
	const [executionFlowsMyTasks, setExecutionFlowsMyTask] = useState([]);
	const taskStore = useSelector((s) => s.task);

	const isExecutionsAdmin = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.executions.admin') > -1;
	const isExecutionsViewer = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.executions.viewer') > -1;

	const selectExecutionFlowForActions = (executionFlow) => {
		executionFlow.selected = !executionFlow.selected;

		setState({});
	};

	let totalFlows = 0;

	const setFilteredList = () => {
		const list = executionFlowListStore.list
			.filter((item) => {
				if (!search) {
					return item;
				}

				const searchParams = search.split(';');
				const params = searchParams.map((param) => param.trim());

				const param = item.workflowObject.phases.find((phases) => (phases.uid === item.currentPhase ? phases : false));
				const currentPhase = param ? param.name : '';

				const values = [
					format.datetime(item.createdAt.toDate()),
					!item.completed.toDate ? '' : format.datetime(item.completed.toDate()),
					item.workflowObject.document.title,
					item.workflowObject.document.description,
					item.uid,
					item.managementProtocolCode,
					item.workflowObject.divisionObject.name,
					item.workflowObject.description || '',
					item.workflowObject.divisionObject.description,
					currentPhase,
					item.agentObject.firstName + ' ' + item.agentObject.lastName,
					item.agentObject.email,
					item.agentObject.uid,
				];

				item.workflowObject.phases.forEach((phase) => {
					phase.actions.forEach((action) => {
						if (action?.executedForm?.fields) {
							Object.values(action.executedForm.fields).forEach((field) => {
								values.push(field);
							});
						}
					});
				});

				const valuesJson = JSON.stringify(values).toLocaleLowerCase();

				const found = params.find((param) => {
					return valuesJson.indexOf(param.toLowerCase()) !== -1;
				});

				return found ? item : false;
			})
			.sort((a, b) => {
				if (typeof a.completed !== 'string' && typeof b.completed !== 'string') {
					if (!a.completed.toDate) return 0;
					if (a.completed.toDate() > b.completed.toDate()) return 1;
					if (a.completed.toDate() < b.completed.toDate()) return -1;
				}
				if (typeof a.completed !== 'string' && b.completed === 'canceled') {
					return -1;
				}
				if (typeof a.completed !== 'string' && b.completed === 'not-completed') {
					return 1;
				}
				if (a.completed === 'not-completed' && typeof b.completed !== 'string') {
					return -1;
				}
				if (a.completed === 'canceled' && typeof b.completed !== 'string') {
					return 1;
				}
				if (a.completed === 'not-completed' && b.completed === 'not-completed') {
					if (a.createdAt.toDate() > b.createdAt.toDate()) return 1;
					if (a.createdAt.toDate() < b.createdAt.toDate()) return -1;
				}
				return 0;
			});
		const areaList = {};
		const companyAreas = {};

		companyAreaStore.list.forEach((company) => {
			companyAreas[company.uid] = company;
		});

		const newList = list.reduce((previousValue, currentValue) => {
			const itemDuplicated = previousValue.find((item) => item.uid === currentValue.uid);
			if (!itemDuplicated) {
				return previousValue.concat([currentValue]);
			} else {
				return previousValue;
			}
		}, []);

		newList.forEach((item) => {
			const companyArea = companyAreas[item.workflowObject.division] || item.workflowObject.divisionObject;

			if (auth.user.companyadmin && companyArea && companyArea.groups && companyArea.groups.length) {
				const userGroups = auth.user.groups.map((group) => group.uid);
				const found = !!companyArea.groups.find((group) => userGroups.includes(group.uid));
				if (!found) return null;
			}

			if (!areaList[item.workflowObject.division]) {
				const division = companyAreas[item.workflowObject.division];

				areaList[item.workflowObject.division] = {
					division: division ? division : item.workflowObject.divisionObject,
					list: [],
				};
			}
			areaList[item.workflowObject.division].list.push(item);
		});

		return Object.values(areaList);
	};

	const list = useMemo(() => {
		return setFilteredList();
	}, [executionFlowListStore.list, search, companyAreaStore.list, currentTab]);

	const getFirstDay = () => {
		const today = new Date();
		let date;
		switch (searchTime) {
			case 'today':
				date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
				break;
			case 'week':
				date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
				date.setDate(date.getDate() - date.getDay());
				break;
			case 'month':
				date = new Date(today.getFullYear(), today.getMonth(), 1);
				break;
			case 'lastMonth':
				date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
				date.setDate(date.getDate() - 30);
				break;
			case 'lastThreeMonths':
				date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
				date.setDate(date.getDate() - 90);
				break;
			default:
				date = 1;
		}
		return date;
	};

	const getFilterQueries = () => {
		const filterQueries = [];

		switch (currentTab) {
			case 'created':
				filterQueries.push([
					['company', '==', auth.company.uid],
					['createdBy', '==', auth.user.uid],
					['deleted', '==', false],
				]);
				break;
			case 'responsible':
				filterQueries.push([
					['company', '==', auth.company.uid],
					['agent', '==', auth.user.uid],
					['deleted', '==', false],
				]);
				break;
			case 'participate':
				const baseQuery = [
					['company', '==', auth.company.uid],
					['deleted', '==', false],
				];

				const newQueries = [[...baseQuery, ['participants', 'array-contains', auth.user.uid]]];

				const groupChunks = getChunks(auth.user.groups, 10);
				for (const groupChunk of groupChunks) {
					const newQuery = [
						...baseQuery,
						[
							'workflowObject.viewGroups',
							'array-contains-any',
							groupChunk.map((group) => ({
								uid: group.uid,
								name: group.name,
							})),
						],
					];

					newQueries.push(newQuery);
				}

				filterQueries.push(...newQueries);
				break;
			case 'admin':
				filterQueries.push([
					['company', '==', auth.company.uid],
					['deleted', '==', false],
				]);
				break;
			default:
				break;
		}

		for (let i = 0; i < filterQueries.length; i++) {
			const query = filterQueries[i];

			if (filters.canceled) {
				query.push(['completed', '==', 'canceled']);
			}
			if (filters.inExecution) {
				query.push(['completed', '==', 'not-completed']);
			}
			if (filters.finished) {
				query.push(['currentPhase', '==', '']);
			}
			if (filters.archived) {
				query.push(['archived', '==', true]);
			}

			switch (searchTime) {
				case 'custom': {
					const initialDate = new Date(searchPeriod.initialDate);
					const finalDate = new Date(searchPeriod.finalDate);

					query.push(['createdAt', '<=', finalDate]);
					query.push(['createdAt', '>=', initialDate]);
					break;
				}
				default: {
					const firstDay = getFirstDay();
					query.push(['createdAt', '>=', firstDay]);
					break;
				}
			}
		}

		return filterQueries;
	};

	const addFiltersToQueriesAndGetResults = async (allQueryFilters) => {
		const promises = [];
		allQueryFilters.map((filterQueries) => {
			let query = executionFlowCollection.limit(1000);

			filterQueries.forEach((filter) => {
				query = query.where(filter[0], filter[1], filter[2]);
			});

			promises.push(query.get().then(({ docs }) => docs));
		});

		const results = await Promise.all(promises);
		return results;
	};

	const getResultsData = (results) => {
		const list = [];
		results.forEach((docs) => {
			docs.map((doc) => list.push(doc.data()));
		});
		return list;
	};

	const getResultsFilteredData = (list) => {
		const filteredList = list
			.filter((listItem) => {
				const newExecutionFlowsMyTasks = executionFlowsMyTasks.filter((flowUid, index) => {
					return executionFlowsMyTasks.indexOf(flowUid) === index;
				});
				if (filters.myTasks) {
					return newExecutionFlowsMyTasks.includes(listItem.uid);
				}

				return listItem;
			})
			.filter(filterByViewPermission(auth.user));
		return filteredList;
	};

	const loadItems = async () => {
		if (!auth.company || !auth.company.uid) {
			return null;
		}
		const allQueryFilters = getFilterQueries();
		services.spinner.show();
		const results = await addFiltersToQueriesAndGetResults(allQueryFilters);
		const list = getResultsData(results);
		const filteredList = getResultsFilteredData(list);
		executionFlowListStore.list = filteredList;
		setQueries(results);
		services.spinner.hide();
	};

	useEffect(() => {
		const today = new Date();
		const initial = new Date(today.getFullYear(), today.getMonth(), today.getDate());
		initial.setDate(initial.getDate() - 30);
		const final = new Date(today.getFullYear(), today.getMonth(), today.getDate());
		setSearchPeriod({
			initialDate: initial,
			finalDate: final,
		});
	}, [searchTime]);

	useEffect(() => {
		(async () => {
			await loadItems();
		})();
	}, [
		auth.company,
		searchTime,
		filters.inExecution,
		filters.finished,
		filters.canceled,
		filters.archived,
		filters.myTasks,
		searchPeriod.finalDate,
		searchPeriod.initialDate,
		currentTab,
	]);

	useEffect(() => {
		const initialDate = new Date(searchPeriod.initialDate);
		const finalDate = new Date(searchPeriod.finalDate);
		const timeDiff = Math.abs(initialDate.getTime() - finalDate.getTime());
		const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
		const newFinalDate = new Date(initialDate.setDate(initialDate.getDate() + 60));
		const dateFormated = formatDate({ date: newFinalDate, orderDirection: 'right' });

		const minInitialDate = new Date(searchPeriod.initialDate);
		const newMinDate = new Date(minInitialDate.setDate(minInitialDate.getDate() + 1));

		setMinDate(formatDate({ date: newMinDate, orderDirection: 'right' }));

		if (newFinalDate < new Date()) {
			setMaxDate(dateFormated);
		} else {
			setMaxDate(formatDate({ date: new Date(), orderDirection: 'right' }));
		}

		if (diffDays >= 90) {
			setSearchPeriod((prevState) => ({
				...prevState,
				finalDate: dateFormated,
			}));
		}
	}, [searchPeriod.initialDate]);

	if (!isExecutionsAdmin && !isExecutionsViewer) {
		return <Unauthorized />;
	}

	const toggleAreaList = (area) => (e) => {
		area.opened = !area.opened;
		setState({});
	};

	let totalItemsSelected = 0;
	list.map((area) => {
		area.list.map((executionFlow) => {
			totalItemsSelected += executionFlow.selected ? 1 : 0;
		});
	});

	const unselectAll = () => {
		list.map((area) => {
			area.list.map((executionFlow) => {
				executionFlow.selected = false;
			});
		});

		setState({});
	};

	const deleteSelected = () => {
		services.message.show(
			i18n('remove.executionFlows'),
			<div>
				<div>{i18n('confirm.executionFlows.deletion')}</div>
				<div className="buttons">
					<simple-button
						save
						onClick={async (e) => {
							services.message.hide();
							services.spinner.show();
							try {
								return await Promise.all(
									list.map((area) => {
										return Promise.all(
											area.list.map((executionFlow) => {
												if (!executionFlow.selected) return Promise.resolve();
												return Api.executionFlow.remove({ id: executionFlow.uid });
											})
										);
									})
								);
							} finally {
								services.message.hide();
								services.message.show(i18n('executionFlows.canceled'));
								services.spinner.hide();
							}
						}}
					>
						{i18n('confirm')}
					</simple-button>
					<simple-button clear onClick={services.message.hide}>
						{i18n('cancel')}
					</simple-button>
				</div>
			</div>
		);
	};
	const cancelSelected = () => {
		services.message.show(
			i18n('cancel.executionFlows'),
			<div>
				<div>{i18n('confirm.action')}</div>
				<div className="buttons">
					<simple-button
						save
						onClick={() => {
							services.message.hide();
							services.spinner.show();
							return Promise.all(
								list.map((area) => {
									return Promise.all(
										area.list.map((executionFlow) => {
											if (!executionFlow.selected) return Promise.resolve();
											return Api.executionFlow.cancel({ id: executionFlow.uid });
										})
									);
								})
							).finally((r) => {
								services.message.hide();
								services.message.show(i18n('executionFlows.canceled'));
								services.spinner.hide();
							});
						}}
					>
						{i18n('confirm')}
					</simple-button>
					<simple-button clear onClick={services.message.hide}>
						{i18n('cancel')}
					</simple-button>
				</div>
			</div>
		);
	};
	const archiveSelected = () => {
		services.message.show(
			i18n('archive.executionFlows'),
			<div>
				<div>{i18n('confirm.action')}</div>
				<div className="buttons">
					<simple-button
						save
						onClick={(e) => {
							services.message.hide();
							services.spinner.show();
							return Promise.all(
								list.map((area) => {
									return Promise.all(
										area.list.map((executionFlow) => {
											if (!executionFlow.selected) return Promise.resolve();
											return Api.executionFlow.archive({ id: executionFlow.uid });
										})
									);
								})
							).finally((r) => {
								services.message.hide();
								services.message.show(i18n('executionFlows.archived'));
								services.spinner.hide();
								setFilters({
									inExecution: false,
									admin: false,
									finished: false,
									canceled: false,
									archived: false,
									lastThreeDays: false,
									lastWeek: false,
									lastMonth: false,
									myTasks: false,
									byOldest: true,
								});
							});
						}}
					>
						{i18n('confirm')}
					</simple-button>
					<simple-button clear onClick={services.message.hide}>
						{i18n('cancel')}
					</simple-button>
				</div>
			</div>
		);
	};

	const unarchiveSelected = () => {
		services.message.show(
			i18n('unarchive.executionFlows'),
			<div>
				<div>{i18n('confirm.action')}</div>
				<div className="buttons">
					<simple-button
						save
						onClick={(e) => {
							services.message.hide();
							services.spinner.show();
							return Promise.all(
								list.map((area) => {
									return Promise.all(
										area.list.map((executionFlow) => {
											if (!executionFlow.selected) return Promise.resolve();
											return Api.executionFlow.unarchive({ id: executionFlow.uid });
										})
									);
								})
							).finally((r) => {
								services.message.hide();
								services.message.show(i18n('executionFlows.unarchived'));
								services.spinner.hide();
								setFilters({
									inExecution: false,
									admin: false,
									finished: false,
									canceled: false,
									archived: false,
									lastThreeDays: false,
									lastWeek: false,
									lastMonth: false,
									myTasks: false,
									byOldest: true,
								});
							});
						}}
					>
						{i18n('confirm')}
					</simple-button>
					<simple-button clear onClick={services.message.hide}>
						{i18n('cancel')}
					</simple-button>
				</div>
			</div>
		);
	};

	const handleInitialDate = (event) => {
		setSearchPeriod((prevState) => ({
			...prevState,
			initialDate: event.target.value,
		}));
	};
	const handleFinalDate = (event) => {
		setSearchPeriod((prevState) => ({
			...prevState,
			finalDate: event.target.value,
		}));
	};

	const handleSearchTime = (event) => {
		setSearchTime(event.target.value);
		loadItems();
	};

	list.map((a) => {
		totalFlows += a.list.length;
	});
	return (
		<content id="executions-list">
			<div className="entity-management">
				<div id="list-panel" className="list-view">
					<div className="info-field">
						<h3>
							<Icon icon="faProjectDiagram" />
							{i18n('executionFlow.list.title')}
						</h3>
						<div className="content-title">
							<div>{i18n('executionFlow.list.subtitle')}</div>
						</div>
					</div>
					<div className="info-field">
						<div style={{ fontSize: 12, color: '#666', marginBottom: 10 }}>
							{i18n('executionFlow.list.searchOptions')}
						</div>
						<div className="info-field-search">
							<Button variant="contained" color="primary" onClick={setFilteredList}>
								{i18n('search')}
							</Button>
							<Input
								label="executionFlow.list.search"
								mode="outlined search-input"
								onChange={(e) => {
									setSearch(e.target.value);
								}}
							/>
							<div className="info-field-search-dates">
								<select onChange={handleSearchTime} value={searchTime}>
									<option value="today">{i18n('today')}</option>
									<option value="week">{i18n('thisweek')}</option>
									<option value="month">{i18n('thismonth')}</option>
									<option value="lastMonth">{i18n('lastThirtyDays')}</option>
									<option value="lastThreeMonths">{i18n('lastNinetyDays')}</option>
									<option value="custom">{i18n('custom')}</option>
								</select>
								{searchTime === 'custom' && (
									<>
										<div className="info-field-time">
											<Input
												mode="top-label"
												label={i18n('earlistDate')}
												type="date"
												max={maxDate}
												value={searchPeriod.initialDate}
												onChange={handleInitialDate}
											/>
											<Input
												mode="top-label"
												label={i18n('mostRecentDate')}
												type="date"
												min={minDate}
												max={maxDate}
												value={searchPeriod.finalDate}
												onChange={handleFinalDate}
											/>
										</div>
										<label>{i18n('maxIntervalNinetyDays')}</label>
									</>
								)}
							</div>
						</div>
					</div>

					<div className="info-field">
						<h4>{i18n('tips')}</h4>
						<div className="tips">
							<div className="tip">
								{i18n('workflow.flow.tipsTextPrimaryStep')}{' '}
								<span>{i18n('workflow.flow.tipsTextPrimaryStepSpan')}</span>
							</div>
							<div className="tip">
								{i18n('workflow.flow.tipsTextSecondaryStep')}{' '}
								<span>{i18n('workflow.flow.tipsTextSecondaryStepSpan')}</span>{' '}
								{i18n('workflow.flow.tipsTextSecondaryStepEnd')}
							</div>
						</div>
					</div>

					<Tabs
						defaultTab="created"
						tabs={[
							{ id: 'created', name: i18n('executionflow.list.tabs.created'), icon: 'faProjectDiagram' },
							{ id: 'responsible', name: i18n('executionflow.list.tabs.responsible'), icon: 'faCodeBranch' },
							{ id: 'participate', name: i18n('executionflow.list.tabs.participate'), icon: 'faBell' },
							{
								id: 'admin',
								name: i18n('executionflow.list.tabs.admin'),
								icon: 'faUserShield',
								hidden: !auth.user.companyadmin,
							},
						]}
						onTabChange={setCurrentTab}
					/>
					<Tabs.Content>
						{currentTab === 'admin' && (
							<div className="info-field">
								<div className="tips">
									<div className="tip">{i18n('workflow.flow.tipsTextVisionAdmin')}</div>
									<div className="tip">
										<div className="tip">
											{i18n('workflow.flow.tipsTextSecondaryStep')}{' '}
											<span>{i18n('workflow.flow.tipsTextSecondaryStepSpan')}</span>{' '}
											{i18n('workflow.flow.tipsTextSecondaryStepEnd')}
										</div>
									</div>
								</div>
							</div>
						)}
						<h3>{i18n('executionflow.list.tabs.title.' + currentTab)}</h3>
						<div className="selected-items-header">
							<div>
								{/* {!!totalItemsSelected && isExecutionsAdmin && (
									<div className="selection-actions">
										<div className="action" onClick={deleteSelected}>
											{i18n('delete')}
											<div className="button">
												<Icon icon="faTrash" />
											</div>
										</div>
										<div className="action" onClick={cancelSelected}>
											{i18n('cancel')}
											<div className="button">
												<Icon icon="faBan" />
											</div>
										</div>

										{button && (
											<div className="archived">
												<div className="action" onClick={archiveSelected}>
													{i18n('archive')}
													<div className="button">
														<Icon icon="faFolderPlus" />
													</div>
												</div>
												<div className="action" onClick={unarchiveSelected}>
													{i18n('unarchive')}
													<div className="button">
														<Icon icon="faFolderMinus" />
													</div>
												</div>
											</div>
										)}
									</div>
								)} */}
								<div className="filter-actions">
									<div
										className={`filter ${filters.inExecution ? 'marked' : ''}`}
										onClick={(e) => {
											filters.inExecution = !filters.inExecution;
											if (filters.inExecution) {
												filters.finished = false;
												filters.archived = false;
												filters.canceled = false;
												filters.myTasks = false;
											}
											setFilters({ ...filters });
											setButton(true);
										}}
									>
										{i18n('executionFlow.filters.inExecution')}{' '}
										<div className="button">{filters.inExecution && <Icon icon={'faCircle'} />}</div>
									</div>
									<div
										className={`filter ${filters.finished ? 'marked' : ''}`}
										onClick={(e) => {
											filters.finished = !filters.finished;
											if (filters.finished) {
												filters.inExecution = false;
												filters.canceled = false;
												filters.archived = false;
												filters.myTasks = false;
											}
											setFilters({ ...filters });
											setButton(false);
										}}
									>
										{i18n('executionFlow.filters.finished')}{' '}
										<div className="button">{filters.finished && <Icon icon={'faCircle'} />}</div>
									</div>
									<div
										className={`filter ${filters.canceled ? 'marked' : ''}`}
										onClick={(e) => {
											filters.canceled = !filters.canceled;
											if (filters.canceled) {
												filters.inExecution = false;
												filters.finished = false;
												filters.archived = false;
												filters.myTasks = false;
											}
											setFilters({ ...filters });
										}}
									>
										{i18n('executionFlow.filters.canceled')}{' '}
										<div className="button">{filters.canceled && <Icon icon={'faCircle'} />}</div>
									</div>
									<div
										className={`filter ${filters.archived ? 'marked' : ''}`}
										onClick={(e) => {
											filters.archived = !filters.archived;
											if (filters.archived) {
												filters.inExecution = false;
												filters.finished = false;
												filters.canceled = false;
												filters.myTasks = false;
											}
											setFilters({ ...filters });
											setButton(true);
										}}
									>
										{i18n('executionFlow.filters.archived')}{' '}
										<div className="button">{filters.archived && <Icon icon={'faCircle'} />}</div>
									</div>

									{currentTab === 'participate' && (
										<div
											className={`filter ${filters.myTasks ? 'marked' : ''}`}
											onClick={(e) => {
												setExecutionFlowsMyTask(taskStore.list.map((t) => t.executionFlow));
												filters.myTasks = !filters.myTasks;
												if (filters.myTasks) {
													filters.inExecution = false;
													filters.finished = false;
													filters.canceled = false;
													filters.archived = false;
												}
												setFilters({ ...filters });
												setButton(false);
											}}
										>
											{i18n('executionFlow.filters.myTasks')}{' '}
											<div className="button">{filters.myTasks && <Icon icon={'faCircle'} />}</div>
										</div>
									)}
									<div className="spacer" />
								</div>
								<div className="columns textual-info">
									<div className="column">
										<div className="totalizers">
											<div className="totalizer">
												{i18n('workflow.flow.tipsTextPrimaryInfoFlow')} {totalFlows}{' '}
												{i18n('workflow.flow.tipsTextPrimaryInfoFlows')}. {totalItemsSelected}{' '}
												{totalItemsSelected == 1
													? i18n('workflow.flow.tipsTextSecondaryInfoFlowsSelected')
													: i18n('workflow.flow.tipsTextSecondaryInfoFlowSelected')}
											</div>
										</div>
									</div>
								</div>
							</div>
							{!!totalItemsSelected && isExecutionsAdmin && (
								<div className="button unselect-icon" onClick={unselectAll}>
									<Icon icon="faCheck" />
								</div>
							)}
						</div>

						<div className="executionflow-list">
							{list.map((area, a) => (
								<div key={a}>
									<div
										className={`area-title ${area.opened ? 'opened' : ''}`}
										id={`area-title-${a}`}
										onClick={toggleAreaList(area)}
									>
										<div id={`area-title-chevron-${a}`}>
											<Icon icon="faChevronRight" />
										</div>
										<span className="area-name">
											{area.division.name} ({area.list.length})
										</span>
									</div>
									{area.opened && (
										<div className="area-headers">
											<div className="area-flow">{i18n('flow')}</div>
											<div className="area-phase">{i18n('phase')}</div>
											<div className="area-responsible">{i18n('task.form.responsible')}</div>
											<div className="area-responsible">{i18n('executions')}</div>
										</div>
									)}
									{area.opened && (
										<div
											className={`area-executionflows ${area.opened ? 'opened' : ''}`}
											id={`area-list-${a}`}
										>
											{area.list
												.sort((a, b) => {
													if (filters.byOldest) {
														return toDate(a.createdAt) - toDate(b.createdAt);
													}

													return toDate(b.createdAt) - toDate(a.createdAt);
												})
												.sort(sortByUrgency)
												.map((executionFlow, i) => {
													let currentPhase = executionFlow.workflowObject.phases.find((phase) => {
														if (phase.uid === executionFlow.currentPhase) {
															return phase;
														}
														return false;
													});
													if (!currentPhase) {
														currentPhase = { name: '' };
													}
													//('executionFlow', executionFlow);
													return (
														<div
															className={`simple-list-item with-columns clickable 
																${executionFlow.uid === executionFlowSelectedUid ? 'selected' : ''}	
																${typeof executionFlow.completed !== 'string' ? 'completed' : ''}
																${executionFlow.completed === 'canceled' ? 'canceled' : ''}
																${executionFlow.completed === 'archived' ? 'archived' : ''}
																${executionFlow.selected ? 'item-selected' : ''}
																${executionFlow.workflowObject.urgent ? 'urgent' : ''}
															`}
															key={i}
															onClick={(e) => {
																if (isExecutionsAdmin && (e.ctrlKey || e.metaKey)) {
																	selectExecutionFlowForActions(executionFlow);
																	return;
																}
																setExecutionFlowSelectedUid(
																	executionFlow.uid === executionFlowSelectedUid
																		? ''
																		: executionFlow.uid
																);
															}}
														>
															{typeof executionFlow.completed !== 'string' ? (
																<div className="left-icon icon green">
																	<Icon icon="faCheckCircle" />
																</div>
															) : executionFlow.completed === 'archived' ? (
																<div className="left-icon icon red">
																	<Icon icon="faArchive" />
																</div>
															) : executionFlow.completed === 'canceled' ? (
																<div className="left-icon icon red">
																	<Icon icon="faTimesCircle" />
																</div>
															) : executionFlow.workflowObject.urgent ? (
																<div className="left-icon icon red">
																	<Icon icon="faExclamationTriangle" />
																</div>
															) : (
																executionFlow.completed === 'not-completed' && (
																	<div className="left-icon icon red">
																		<Icon icon="faClock" />
																	</div>
																)
															)}

															<div className="executionflow-texts" style={{ flex: 1 }}>
																<div className="line bold small gray creation-date">
																	{format.datetime(executionFlow.createdAt.toDate())}
																</div>
																<div className="line bold title workflow-description">
																	{executionFlow.workflowObject.description}
																</div>
																<div className="line  title document-title">
																	{executionFlow.workflowObject.document.title}
																</div>

																{typeof executionFlow.completed !== 'string' && (
																	<div className="line finish-date">
																		{i18n('executionFlow.list.finishedAt')}
																		{': '}
																		{format.datetime(executionFlow.completed.toDate())}
																	</div>
																)}
															</div>
															<div className="area-phase">{currentPhase.name}</div>
															<div className="area-responsible">
																{executionFlow.agentObject.firstName}{' '}
																{executionFlow.agentObject.lastName}
															</div>
															<div className="executionflow-texts execution-tasks" style={{}}>
																<div className="line bold small gray creation-date">
																	{i18n('execution')}
																</div>
																<div className="line">
																	<div>
																		{i18n('executionFlow.form.documents')}:{' '}
																		{executionFlow.documents.length}
																	</div>
																	<div>
																		{i18n('executionFlow.form.forms')}:{' '}
																		{executionFlow.forms.length}
																	</div>
																	<div>
																		{i18n('executionFlow.form.approvals')}:{' '}
																		{executionFlow.approvals.length}
																	</div>
																</div>
															</div>

															<div
																className="right-icon icon"
																onClick={(e) => {
																	if (executionFlow.selected) {
																		e.preventDefault();
																		e.stopPropagation();
																		selectExecutionFlowForActions(executionFlow);
																	}
																}}
															>
																<Icon
																	icon={
																		executionFlow.selected
																			? 'faCheck'
																			: executionFlowSelectedUid
																			? 'faChevronLeft'
																			: 'faChevronRight'
																	}
																/>
															</div>
														</div>
													);
												})}
										</div>
									)}
								</div>
							))}
						</div>
					</Tabs.Content>
				</div>

				<div id="form-panel" className={`${executionFlowSelectedUid ? 'visible' : ''}`}>
					<header className="form-panel-header">
						<div style={{ flex: 1 }}></div>
						<div
							className="header-button"
							onClick={() => {
								setExecutionFlowSelectedUid('');
							}}
						>
							<Icon icon="faTimes" />
						</div>
					</header>
					<View
						noTaskView={true}
						closeButton={() => setExecutionFlowSelectedUid('')}
						match={{
							params: {
								id: executionFlowSelectedUid,
							},
						}}
					/>
				</div>
			</div>
		</content>
	);
};

const fixDate = (date) => {
	if (!date.toDate && date.nanoseconds) {
		date.toDate = () => {
			return new Date(parseInt(date.seconds + '' + date.nanoseconds.toString().substr(0, 3)));
		};
	}
};

export default connect(({ auth, executionFlowListStore, executionFlowView, companyAreaStore }) => ({
	auth: auth,
	executionFlowListStore: executionFlowListStore,
	executionFlowStore: executionFlowView,
	companyAreaStore,
}))(ExecutionFlowList);
