import React, { useState, useEffect } from 'react';
import firebase from '@firebase-config';
import i18n, { format } from '@i18n';
import { ExecutionFlow } from '@taugor/taugor-bpmn-models';

import Icon from '@custom/Icon';

import { store } from '@redux';

const Relatory = ({ list, filters, executionFlowSelectedUid, setExecutionFlowSelectedUid, selectExecutionFlowForActions }) => {
	const toggleAreaList = (id) => (e) => {
		let el = document.getElementById(`area-title-${id}`);
		let list = document.getElementsByClassName(`area-list-${id}`);

		if (!el || !list) return;

		for (var i = 0; i < list.length; i++) {
			if (list[i].classList.contains('opened')) {
				el.classList.remove('opened');

				list[i].classList.remove('opened');
			} else {
				el.classList.add('opened');

				list[i].classList.add('opened');
			}
		}
	};

	let agents = {};
	list.map((el) => {
		if (!agents[el.agentObject.uid]) {
			agents[el.agentObject.uid] = { list: [], ...el.agentObject };
		}
		agents[el.agentObject.uid].list.push(el);
	});

	return (
		<div>
			{filters.inExecution && (
				<div>
					{Object.values(agents).map((agent, a) => {
						//('agent', agent);
						return (
							<div key={a} className="-----item-size" id="relatory">
								<div className="area-title opened" id={`area-title-${a}`} onClick={toggleAreaList(a)}>
									<div id={`area-title-chevron-${a}`}>
										<Icon icon="faChevronRight" />
									</div>
									<span className="area-name">
										{i18n("task.form.responsible")}: {agent.firstName} {agent.lastName}
									</span>
									<div className={`area-phase ${executionFlowSelectedUid ? 'widthLow' : ''}`}>{i18n("currentPhase")} </div>
									<div className={`area-responsible ${executionFlowSelectedUid ? 'widthLow' : ''}`}>
										{i18n("remainingActivities")}
									</div>
									<div className={`area-responsible ${executionFlowSelectedUid ? 'widthLow' : ''}`}>{i18n("workflow.form.addActionButton")}</div>
								</div>
								{agent.list.map((item, i) => {
									let currentPhase = item.workflowObject.phases.find((phase) => {
										if (phase.uid === item.currentPhase) {
											return phase;
										}
										return false;
									});

									if (!currentPhase) {
										currentPhase = { name: '', actions: [] };
									}

									let actions = [];
									let numberOfActions = 0;
									currentPhase.actions.map((action) => {
										if (action.status !== 'completed') {
											numberOfActions++;
										}
									});
									actions.push(numberOfActions);

									return (
										<div className={`area-executionflows opened area-list-${a}`}>
											<div
												className={`simple-list-item with-columns clickable 
														${item.uid === executionFlowSelectedUid ? 'selected' : ''}	
														${typeof item.completed !== 'string' ? 'completed' : ''}
														${item.completed === 'canceled' ? 'canceled' : ''}
														${item.completed === 'archived' ? 'archived' : ''}
														${item.selected ? 'item-selected' : ''}
													`}
												key={i}
												onClick={(e) => {
													setExecutionFlowSelectedUid(
														item.uid === executionFlowSelectedUid ? '' : item.uid
													);
												}}
											>
												{typeof item.completed !== 'string' && (
													<div className="left-icon icon green">
														<Icon icon="faCheckCircle" />
													</div>
												)}

												{item.completed === 'archived' && (
													<div className="left-icon icon red">
														<Icon icon="faArchive" />
													</div>
												)}

												{item.completed === 'canceled' && (
													<div className="left-icon icon red">
														<Icon icon="faTimesCircle" />
													</div>
												)}
												{item.completed === 'not-completed' && (
													<div className="left-icon icon red">
														<Icon icon="faClock" />
													</div>
												)}

												<div className="executionflow-texts" style={{ flex: 1 }}>
													<div className="line bold small gray creation-date">
														{format.datetime(item.createdAt.toDate())}
													</div>
													<div className="line bold title document-title">
														{i18n("title")}: {item.workflowObject.document.title}
													</div>
													<div className="line bold title workflow-description">
														{i18n("companyArea.form.item.description")}: {item.workflowObject.description}
													</div>

													{typeof item.completed !== 'string' && (
														<div className="line finish-date">
															{i18n('executionFlow.list.finishedAt')}
															{': '}
															{format.datetime(item.completed.toDate())}
														</div>
													)}
												</div>
												<div
													className={`area-phase ${item.uid === executionFlowSelectedUid ? 'widthLow' : ''
														}`}
												>
													{currentPhase.name}
												</div>
												<div
													className={`area-responsible ${item.uid === executionFlowSelectedUid ? 'widthLow' : ''
														}`}
												>
													{actions[0]}
												</div>
												<div
													className="right-icon icon"
													onClick={(e) => {
														if (item.selected) {
															e.preventDefault();
															e.stopPropagation();
															selectExecutionFlowForActions(item);
														}
													}}
												>
													<Icon icon={item.selected ? 'faCheck' : 'faChevronRight'} />
												</div>
											</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Relatory;
