import React from 'react';
import {
	Button
} from '@material-ui/core';
import { convertTimestampDateAndHours } from '../../../../utils/dates';
import { useSelector } from 'react-redux';
import { history } from "@redux";
import styles from './calendar.module.css';

export function RenderCardCalendar({ calendar, handleEdit }) {
	const auth = useSelector((s) => s.auth);

	const date = convertTimestampDateAndHours({ timestamp: calendar.createdAt });

	return (
		<section className={styles.card}>
			<section className={styles.areaText}>
				<section className={styles.header}>
					<section>
						<p>{date.date}</p>
						<p>ás {date.hours}</p>
					</section>
				</section>
				<h2>{calendar.title}</h2>
				<section>
					<p>Detalhes:</p>
					<p dangerouslySetInnerHTML={{ __html: calendar.text }} />
				</section>
			</section>
			<section className={styles.areaButtons}>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					onClick={() => history.push(`/calendar/${calendar.id}`)}
				>
					+ Detalhes
				</Button>
				{auth.user.companyadmin &&
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						onClick={() => handleEdit({ calendarSelected: calendar })}
					>
						Editar
					</Button>
				}
			</section>
		</section>
	);
}
