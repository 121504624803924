import React, { useState, useEffect } from 'react';
import firebase, { workfluxMainFirebase, workfluxFirestore } from '@firebase-config';
import i18n, { format } from '@i18n';
import { store, services } from '@redux';
import { useSelector, connect } from 'react-redux';
import {
	ExecutionFlow,
	Task,
	ExecutedDocument,
	Absense,
	Signing,
	WorkflowPhaseAction as WorkflowPhaseActionShape,
} from '@taugor/taugor-bpmn-models';
import { generateUid } from '@custom/common';
import Icon from '@custom/Icon';
import Input from '@custom/Input';
import Message from '@custom/Message';
import SocialShareMedia from '@custom/SocialShareMedia';
import { TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Company, Group, User } from '@taugor/taugor-appcenter-sdk-js';
import '@assets/sass/task.absent.scss';
import Api from '../../Api';

let oExecutionFlow = new ExecutionFlow();
const oTaskService = new Task().getService(firebase, store, 'taskDocumentInputView');
const oExecutionFlowService = oExecutionFlow.getService(firebase);
const oAbsenseService = new Absense().getService(firebase);
const executionFlowCollection = workfluxFirestore.collection(oExecutionFlow.getModelName());
let tokenUid, mediaShareLink;

const TaskShare = ({ auth, executionFlowView, task }) => {
	const [showShareWindow, setShowShareWindow] = useState(false);
	const [url, setURL] = useState('');

	if (!task || !task.uid) return <div></div>;

	const share = (media, getLink) => {
		let tokenUid = generateUid();
		let url = `http://workflux.digital/external/${task.action.type.type}/${tokenUid}`;

		let link = getLink({
			url: url,
			title: text,
		})[media];

		services.spinner.show();

		executionFlowCollection
			.doc(task.executionFlow)
			.update({
				history: workfluxMainFirebase.firestore.FieldValue.arrayUnion({
					type: 'external.share',
					date: new Date(),
					user: auth.user.uid,
					url: url,
					token: tokenUid,
					task: task.uid,
					agent: auth.user.uid,
					media: i18n(`social.brands.${media}`),
					agentName: auth.user.firstName + ' ' + auth.user.lastName,
				}),
			})
			.then((r) => {
				return oTaskService
					.patch(task.uid, {
						action: {
							...task.action,
							externalShareLink: url,
							externalShareToken: tokenUid,
						},
					})
					.then((r) => {
						window.open(link, 'blank');
						setShowShareWindow(false);
						services.message.show('Compartilihamento', 'Compartilhado com sucesso');
					});
			})
			.finally(services.spinner.hide);
	};

	let text = '';
	if (task.action.type.type === 'formInput') {
		text = task.action.formTitle;
	} else {
		text = task.action.instructions;
	}

	return (
		<div>
			<div className="complementary-task-panel task-b">
				<simple-button
					iconed
					class="task-action"
					tooltip={i18n('share.external')}
					onClick={(e) => setShowShareWindow(true)}
				>
					<Icon icon="faShareAlt" />
				</simple-button>
			</div>
			<Message
				visible={showShareWindow}
				hide={() => {
					setShowShareWindow(false);
				}}
				title={i18n('share.external')}
				description={
					<div>
						<SocialShareMedia
							onClick={share}
							title={text}
							url={url}
							onChange={share}
							emailaddress=""
							cemailaddress=""
							bccemailaddress=""
						/>
					</div>
				}
			/>
		</div>
	);
};

export default connect(({ auth, executionFlowView }) => ({ auth, executionFlowView }))(TaskShare);
