import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { uid } from 'zerocode';

import { format } from '@i18n';
import { toDate } from '../../utils/dates';
import formatBytes from '../../utils/formatBytes';
import { workfluxFirestore, workfluxStorage } from '@firebase-config';

import Icon from '@custom/Icon';

import './styles.scss';

const executionFlowCollection = workfluxFirestore.collection('executionFlow');

/**
 * @typedef TextContent
 * @type {{
 *     text: string;
 * }}
 */

/**
 * @typedef FileContent
 * @type {{
 *     name: string;
 *     type: string;
 *     extension: string;
 *     url: string;
 *     size: number;
 * }}
 */

/**
 * @typedef Content
 * @type {TextContent | FileContent}
 */

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'psd', 'webp', 'raw', 'tiff', 'bmp'];

const extensionToIconName = (extension) => {
	switch (extension) {
		case 'pdf':
			return 'faFilePdf';
		case 'csv':
			return 'faFileCsv';
		case 'pptx':
		case 'pptm':
		case 'ppt':
		case 'potx':
		case 'potm':
		case 'pot':
		case 'thmx':
		case 'ppsx':
		case 'ppsm':
		case 'pps':
		case 'ppam':
		case 'ppa':
			return 'faFilePowerpoint';
		case 'xlsx':
		case 'xlsm':
		case 'xlsb':
		case 'xltx':
		case 'xltm':
		case 'xls':
		case 'xlt':
		case 'xml':
		case 'xlam':
		case 'xla':
		case 'xps':
			return 'faFileExcel';
		default:
			return 'faFile';
	}
};

function PhaseComments({ executionFlow, phase }) {
	const { auth } = useSelector((state) => state);
	const [messageText, setMessageText] = useState('');

	if (!phase) return <></>;
	if (!phase.comments) phase.comments = [];

	/**
	 * @param {"text" | "file"} type
	 * @param {Content} content
	 */
	const generateNewComment = (type, content) => {
		const fullName = `${auth.user.firstName} ${auth.user.lastName}`.replace(/\s/g, ' ').trim();
		return {
			/** @type {string} */
			uid: uid(),
			type,
			content,
			user: {
				/** @type {string} */
				uid: auth.user.uid,
				name: fullName,
				/** @type {string} */
				email: auth.user.email,
			},
			date: new Date(),
		};
	};

	const sendComment = async (newComment) => {
		const phases = executionFlow.workflowObject.phases
			.map((p) => ({
				...p,
				comments: [...(p.comments || [])].map((comment) => ({
					...comment,
					date: toDate(comment.date),
				})),
			}))
			.map((p) => {
				if (p.uid !== phase.uid) return p;
				return {
					...p,
					comments: [...phase.comments, newComment],
				};
			});

		await executionFlowCollection.doc(executionFlow.uid).update({ 'workflowObject.phases': phases });
	};

	const handleSendTextComment = async () => {
		const newComment = generateNewComment('text', { text: messageText });
		setMessageText('');
		await sendComment(newComment);
	};

	const handleSendFileComment = () => {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');

		input.addEventListener('change', async () => {
			const files = input.files;
			if (!files) return;

			const file = files[0];
			if (!file) return;

			const ref = workfluxStorage.ref(`comments/${file.name}`);
			await ref.put(file);
			/** @type {string} */
			const url = await ref.getDownloadURL();

			/** @type {string} */
			const extension = (() => {
				const parts = file.name.split('.');
				return parts[parts.length - 1];
			})();
			const newComment = generateNewComment('file', {
				name: file.name,
				type: file.type,
				url,
				extension,
				size: file.size,
			});

			await sendComment(newComment);

			input.value = '';
		});

		input.click();
	};

	/** @param {React.FormEvent<HTMLTextAreaElement>} evt */
	const textareaAutoGrow = (evt) => {
		const textarea = evt.target;
		if (!textarea) return;

		const heightLimit = 90;
		textarea.style.height = '';
		textarea.style.height = Math.min(textarea.scrollHeight, heightLimit) + 'px';
	};

	const handleViewFile =
		({ url }) =>
		() => {
			const a = document.createElement('a');
			a.href = url;
			a.target = '_blank';
			a.click();
		};

	const handleDownloadFile =
		({ name, url }) =>
		() => {
			const a = document.createElement('a');
			a.href = url;
			a.download = name;
			a.click();
		};

	const comments = (phase.comments || []).map((comment) => ({
		...comment,
		date: toDate(comment.date),
	}));

	return (
		<div className="PhaseComments">
			{comments.length === 0 ? (
				<p className="PhaseComments-noComments">Ninguém comentou sobre "{phase.name}". Seja o primeiro a comentar.</p>
			) : (
				<ul className="PhaseComments-list">
					{comments
						.sort((a, b) => {
							const aTime = a.date.getTime();
							const bTime = b.date.getTime();
							if (aTime < bTime) return 1;
							if (aTime > bTime) return -1;
							return 0;
						})
						.map((comment) => {
							const isText = comment.type === 'text' && typeof comment.content.text === 'string';
							const isImage = comment.type === 'file' && imageExtensions.includes(comment.content.extension);
							const isDoc = comment.type === 'file' && !isText && !isImage;

							return (
								<li key={comment.uid} className="PhaseComments-comment">
									<div className="PhaseComments-comment-info">
										<Tooltip
											title={`${comment.user.name} (UID: ${comment.user.uid}; email: ${comment.user.email})`}
										>
											<span className="PhaseComments-comment-name">{comment.user.name}</span>
										</Tooltip>

										<span className="PhaseComments-comment-date">{format.datetime(comment.date)}</span>
									</div>
									{isText && <p className="PhaseComments-comment-text">{comment.content.text}</p>}
									{isImage && (
										<img
											className="PhaseComments-comment-image"
											src={comment.content.url}
											alt={comment.content.name}
										/>
									)}
									{isDoc && (
										<div className="PhaseComments-comment-file">
											<div className="PhaseComments-comment-file-icon">
												<Icon icon={extensionToIconName(comment.content.extension)} />
											</div>
											<div className="PhaseComments-comment-file-info">
												<p>{comment.content.name}</p>
												<p>{formatBytes(comment.content.size, 'bytes')}</p>
											</div>
											<IconButton onClick={handleViewFile(comment.content)}>
												<Icon icon="faEye" />
											</IconButton>
											<IconButton onClick={handleDownloadFile(comment.content)}>
												<Icon icon="faDownload" />
											</IconButton>
										</div>
									)}
								</li>
							);
						})}
				</ul>
			)}
			<div className="PhaseComments-newComment">
				<textarea
					rows={1}
					cols={50}
					onInput={textareaAutoGrow}
					onChange={(evt) => setMessageText(evt.target.value)}
					value={messageText}
					placeholder="Digite seu comentário..."
				/>
				<IconButton onClick={handleSendFileComment}>
					<Icon icon="faFile" />
				</IconButton>
				<IconButton onClick={handleSendTextComment} disabled={!messageText}>
					<Icon icon="faPaperPlane" />
				</IconButton>
			</div>
		</div>
	);
}

export default PhaseComments;
