import React, { useState } from 'react';
import {
	Tooltip,
	TextField,
	Checkbox,
	FormControlLabel,
	Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';

import Api from '../../Api';
import i18n from '@i18n';
import { services, history } from '@redux';
import { toDate } from '../../utils/dates';
import { store as zerocodeStore } from 'zerocode';

import TaskApproval from '../executionFlow/task.approval';
import TaskDocumentInput from '../executionFlow/task.documentInput';
import TaskFormInput from '../executionFlow/task.formInput';

import Input from '@custom/Input';

const startExecutionFlow = (workflow) => () => {

	services.spinner.show();
	Api.executionFlow
		.create({ workflow })
		.finally(services.spinner.hide)
		.then((r) => {
			services.message.show(
				i18n('executionFlow.initialized'),
				<>
					<p>Ir para o fluxo criado?</p>
					<div className="buttons">
						<Button
							onClick={(e) => {
								history.push(`/executionFlow/view/${r.data.executionFlow.uid}`);
								services.message.hide();
							}}
						>
							Confirmar
						</Button>
						<Button onClick={services.message.hide}>{i18n("cancel")}</Button>
					</div>
				</>
			);
		})
		.catch((e) => {
			if (!e.response || !e.response.data) {
				services.message.show('Não foi possível criar o fluxo devido a um erro');
				return;
			}
			console.log(e)
			services.message.show(
				'Não foi possível criar o fluxo devido a um erro',
				<div>
					<div>{i18n(`executionFlow.create.error.${e.response.data.id}`)}</div>
				</div>
			);
		});
};

/** @param {Date} date */
const dateToValue = (date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hour = String(date.getHours()).padStart(2, "0");
	const minute = String(date.getMinutes()).padStart(2, "0");
	return `${year}-${month}-${day}T${hour}:${minute}`;
};

const WorkflowStartup = ({ workflow }) => {
	const appcenterStore = zerocodeStore.getState().appcenter;
	const auth = useSelector((o) => o.auth);
	const groups = appcenterStore.groups || [];
	const firstPhase = workflow.phases.find((phase) => !phase.deleted);
	const today = new Date();
	today.setSeconds(0);
	today.setMilliseconds(0);
	const [archivationDate, setArchivationDate] = useState(dateToValue(today));

	const renderTaskForm = (task) => {
		switch (task.action.type.type) {
			case 'approval':
				return <TaskApproval task={task} key={task.action.uid} />;
			case 'documentInput':
				return <TaskDocumentInput task={task} key={task.action.uid} />;
			case 'formInput':
				return <TaskFormInput task={task} key={task.action.uid} />;
		}
	};

	const handleArchivationDateChange = (evt) => {
		setArchivationDate(evt.target.value);
	};

	const handleOnBlur = () => {
		console.log("blur");
		let date = toDate(archivationDate);
		date = Math.max(date.getTime(), today.getTime());
		console.log("date", date);
		setArchivationDate(dateToValue(date));
		workflow.archivationDate = date;
	};

	console.log("archivationDate", archivationDate);

	return (
		<div className={`workflow-startup ${firstPhase.isOpening ? 'with-opening' : ''}`}>
			<Input
				mode="outlined top-label"
				label="Descrição do Fluxo"
				onChange={(e) => {
					workflow.description = e.target.value;
				}}
				onEnterPress={(e) => {
					startExecutionFlow(workflow)();
					services.message.hide();
				}}
			/>

			<Tooltip title="Ao marcar como urgente, este fluxo aparecerá primeiro nas listagens de fluxos">
				<FormControlLabel
					label="Marcar como urgente"
					onChange={(e) => {
						workflow.urgent = e.target.checked;
					}}
					control={<Checkbox />}
				/>
			</Tooltip>

			<Tooltip title="Permitir que todos os participantes do fluxo e os que estão só visualizando possam realizar comentarios em cada fase">
				<FormControlLabel
					label="Habilitar comentários/conversação"
					onChange={(e) => {
						workflow.allowComments = e.target.checked;
					}}
					control={<Checkbox />}
				/>
			</Tooltip>

			<Tooltip title="Quando a última fase for finalizada, o fluxo não será completado automaticamente">
				<FormControlLabel
					label="Finalizar o fluxo manualmente"
					onChange={(e) => {
						workflow.completeManually = e.target.checked;
					}}
					control={<Checkbox />}
				/>
			</Tooltip>

			<TextField
				fullWidth
				variant="standard"
				type="datetime-local"
				label="Arquivar automaticamente após esta data"
				onChange={handleArchivationDateChange}
				onBlur={handleOnBlur}
				value={archivationDate}
				InputLabelProps={{ shrink: true }}
			/>

			<Autocomplete
				multiple
				fullWidth
				disablePortal
				variant="outlined"
				options={groups}
				defaultValue={workflow.viewGroups}
				getOptionLabel={(option) => option.name || ''}
				renderInput={(params) => <TextField {...params} label="Grupos que podem ver os fluxo executados" />}
				onChange={(e, value) => {
					workflow.viewGroups = [...value].map(group => ({
						uid: group.uid,
						name: group.name
					}));
				}}
			/>

			<div className="tasks">
				{firstPhase.isOpening &&
					firstPhase.actions.map((action) => {
						if (action.deleted) return <></>;

						const task = {
							uid: 'startup',
							workflowObject: workflow,
							status: 'pending',
							agent: auth.user.uid,
							action: {
								...action,
								agent: auth.user.uid,
								agentName: auth.user.firstName + ' ' + auth.user.lastName,
							},
						};

						return renderTaskForm(task);
					})}
			</div>
			<div className="buttons">
				<Button
					onClick={() => {
						startExecutionFlow(workflow)();
						services.message.hide();
					}}
				>
					Confirmar
				</Button>
				<Button onClick={services.message.hide}>
					{i18n("cancel")}
				</Button>
			</div>
		</div>
	);
};
export default WorkflowStartup;
