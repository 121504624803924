import React from 'react';
import {
  Box,
  LinearProgress,
  Typography, 
  withStyles
} from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: '#a5d6a7',
	},
	bar: {
		borderRadius: 5,
		backgroundColor: '#43a047',
	},
}))(LinearProgress);

export function LinearProgressWithLabel(props) {
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={1}>
				<BorderLinearProgress variant="determinate" {...props} />
			</Box>
			<Box minWidth={35}>
				<Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}