import React, { useState, useEffect } from 'react';
import firebase, { workfluxFirestore } from '@firebase-config';
import { mainFirebaseNewton as mainFirebase } from 'zerocode';
import { useSelector } from 'react-redux';
import i18n from '@i18n';
import {
	Workflow,
	WorkflowPhase as WorkflowPhaseShape,
	ProcessDocument as ProcessDocumentShape,
} from '@taugor/taugor-bpmn-models';
import Api from '../../Api';

import { store as zerocodeStore } from 'zerocode';

import Phase from './Phase';
import { showGroupSelectionWindow } from './functions';

import { generateUid } from '@custom/common';
import { services, history } from '@redux';
import Icon from '@custom/Icon';
import '@assets/sass/workflow.scss';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Unauthorized from '../Unauthorized';
import { workflowCollection } from '../../@custom/WorkflowActionCard';
import { Autocomplete } from '@material-ui/lab';
import WorkflowStartup from './WorkflowStartup';

const FieldValue = mainFirebase.firestore.FieldValue;

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
	},
	textFieldDivision: {
		height: 20,
		padding: 3,
	},
}));

// movido para a parte que executa as workflows, companyArea/form.js
const updateGroups = async (workflow) => {
	services.spinner.show();
	await Api.updateGroups(workflow.uid);
	services.spinner.hide();
};

const addPhase = (workflow, updateWorkflow, index) => () => {
	let phase = new WorkflowPhaseShape().$toPlainObject();
	phase.uid = 'phase-' + generateUid();
	if (typeof index == 'number') {
		workflow.phases.splice(index, 0, phase);
	} else {
		workflow.phases.push(phase);
	}
	updateWorkflow();
};

const toggleVisibleId = (id) => () => {
	document.getElementById(id).classList.toggle('visible');
};

const oWorkflow = new Workflow();
const oProcessDocument = new ProcessDocumentShape();
const oService = oWorkflow.getService(firebase);

let historyIncrementTimeout;
let currentHistoryIndex = 0;
let tipsOpened = false;

const confirmStartExecutionFlow = (workflow) => () => {
	workflow.isTest = true;

	const reactions = workflow.phases.reduce((accumulator, phase) => {
		const nonDeletedReactions = phase.reactions.filter((reaction) => !reaction.deleted);
		return accumulator.concat(nonDeletedReactions);
	}, []);

	const classificationExists = reactions.some(
		(reaction) =>
			reaction.type.type === 'dokia' &&
			reaction.dokiaInfo &&
			reaction.dokiaInfo.classification &&
			reaction.dokiaInfo.classification.length > 0
	);

	const dokiaReactions = reactions.some((reaction) => reaction.type.type === 'dokia');

	!classificationExists && dokiaReactions
		? services.message.show('Preencha o campo de classificação')
		: services.message.show('Por favor, informe uma descrição para esta execução', <WorkflowStartup workflow={workflow} />);
};

const Form = ({ match }) => {
	const workflowHistoryCollection = workfluxFirestore.collection('workflowHistory');
	const [state, setState] = useState({});
	const [isDrawerOpen, setIsDrawerOpen] = useState({});
	const [actionHistory, setActionHistory] = useState([]);
	const [workflow, setWorkflow] = useState(oWorkflow.$toPlainObject());
	const [workflowHistory, setWorkflowHistory] = useState({});
	const [errors, setErrors] = useState({});
	const [fullName, setFullName] = useState();
	const auth = useSelector((s) => s.auth);
	const groups = useSelector((s) => s.groups);
	const companyAreaStore = useSelector((s) => s.companyAreaStore);
	const companyStore = useSelector((s) => s.company);

	const classes = useStyles();

	if (!workflow.phases) {
		workflow.phases = [];
	}
	if (!workflow.executorGroups) {
		workflow.executorGroups = [];
	}
	if (!workflow.experation) {
		workflow.experation = {
			daysToExpiration: '',
			daysToFirstWarning: '',
			daysToLastWarning: '',
			expirationConsequence: '',
			doesExpire: false,
			isExpired: false,
		};
	}

	const isMappingAdmin = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.mapping.admin') > -1;
	const isMappingViewer = auth.user.companyadmin || auth.user.permissions.indexOf('workflux.mapping.viewer') > -1;

	let appcenterStore = zerocodeStore.getState().appcenter;

	let newArray = [];
	let array = [];

	appcenterStore.groups.map((group) => {
		group.members.map((member) => {
			if (array.indexOf(member) === -1) {
				array.push(member);
			}
		});
	});

	appcenterStore.groups.map((group) => {
		appcenterStore.users.map((member) => {
			if (group.members.indexOf(member.uid) == -1) return;
			let i = newArray.findIndex((x) => x.uid == member.uid);
			if (i <= -1) {
				newArray.push({
					uid: member.uid,
					firstName: member.firstName,
					lastName: member.lastName,
					email: member.email,
				});
			}
		});
	});

	const [hasLicensesMappings, setHasLicensesMappings] = useState(false);
	const [hasLicensesMaxPhases, setHasLicensesMaxPhases] = useState(false);
	const [maxPhasesLicenses, setMaxPhasesLicenses] = useState(0);
	const [loadingHasLicenses, setLoadingHasLicenses] = useState(false);

	const getWorkflowHistory = async (uid) => {
		const docs = await workflowHistoryCollection.doc(uid).get();
		const data = docs.data();
		setWorkflowHistory(data);
	};

	useEffect(() => {
		if (match.params.id) {
			getWorkflowHistory(match.params.id);
		}
	}, []);

	useEffect(() => {
		workflow.division = companyAreaStore.current.uid;
		workflow.divisionObject = companyAreaStore.current
			? {
					name: companyAreaStore.current.name,
					uid: companyAreaStore.current.uid,
					groups: companyAreaStore.current.groups,
			  }
			: '';
	}, []);

	useEffect(() => {
		let maxPhasesLicensesAvailable = 100;
		let mappingsLicensesAvailable = 100;

		let foundMappingsLicense = false;
		let foundMaxPhasesLicense = false;

		appcenterStore.licenses.map((license) => {
			if (license.type === 'workflux-mappings') {
				foundMappingsLicense = true;
				if (license.amount === 'unlimited') {
					mappingsLicensesAvailable += Infinity - parseInt(license.used || 0);
				} else {
					mappingsLicensesAvailable += parseInt(license.amount || 0) - parseInt(license.used || 0);
				}
			}

			if (license.type === 'workflux-max-phases') {
				foundMaxPhasesLicense = true;
				if (license.amount === 'unlimited') {
					maxPhasesLicensesAvailable += Infinity - parseInt(license.used || 0);
				} else {
					if (license.amount === 'unlimited') {
						maxPhasesLicensesAvailable += Infinity - parseInt(license.used || 0);
					} else {
						maxPhasesLicensesAvailable += parseInt(license.amount || 0) - parseInt(license.used || 0);
					}
				}
			}
		});

		let hasEnoughMappingsLicense = foundMappingsLicense ? mappingsLicensesAvailable : true;
		let hasEnoughMaxPhasesLicense = foundMaxPhasesLicense ? maxPhasesLicensesAvailable : true;

		setHasLicensesMappings(hasEnoughMappingsLicense);

		setHasLicensesMaxPhases(hasEnoughMaxPhasesLicense);
		setMaxPhasesLicenses(maxPhasesLicensesAvailable);

		setLoadingHasLicenses(true);
	}, [appcenterStore.licenses]);

	useEffect(() => {
		if (match.params.id) {
			setActionHistory([]);
			currentHistoryIndex = 0;
			services.spinner.show();
			oService
				.get(match.params.id)
				.then((r) => {
					if (r.company !== auth.company.uid) {
						history.push('/unauthorized');
						return;
					}
					setWorkflow(r);
					setActionHistory([JSON.stringify(r)]);
				})
				.finally(services.spinner.hide);
		}
	}, [auth.company]);

	if (!isMappingAdmin && !isMappingViewer) {
		return <Unauthorized />;
	}

	const workflowExpirationDate = (workflow) => () => {
		const onLocalChangeProp = (prop) => (e) => {
			if (prop === 'doesExpire') {
				workflow.experation.doesExpire = !workflow.experation.doesExpire;
				updateWorkflow();
				return;
			}
			workflow.experation[prop] = e.target.value;
			updateWorkflow();
		};

		services.message.show(
			'Por favor, informe uma data para expiração.',
			<div>
				<FormControlLabel
					label={i18n('workflow.form.actions.active')}
					className="checkbox-control-expiration"
					// disabled={!isMappingAdmin}
					control={
						<Checkbox defaultChecked={workflow.experation.doesExpire} onChange={onLocalChangeProp('doesExpire')} />
					}
				/>
				<TextField
					variant="filled"
					style={{ width: '100%' }}
					label={i18n('workflow.form.actions.daysToExpiration')}
					InputLabelProps={{ shrink: true }}
					defaultValue={workflow.experation.daysToExpiration}
					type={'number'}
					onChange={onLocalChangeProp('daysToExpiration')}
				/>
				<TextField
					variant="filled"
					style={{ width: '100%' }}
					label={i18n('workflow.form.actions.daysToFirstWarning')}
					InputLabelProps={{ shrink: true }}
					defaultValue={workflow.experation.daysToFirstWarning}
					type={'number'}
					disabled={!isMappingAdmin}
					onChange={onLocalChangeProp('daysToFirstWarning')}
				/>
				<TextField
					variant="filled"
					style={{ width: '100%' }}
					label={i18n('workflow.form.actions.daysToLastWarning')}
					InputLabelProps={{ shrink: true }}
					defaultValue={workflow.experation.daysToLastWarning}
					type={'number'}
					disabled={!isMappingAdmin}
					onChange={onLocalChangeProp('daysToLastWarning')}
				/>
				<FormControl style={{ width: '100%' }}>
					<InputLabel id={`workflow-experation-select-expirationConsequence`}>
						{i18n('workflow.form.actions.expirationConsequence')}
					</InputLabel>
					<Select
						labelId={`workflow-experation-select-expirationConsequence-select`}
						disabled={!isMappingAdmin}
						defaultValue={workflow.experation.expirationConsequence}
						onChange={onLocalChangeProp('expirationConsequence')}
					>
						<MenuItem value="ask-responsible">{i18n('workflow.expiration.extend')}</MenuItem>
						<MenuItem value="cancel">{i18n('workflow.expiration.cancel')}</MenuItem>
					</Select>
				</FormControl>
			</div>
		);
	};

	const showErrors = (prop) => {
		return errors[prop] ? i18n(`form.errors.${errors[prop][0]}`) : <span></span>;
	};
	const updateWorkflow = () => {
		incrementHistory();
		setWorkflow({ ...workflow });
	};
	const incrementHistory = (delay) => {
		clearTimeout(historyIncrementTimeout);

		if (!delay) {
			// actionHistory.push(JSON.stringify(workflow));

			actionHistory.splice(currentHistoryIndex + 1, 100000000000, JSON.stringify(workflow));
			currentHistoryIndex = actionHistory.length - 1;

			setState({});
			return;
		}
		historyIncrementTimeout = setTimeout(() => {
			// actionHistory.push(JSON.stringify(workflow));

			actionHistory.splice(currentHistoryIndex + 1, 100000000000, JSON.stringify(workflow));
			currentHistoryIndex = actionHistory.length - 1;

			setState({});
		}, 300);
	};
	const historyRestore = () => {
		// if (actionHistory.length <= 1) return;

		if (!actionHistory[currentHistoryIndex - 1] || currentHistoryIndex - 1 < 0) return;
		setWorkflow(JSON.parse(actionHistory[currentHistoryIndex - 1]));
		currentHistoryIndex--;
	};
	const historyAdvance = () => {
		if (!actionHistory[currentHistoryIndex + 1]) return;
		setWorkflow(JSON.parse(actionHistory[currentHistoryIndex + 1]));
		currentHistoryIndex++;
	};
	const removePhase = (phase, index) => () => {
		phase.toDelete = true;
		setTimeout(() => {
			// workflow.phases[index].deleted = true;
			workflow.phases.splice(index, 1);
			// delete phase.toDelete;
			setState({});
		}, 500);
		updateWorkflow();
	};

	const unique = (value, index, self) => {
		self = self.map((s) => s.uid);
		return self.indexOf(value.uid) === index;
	};

	const save = async (hideMessage) => {
		const reactions = workflow.phases.reduce((accumulator, phase) => {
			const nonDeletedReactions = phase.reactions.filter((reaction) => !reaction.deleted);
			return accumulator.concat(nonDeletedReactions);
		}, []);

		const classificationExists = reactions.some(
			(reaction) =>
				reaction.type.type === 'dokia' &&
				reaction.dokiaInfo &&
				reaction.dokiaInfo.classification &&
				reaction.dokiaInfo.classification.length > 0
		);

		const dokiaReactions = reactions.some((reaction) => reaction.type.type === 'dokia');

		let err = validateDocument();
		if (!workflow.group || !workflow.group.uid) {
			err.group = ['empty'];
		}
		if (!workflow.phases || !workflow.phases.filter((a) => !a.deleted).length) {
			err.phases = ['empty'];
		} else {
			workflow.phases
				.filter((a) => !a.deleted)
				.map((p) => {
					if (!p.actions || !p.actions.filter((a) => !a.deleted).length) {
						err.phases = ['noactions'];
						return;
					}
					p.actions
						.filter((a) => !a.deleted)
						.map((a) => {
							if (!a.group || !a.group.uid) {
								err.actions = ['nogroup'];
							}
							// console.log('ACTION', a);
							if (a.type.type === 'approval' && a.isApprovalRequired) {
								if (
									(!a.onReprovalGoesBack && !a.onReprovalCancel) ||
									p.reactions.filter((r) => r.type.type === 'phaseJump' && r.deleted !== true).length === 0
								) {
									err.phases = ['avoid-irresolution'];
									return;
								}
								console.log('preciso ter 2 ou 3 ou Ir para fase nesta fase!');
								// console.log(
								// 	'Ir para fase',
								// 	p.reactions.filter((r) => r.type.type === 'phaseJump' && r.deleted !== '').length
								// );
							}
						});
					p.reactions
						.filter((r) => !r.deleted)
						.map((r) => {
							if (r.type.type === 'start-collectame-process') {
								if (
									!r.originDocument ||
									!!p.actions.filter((a) => r.originDocument === a.uid && a.deleted === true).length
								) {
									err.originDocument = ['empty'];
								}
							}
						});
				});
		}

		if (!classificationExists && dokiaReactions) {
			await (() =>
				new Promise((resolve) => {
					services.message.show(
						i18n('workflow.form.document.errorDialogTitle'),
						<div>
							<p>Reações: Ao classificar no Dok.Plus o campo Classificação é obrigatório </p>
						</div>,
						[],
						() => {
							services.message.hide();
							resolve();
						}
					);
				}))();
			return;
		}

		if (Object.keys(err).length && !hideMessage) {
			await (() =>
				new Promise((resolve) => {
					services.message.show(
						i18n('workflow.form.document.errorDialogTitle'),
						<div>
							<div>{i18n('workflow.form.saveSuccess')}</div>
							{i18n('workflow.form.document.errorDialogDescription')}
							<div>{i18n('workflow.form.document.errorDialogCheckFieldsMessage')}</div>
							{Object.keys(err).map((prop, i) => {
								return (
									<div key={i}>
										<b>{i18n(`workflow.form.document.${prop}`)}: </b>
										<span>{i18n(`form.errors.${err[prop][0]}`)}</span>
									</div>
								);
							})}
						</div>,
						[],
						() => {
							services.message.hide();
							resolve();
						}
					);
				}))();
		}

		/**
		 * Consumir licença
		 */

		if (!hasUid()) {
			console.log('caiu aqui 1');
			Api.company.licenses
				.consume(auth.company.uid, 'workflux_mappings', 1, auth.user)
				.then((result) => {
					// Licença consumida
				})
				.catch((e) => {});
		}

		workflow.company = auth.company.uid;
		workflow.executorGroups = workflow.executorGroups.filter(unique);
		oService.save(workflow).then((r) => {
			workflowCollection
				.doc(r.uid)
				.set(r, { merge: true })
				.then((w) => {
					incrementHistory();
					if (r.uid) {
						history.push('/workflow/form/' + r.uid);
					}
					if (Object.keys(err).length && hideMessage) return;
					services.message.show(i18n('workflow.form.saveSuccess'), '');
				});

			if (!workflow.createdAt) {
				workflowHistoryCollection.doc(r.uid).set(
					{
						company: workflow.company,
						uid: r.uid,
						division: workflow.division,
						name: workflow.document.title,
						history: FieldValue.arrayUnion({
							date: new Date(),
							user: auth.user.uid,
							userName: auth.user.firstName + ' ' + auth.user.lastName,
							type: 'workflow.history.create',
						}),
					},
					{ merge: true }
				);
			} else {
				workflowHistoryCollection.doc(r.uid).set(
					{
						company: workflow.company,
						uid: r.uid,
						division: workflow.division,
						name: workflow.document.title,
						history: FieldValue.arrayUnion({
							date: new Date(),
							user: auth.user.uid,
							userName: auth.user.firstName + ' ' + auth.user.lastName,
							type: 'workflow.history.update',
						}),
					},
					{ merge: true }
				);
			}
		});
	};

	const validateDocument = () => {
		oProcessDocument.$fill(workflow.document);
		let validation = oProcessDocument.$validate();
		setErrors(validation);
		return validation;
	};
	const handleDocumentChange = (prop) => (e) => {
		workflow.document[prop] = e.target.value;
		let err = errors;
		oProcessDocument[prop] = workflow.document[prop];
		let validation = oProcessDocument.$fieldConfig[prop].validate();
		if (validation.length) {
			err[prop] = validation;
		} else {
			delete err[prop];
		}
		setErrors(err);
		updateWorkflow();
	};

	const selectGroup = (group, name) => () => {
		incrementHistory();
		setFullName(name);
		//(group, 'groupppp');
		services.message.hide();
		workflow.group = group;
		updateWorkflow();
	};

	const sizeArrayPhases = () => {
		let size = [];
		size = workflow.phases.filter((phase) => {
			return phase.deleted === true;
		});

		return workflow.phases.length - size.length;
	};

	const hasUid = () => {
		return workflow.uid ? true : false;
	};

	let firstPhase = workflow.phases.find((p) => (!p.deleted ? p : false));

	return (
		<>
			<content className="relative workflow-mapping-content">
				<header>
					<div style={{ flex: 1 }}>
						<div className="columns">
							<div>
								{/* {i18n('workflow.form.area')}: {workflow.divisionObject.name} */}
								{!workflow.uid && (workflow.division || 'a') && (
									<TextField
										variant={'outlined'}
										style={{ width: 200, height: 20 }}
										label={i18n('companyArea.form.screenTitle')}
										value={workflow.division}
										error={!workflow.division}
										onChange={(e) => {
											let area = (e.target.value
												? companyAreaStore.list.find((area) => {
														if (area.uid === e.target.value) return area;
												  })
												: null) || { name: '', uid: '', groups: [] };
											incrementHistory();

											workflow.division = area.uid;
											workflow.divisionObject = {
												name: area.name,
												uid: area.uid,
												groups: area.groups,
											};
											//('workflow.division', workflow.division);

											setWorkflow({ ...workflow });
										}}
										className={`business-area-select header-chip`}
										select
									>
										{companyAreaStore.list
											.sort((a, b) => {
												if (a.name > b.name) return 1;
												if (a.name < b.name) return -1;
												return 0;
											})
											.filter((ca) => {
												if (auth.user.companyadmin) return true;
												if (ca.groups && ca.groups.length) {
													let found = false;
													ca.groups.find((group) => {
														auth.user.groups.find((uGroup) => {
															if (uGroup.uid === group.uid) {
																found = true;
																return uGroup;
															}
														});
														if (found) {
															return found;
														}
													});
													return found;
												}
												return true;
											})
											.map((area) => {
												return (
													<MenuItem
														// value={{
														// 	name: area.name,
														// 	uid: area.uid,
														// }}
														value={area.uid}
														key={area.uid}
													>
														{area.name}
													</MenuItem>
												);
											})}
									</TextField>
								)}
							</div>
							<div
								className={`header-chip ${!workflow.group || !workflow.group.uid ? 'error' : ''}`}
								onClick={showGroupSelectionWindow(selectGroup, companyStore, true, newArray)}
							>
								<div className="chip-label">{i18n('workflow.form.group')}</div>
								{workflow.group && workflow.group.uid ? workflow.group.name : 'Não Definido'}
							</div>
							{fullName && (
								<div className="header-chip">
									{'Responsável'}: {`${fullName.firstName} ${fullName.lastName}`}
								</div>
							)}

							<div>
								<Tooltip title="Mostrar no portal Taugor do Workflux">
									<FormControlLabel
										label="Mostrar no portal"
										onChange={(e) => {
											workflow.security = e.target.checked ? 'public' : 'private';
											setWorkflow({ ...workflow });
										}}
										checked={workflow.security === 'public'}
										control={<Checkbox />}
									/>
								</Tooltip>
							</div>
						</div>
						<h3>{workflow.document.title}</h3>
						<div
							className={`tips ${tipsOpened ? 'opened' : 'closed'}`}
							onClick={(e) => {
								tipsOpened = !tipsOpened;
								setState({});
							}}
						>
							<div className="title">#{i18n('workflow.form.tips')}</div>
							<div className="tip">
								{i18n('workflow.form.stockHistorySize')} ({actionHistory.length - 1})
							</div>
							<div className="tip">
								{i18n('workflow.form.marckPhase')} <span>{i18n('opening')}</span>
								{i18n('workflow.form.marckPhaseTextFinaly')}
							</div>
							<div className="tip">
								{i18n('workflow.flow.tipsTextSecondaryStep')} <span>Shift</span> {i18n('andDragTaskToCopy')}
							</div>
							<div className="tip">
								{i18n('workflow.flow.tipsTextSecondaryStep')} <span>Shift</span> + <span>Control</span>{' '}
								{i18n('andDragTaskToMove')}
							</div>
							<div className="tip">
								{i18n('workflow.flow.tipsTextSecondaryStep')} <span>Control </span>
								{i18n('andClickOnTaskSelectCreateLinks')}
							</div>
						</div>
					</div>
					<div className="header-buttons">
						{isMappingAdmin && (
							<Tooltip title={i18n('undo')}>
								<div
									className="header-button "
									disabled={!actionHistory[currentHistoryIndex - 1] || currentHistoryIndex - 1 < 0}
									onClick={historyRestore}
								>
									<Icon icon="faUndo" />
								</div>
							</Tooltip>
						)}
						{isMappingAdmin && (
							<Tooltip title={i18n('rephase')}>
								<div
									className="header-button "
									disabled={!actionHistory[currentHistoryIndex + 1]}
									onClick={historyAdvance}
								>
									<Icon icon="faRedo" />
								</div>
							</Tooltip>
						)}
						{isMappingAdmin && (
							<Tooltip title={i18n('addPhase')}>
								<div
									className={'header-button add' + (sizeArrayPhases() === maxPhasesLicenses ? ' disabled' : '')}
									onClick={addPhase(workflow, updateWorkflow)}
								>
									<Icon icon="faPlus" size={'2x'} />
								</div>
							</Tooltip>
						)}
						{isMappingAdmin && (
							<Tooltip title={i18n('testFlow')}>
								<div className={`header-button`} onClick={confirmStartExecutionFlow(workflow)}>
									<Icon icon="faFlask" size={'2x'} />
								</div>
							</Tooltip>
						)}
						{isMappingAdmin && (
							<Tooltip title="Grupos que podem visualizar">
								<div
									className="header-button"
									onClick={() => {
										services.message.show(
											'Grupos que podem visualizar os fluxos',
											<div>
												<Autocomplete
													disablePortal
													multiple
													defaultValue={workflow.viewGroups}
													variant="outlined"
													options={appcenterStore.groups}
													getOptionLabel={(option) => option.name || ''}
													renderInput={(params) => (
														<TextField {...params} label="Grupos que podem ver os fluxo executados" />
													)}
													onChange={(e, value) => {
														setWorkflow((prev) => ({
															...prev,
															viewGroups: (value || []).map((group) => ({
																uid: group.uid,
																name: group.name,
															})),
														}));
													}}
												/>
											</div>
										);
									}}
								>
									<Icon icon="faEye" size="2x" />
								</div>
							</Tooltip>
						)}
						{isMappingAdmin && (
							<Tooltip title={i18n('streamExpirationDate')}>
								<div className={`header-button`} onClick={workflowExpirationDate(workflow)}>
									<Icon icon="faStopwatch" />
								</div>
							</Tooltip>
						)}
						{isMappingAdmin && (
							<Tooltip title={i18n('groupsThatCanRun')}>
								<div
									className={`header-button`}
									// onClick={showGroupSelectionWindow(
									// 	companyStore,
									// 	true,
									// 	newArray,
									// 	null,
									// 	true,
									// 	true
									// )}
									onClick={() => {
										workflow.executorGroups = workflow.executorGroups.filter(unique);
										setWorkflow(workflow);
										services.message.show(
											'Grupos executores',
											<div>
												<Autocomplete
													disablePortal
													multiple
													defaultValue={workflow.executorGroups}
													options={appcenterStore.groups}
													// getOptionLabel={(option) => option.name || ''}
													getOptionLabel={(option) => option.name}
													renderInput={(params) => <TextField {...params} label={i18n('groups')} />}
													onChange={(e, value) => {
														if (value.length === 0) {
															workflow.executorGroups = [];
															return;
														} else {
															workflow.executorGroups = [];
															value.forEach((group) => {
																workflow.executorGroups.push({
																	name: group.name,
																	uid: group.uid,
																});
															});
														}
														setWorkflow(workflow);
													}}
												/>
											</div>
										);
									}}
								>
									<Icon icon="faIdBadge" size={'2x'} />
								</div>
							</Tooltip>
						)}

						{/* {isMappingAdmin && (
						<Tooltip title="Atualizar grupos">
							<div
								className={`header-button`}
								onClick={() => {
									updateGroups(workflow);
								}}
							>
								<Icon icon="faUsersCog" size={'2x'} />
							</div>
						</Tooltip>
					)} */}
						{isMappingAdmin && (
							<Tooltip title={i18n('selectThePersonResponsibleForTheFlow')}>
								<div
									className={`header-button ${!workflow.group || !workflow.group.uid ? 'call-attention' : ''}`}
									onClick={showGroupSelectionWindow(selectGroup, companyStore, true, newArray)}
								>
									<Icon icon="faUsers" size={'2x'} />
								</div>
							</Tooltip>
						)}
						<Tooltip title={i18n('workflow.form.document.formTitle')}>
							<div
								className={`header-button ${!workflow.document.title ? 'call-attention' : ''}`}
								onClick={toggleVisibleId('document-form')}
							>
								<Icon icon="faFileAlt" size={'2x'} />
							</div>
						</Tooltip>
						<Tooltip title={i18n('executionFlow.form.history')}>
							<div
								className={`header-button ${!workflow.document.title ? 'call-attention' : ''}`}
								onClick={toggleVisibleId('update-form')}
							>
								<Icon icon="faClock" size={'2x'} />
							</div>
						</Tooltip>
						{isMappingAdmin && (
							<Tooltip title={i18n('save')}>
								<div
									className={'header-button save' + (hasUid() ? '' : hasLicensesMappings ? '' : ' disabled')}
									onClick={() => {
										// updateGroups(workflow)
										save(false);
									}}
									save={true}
								>
									<Icon icon="faSave" size={'2x'} />
								</div>
							</Tooltip>
						)}
					</div>
				</header>

				{loadingHasLicenses && sizeArrayPhases() === maxPhasesLicenses && (
					<div
						style={{
							display: 'flex ',
							justifyContent: 'flex-end',
						}}
					>
						<div className="message-danger">{i18n('without-licences-phases', [`${maxPhasesLicenses}`])}</div>
					</div>
				)}
				{!hasUid() && loadingHasLicenses && !hasLicensesMappings && (
					<div
						style={{
							display: 'flex ',
							justifyContent: 'flex-end',
						}}
					>
						<div className="message-danger">{i18n('without-licences-mapping')}</div>
					</div>
				)}
				<section id="document-form" onClick={toggleVisibleId('document-form')}>
					<div className="content" onClick={(e) => e.stopPropagation()}>
						<header>
							<div>
								<Typography variant={'h4'}>{i18n('workflow.form.document.formTitle')}</Typography>
								<Typography>{i18n('workflow.form.document.formDescription')}</Typography>
							</div>
							<div id="document-close" onClick={toggleVisibleId('document-form')}>
								<Icon icon="faTimesCircle" />
							</div>
						</header>
						<div>
							<TextField
								value={workflow.document.title}
								label={i18n('workflow.form.document.title')}
								onChange={handleDocumentChange('title')}
								className={classes.textField}
								helperText={showErrors('title')}
								disabled={!isMappingAdmin}
							/>
							<TextField
								value={workflow.document.description}
								label={i18n('workflow.form.document.description')}
								onChange={handleDocumentChange('description')}
								className={classes.textField}
								helperText={showErrors('description')}
								disabled={!isMappingAdmin}
								multiline={true}
							/>
							<TextField
								value={workflow.document.inputs}
								label={i18n('workflow.form.document.inputs')}
								onChange={handleDocumentChange('inputs')}
								className={classes.textField}
								helperText={showErrors('inputs')}
								disabled={!isMappingAdmin}
								multiline={true}
							/>
							<TextField
								value={workflow.document.beginConditions}
								label={i18n('workflow.form.document.beginConditions')}
								onChange={handleDocumentChange('beginConditions')}
								className={classes.textField}
								helperText={showErrors('beginConditions')}
								disabled={!isMappingAdmin}
								multiline={true}
							/>
							<TextField
								value={workflow.document.roles}
								label={i18n('workflow.form.document.roles')}
								onChange={handleDocumentChange('roles')}
								className={classes.textField}
								helperText={showErrors('roles')}
								disabled={!isMappingAdmin}
								multiline={true}
							/>
							<TextField
								value={workflow.document.activities}
								label={i18n('workflow.form.document.activities')}
								onChange={handleDocumentChange('activities')}
								className={classes.textField}
								helperText={showErrors('activities')}
								disabled={!isMappingAdmin}
								multiline={true}
							/>
							<TextField
								value={workflow.document.endConditions}
								label={i18n('workflow.form.document.endConditions')}
								onChange={handleDocumentChange('endConditions')}
								className={classes.textField}
								helperText={showErrors('endConditions')}
								disabled={!isMappingAdmin}
								multiline={true}
							/>
							<TextField
								value={workflow.document.outputs}
								label={i18n('workflow.form.document.outputs')}
								onChange={handleDocumentChange('outputs')}
								className={classes.textField}
								helperText={showErrors('outputs')}
								disabled={!isMappingAdmin}
								multiline={true}
							/>
						</div>
					</div>
				</section>

				{/* histórico */}
				<section id="update-form" onClick={toggleVisibleId('update-form')}>
					<div className="content" onClick={(e) => e.stopPropagation()}>
						<header>
							<div>
								<Typography variant={'h4'}>{i18n('workflow.form.document.updateHistory')}</Typography>
							</div>
							<div id="update-close" onClick={toggleVisibleId('update-form')}>
								<Icon icon="faTimesCircle" />
							</div>
						</header>
						<div>
							{workflowHistory?.history?.map((item, index) => {
								console.log('item', item.date);
								const type = item.type === 'workflow.history.create' ? 'Criado' : 'Editado';
								const date = item.date.toDate();

								const options = {
									weekday: 'long',
									year: 'numeric',
									month: 'long',
									day: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
									second: 'numeric',
								};

								// Formatando a data com as opções
								const formattedDate = date.toLocaleDateString('pt-BR', options);

								return (
									<Paper
										key={index}
										elevation={3}
										style={{ padding: '16px', margin: '8px', maxWidth: '300px', marginBottom: '8px' }}
									>
										<Typography variant="body1">{`${type}`}</Typography>
										<Typography variant="body1"> por {item.userName}</Typography>
										<Typography variant="body1">em {formattedDate}</Typography>
									</Paper>
								);
							})}
						</div>
					</div>
				</section>
				<section id="board">
					{workflow.phases.map((phase, i) => {
						if (phase.deleted) return;

						return (
							<Phase
								isFirst={firstPhase == phase}
								auth={auth}
								phase={phase}
								addPhase={addPhase(workflow, updateWorkflow, i)}
								updateWorkflow={updateWorkflow}
								removePhase={removePhase(phase, i)}
								key={phase.uid}
								companyStore={companyStore}
								workflow={workflow}
								isMappingAdmin={isMappingAdmin}
								incrementHistory={incrementHistory}
								saveWorkflow={() => save(true)}
							/>
						);
					})}
				</section>
			</content>
			{/* <IconButton
				size='large'
				edge='start'
				color='inherit'
				aria-label='logo'
				onClick={() => setIsDrawerOpen(true)}
			>
				<Icon icon="faPlus" />
			</IconButton>
			<Drawer
				anchor='right'
				open={isDrawerOpen}
				onClose={() => setIsDrawerOpen(false)}
			>
			</Drawer> */}
		</>
	);
};

export default Form;
