import React, { useMemo, useState } from 'react';
import { FormControlLabel, Checkbox, TextField, Button } from '@mui/material';
import firebase, { workfluxFirestore } from '@firebase-config';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { store } from 'zerocode';
import { useSelector } from 'react-redux';
import { services } from '@redux';
import Api from '../../Api';
import './styles.scss';

const executionFlowCollection = workfluxFirestore.collection('executionFlow');

function ShareExecutionFlow({ executionFlow }) {
	const auth = useSelector((s) => s.auth);
	const [shareType, setShareType] = useState('');
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const state = store.getState();

	const allGroups = state.appcenter.groups;
	const allUsers = state.appcenter.users;

	const canSend = useMemo(() => {
		if (!shareType) return false;
		return shareType === 'users' ? selectedUsers.length > 0 : selectedGroups.length > 0;
	}, [shareType, selectedGroups, selectedUsers]);

	const handleSetShareType = (type) => (evt) => {
		const checked = evt.target.checked;
		setShareType(checked ? type : '');
	};

	const handleSendDocument = () => {
		services.spinner.show();

		Api.executionFlow
			.share(executionFlow.uid, { users: selectedUsers, groups: selectedGroups })
			.then(() => {
				services.message.show('Fluxo encaminhado com sucesso');
			})
			.catch((err) => {
				console.error(err);
				services.message.show('Erro ao encaminhar o fluxo');
			})
			.finally(services.spinner.hide);
	};

	return (
		<div className="ShareExecutionFlow">
			<FormControlLabel
				label="Encaminhar para grupos/setores"
				onChange={handleSetShareType('groups')}
				checked={shareType === 'groups'}
				control={<Checkbox />}
			/>
			{shareType === 'groups' && (
				<Autocomplete
					multiple
					fullWidth
					options={allGroups}
					onChange={(_, newValue) => setSelectedGroups(newValue)}
					value={selectedGroups}
					getOptionLabel={(option) => option.name || ''}
					renderInput={(params) => <TextField {...params} variant="standard" label="Lista de grupos/setores" />}
				/>
			)}
			<FormControlLabel
				label="Encaminhar para usuários específicos"
				onChange={handleSetShareType('users')}
				checked={shareType === 'users'}
				control={<Checkbox />}
			/>
			{shareType === 'users' && (
				<Autocomplete
					multiple
					fullWidth
					options={allUsers}
					onChange={(_, newValue) => setSelectedUsers(newValue)}
					value={selectedUsers}
					getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
					renderInput={(params) => <TextField {...params} variant="standard" label="Lista de usuários" />}
				/>
			)}

			<p className="ShareExecutionFlow-warning">
				* Ao encaminhar fluxo, o usuário terá acesso de leitura/visualização do fluxo inteiro.
			</p>

			<Button variant="contained" onClick={handleSendDocument} disabled={!canSend}>
				Enviar
			</Button>
		</div>
	);
}

export default ShareExecutionFlow;
