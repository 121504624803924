import React, { useState, useEffect } from 'react';
import firebase from '@firebase-config';
import i18n, { format } from '@i18n';
import { ExecutionFlow } from '@taugor/taugor-bpmn-models';
import { services } from '@redux';
import Api from '../../Api';
import Icon from '@custom/Icon';
import { uid } from '@zerobytes/firebase-basic-service';
import { store } from '@redux';
import FluxView from '../executionFlow/view';
import Unauthorized from '../Unauthorized';

import { Typography, TextField, List, ListItem, ListItemText, ListItemIcon, Button, IconButton } from '@material-ui/core';
import Input from '@custom/Input';
import { useSelector, connect } from 'react-redux';
import ReportExecutionFlowByResponsible from './ReportExecutionFlowByResponsible';

import '@assets/sass/report.scss';

const oExecutionFlowService = new ExecutionFlow().getService(firebase, store, 'executionFlowListReportStore');

const Form = ({ companyAreaStore, auth, executionFlowListReportStore, executionFlowStore }) => {
	let currentUid;
	if (executionFlowStore && executionFlowStore.current) {
		currentUid = executionFlowStore.current.uid;
	}
	const [executionFlowSelectedUid, setExecutionFlowSelectedUid] = useState(currentUid);
	const [search, setSearch] = useState('');
	const [list, setList] = useState([]);
	const [filters, setFilters] = useState({
		inExecution: false,
		finished: false,
		canceled: false,
		archived: false,
	});

	// const isExecutionsAdmin = auth.user.permissions.indexOf('workflux.executions.admin') > -1;
	// const isExecutionsViewer = auth.user.permissions.indexOf('workflux.executions.viewer') > -1;

	useEffect(() => {
		if (auth.company && auth.company.uid) {
			let allQueryFilters = [];
			let queryFilters = [
				[
					['company', '==', auth.company.uid],
					['completed', '==', 'not-completed'],
					['deleted', '==', false],
				],
			];

			services.spinner.show();

			oExecutionFlowService.filter(queryFilters).list().finally(services.spinner.hide);
		}
	}, [auth.company, filters.inExecution, filters.finished, filters.canceled, filters.archived]);

	useEffect(() => {
		setFilteredList();
	}, [executionFlowListReportStore.list, search, companyAreaStore.list]);

	const selectExecutionFlowForActions = (executionFlow) => {
		executionFlow.selected = !executionFlow.selected;
		setList([...list]);
	};

	const setFilteredList = () => {
		const list = executionFlowListReportStore.list;
		//(list, 'list const');

		setList(list);
	};

	if (!auth.user.companyadmin) {
		return <Unauthorized />;
	}

	return (
		<content id="executions-list">
			<div className="entity-management">
				<div id="list-panel" className="list-view">
					<div className="info-field">
						<h3>
							<Icon icon="faFileContract" />
							{i18n('reports.list.title')}
						</h3>
					</div>

					<div className="selected-items-header">
						<Button
							variant="contained"
							color="primary"
							onClick={() =>
								setFilters({
									inExecution: true,
									finished: false,
									canceled: false,
									archived: false,
								})
							}
							className="button-relatory"
						>
							{i18n('reports.list.flowsNotCompletedByResponsible.title')}
						</Button>
					</div>

					<div className="executionflow-list">
						<ReportExecutionFlowByResponsible
							list={list}
							filters={filters}
							executionFlowSelectedUid={executionFlowSelectedUid}
							setExecutionFlowSelectedUid={setExecutionFlowSelectedUid}
							selectExecutionFlowForActions={selectExecutionFlowForActions}
						/>
					</div>
				</div>

				<div id="form-panel" className={`${executionFlowSelectedUid ? 'visible' : ''}`}>
					<header className="form-panel-header">
						<div style={{ flex: 1 }}></div>
						<div
							className="header-button"
							onClick={() => {
								setExecutionFlowSelectedUid('');
							}}
						>
							<Icon icon="faTimes" />
						</div>
					</header>
					<FluxView
						noTaskView={true}
						match={{
							params: {
								id: executionFlowSelectedUid,
							},
						}}
					/>
				</div>
			</div>
		</content>
	);
};

export default connect(({ auth, executionFlowListReportStore, executionFlowView, companyAreaStore }) => ({
	auth: auth,
	executionFlowListReportStore: executionFlowListReportStore,
	executionFlowStore: executionFlowView,
	companyAreaStore,
}))(Form);
