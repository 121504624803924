import { workfluxFirebase, workfluxMainFirebase } from 'zerocode';

/** @type {firebase.auth.Auth} */
const workfluxAuth = workfluxFirebase.auth();
/** @type {firebase.firestore.Firestore} */
const workfluxFirestore = workfluxFirebase.firestore();
workfluxFirestore.settings({
    ignoreUndefinedProperties: true,
});
/** @type {firebase.storage.Storage} */
const workfluxStorage = workfluxFirebase.storage();

export { workfluxMainFirebase, workfluxAuth, workfluxStorage, workfluxFirestore };
export default workfluxFirebase;
 