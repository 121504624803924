import React, { useState, useEffect } from 'react';
import firebase, { workfluxFirestore } from '@firebase-config';
import i18n, { format } from '@i18n';
import { store, services } from '@redux';
import { useSelector } from 'react-redux';
import { LinearProgressWithLabel } from '../../utils/linearProgressWithLabel';
import { ExecutionFlow, Task, ExecutedApproval, Signing } from '@taugor/taugor-bpmn-models';
import {
	TextField,
	Typography,
	Button,
	MenuItem,
	FormControl,
	Select,
	InputLabel,
	Input,
	Box,
	LinearProgress,
	withStyles
} from '@material-ui/core';
import Api from '@api';
import Icon from '@custom/Icon';
import LacunaWebPKI from 'web-pki';
import TaskOwnerAbsent from './task.owner.absent.js';
import TaskComplementary from './task.complementary.js';
import TaskShare from './task.share.js';
import TaskCard from './TaskCard.js';
import { getAppImageSrc, store as zerocodeStore } from 'zerocode';

const taskCollection = workfluxFirestore.collection('task');
const oTaskService = new Task().getService(firebase, store, 'taskApprovalView');
const oSigningService = new Signing().getService(firebase, store, 'signingTaskView');
const oExecutedApprovalService = new ExecutedApproval().getService(firebase, store, 'taskViewExecutedApproval');

var pki = new LacunaWebPKI();

let isSigning = false;
const TaskApproval = ({ taskId, task, setMobileFormVisible, external, onSave }) => {
	const authStore = useSelector((s) => s.auth);
	const absenseStore = useSelector((s) => s.absenseStore);
	const taskStore = useSelector((s) => s.taskApprovalView);
	const signingStore = useSelector((s) => s.signingTaskView);
	const executedApprovalStore = useSelector((s) => s.taskViewExecutedApproval);
	const [action, setAction] = useState({ reason: '', approved: false });

	const [signingRequired, setSigningRequired] = useState(false);
	const [reactions, setReactions] = useState([]);
	const [pkiStarted, setPkiStarted] = useState([]);
	const [pkiCertificates, setPkiCertificates] = useState([]);
	const [discussions, setDiscussions] = useState([]);
	const [selectedCert, setSelectedCert] = useState('');
	const [keys, setKeys] = useState([]);
	const [values, setValues] = useState([]);
	const [formName, setFormName] = useState('');
	const [hasHsmLicense, setHasHsmLicense] = useState(false);
	const appcenterStore = zerocodeStore.getState().appcenter;
	const [checklist, setChecklist] = useState(false);

	useEffect(() => {
		if (appcenterStore.licenses) {
			const hasHsmLicense = appcenterStore.licenses.some((license) => license.type === 'workflux-hsm');
			setHasHsmLicense(hasHsmLicense);
		}
	}, [appcenterStore.licenses])

	useEffect(() => {
		if (!taskId) return;
		return taskCollection
			.doc(taskId)
			.collection('discussion')
			.where('deleted', '==', false)
			.onSnapshot((snapshot) => {
				setDiscussions(snapshot.docs.map((d) => d.data()));
			});
	}, [, taskStore.current.uid]);

	useEffect(() => {
		pki.init(onWebPkiReady);
	}, []);

	const onTaskGet = (task) => {
		if (!task || !task.uid) return;
		let r = [];
		task.workflowObject.phases.map((phase) => {
			phase.reactions.map((reaction) => {
				if (reaction.originApproval === task.action.uid) {
					r.push(reaction);
					if (reaction.type.type === 'documentOutput' && reaction.requireApprovalDocumentSign) {
						setSigningRequired(true);
					}
				}
			});
		});
		setReactions(r);
	};

	useEffect(() => {
		if (taskId || !task) return;
		taskStore.current = task;
		onTaskGet(task);
	}, [task]);

	useEffect(() => {
		if (signingStore.current.status === 'pending-sign' && !isSigning) {
			//('HERE');
			isSigning = true;
			pki.signWithRestPki({
				token: signingStore.current.token,
				thumbprint: signingStore.current.certificateThumbprint,
			}).success(function (token) {
				oSigningService
					.patch(signingStore.current.uid, {
						token,
						status: 'done',
					})
					.then((r) => {
						isSigning = false;
					});
			});
		}
	}, [signingStore.current, signingStore.current.status]);

	function onWebPkiReady() {
		setPkiStarted(true);
		let c = [];
		pki.listCertificates().success(function (certs) {
			for (var i = 0; i < certs.length; i++) {
				var cert = certs[i];
				// var thumbprint = cert.thumbprint;
				// var subjectName = cert.subjectName;
				// var issuerName = cert.issuerName;
				c.push(cert);
			}
			setPkiCertificates(c);
			//('CERT', c);
		});
	}

	useEffect(() => {
		if (!taskId) return;
		services.spinner.show();
		oTaskService
			.get(taskId)
			.then((task) => {
				onTaskGet(task);
			})
			.finally(services.spinner.hide);
	}, [taskId]);

	useEffect(() => {
		if (!taskStore.current || !taskStore.current.uid) return;
		oExecutedApprovalService
			.filter([
				[
					['action.uid', '==', taskStore.current.action.uid],
					['executionFlow', '==', taskStore.current.executionFlow],
					['company', '==', authStore.company.uid],
				],
			])
			.list();
		if (taskStore.current.action.hasFormReference) {
			taskStore.current.workflowObject.phases &&
				taskStore.current.workflowObject.phases.map((phase) => {
					if (phase.actions) {
						phase.actions.map((action) => {
							if (action.executedForm == undefined) return;
							if (action.uid === taskStore.current.action.formReference.action) {
								setFormName(action.formTitle);
								setKeys(Object.keys(action.executedForm.fields));
								setValues(Object.values(action.executedForm.fields));
							}
						});
					}
				});
		}
	}, [taskStore.current]);

	useEffect(() => {
		if (!taskId) return;
		let checklistDoc = taskCollection.doc(taskId).collection('checklist').doc(taskId);
		checklistDoc.get().then((d) => {
			let data = {
				actions: [],
			};
			if (d.exists) {
				data = d.data();
			}
			setChecklist(data.actions);
		});
	}, [, taskStore.current.uid]);

	if (!taskStore.current || !taskStore.current.uid) return <div></div>;

	let progress = 100;
	if (checklist && checklist.length > 0) {
		progress = (checklist.filter((a) => a.finished).length * 100) / checklist.length;
	}

	if (taskStore.current.status && taskStore.current.status !== 'completed' && taskStore.current.agent !== authStore.user.uid) {
		let isAbsent = false;
		absenseStore.list.map((absense) => {
			let today = new Date();
			if (absense.dateStart.toDate() <= today && absense.dateEnd.toDate() >= today) {
				isAbsent = true;
			}
		});
		if (isAbsent) {
			return <TaskOwnerAbsent task={taskStore.current} />;
		}
	}

	const sendLinkHsm = async (e, task) => {
		e.preventDefault();
		e.stopPropagation();

		let phone = ''

		await new Promise((resolve) => {
			services.message.show(
				'Enviar link de assinatura por HSM',
				<div>
					<div
						style={{
							marginBottom: 16,
						}}
					>
						Digite o número do celular:
					</div>
					<Input
						mode="outlined"
						type="text"
						label={'Número de telefone'}
						mask="(99) 99999-9999"
						onChange={(e) => phone = e.target.value}
					/>
				</div>,
				[
					{
						text: 'confirm',
						onClick: (e) => resolve(),
					},
					{
						text: 'cancel',
						onClick: (e) => resolve(),
					},
				],
				resolve
			)
		});

		services.message.hide();

		services.spinner.show();

		phone = `55${phone.replace(/[^0-9]/g, '')}`;
		const data = {
			to: phone,
			namespace: '86ae2fa8_6186_4b8c_81af_8d7b71b3a2d0',
			elementName: 'voc_recebeu_uma_tarefa_real',
			components: [
				{
					type: 'body',
					parameters: [
						{
							type: 'text',
							text: task.action.agentName,
						},
						{
							type: 'text',
							text: `${task.workflowObject.document.title || ''} - ${task.action.type.name || ''}`.trim(),
						},
						{
							type: 'text',
							text: task.action.instructions,
						},
						{
							type: 'text',
							text: `https://workflux.digital//executionFlow/${taskStore.current.executionFlow}/task/${taskStore.current.action.type.type}/${taskStore.current.uid}`,
						}
					],
				},
			],
		};

		try {
			await Api.hsm.sendHsm(data);

			services.spinner.hide();
			services.message.show('Link enviado com sucesso!', `O link foi enviado para o número +${phone}.`);
		} catch (err) {
			console.log(err);
			services.spinner.hide();
			services.message.show('Ocorreu um erro ao enviar o link.', `Confira se o número +${phone} está certo e entre em contato com o suporte.`);
		}
	}

	const onChange = (prop) => (e) => {
		setAction({
			...action,
			[prop]: e.target.value,
		});
	};
	const saveMessage = () => {
		services.spinner.hide();
		services.message.show('Tarefa concluída com sucesso');
	};
	const approve = () => {
		if (signingRequired && !selectedCert) {
			services.message.show(i18n('task.form.reproval.needsSigning'));
			return;
		}
		services.spinner.show();
		if (signingRequired) {
			oSigningService.save({ status: 'pending', certificateThumbprint: selectedCert }).then((r) => {
				Api.executionFlow.task.approve(taskId, true, action.reason, r.uid).finally(saveMessage);
				//('getting', r.uid);
				oSigningService.get(r.uid);
			});
		} else {
			Api.executionFlow.task.approve(taskId, true, action.reason).finally(saveMessage);
		}
	};
	const reprove = () => {
		if (signingRequired && !selectedCert) {
			services.message.show(i18n('task.form.reproval.needsSigning'));
			return;
		}
		if (!action.reason) {
			services.message.show(i18n('task.form.reproval.needsReason'));
			return;
		}
		services.spinner.show();
		if (signingRequired) {
			oSigningService.save({ status: 'pending', certificateThumbprint: selectedCert }).then((r) => {
				Api.executionFlow.task.approve(taskId, false, action.reason, r.uid).finally(saveMessage);
				oSigningService.get(r.uid);
			});
		} else {
			Api.executionFlow.task.approve(taskId, false, action.reason).finally(saveMessage);
		}
	};

	const renderInfoContent = () => {
		return (
			<div>
				{taskStore.current.status === 'review' ? (
					<div>
						{taskStore.current.action.agent !== authStore.user.uid && (
							<div style={{ marginBottom: 20 }}>
								{i18n('task.form.responsible')}:{''}
								{taskStore.current.action.agentName}
							</div>
						)}
						<div style={{ marginBottom: 20 }}>{i18n('task.form.inReview')}</div>
					</div>
				) : (
					<div>
						<div style={{ marginBottom: 20 }}>
							{i18n('task.form.responsible')}: {taskStore.current.action.agentName}
						</div>
						<div style={{ color: '#666', marginBottom: 10 }}>{i18n('task.form.instructions')}</div>
						<div style={{ marginBottom: 20 }}>
							{taskStore.current.action.instructions || i18n('task.form.noInstructions')}
						</div>
					</div>
				)}
				{!external && taskStore.current.uid !== 'startup' && (
					<div className="absent-panel">
						<TaskOwnerAbsent task={taskStore.current} hideTexts={true} />
					</div>
				)}
				{taskStore.current.expirationDateString && (
					<div style={{ color: '#666', marginBottom: 10 }}>
						{i18n('task.form.taskWillExpire', {
							date: format.date(new Date(taskStore.current.expirationDateString + ' 12:00')),
						})}
					</div>
				)}
				{taskStore.current.action.complementary && (
					<div className="complementary-task-warning">{i18n('executionFlow.form.complementaryTask')}</div>
				)}
				{taskStore.current.status === 'review' && taskStore.current.action.agent === authStore.user.uid && (
					<div style={{ marginBottom: 20 }}>
						<Button
							style={{ marginBottom: 20 }}
							className="simple-filled-button"
							onClick={() => {
								services.spinner.show();
								Api.executionFlow.task
									.confirmReview({ task: taskStore.current.uid })
									.finally(services.spinner.hide);
							}}
						>
							{i18n('task.form.inReview.useCurrentInfo')}
						</Button>
						{renderFormContent()}
					</div>
				)}

				{!!checklist && checklist.length > 0 && (
					<div className="progress-bar">
						<p>{i18n("activityChecklist")}:</p>
						<LinearProgressWithLabel value={progress} />
					</div>
				)}

				{!!checklist && (
					<div className="task-checklist-actions" style={{ marginBottom: 15 }}>
						{checklist.map((action, i) => {
							return (
								<div className="checklist-action" style={{ color: '#666', marginBottom: 5 }} key={i}>
									{action.finished ? (<><Icon icon="faCheck" /> </>) : (<><Icon icon="faTimes" /> </>)}
									{action.name}
								</div>
							);
						})}
					</div>
				)}
				{!!discussions[0] && (
					<div className="discussion-content">
						<p className="title">{i18n("workflow.kanban.card.step.discussion")}:</p>
						{discussions.map((discussion) => {
							return (
								<div className="discussion">
									<div className="name" >{discussion.user.name}:</div>
									<div className="content" >{discussion.comment}</div>
								</div>
							);
						})}
					</div>
				)}

				{executedApprovalStore.list
					.sort((a, b) => {
						if (a.createdAt.toDate() > b.createdAt.toDate()) return -1;
						if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
						return 0;
					})
					.map((executedApproval, i) => {
						return (
							<div style={{ borderBottom: '1px solid #ddd' }} key={i}>
								{executedApproval.approvedAt ? (
									<div className="simple-list-item">
										<div className="line bold">{i18n('task.approval.form.approvedAt')}</div>
										<div className="line">{format.datetime(executedApproval.approvedAt.toDate())}</div>
										<div className="line bold">{i18n('task.approval.form.reason')}</div>
										<div className="line">
											<div>{executedApproval.approvalReason}</div>
										</div>
									</div>
								) : (
									<div className="simple-list-item">
										<div className="line bold">{i18n('task.approval.form.reprovedAt')}</div>
										<div className="line">{format.datetime(executedApproval.reprovedAt.toDate())}</div>
										<div className="line bold">{i18n('task.approval.form.reason')}</div>
										<div className="line">{executedApproval.reprovalReason}</div>
									</div>
								)}
							</div>
						);
					})}
			</div>
		);
	};
	const renderSignatureForm = () => {
		if (!signingRequired) return;
		return (
			<div style={{ marginTop: 10 }}>
				<div style={{ marginBottom: 10 }}>{i18n("thisApprovalRequiresSignature")}</div>
				<div style={{ marginBottom: 10, fontSize: 14 }}>{i18n("pleaseSelectYourCertificate")}</div>
				<div>
					<FormControl style={{ width: '100%' }} variant="filled">
						<InputLabel>{i18n("yourCertificates")}</InputLabel>
						<Select value={null} onChange={(e) => setSelectedCert(e.target.value)}>
							<MenuItem value={''}></MenuItem>
							{pkiCertificates.map((cert, i) => {
								return (
									<MenuItem
										value={cert.thumbprint}
										key={i}
										style={{
											borderBottom: '1px solid #ddd',
										}}
									>
										<div>
											<div style={{ fontSize: 12, fontWeight: 'bold' }}>
												{cert.pkiBrazil.certificateType} - {cert.subjectName}
											</div>
											<div style={{ fontSize: 12 }}>{cert.issuerName}</div>
											{cert.validityEnd < new Date() && (
												<div style={{ fontSize: 12, fontWeight: 'bold' }}>Expirado</div>
											)}
										</div>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
			</div>
		);
	};
	const renderFormContent = () => (
		<div className="task-form task-form-main">
			<TaskCard
				isFinalPhase={true}
				key={taskStore.current.uid}
				parentStatus={''}
				auth={authStore}
				doc={{
					id: taskStore.current.uid,
					data: () => taskStore.current,
				}}
			/>
			{taskStore.current.expirationDateString && (
				<div style={{ color: '#666', marginBottom: 10 }}>
					{i18n('task.form.taskWillExpire', {
						date: format.date(new Date(taskStore.current.expirationDateString + ' 12:00')),
					})}
				</div>
			)}
			{!external && taskStore.current.uid !== 'startup' && (
				<div>
					<TaskComplementary task={taskStore.current} />
					<TaskShare task={taskStore.current} />
				</div>
			)}
			{!external && taskStore.current.uid !== 'startup' && (
				<div style={{ marginBottom: 15 }}>
					<TaskOwnerAbsent task={taskStore.current} hideTexts={true} />
				</div>
			)}
			<div style={{ marginBottom: 20, display: 'flex' }}>
				<Button className="simple-green-button" onClick={approve}>
					<Icon icon="faCheckCircle" style={{ marginRight: 10 }} />
					<span>{i18n('executionFlow.form.approve')}</span>
				</Button>
				<div style={{ flex: 1 }}></div>
				<Button className="simple-red-button" onClick={reprove}>
					<Icon icon="faTimesCircle" style={{ marginRight: 10 }} />
					<span>{i18n('executionFlow.form.repprove')}</span>
				</Button>
			</div>
			<div>
				<TextField
					variant="filled"
					style={{ width: '100%' }}
					multiline={true}
					rows={10}
					label={i18n('task.approval.form.reason')}
					InputLabelProps={{ shrink: true }}
					value={action.reason}
					onChange={onChange('reason')}
				/>
			</div>
			{renderSignatureForm()}
		</div>
	);

	const renderContent = () => {
		if (!external && taskStore.current.action.agent !== authStore.user.uid) {
			return renderInfoContent();
		}
		return taskStore.current.status !== 'pending' ? renderInfoContent() : renderFormContent();
	};

	return (
		<content>
			<header className="form-panel-header">
				<div>
					<Typography variant="h4">{i18n("workflow.form.actionTypes.approval.name")}</Typography>
					<Typography style={{ marginTop: 10 }}>
						{i18n('workflow.form.title')}
						{': '}
						{taskStore.current.workflowObject.document.title}
					</Typography>
					<div style={{ display: 'flex', justifyContent: 'space-around' }}>
						<simple-button
							style={{
								border: '2px solid #3c3',
								color: '#3c3',
								textDecoration: 'none',
								padding: 6,
								borderRadius: 4,
								fontSize: 12,
								backgroundColor: 'transparent',
								width: 'fit-content',
							}}
							onClick={(e) => {
								if (taskStore.current.action.hasFormReference) {
									let s = `A tarefa: ${taskStore.current.action.instructions || taskStore.current.action.formTitle
										}, do fluxo: https://workflux.digital//executionFlow/${taskStore.current.executionFlow}/task/${taskStore.current.action.type.type
										}/${taskStore.current.uid}\n
							Formulário: ${formName},\n
							Campos e valores do formulário\n
							${keys.map((k, i) => ` ${k} : ${values[i]}\n`)}`;
									window.open(`https://api.whatsapp.com/send?text=${encodeURI(s)}`);
								} else {
									window.open(`https://api.whatsapp.com/send?text=
							A tarefa ${taskStore.current.action.instructions || taskStore.current.action.formTitle} do fluxo https://workflux.digital//executionFlow/${taskStore.current.executionFlow
										}/task/${taskStore.current.action.type.type}/${taskStore.current.uid}
							`);
								}
							}}
						>
							<Icon icon="faWhatsapp" />
							{i18n("submitTask")}
						</simple-button>
						{hasHsmLicense && <simple-button
							style={{
								border: '2px solid #3c3',
								color: '#3c3',
								textDecoration: 'none',
								padding: 6,
								borderRadius: 4,
								fontSize: 12,
								backgroundColor: 'transparent',
								width: 'fit-content',
							}}
							onClick={(e) => {
								sendLinkHsm(e, taskStore.current)
							}}
						>
							<Icon icon="faWhatsapp" />
							{i18n("submitTask")} HSM
						</simple-button>}
					</div>
				</div>
				<div
					className="header-button"
					onClick={() => {
						if (typeof setMobileFormVisible === 'function') setMobileFormVisible(false);
					}}
				>
					<Icon icon="faTimes" />
				</div>
			</header>

			<div style={{ padding: 20 }}>{renderContent()}</div>
		</content>
	);
};

export default TaskApproval;
