import React from 'react';
import { Route, Switch, Router } from 'react-router';
import { history } from '@redux';

import ProtectedComponent from '@custom/ProtectedComponent';
import Dashboard from './screens/Dashboard';
import Authentication from './screens/Authentication';
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import WorkflowForm from './screens/workflow/form';
import ExecutionFlowList from './screens/executionFlow/ExecutionFlowList';
import CompanyAreaForm from './screens/companyArea/form';
import ExecutionFlowForm from './screens/executionFlow/view';
import Reports from './screens/reports/view';
import Unauthorized from './screens/Unauthorized';
import Profile from './screens/Profile';
import Collections from './screens/collections/list';
import ExternalAction from './screens/ExternalAction';
import KanbanBasic from './screens/KanbanBasic';
import SSO from './screens/SSO';
import { News } from './screens/news';
import { Calendar } from './screens/calendar';
import { DetailNews } from './screens/detailNews';
import { DetailCalendar } from './screens/detailCalendar';
import { Details } from '@mui/icons-material';

const CustomRoute = ({ key, Component, path, unprotected }) => {
	return (
		<Route
			exact={true}
			key={key}
			path={path}
			render={(props) => <ProtectedComponent {...props} Screen={Component} isProtected={!unprotected} />}
		/>
	);
};

/**
 * Routes list
 * Add new routes here
 * Min properties are [key] and [component]
 */
const routes = [
	{ key: 'profile', Component: Profile, path: '/profile' },
	{ key: 'news', Component: News, path: '/news' },
	{ key: 'news', Component: DetailNews, path: '/news/:id' },
	{ key: 'companyAreaForm', Component: CompanyAreaForm, path: '/companyArea/form' },
	{ key: 'workflowForm', Component: WorkflowForm, path: '/workflow/form/:id?' },
	{ key: 'executionFlowList', Component: ExecutionFlowList, path: '/workflow/list' },
	{ key: 'kanban1', Component: KanbanBasic, path: '/kanban/basic' },
	{ key: 'reports', Component: Reports, path: '/workflow/reports' },
	{ key: 'calendar', Component: Calendar, path: '/calendar' },
	{ key: 'calendar', Component: DetailCalendar, path: '/calendar/:id' },
	{ key: 'executionFlowForm', Component: ExecutionFlowForm, path: '/executionFlow/view/:id' },
	{
		key: 'TaskForm',
		Component: ExecutionFlowForm,
		path: '/executionFlow/:id/task/:type/:task',
	},
	{ key: 'collections', Component: Collections, path: '/collections' },
	{ key: 'external', Component: ExternalAction, path: '/external/:type/:token', unprotected: true },
	{ key: 'sso', Component: SSO, path: '/sso/:token', unprotected: true },
	{ key: 'unauthorized', Component: Unauthorized, path: '/unauthorized', unprotected: true },
	{ key: 'dashboard', Component: Dashboard, path: '/*' },
	{
		key: 'authentication',
		Component: Authentication,
		path: '/auth/:recoveryToken?',
		unprotected: true,
		hideUltrabar: true,
		hideAppdrawer: true
	},
	{
		key: "privacyPolicy",
		Component: PrivacyPolicy,
		path: "/privacy-policy",
		unprotected: true,
		hideUltrabar: true,
		hideAppdrawer: true
	},
	{
		key: "termsAndConditions",
		Component: TermsAndConditions,
		path: "/terms-and-conditions",
		unprotected: true,
		hideUltrabar: true,
		hideAppdrawer: true
	},
];

const Routes = () => {
	return (
		<Router history={history}>
			<Switch>
				{routes.map((route, i) => {
					return CustomRoute({
						...route,
					});
				})}
			</Switch>
		</Router>
	);
};

export default Routes;
