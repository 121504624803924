import settings from './settings';
import auth from './auth';
import spinner from './spinner';
import message from './message';
import company from './company';

const reducers = {
	settings: settings.reducer,
	auth: auth.reducer,
	spinner: spinner.reducer,
	message: message.reducer,
	company: company.reducer
};
const services = {
	settings: settings.service,
	auth: auth.service,
	spinner: spinner.service,
	message: message.service,
	company: company.service
};

export { reducers, services };
