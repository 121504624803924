import styles from './category.module.css';
import React, { useState } from 'react';
import List from '@material-ui/core/List';
import { ListItemText, ListItem } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { Button } from '@material-ui/core';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';

import { workfluxFirestore } from '../../../../@firebase-config';

export function Categories({ listCategory, closeModal, handleEdit, addCategory }) {

	const [loading, setLoading] = useState(false)

	const handleUpdate = async ({ categoryId, action }) => {
		setLoading(true)
		const categoryCollection = workfluxFirestore.collection("categoryNews");

		const refDoc = categoryCollection.doc(categoryId);
		try {
			const response = await refDoc.update({
				delete: action === "delete" ? true : false
			});
		} catch (error) {
		}
		setLoading(false)
	}
	const listActive = listCategory.filter((category) => !category.delete)

	return (
		<>
			<List style={{
				width: "100%",
				maxWidth: 400
			}}>
				{
					listActive.length === 0 ?
						(
							<section
								style={{
									width: "100%",
									textAlign: "center",
									marginBottom: 16,
									margintop: 16
								}}
							>
								<h3>Cadastre uma categoria</h3>
							</section>
						) :
						(
							listCategory.map((category) => (
								<ListItem key={category.id} styles={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between"
								}}>
									<ListItemText primary={category.category} secondary={category.delete ? "deletada" : "ativa"} />

									<section>
										{loading ? (
											<Button
												type="button"
												variant="contained"
												color="secondary"
												disabled={loading}
											>
												carregando...
											</Button>
										) : (
											category.delete ? (
												<IconButton
													type='button'
													aria-label="edit"
													color='primary'
													onClick={() => handleUpdate({ categoryId: category.id, action: "active" })}
												>
													<UndoIcon />
												</IconButton>

											) : (

												<>
													<IconButton
														aria-label="edit"
														color='primary'
														onClick={() => handleEdit({ category: category })}
													>
														<EditIcon />
													</IconButton>
													<IconButton
														aria-label="delete"
														color='delete'
														onClick={() => handleUpdate({ categoryId: category.id, action: "delete" })}
														loading={loading}
													>
														<DeleteIcon />
													</IconButton>
												</>
											)
										)}
									</section>
								</ListItem>
							))
						)}

				<section
					className={styles.areaButtons}

				>
					<Button
						type="button"
						variant="contained"
						color="secondary"
						onClick={closeModal}
					>
						Voltar
					</Button>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						onClick={addCategory}
					>
						Adicinar Categoria
					</Button>
				</section>
			</List>
		</>
	);
}
