import styles from './modal.module.css';
import React, { useRef } from 'react';

import {
  Button
} from '@material-ui/core';

export function Modal({ children, setRenderModal }) {

  const containerRef = useRef();

  const handleClick = (e) => {
    if (e.target === containerRef.current) {
      setRenderModal(false);
    }
  }

  return (
    <section
      className={styles.containerModal}
      onClick={(e) => handleClick(e)}
      ref={containerRef}
    >
      <section className={styles.content}>
        <section className={styles.contentHeader}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => setRenderModal(false)}
          >
            x
          </Button>
        </section>
        {children}
      </section>
    </section>
  )
}