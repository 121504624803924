import { useRef, useEffect } from "react";

/**
 * The same as setInterval
 * @param {(id: NodeJS.Timer) => void} callback - The function to be called on every interval, you can also clear the interval inside this function. 
 * @param {number | null} delay - The time interval in milliseconds to execute the function, if null, it clears the interval. 
 * @param {any[]=} triggers - If one of these values change, it will restart the interval.
 */
function useInterval(callback, delay, triggers = []) {
    const savedCallback = useRef(
        (/** @type {string | number | NodeJS.Timeout} */ id) => {
            clearInterval(id);
        }
    );

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay === null) {
            return;
        }

        const id = setInterval(() => {
            savedCallback.current(id);
        }, delay);

        return () => clearInterval(id);
    }, [delay, ...triggers]);
}

export default useInterval;