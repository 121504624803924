import React, { useState, useEffect } from 'react';
import firebase, { workfluxFirestore } from '@firebase-config';
import { appcenter, store as zerocodeStore, firebaseNewton, dokia as dokiaApi, DokiaComponents } from 'zerocode';
import { LinearProgressWithLabel } from '../../utils/linearProgressWithLabel';
import i18n, { format } from '@i18n';
import { store, services } from '@redux';
import { useSelector } from 'react-redux';
import { ExecutionFlow, Task, ExecutedDocument, Signing } from '@taugor/taugor-bpmn-models';
import {
	TextField,
	Typography,
	Button,
	MenuItem,
	FormControl,
	Select,
	InputLabel,
	Checkbox,
	FormControlLabel,
	Box,
	LinearProgress,
	withStyles,
} from '@material-ui/core';
import Api from '../../Api';
import { generateUid } from '@custom/common';
import Icon from '@custom/Icon';
import Message from '@custom/Message';
import Input from '@custom/Input';
import LacunaWebPKI from 'web-pki';
import TaskOwnerAbsent from './task.owner.absent.js';
import TaskComplementary from './task.complementary.js';
import TaskShare from './task.share.js';
import TaskCard from './TaskCard.js';
import EntityFieldsForm from './RenderField';
import { RenderField } from 'zerocode/dist/@components/dokia/entity/EntityForm';

const oSigningService = new Signing().getService(firebase, store, 'signingFormView');
const oTaskService = new Task().getService(firebase, store, 'taskDocumentInputView');
const oExecutedDocumentService = new ExecutedDocument().getService(firebase, store, 'taskViewExecutedDocument');
const taskCollection = workfluxFirestore.collection('task');

var pki = new LacunaWebPKI();

function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
}
let isSigning = false;

function onWebPkiError(message, error, origin) { }

function onWebPkiNotInstalled(status, message) {
	if (status === 1) return;

	pki.redirectToInstallPage();
}
let onWebPkiReady;
const TaskDocumentInput = ({ taskId, task, setMobileFormVisible, external, onSave }) => {
	const authStore = useSelector((s) => s.auth);
	const absenseStore = useSelector((s) => s.absenseStore);
	const taskStore = useSelector((s) => s.taskDocumentInputView);
	const signingStore = useSelector((s) => s.signingFormView);
	const executedDocumentStore = useSelector((s) => s.taskViewExecutedDocument);

	const [reactions, setReactions] = useState([]);
	const [ged17Reaction, setGed17Reaction] = useState('');
	const [ged17Fields, setGed17Fields] = useState([]);
	const [signingRequired, setSigningRequired] = useState(false);
	const [pkiStarted, setPkiStarted] = useState([]);
	const [pkiCertificates, setPkiCertificates] = useState([]);
	const [discussions, setDiscussions] = useState([]);
	const [selectedCert, setSelectedCert] = useState('');
	const [dokiaReaction, setDokiaReaction] = useState('');
	const [dokiaEntities, setDokiaEntities] = useState([]);
	const [classification, setClassification] = useState('');
	const [dokiaDocument, setDokiaDocument] = useState({
		meta: {
			code: generateUid,
			fields: {},
		},
		name: '',
		size: 0,
	});
	const [checklist, setChecklist] = useState(false);

	const [files, setFiles] = useState([]);
	const [description, setDescription] = useState(files.map((i) => ''));
	const [hasHsmLicense, setHasHsmLicense] = useState(false);
	const appcenterStore = zerocodeStore.getState().appcenter;

	useEffect(() => {
		if (appcenterStore.licenses) {
			const hasHsmLicense = appcenterStore.licenses.some((license) => license.type === 'workflux-hsm');
			setHasHsmLicense(hasHsmLicense);
		}
	}, [appcenterStore.licenses]);

	const onWebPkiReady = function () {
		setPkiStarted(true);
		let c = [];
		pki.listCertificates().success(function (certs) {
			for (var i = 0; i < certs.length; i++) {
				var cert = certs[i];
				c.push(cert);
			}
			setPkiCertificates(c);
		});
	};
	useEffect(() => {
		pki.init({
			ready: onWebPkiReady,
			notInstalled: onWebPkiNotInstalled,
			defaultError: onWebPkiError,
		});
	}, []);

	useEffect(() => {
		if (!taskId) return;
		return taskCollection
			.doc(taskId)
			.collection('discussion')
			.where('deleted', '==', false)
			.onSnapshot((snapshot) => {
				setDiscussions(snapshot.docs.map((d) => d.data()));
			});
	}, [, taskStore.current.uid]);

	const onTaskGet = (task) => {
		let r = [];
		task.workflowObject.phases.map((phase) => {
			phase.reactions.map((reaction) => {
				if (reaction.originDocument === task.action.uid) {
					r.push(reaction);
					//('DOKIA REACTION', reaction);
					if (reaction.type.type === 'dokia' && !reaction.deleted) {
						setDokiaReaction(reaction);
					}
					if (reaction.type.type === 'ged17' && !reaction.deleted) {
						setGed17Reaction(reaction);
						//('REACTION', reaction);
						Api.ged17.authenticate(reaction.ged17User, reaction.ged17Password, reaction.ged17UrlGed).then((r) => {
							Api.ged17
								.getFields(
									reaction.ged17Config.classification.identifier,
									reaction.ged17Config.company.identifier
								)
								.then((r) => {
									//('FIELDS', r);
									setGed17Fields(r);
								});
						});
					}
				}
			});
		});
		setReactions(r);
	};
	useEffect(() => {
		if (taskId || !task) return;
		taskStore.current = task;
		onTaskGet(task);
	}, [task]);

	useEffect(() => {
		//('dokiaReaction', dokiaReaction);
		if (!dokiaReaction) return;
		let last = dokiaReaction.dokiaInfo.classification[dokiaReaction.dokiaInfo.classification.length - 1];

		dokiaApi.oi.classification.get(last.uid).then((classification) => {
			setDokiaDocument({
				...dokiaDocument,
				fields: classification.fields,
			});
			setClassification(classification);
		});
	}, [dokiaReaction]);

	useEffect(() => {
		if (!taskId) return;
		services.spinner.show();
		oTaskService
			.get(taskId)
			.then((task) => {
				onTaskGet(task);
			})
			.then(services.spinner.hide);
		onWebPkiReady();
	}, [taskId]);

	let progress = 100;
	if (checklist && checklist.length > 0) {
		progress = (checklist.filter((a) => a.finished).length * 100) / checklist.length;
	}

	useEffect(() => {
		if (!taskStore.current || !taskStore.current.uid) return;
		oExecutedDocumentService
			.filter([
				[
					['action.uid', '==', taskStore.current.action.uid],
					['executionFlow', '==', taskStore.current.executionFlow],
					['company', '==', authStore.company.uid],
				],
			])
			.list();
		setSigningRequired(taskStore.current.action.isSigningRequired);
	}, [taskStore.current]);

	useEffect(() => {
		if (!taskId) return;
		let checklistDoc = taskCollection.doc(taskId).collection('checklist').doc(taskId);
		checklistDoc.get().then((d) => {
			let data = {
				actions: [],
			};
			if (d.exists) {
				data = d.data();
			}
			setChecklist(data.actions);
		});
	}, [, taskStore.current.uid]);

	if (!taskStore.current || !taskStore.current.uid) return <div></div>;

	if (taskStore.current.status && taskStore.current.status !== 'completed' && taskStore.current.agent !== authStore.user.uid) {
		let isAbsent = false;
		absenseStore.list.map((absense) => {
			let today = new Date();
			if (absense.dateStart.toDate() <= today && absense.dateEnd.toDate() >= today) {
				isAbsent = true;
			}
		});
		if (isAbsent) {
			return <TaskOwnerAbsent task={taskStore.current} />;
		}
	}
	const saveMessage = () => {
		services.spinner.hide();
		services.message.show('Tarefa concluída com sucesso');
	};

	const sendLinkHsm = async (e, task) => {
		e.preventDefault();
		e.stopPropagation();

		let phone = '';

		await new Promise((resolve) => {
			services.message.show(
				'Enviar link de assinatura por HSM',
				<div>
					<div
						style={{
							marginBottom: 16,
						}}
					>
						Digite o número do celular:
					</div>
					<Input
						mode="outlined"
						type="text"
						label={'Número de telefone'}
						mask="(99) 99999-9999"
						onChange={(e) => (phone = e.target.value)}
					/>
				</div>,
				[
					{
						text: 'confirm',
						onClick: (e) => resolve(),
					},
					{
						text: 'cancel',
						onClick: (e) => resolve(),
					},
				],
				resolve
			);
		});

		services.message.hide();

		services.spinner.show();

		phone = `55${phone.replace(/[^0-9]/g, '')}`;

		const data = {
			to: phone,
			namespace: '86ae2fa8_6186_4b8c_81af_8d7b71b3a2d0',
			elementName: 'voc_recebeu_uma_tarefa_real',
			components: [
				{
					type: 'body',
					parameters: [
						{
							type: 'text',
							text: task.action.agentName,
						},
						{
							type: 'text',
							text: `${task.workflowObject.document.title || ''} - ${task.action.type.name || ''}`.trim(),
						},
						{
							type: 'text',
							text: task.action.instructions,
						},
						{
							type: 'text',
							text: `https://workflux.digital//executionFlow/${taskStore.current.executionFlow}/task/${taskStore.current.action.type.type}/${taskStore.current.uid}`,
						},
					],
				},
			],
		};

		try {
			await Api.hsm.sendHsm(data);

			services.spinner.hide();
			services.message.show('Link enviado com sucesso!', `O link foi enviado para o número +${phone}.`);
		} catch (err) {
			console.log(err);

			services.spinner.hide();
			services.message.show(
				'Ocorreu um erro ao enviar o link.',
				`Confira se o número +${phone} está certo e entre em contato com o suporte.`
			);
		}
	};

	const sendFile = async (e) => {
		e.preventDefault();

		let fileElement = document.getElementById('file');
		if (!fileElement || !fileElement.files.length) {
			services.message.show(i18n('task.documentInput.form.needsFile'));
			return;
		}

		if (signingRequired && !selectedCert) {
			services.message.show(i18n('task.form.reproval.needsSigning'));
			return;
		}
		let sendFilePromise = Promise.resolve();

		if (signingRequired) {
			sendFilePromise = new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.addEventListener(
					'load',
					() => {
						services.spinner.show();
						Api.executionFlow.task.signedDocumentInput
							.start({ fileBase64: reader.result })
							.then((r) => {
								pki.signWithRestPki({
									token: r.data.token,
									thumbprint: selectedCert,
								}).success(function (token) {
									Api.executionFlow.task.signedDocumentInput
										.complete({ token: token })
										.then((r) => {
											Api.executionFlow.task.signedDocumentInput
												.saveReference({
													task: taskStore.current,
													location: r.data.mediaLink,
													ged17Fields,
												})
												.finally((e) => {
													resolve();
												});
										})
										.catch((r) => {
											reject();
											services.spinner.hide();
											services.message.show(
												'Não foi possível assinar seu documento. Por favor verifique o certificado'
											);
										});
								});
							})
							.catch((r) => {
								reject();
								services.spinner.hide();
								services.message.show(
									'Não foi possível assinar seu documento. Por favor verifique o certificado'
								);
							});
					},
					false
				);

				reader.readAsDataURL(fileElement.files[0]);
			});
		} else {
			if (taskStore.current.action.isMultiple) {
				let i = 0;
				services.spinner.show();
				for (let file of files) {
					let task = {
						...taskStore.current,
						currentDocumentDescription: description[i],
					};

					sendFilePromise = new Promise((resolve, rejec) => {
						Api.executionFlow.task
							.documentInput({
								task,
								file: file,
								ged17Fields,
							})
							.finally((e) => {
								resolve();
							});
					});

					await sendFilePromise.then(async (r) => {
						if (dokiaReaction) {
							file.meta = {
								fields: dokiaDocument.fields,
							};
							dokiaApi.document
								.save({
									hierarchy: [
										dokiaReaction.dokiaInfo.organization,
										dokiaReaction.dokiaInfo.company,
										dokiaReaction.dokiaInfo.subsidiary,
										...dokiaReaction.dokiaInfo.classification,
									],
									file: file,
									onProgressUpdate: () => { },
								})
								.then((r) => {
									console.log('enviou');
									window.location.reload();
									/* saveMessage(); */
								})
								.catch((r) => {
									console.log(' nao enviou');
									//('ERROR AT DOKIA', r);
								});
							return;
						}
					});
					i++;
				}
				services.spinner.hide();
				saveMessage();
			} else {
				let file = fileElement.files[0];

				sendFilePromise = new Promise((resolve, rejec) => {
					services.spinner.show();
					Api.executionFlow.task
						.documentInput({
							task: taskStore.current,
							file: file,
							ged17Fields,
						})
						.finally((e) => {
							resolve();
						});
				});
				sendFilePromise.then(async (r) => {
					if (dokiaReaction) {
						let file = fileElement.files[0];
						file.meta = {
							fields: dokiaDocument.fields,
						};
						dokiaApi.document
							.save({
								hierarchy: [
									dokiaReaction.dokiaInfo.organization,
									dokiaReaction.dokiaInfo.company,
									dokiaReaction.dokiaInfo.subsidiary,
									...dokiaReaction.dokiaInfo.classification,
								],
								file: file,
								onProgressUpdate: () => { },
							})
							.then((r) => {
								saveMessage();
							})
							.catch((r) => {
								//('ERROR AT DOKIA', r);
							});
						return;
					}
					saveMessage();
				});
			}
		}
	};

	const renderInfoContent = () => {
		return (
			<div>
				{taskStore.current.status === 'review' ? (
					<div>
						{taskStore.current.action.agent !== authStore.user.uid && (
							<div style={{ marginBottom: 20 }}>
								{i18n('task.form.responsible')}: {taskStore.current.action.agentName}
							</div>
						)}
						<div style={{ marginBottom: 20 }}>{i18n('task.form.inReview')}</div>
					</div>
				) : (
					<div>
						<div style={{ marginBottom: 20 }}>
							{i18n('task.form.responsible')}: {taskStore.current.action.agentName}
						</div>
						<div style={{ color: '#666', marginBottom: 10 }}>{i18n('task.form.instructions')}</div>
						<div style={{ marginBottom: 20 }}>
							{taskStore.current.action.instructions || i18n('task.form.noInstructions')}
						</div>
					</div>
				)}
				{!external && taskStore.current.uid !== 'startup' && (
					<div className="absent-panel">
						<TaskOwnerAbsent task={taskStore.current} hideTexts={true} />
					</div>
				)}
				{taskStore.current.expirationDateString && (
					<div style={{ color: '#666', marginBottom: 10 }}>
						{i18n('task.form.taskWillExpire', {
							date: format.date(new Date(taskStore.current.expirationDateString + ' 12:00')),
						})}
					</div>
				)}
				{taskStore.current.action.complementary && (
					<div className="complementary-task-warning">{i18n('executionFlow.form.complementaryTask')}</div>
				)}

				{taskStore.current.status === 'review' && taskStore.current.action.agent === authStore.user.uid && (
					<div style={{ marginBottom: 20 }}>
						<Button
							style={{ marginBottom: 20 }}
							className="simple-filled-button"
							onClick={() => {
								services.spinner.show();
								Api.executionFlow.task
									.confirmReview({ task: taskStore.current.uid })
									.finally(services.spinner.hide);
							}}
						>
							{i18n('task.form.inReview.useCurrentInfo')}
						</Button>
						{renderFormContent()}
					</div>
				)}

				{!!checklist && checklist.length > 0 && (
					<div className="progress-bar">
						<p>{i18n("activityChecklist")}:</p>
						<LinearProgressWithLabel value={progress} />
					</div>
				)}

				{!!checklist && (
					<div className="task-checklist-actions" style={{ marginBottom: 15 }}>
						{checklist.map((action, i) => {
							return (
								<div className="checklist-action" style={{ color: '#666', marginBottom: 5 }} key={i}>
									{action.finished ? (
										<>
											<Icon icon="faCheck" />{' '}
										</>
									) : (
										<>
											<Icon icon="faTimes" />{' '}
										</>
									)}
									{action.name}
								</div>
							);
						})}
					</div>
				)}

				{!!discussions[0] && (
					<div className="discussion-content">
						<p className="title">{i18n("workflow.kanban.card.step.discussion")}:</p>
						{discussions.map((discussion) => {
							return (
								<div className="discussion">
									<div className="name">{discussion.user.name}:</div>
									<div className="content">{discussion.comment}</div>
								</div>
							);
						})}
					</div>
				)}
				{executedDocumentStore.list
					.sort((a, b) => {
						if (a.createdAt.toDate() < b.createdAt.toDate()) return 1;
						if (a.createdAt.toDate() > b.createdAt.toDate()) return -1;
						return 0;
					})
					.map((executedDocument, i) => {
						return (
							<div
								style={{
									borderBottom: '1px solid #ddd',
									padding: 15,
									background: i == 0 ? '#fafffa' : '',
								}}
								key={i}
							>
								<div style={{ display: 'flex', alignItems: 'flex-start' }}>
									<div style={{ paddingRight: 10, flex: 1 }}>
										<label style={{ color: '#666' }}>{i18n('task.documentInput.form.uploadedAt')}</label>
										<div>{format.datetime(executedDocument.createdAt.toDate())}</div>
									</div>

									<Button
										className="simple-filled-button"
										onClick={() => {
											window.open(executedDocument.location);
										}}
									>
										<label>{i18n('task.documentInput.form.downloadFile')}</label>
										<Icon icon="faFileDownload" style={{ marginLeft: 15 }} />
									</Button>
								</div>
							</div>
						);
					})}
			</div>
		);
	};
	const renderSignatureForm = () => {
		if (!signingRequired) return;

		if (!pkiCertificates.length) {
			// onWebPkiReady();
		}
		return (
			<div style={{ marginTop: 10, marginBottom: 15 }}>
				<div className="certificate-required-message">{i18n("thisApprovalRequiresSignature")}</div>
				<div className="select-certificate-message">{i18n("pleaseSelectYourCertificate")}</div>
				<div className="certificate-controls">
					<FormControl style={{ width: '100%' }} variant="filled">
						<InputLabel>{i18n("yourCertificates")}</InputLabel>
						<Select value={selectedCert} onChange={(e) => setSelectedCert(e.target.value)}>
							<MenuItem value={''}></MenuItem>
							{pkiCertificates.map((cert, i) => {
								return (
									<MenuItem
										value={cert.thumbprint}
										key={i}
										style={{
											borderBottom: '1px solid #ddd',
										}}
									>
										<div>
											<div style={{ fontSize: 12, fontWeight: 'bold' }}>
												{cert.pkiBrazil.certificateType} - {cert.subjectName}
											</div>
											<div style={{ fontSize: 12 }}>{cert.issuerName}</div>
											{cert.validityEnd < new Date() && (
												<div style={{ fontSize: 12, fontWeight: 'bold' }}>Expirado</div>
											)}
										</div>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
			</div>
		);
	};
	const renderFormContent = () => (
		<div className="task-form task-form-main">
			<TaskCard
				isFinalPhase={true}
				key={taskStore.current.uid}
				parentStatus={''}
				auth={authStore}
				doc={{
					id: taskStore.current.uid,
					data: () => taskStore.current,
				}}
			/>
			{/* <div className="form-info agent-name">
				<Icon icon="faUser" />
				{taskStore.current.action.agentName}
			</div>
			<div className="form-info instructions">
				<Icon icon="faInfo" />
				{taskStore.current.action.instructions || i18n('task.form.noInstructions')}
			</div> */}

			{taskStore.current.expirationDateString && (
				<div className="form-info expiration-date">
					<Icon icon="faCalendarDay" />
					{i18n('task.form.taskWillExpire', {
						date: format.date(new Date(taskStore.current.expirationDateString + ' 12:00')),
					})}
				</div>
			)}
			{!external && taskStore.current.uid !== 'startup' && (
				<div>
					<TaskComplementary task={taskStore.current} />
					<TaskShare task={taskStore.current} />
				</div>
			)}

			{!external && taskStore.current.uid !== 'startup' && (
				<div className="absent-panel">
					<TaskOwnerAbsent task={taskStore.current} hideTexts={true} />
				</div>
			)}
			<form onSubmit={sendFile}>
				<div className="send-file-content">
					<div className="send-file-control" style={{ marginBottom: 20 }}>
						<input
							type="file"
							id="file"
							style={{ margin: 15 }}
							multiple={taskStore.current.action.isMultiple}
							onChange={(e) => {
								setFiles(Array.from(e.target.files));
							}}
						/>
					</div>
				</div>
				{taskStore.current.action.isMultiple && !!files.length && (
					<div>
						{files.map((file, i) => {
							return (
								<Input
									label={`Descrição de Envio do arquivo '${file.name}'`}
									onChange={(e) => {
										let descriptions = description;
										descriptions[i] = e.target.value;
										setDescription(descriptions);
									}}
								/>
							);
						})}
						<FormControlLabel
							style={{ width: '100%' }}
							label={'Concluir atividade, no envio'}
							className="checkbox-control"
							control={
								<Checkbox
									checked={taskStore.current.finishUpload}
									onChange={(e) => {
										taskStore.current.finishUpload = e.target.checked;
									}}
								/>
							}
						/>
					</div>
				)}

				{dokiaReaction && !!classification.uid && (
					<div>
						<h5>{classification.title} </h5>
						{Object.values(classification.fields).map((entity) => {
							return (
								<EntityFieldsForm
									document={dokiaDocument}
									entity={entity}
								/* onChange={(e) => {}}
								validateRequired={false} */
								/>
							);
						})}
					</div>
				)}

				{!!ged17Reaction && (
					<div className="ged-17-content">
						<div className="form-info">
							<Icon icon="faUpload" />
							<div>
								Classificação no GED
								<div className="">
									{ged17Reaction.ged17Config.workspace.title + ' > '}
									{ged17Reaction.ged17Config.organizationUnit.title + ' > '}
									{ged17Reaction.ged17Config.company.title}
									{' >'} {ged17Reaction.ged17Config.classification.title}
								</div>
							</div>
						</div>
						<div style={{ maginTop: 30, marginBottom: 30 }}>
							{ged17Fields.map((field, i) => {
								if (field.value === undefined) {
									field.value = '';
								}

								if (['Title', 'Tags', 'DocumentDate'].indexOf(field.internalName) > -1) {
									return;
								}
								let content = '';

								switch (field.type) {
									// IntegerType = 1
									case 1:
										content = (
											<Input
												mode={'top-label field-fill-input'}
												label={field.title}
												value={field.value}
												style={{ width: '100%' }}
												type="number"
												onChange={(e) => {
													field.value = e.target.value;
													setGed17Fields([...ged17Fields]);
												}}
												required={field.definitions.required}
											/>
										);
										break;
									// TextType = 2
									case 2:
										break;
									// NoteType = 3
									case 3:
										break;
									// DateTimeType = 4
									case 4:
										content = (
											<Input
												style={{ width: '100%' }}
												mode={'top-label field-fill-input'}
												label={field.title}
												value={field.value}
												type="datetime-local"
												onChange={(e) => {
													field.value = e.target.value;
													setGed17Fields([...ged17Fields]);
												}}
												pattern={'\\d+'}
												required={field.definitions.required}
											/>
										);
										break;
									// CounterType = 5
									case 5:
										break;
									// ChoiceType = 6
									case 6:
										content = (
											<div className="field-select-ged">
												<TextField
													style={{ width: '100%' }}
													label={field.title}
													required={field.definitions.required}
													onChange={(e) => {
														field.value = e.target.value;
														setGed17Fields([...ged17Fields]);
													}}
													value={field.value}
													select
												>
													<MenuItem value={''}>{''}</MenuItem>;
													{field.definitions.options.map((option, i) => {
														return <MenuItem value={option}>{option}</MenuItem>;
													})}
												</TextField>
											</div>
										);
										break;
									// LookupType = 7
									case 7:
										break;
									// BooleanType = 8
									case 8:
										if (typeof field.value === 'string') {
											field.value = false;
										}
										content = (
											<FormControlLabel
												style={{ width: '100%' }}
												label={field.title}
												className="checkbox-control"
												control={
													<Checkbox
														checked={field.value}
														onChange={(e) => {
															field.value = e.target.checked;
															setGed17Fields([...ged17Fields]);
														}}
														required={field.definitions.required}
													/>
												}
											/>
										);
										break;
									// NumberType = 9
									case 9:
										content = (
											<Input
												style={{ width: '100%' }}
												mode={'top-label field-fill-input'}
												label={field.title}
												value={field.value}
												type="number"
												// pattern="[-+]?[0-9]*[.,]?[0-9]+"
												pattern="[0-9]+([\.,][0-9]+)?"
												inputmode="numeric"
												step={1 / 10 ** field.definitions.decimalPlaces}
												onChange={(e) => {
													field.value = e.target.value;
													setGed17Fields([...ged17Fields]);
												}}
												required={field.definitions.required}
											/>
										);
										break;
									// CurrencyType = 10
									case 10:
										break;
									// URLType = 11
									case 11:
										break;
									// MultiChoiceType = 12
									case 12:
										break;
									// UserType = 13
									case 13:
										break;
								}
								if (content) {
									return (
										<div key={i}>
											{field.definitions.required && <div style={{ fontSize: 12 }}>Obrigatório</div>}
											<div>{content}</div>
										</div>
									);
								}

								return (
									<div key={i} className="field">
										<TextField
											style={{ width: '100%' }}
											label={field.title}
											value={field.value}
											onChange={(e) => {
												field.value = e.target.value;
												setGed17Fields([...ged17Fields]);
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
				)}
				<div>{renderSignatureForm()}</div>
				{taskStore.current.uid !== 'startup' && (
					<Button className="simple-filled-button" type="submit">
						<Icon icon="faFileUpload" style={{ marginRight: 15 }} />
						{signingRequired
							? i18n('task.documentInput.form.sendSignedFile')
							: i18n('task.documentInput.form.sendFile')}
					</Button>
				)}
			</form>
		</div>
	);
	const renderContent = () => {
		if (!external && taskStore.current.action.agent !== authStore.user.uid) {
			return renderInfoContent();
		}

		return taskStore.current.status !== 'pending' ? renderInfoContent() : renderFormContent();
	};
	return (
		<content className="type-documentInput">
			<header className="form-panel-header">
				<div>
					<Typography variant="h4">{i18n("task.documentInput.type")}</Typography>
					<Typography style={{ marginTop: 10 }}>
						{i18n('workflow.form.title')}
						{': '}
						{taskStore.current.workflowObject.document.title}
					</Typography>
					<div style={{ display: 'flex', justifyContent: 'space-around' }}>
						<simple-button
							style={{
								border: '2px solid #3c3',
								color: '#3c3',
								textDecoration: 'none',
								padding: 6,
								borderRadius: 4,
								fontSize: 12,
								backgroundColor: 'transparent',
								width: 'fit-content',
							}}
							onClick={() => {
								window.open(`https://api.whatsapp.com/send?text=
								A tarefa ${taskStore.current.action.instructions || taskStore.current.action.formTitle
									} do fluxo https://workflux.digital//executionFlow/${taskStore.current.executionFlow}/task/${taskStore.current.action.type.type
									}/${taskStore.current.uid}
								`);
							}}
						>
							<Icon icon="faWhatsapp" />
							{i18n("submitTask")}
						</simple-button>

						{hasHsmLicense && (
							<simple-button
								style={{
									border: '2px solid #3c3',
									color: '#3c3',
									textDecoration: 'none',
									padding: 6,
									borderRadius: 4,
									fontSize: 12,
									backgroundColor: 'transparent',
									width: 'fit-content',
								}}
								onClick={(e) => {
									// console.log(taskStore.current)
									sendLinkHsm(e, taskStore.current);
								}}
							>
								<Icon icon="faWhatsapp" />
								{i18n("submitTask")} HSM
							</simple-button>
						)}
					</div>
				</div>
				<div
					className="header-button"
					onClick={() => {
						if (typeof setMobileFormVisible === 'function') setMobileFormVisible(false);
					}}
				>
					<Icon icon="faTimes" />
				</div>
			</header>
			<div style={{ padding: 20 }}>{renderContent()}</div>
		</content>
	);
};

export default TaskDocumentInput;
