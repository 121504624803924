const SET_LANGUAGE = "SETTINGS_SET_LANGUAGE";
const START_USER_TOUR = "SETTINGS_START_USER_TOUR";
const END_USER_TOUR = "SETTINGS_END_USER_TOUR";

const service = dispatch => ({
	setLanguage: language => {
		dispatch({
			type: SET_LANGUAGE,
			language
		});
	},
	startTour: () => {
		dispatch({
			type: START_USER_TOUR
		});
	},
	endTour: () => {
		dispatch({
			type: END_USER_TOUR
		});
	}
});

const locale =
	localStorage.getItem("chosen-locale") ||
	new Intl.NumberFormat().resolvedOptions().locale;

const INIT_STATE = {
	isUserTourHappening: false,
	locale: locale,
	languages: [
		{
			languageId: "en-us",
			locale: "en-US",
			name: "English",
			icon: "en-us"
		},
		{
			languageId: "pt-br",
			locale: "pt-BR",
			name: "Português",
			icon: "pt-br"
		}
	]
};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case START_USER_TOUR:
			return { ...state, isUserTourHappening: true };

		case END_USER_TOUR:
			return { ...state, isUserTourHappening: false };

		case SET_LANGUAGE:
			return { ...state, locale: action.language };

		default:
			return { ...state };
	}
};

export default { reducer, service };
