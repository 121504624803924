import iconDokplus from '../@assets/images/icon-dokplus.png'
import iconYopenLight from '../@assets/images/Yopen-laranja.ico'
import iconYopenDark from '../@assets/images/Yopen-Branco.png'
import iconAppcenter from '../@assets/images/Appcenter.svg'
import iconEditFy from '../@assets/images/EditFy.svg'
import iconInbox from '../@assets/images/Inbox.svg'
import iconWorkflux from '../@assets/images/Workflux.svg'
import iconIndexia from '../@assets/images/Indexia.svg'

export const fixTimezone = (d) => {
	let date = new Date(d.getTime())
	date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
	return date
}
export const toDate = (value, extraLog) => {
	if (value && typeof value === 'object' && value.toDate) {
		let date = value.toDate()
		return date
	}
	if (value && typeof value === 'object' && value._seconds && value._nanoseconds !== undefined) {
		let date = new Date(parseInt(value._seconds + '' + ('000' + value._nanoseconds).slice(-3).substr(0, 3)))
		return date
	}
	if (value && typeof value === 'object' && value.seconds && value.nanoseconds !== undefined) {
		let date = new Date(parseInt(value.seconds + '' + ('000' + value.nanoseconds).slice(-3).substr(0, 3)))
		return date
	}
	if (value instanceof Date) {
		return value
	}
	if (typeof value === 'string' && value !== '') {
		return fixTimezone(new Date(value))
	}
	return value
}
export const objectHasProps = (object, props) => {
	let has = true
	props.find((prop) => {
		if (!object[prop]) {
			has = false
			return prop
		}
	})
	return has
}

export const getChunks = (array, size) => {
	let chunks = []
	let i, j
	for (i = 0, j = array.length; i < j; i += size) {
		chunks.push(array.slice(i, i + size))
	}
	return chunks
}

export const groupByItemProperty = (array, property, reduceItem) => {
	reduceItem = typeof reduceItem === 'function' ? reduceItem : (o) => o
	const result = {}
	;(array || []).map((item) => {
		let propValue = getPropFromPath(item, property)
		if (!result[propValue]) {
			result[propValue] = []
		}
		result[propValue].push(reduceItem(item))
	})
	return result
}

const randInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
export const uid = (prefix = '') => {
	let a = Math.ceil(Math.random() * 100000000000).toString(32)
	let b = new Date().getTime().toString(32)
	let c = Math.ceil(Math.random() * (32 - 28) + 28)
	let d = prefix + a + b + c
	let length = d.length
	for (let i = 0; i < length - 5; i++) {
		let index = randInterval(0, length)
		d = d.substr(0, index) + d.substr(index, 1).toUpperCase() + d.substr(index + 1)
	}
	return d
}
export const smallUid = (prefix = '') => {
	let a = Math.ceil(Math.random() * 1000000000).toString(32)
	let b = '' //new Date().getTime().toString(32);
	let c = randInterval(10, 99)
	let d = prefix + a + b + c
	return d.toUpperCase()
}

export const getAppImageSrc = (app, theme) => {
	const isLight = !theme || theme === 'light'

	switch (app) {
		case 'appcenter':
		case 'appcenter-customer':
			return isLight ? iconAppcenter : iconAppcenter
		case 'dokia':
			return isLight ? iconDokplus : iconDokplus
		case 'collectame':
			return isLight ? iconYopenLight : iconYopenDark
		case 'workflux':
		case 'workflux-portal':
			return isLight ? iconWorkflux : iconWorkflux
		case 'lawdoc':
			return isLight ? iconEditFy : iconEditFy
		case 'inboxticket':
			return isLight ? iconInbox : iconInbox
		case 'indexia-web':
		case 'indexia':
			return isLight ? iconIndexia : iconIndexia
	}
}
export const playSound = (url) => {
	const audio = new Audio(url)
	audio.volume = 0.2
	audio.play()
}
