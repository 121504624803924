import React, { useState, useEffect } from 'react';
import firebase from '@firebase-config';
import i18n, { format } from '@i18n';
import { store, services } from '@redux';
import { useSelector, connect } from 'react-redux';
import { ExecutionFlow, Task, ExecutedDocument, Absense, Signing } from '@taugor/taugor-bpmn-models';
import Icon from '@custom/Icon';
import { TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Company, Group, User } from '@taugor/taugor-appcenter-sdk-js';
import '@assets/sass/task.absent.scss';
import Api from '../../Api';
import { store as zerocodeStore } from 'zerocode';
import { Autocomplete } from '@material-ui/lab';

const oTaskService = new Task().getService(firebase, store, 'taskDocumentInputView');
const oAbsenseService = new Absense().getService(firebase);

const TaskOwnerAbsent = ({ auth, task, absenseStore, hideTexts, executionFlowStore }) => {
	const [group, setGroup] = useState('');
	const [member, setMember] = useState('');
	const [delegateVisible, setDelegateVisible] = useState(false);

	if (!task || !task.uid || task.status === 'completed' || !executionFlowStore) return <div></div>;

	const takeResponsabilityMessage = () => {
		services.message.show(
			i18n('take.responsibility.header.title'),
			<div>
				<div>{i18n('take.responsibility.confirmation')}</div>
				<div className="buttons" style={{ marginTop: 10 }}>
					<simple-button onClick={takeResponsability}>{i18n('confirm')}</simple-button>
					<simple-button onClick={services.message.hide}>{i18n('cancel')}</simple-button>
				</div>
			</div>
		);
	};
	const takeResponsability = () => {
		services.spinner.show();
		Api.updateTaskOwner(task.uid, {
			uid: auth.user.uid,
			agentName: auth.user.firstName + ' ' + auth.user.lastName,
			agentEmail: auth.user.email,
		})
			.then((r) => {
				services.message.show(i18n('take.responsibility.takenSuccessfuly'));
			})
			.finally(services.spinner.hide);
	};
	const delegateResponsabilityMessage = () => {
		setDelegateVisible(true);
	};
	const delegateResponsability = () => {
		services.spinner.show();
		Api.updateTaskOwner(task.uid, {
			uid: member.uid,
			agentName: member.firstName + ' ' + member.lastName,
			agentEmail: member.email,
		})
			.then((r) => {
				services.message.show(i18n('take.responsibility.takenSuccessfuly'));
			})
			.finally(services.spinner.hide);
	};
	const appcenterStore = zerocodeStore.getState().appcenter;

	return (
		<div className="task-responsability">
			{!hideTexts && (
				<div>
					<div className="title">{i18n('task.owner.absent.message')}</div>
					<div className="subtitle">{i18n('task.owner.absent.chosenew')}</div>
					<div className="subtitle" style={{ marginTop: 20 }}>
						{task.action.type.name}
					</div>
					<div>
						<div style={{ marginBottom: 20 }}>
							{i18n('task.form.responsible')}: {task.action.agentName}
						</div>
						<div style={{ color: '#666', marginBottom: 10 }}>{i18n('task.form.instructions')}</div>
						<div style={{ marginBottom: 20 }}>{task.action.instructions || i18n('task.form.noInstructions')}</div>
					</div>
					{/* 					
					{task.expirationDateString && (
						<div style={{ color: '#666', marginBottom: 10 }}>
							{i18n('task.form.taskWillExpire', {
								date: format.date(new Date(task.expirationDateString + ' 12:00')),
							})}
						</div>
					)} */}
				</div>
			)}

			{task.status !== 'completed' &&
				(auth.user.companyadmin || task.action.canDelegate || auth.user.uid === executionFlowStore.current.agent) && (
					<div>
						{auth.user.uid !== task.agent && (
							<div className="complementary-task-panel task-c">
								<simple-button
									class="responsability-action-take task-action"
									iconed
									tooltip={i18n('take.responsibility.take')}
									onClick={takeResponsabilityMessage}
								>
									<Icon icon="faUserPlus" />
								</simple-button>
							</div>
						)}

						<div className="complementary-task-panel task-d">
							<simple-button
								class="responsability-action-pass task-action"
								iconed
								onClick={delegateResponsabilityMessage}
								tooltip={i18n('take.responsibility.pass')}
							>
								<Icon icon="faUsers" />
							</simple-button>
						</div>
					</div>
				)}
			{delegateVisible && (
				<div className="floating-message visible">
					<div className="content">
						<div className="header">{i18n('take.responsibility.executionResponsible')}</div>
						<div className="description">
							<div style={{ height: '50vh', width: '45vw' }}>
								<div>{i18n('take.responsibility.choseResponsible')}</div>
								<div>
									<FormControl style={{ width: '100%' }} variant="filled">
										<Autocomplete
											options={appcenterStore.groups}
											disablePortal
											getOptionLabel={(option) => option.name}
											onChange={(e, value) => {
												if (!value) {
													// setMembers([]);
													setGroup('');
													return;
												}

												setGroup(value);
												setMember('');
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label={i18n('group')}
													inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
													variant="outlined"
												/>
											)}
										/>
									</FormControl>
								</div>
								{/* {memberList.length} */}
								<div>
									<FormControl style={{ width: '100%' }} variant="filled">
										<Autocomplete
											options={appcenterStore.users.filter((member) => {
												if (!group) return false;

												return group.members.indexOf(member.uid) != -1;
											})}
											disablePortal
											getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
											onChange={(e, value) => {
												setMember(value || '');
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label={i18n("user")}
													inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
													variant="outlined"
												/>
											)}
										/>
									</FormControl>
								</div>
								<div className="buttons" style={{ marginTop: 10 }}>
									<simple-button
										onClick={() => {
											setDelegateVisible(false);
											delegateResponsability();
										}}
									>
										{i18n("yes")}
									</simple-button>
									<simple-button onClick={() => setDelegateVisible(false)}>{i18n("cancel")}</simple-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default connect(({ auth, executionFlowView }) => ({ auth, executionFlowStore: executionFlowView }))(TaskOwnerAbsent);
