import { default as pt_BR } from '@i18n/pt_BR.json';
import { default as pt_PT } from '@i18n/pt_PT.json';
import { default as en_US } from '@i18n/en_US.json';

// import IntlPolyfill from "intl";
// IntlPolyfill.__disableRegExpRestore();
// import "intl/locale-data/jsonp/pt-BR.js";
const IntlPolyfill = Intl;

const allLanguages = {
	pt_BR,
	pt_PT,
	en_US,
};

const normalizeTranslate = {
	pt_BR: 'pt_BR',
	'pt-BR': 'pt_BR',
	pt_PT: 'pt_PT',
	'pt-PT': 'pt_PT',
	pt: 'pt_BR',
	en_US: 'en_US',
	'en-US': 'en_US',
	en: 'en_US',
};


let language;
let current;

const getLanguageByDevice = () => {
	let preSelected = localStorage.getItem('language');
	if (preSelected) return preSelected;
	localStorage.setItem('language', Intl.NumberFormat().resolvedOptions().locale);
	return new Intl.NumberFormat().resolvedOptions().locale;
};

export const changeLanguage = (newLanguage) => {
	language = newLanguage || normalizeTranslate[getLanguageByDevice()];
	current = allLanguages[language];
	if (!language || !current) {
		language = 'pt_BR';
		current = allLanguages[language];
	}
	localStorage.setItem('language', language);
};
changeLanguage();

export const getCurrentLanguage = () => {
	return { current, language };
};

const i18n = (key, replaceMatrix) => {
	let message = current[key];
	if (replaceMatrix instanceof Array && typeof message === 'string') {
		for (let i = 0; i < replaceMatrix.length; i++) {
			message = message.replace('@', replaceMatrix[i]);
		}
	}
	if (typeof replaceMatrix === 'object' && !(replaceMatrix instanceof Array) && typeof message === 'string') {
		for (let i in replaceMatrix) {
			if (!replaceMatrix.hasOwnProperty(i)) continue;
			message = message.replace(new RegExp('@' + i, 'g'), replaceMatrix[i]);
		}
	}
	return message || key;
};

const format = {
	number: (n, d = 2) => {
		return new IntlPolyfill.NumberFormat(language.replace('_', '-'), {
			maximumFractionDigits: 2,
		}).format(n);
	},
	currency: (n, d = 2) => {
		return new IntlPolyfill.NumberFormat(language.replace('_', '-'), {
			style: 'currency',
			currency: current.default.currency.abbreviation,
		}).format(n);
	},
	date: (d, o) => {
		if (!o) {
			o = current.default.date;
		}
		return new IntlPolyfill.DateTimeFormat(language.replace('_', '-'), o).format(d);
	},
	datetime: (d, o) => {
		if (!o) {
			o = current.default.datetime;
		}
		return new IntlPolyfill.DateTimeFormat(language.replace('_', '-'), o).format(d);
	},
};

const toDate = (value) => {
	if (value && typeof value === 'object' && value.toDate) {
		return value.toDate();
	}
	if (value && typeof value === 'object' && value._seconds && value._nanoseconds !== undefined) {
		return new Date(parseInt(value._seconds + '' + ('000' + value._nanoseconds).slice(-3).substr(0, 3)));
	}
	if (value && typeof value === 'object' && value.seconds && value.nanoseconds !== undefined) {
		return new Date(parseInt(value.seconds + '' + ('000' + value.nanoseconds).slice(-3).substr(0, 3)));
	}
	if (value instanceof Date) {
		return value;
	}
	if (typeof value === 'string' && value !== '') {
		return new Date(value);
	}
	return value;
};

export { format, current, toDate };
export default i18n;
