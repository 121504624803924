import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { renderExecutionFlowMenuItem, renderTaskMenuItem, sortByUrgency } from '@custom/common';
import { ExecutionFlow, Task, Absense, CompanyArea } from '@taugor/taugor-bpmn-models';
import { UltraBar, store as zerocodeStore, GlobalLoader, dokia as dokiaApi, Message, getChunks, toDate } from 'zerocode';

import i18n, { changeLanguage, getCurrentLanguage } from '@i18n';
import Icon from '@custom/Icon';
import firebase from '@firebase-config';
import { history, store, services } from '@redux';

import Api from './Api';
import Routes from './Routes';

import {
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Badge,
	Menu,
	MenuItem,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Tooltip,
	TextField,
	Button,
	Popover,
} from '@material-ui/core';
import { getUser } from './utils/user';
import { getDiffDaysWeek } from './utils/dates';
import { CustomerCareProvider, injectUserGuiding } from 'taugor-customer-care-utils';
import './schedules/detectLoginExpiration';

dokiaApi.instance('newton');

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
		width: 50,
		height: 50,
	},
	rightButton: {
		width: 50,
		height: 50,
		marginLeft: 7,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	},
}));

const modules = [
	{
		items: [
			{
				name: i18n('app.modules.profile.name'),
				icon: 'faUser',
				onClick: () => history.push('/profile'),
			},
			{
				name: i18n('app.modules.dashboard.name'),
				icon: 'faPollH',
				onClick: () => history.push('/'),
			},
			{
				name: i18n('app.modules.workflow.name'),
				icon: 'faSearch',
				onClick: () => history.push('/workflow/list'),
			},
			{
				name: i18n('app.modules.schedule.name'),
				icon: 'faCalendar',
				onClick: () => history.push('/calendar'),
			},
			{
				name: i18n('app.modules.news.name'),
				icon: 'faNewspaper',
				onClick: () => history.push('/news'),
			},
			{
				name: i18n("app.modules.activites.name"),
				icon: 'faColumns',
				onClick: () => history.push('/kanban/basic'),
			},
			{
				name: i18n('app.modules.companyArea.name'),
				icon: 'faBoxes',
				onClick: () => history.push('/companyArea/form'),
			},
			{
				name: i18n('app.modules.reports.name'),
				icon: 'faFileContract',
				onClick: () => history.push('/workflow/reports'),
			},
		],
	},
];

const oExecutionFlowService = new ExecutionFlow().getService(firebase, store);
const oExecutionFlowServiceCreated = new ExecutionFlow().getService(firebase, store, 'myExecutionFlowStore');
const oTaskService = new Task().getService(firebase, store);
const oGroupTaskService = new Task().getService(firebase, store, 'groupTaskStore');
const oAbsenseService = new Absense().getService(firebase, store, 'absenseStore');
const oCompanyAreaService = new CompanyArea().getService(firebase, store, 'companyAreaStore');

const updatePanelsHeight = () => {
	let appBar = document.getElementById('ultra-bar');
	let header = document.getElementById('page-header') || { offsetHeight: 0 };
	let micro = document.getElementById('micro-panel');
	let list = document.getElementById('list-panel');
	let form = document.getElementById('form-panel');

	if (!list || !form) return;
	let maxHeight = document.body.offsetHeight - appBar.offsetHeight - header.offsetHeight;
	list.style.maxHeight = maxHeight - 18 + 'px';
	form.style.maxHeight = maxHeight - 18 + 'px';
	if (micro) {
		micro.style.maxHeight = maxHeight - 18 + 'px';
	}
};
window.addEventListener(
	'resize',
	() => {
		updatePanelsHeight();
	},
	false
);

function AppCommon() {
	const [users, setUsers] = useState();
	const [whitelabel, setWhitelabel] = useState({});
	const [taskSearch, setTaskSearch] = useState('');
	const [taskPeriod, setTaskPeriod] = useState('week');
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [groupTasksOpened, setGroupTasksOpened] = useState(false);
	const [notificationsOpen, setNotificationsOpen] = useState(false);
	const [executionFlowsOpen, setExecutionFlowsOpen] = useState(false);
	const [executionFlowsCreatedOpen, setExecutionFlowsCreatedOpen] = useState(false);
	const [newTasks, setNewTasks] = useState([]);

	const [taskFormFilter, setTaskFormFilter] = useState(true);
	const [taskDocumentFilter, setTaskDocumentFilter] = useState(true);
	const [taskApprovalFilter, setTaskApprovalFilter] = useState(true);

	const auth = useSelector((s) => s.auth);
	const spinner = useSelector((s) => s.spinner);
	const message = useSelector((s) => s.message);
	const executionFlowStore = useSelector((s) => s.executionFlow);
	const executionFlowStoreMine = useSelector((s) => s.myExecutionFlowStore);
	const taskStore = useSelector((s) => s.task);
	const groupTaskStore = useSelector((s) => s.groupTaskStore);
	const appcenterStore = zerocodeStore.getState().appcenter;
	const classes = useStyles();
	const currentScope = {};

	useEffect(() => {
		injectUserGuiding();
	}, []);

	useEffect(() => {
		if (!appcenterStore.licenses) {
			setWhitelabel(false);
			return;
		}
		appcenterStore.licenses.map((license) => {
			if (license.type === 'whitelabel' && license.status === 'paid') {
				const company = auth.company;
				const meta = company?.meta;
				setWhitelabel(meta?.whitelabel);
			}
		});
	}, [appcenterStore.licenses]);

	useEffect(() => {
		if (!auth.isAuthenticated || !auth.user || !auth.user.uid || !auth.company || !auth.company.uid) {
			currentScope.company = '';
			currentScope.user = '';
			currentScope.groups = '';

			return;
		}

		if (currentScope.user == auth.user.uid && currentScope.company == auth.company.uid) return;
		currentScope.user = auth.user.uid;
		currentScope.company = auth.company.uid;

		Api.company.licenses.byCompanyUid(auth.user.currentCompany.uid).then(({ data }) => {
			if (!auth.user.currentCompany.meta) return;
			const licenses = data;
			if (!licenses) return;

			licenses.map((license) => {
				if (license.type === 'whitelabel' && license.status === 'paid') {
					setWhitelabel(auth.user.currentCompany.meta.whitelabel);
				}
			});
		});

		oAbsenseService
			.filter([
				[
					['user', '==', auth.user.uid],
					['deleted', '==', false],
				],
			])
			.list();
		oExecutionFlowService
			.filter([
				[
					['agentObject.uid', '==', auth.user.uid],
					['completed', '==', 'not-completed'],
					['company', '==', auth.company.uid],
					['deleted', '==', false],
				],
			])
			.list();
		oExecutionFlowServiceCreated
			.filter([
				[
					['createdBy', '==', auth.user.uid],
					['completed', '==', 'not-completed'],
					['company', '==', auth.company.uid],
					['deleted', '==', false],
				],
			])
			.list();
		oTaskService
			.filter([
				[
					['action.agent', '==', auth.user.uid],
					['status', '==', 'review'],
					['deleted', '==', false],
				],
				[
					['action.agent', '==', auth.user.uid],
					['status', '==', 'pending'],
					['company', '==', auth.company.uid],
					['deleted', '==', false],
				],
			])
			.list();
		oCompanyAreaService
			.filter([
				[
					['company', '==', auth.company.uid],
					['deleted', '==', false],
				],
			])
			.list()
			.then((list) => {
				return list;
			});
	}, [auth.isAuthenticated, auth.user, auth.company, auth.user.permissions]);

	useEffect(() => {
		if (!auth.user.groups) return;

		const groups = JSON.stringify(auth.user.groups);
		if (groups === currentScope.groups) return;

		currentScope.groups = groups;

		const groupChunks = getChunks(auth.user.groups, 10).map((group) => group.uid);

		const filters = [];
		groupChunks.map((chunk) => {
			filters.push([
				['action.agent', '==', 'non-distributed-task'],
				['action.group.uid', 'in', chunk],
				['status', '==', 'pending'],
				['company', '==', auth.company.uid],
				['deleted', '==', false],
			]);
		});

		oGroupTaskService.filter(filters).list();
	}, [auth.user.groups]);

	const filterTask = async () => {
		const orderedTasks = taskStore.list.sort((a, b) => {
			if (a.createdAt.toDate() > b.createdAt.toDate()) return 1;
			if (a.createdAt.toDate() < b.createdAt.toDate()) return -1;
			return 0;
		});

		const taskFilteredByType = orderedTasks.filter((task) => {
			const { type } = task.action.type;
			if (taskDocumentFilter && type === 'documentInput') {
				return true;
			}
			if (taskFormFilter && type === 'formInput') {
				return true;
			}
			if (taskApprovalFilter && type === 'approval') {
				return true;
			}
			if (!taskDocumentFilter && !taskFormFilter && !taskApprovalFilter) {
				return true;
			}

			return false;
		});

		const taskFilteredByDate = taskFilteredByType.filter((task) => {
			const createdAt = toDate(task.createdAt);

			const today = new Date();
			today.setHours(0, 0, 0);
			let taskDateMiliseconds = 0;

			switch (taskPeriod) {
				case 'today':
					taskDateMiliseconds = today;
					break;

				case 'week':
					const diffDaysWeek = getDiffDaysWeek(today);
					taskDateMiliseconds = today.setDate(today.getDate() - diffDaysWeek);
					break;

				case 'month':
					taskDateMiliseconds = today.getDate() - 1;
					break;

				case 'six_month':
					taskDateMiliseconds = today.setMonth(today.getMonth() - 6);
					break;

				case 'year':
					taskDateMiliseconds = today.setFullYear(today.getFullYear() - 1);
					break;

				default:
					taskDateMiliseconds = today;
			}

			const taskDate = new Date(taskDateMiliseconds);
			if (createdAt >= taskDate) {
				return true;
			}

			return false;
		});

		const taskSearchLowerCase = taskSearch.toLowerCase();
		if (!taskSearchLowerCase || taskSearchLowerCase.length < 3) {
			return setNewTasks(taskFilteredByDate);
		}

		const taskFilteredBySearch = taskFilteredByDate.filter((task) => {
			const { workflowObject } = task;
			const createdBy = workflowObject.createdBy;
			const title = workflowObject.document.title;
			const description = workflowObject.document.description;

			const taskTitle = typeof title == 'string' && title.toLowerCase();
			const taskDescription = typeof description == 'string' && description.toLowerCase();
			const user = users[createdBy];
			const userName = `${user.firstName} ${user.lastName}`.toLocaleLowerCase();

			if (userName && userName.includes(taskSearchLowerCase)) {
				return true;
			}

			if (taskTitle.includes(taskSearchLowerCase) || taskDescription.includes(taskSearchLowerCase)) {
				return true;
			}

			return false;
		});

		setNewTasks(taskFilteredBySearch);
	};

	useEffect(() => {
		filterTask();
	}, [taskDocumentFilter, taskFormFilter, taskApprovalFilter, taskPeriod, taskStore]);

	useEffect(() => {
		(async () => {
			const tempUsers = {};
			for (const task of taskStore.list) {
				const { workflowObject } = task;
				const createdBy = workflowObject.createdBy;

				tempUsers[createdBy] = await getUser(createdBy);
			}

			setUsers(tempUsers);
		})();
	}, [taskStore]);

	const currentLang = getCurrentLanguage();

	return (
		<div
			id={'container'}
			style={{
				background: whitelabel && whitelabel?.background ? whitelabel.background : false,
			}}
		>
			<UltraBar
				app="workflux"
				onMenuIconClick={() => setDrawerOpen(prev => !prev)}
				themeSwitch={false}
				iconMenuComponents={[
					<Tooltip title={i18n('appcommon.menutop.executionFlowsICreated')}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							id="executionFlowStoreMineList-menubutton"
							onClick={(e) =>
								setExecutionFlowsCreatedOpen(
									executionFlowsCreatedOpen
										? false
										: document.getElementById('executionFlowStoreMineList-menubutton')
								)
							}
							edge="start"
							className={clsx(classes.rightButton)}
						>
							{executionFlowStoreMine.list.length ? (
								<Badge badgeContent={executionFlowStoreMine.list.length} color="error">
									<Icon icon="faProjectDiagram" style={{ fontSize: 20 }} />
								</Badge>
							) : (
								<Icon icon="faProjectDiagram" style={{ fontSize: 20 }} />
							)}
						</IconButton>
					</Tooltip>,
					<Menu
						style={{ top: 25 }}
						anchorEl={executionFlowsCreatedOpen}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						onClick={(e) => setExecutionFlowsCreatedOpen(false)}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={!!executionFlowsCreatedOpen}
						PaperProps={{
							style: {
								maxHeight: 400,
							},
						}}
					>
						<div style={{ padding: 15, background: '#eee' }}>{i18n('appcommon.menutop.executionFlowsICreated')}</div>
						{!executionFlowStoreMine.list.length && (
							<MenuItem style={{ borderBottom: '1px solid #ddd' }} key={'Você não criou nenhum fluxo'}>
								<div>
									<div style={{ fontSize: 10, color: '#666' }}>
										<b>{i18n('Você não criou nenhum fluxo')}</b>
									</div>
								</div>
							</MenuItem>
						)}
						{executionFlowStoreMine.list
							.sort((a, b) => {
								if (a.createdAt.seconds > b.createdAt.seconds) return 1;
								if (a.createdAt.seconds < b.createdAt.seconds) return -1;
								return 0;
							})
							.map((execution, i) => {
								return renderExecutionFlowMenuItem(execution, i, () => setExecutionFlowsOpen(null));
							})}
					</Menu>,
					<Tooltip title={i18n('appcommon.menutop.executionFlowsImResponsible')}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							id="executionFlowStoreList-menubutton"
							onClick={(e) =>
								setExecutionFlowsOpen(
									executionFlowsOpen ? false : document.getElementById('executionFlowStoreList-menubutton')
								)
							}
							edge="start"
							className={clsx(classes.rightButton)}
						>
							{executionFlowStore.list.length ? (
								<Badge badgeContent={executionFlowStore.list.length} color="error">
									<Icon icon="faCodeBranch" style={{ fontSize: 20 }} />
								</Badge>
							) : (
								<Icon icon="faCodeBranch" style={{ fontSize: 20 }} />
							)}
						</IconButton>
					</Tooltip>,
					<Menu
						style={{ top: 25 }}
						anchorEl={executionFlowsOpen}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={!!executionFlowsOpen}
						onClose={() => setExecutionFlowsOpen(null)}
						PaperProps={{
							style: {
								maxHeight: 400,
							},
						}}
					>
						<div style={{ padding: 15, background: '#eee' }}>
							{i18n('appcommon.menutop.executionFlowsImResponsible')}
						</div>
						{!executionFlowStore.list.length && (
							<MenuItem style={{ borderBottom: '1px solid #ddd' }} key={'Você não possui nenhum Fluxo'}>
								<div>
									<div style={{ fontSize: 10, color: '#666' }}>
										<b>{i18n('Você não possui nenhum Fluxo em sua responsabilidade')}</b>
									</div>
								</div>
							</MenuItem>
						)}
						{executionFlowStore.list.map((execution, i) => {
							return renderExecutionFlowMenuItem(execution, i, () => setExecutionFlowsOpen(null));
						})}
					</Menu>,
					<Tooltip title={i18n('Fluxos não distribuídos')}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							id="groupTaskStoreList-menubutton"
							onClick={(e) => setGroupTasksOpened(groupTasksOpened ? false : e.currentTarget)}
							edge="start"
							className={clsx(classes.rightButton)}
						>
							{groupTaskStore.list.length ? (
								<Badge badgeContent={groupTaskStore.list.length} color="error">
									<Icon icon="faUsers" style={{ fontSize: 20 }} />
								</Badge>
							) : (
								<Icon icon="faUsers" style={{ fontSize: 20 }} />
							)}
						</IconButton>
					</Tooltip>,
					<Menu
						style={{ top: 25, maxHeight: 400 }}
						anchorEl={groupTasksOpened}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={!!groupTasksOpened}
						onClose={() => setGroupTasksOpened(null)}
						PaperProps={{
							style: {
								maxHeight: 400,
							},
						}}
					>
						<div style={{ padding: 15, background: '#eee' }}>Não distribuídas</div>
						{!groupTaskStore.list.length && (
							<MenuItem
								style={{ borderBottom: '1px solid #ddd' }}
								key={'No momento todos Fluxos foram distribuidos'}
							>
								<div>
									<div style={{ fontSize: 10, color: '#666' }}>
										<b>{i18n('No momento todos Fluxos foram distribuidos')}</b>
									</div>
								</div>
							</MenuItem>
						)}
						{groupTaskStore.list
							.sort((a, b) => {
								if (a.createdAt.toDate() > b.createdAt.toDate()) return 1;
								if (a.createdAt.toDate() < b.createdAt.toDate()) return -1;
								return 0;
							})
							.map((task, i) => {
								return renderTaskMenuItem(task, i, () => setGroupTasksOpened(null));
							})}
					</Menu>,
					<Tooltip title={i18n('appcommon.menutop.myTasks')}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							id="taskStoreList-menubutton"
							onClick={(e) =>
								setNotificationsOpen(
									notificationsOpen ? false : document.getElementById('taskStoreList-menubutton')
								)
							}
							edge="start"
							className={clsx(classes.rightButton)}
						>
							{newTasks.length ? (
								<Badge badgeContent={newTasks.length} color="error">
									<Icon icon="faBell" style={{ fontSize: 20 }} />
								</Badge>
							) : (
								<Icon icon="faBell" style={{ fontSize: 20 }} />
							)}
						</IconButton>
					</Tooltip>,
					<Popover
						style={{ top: 25 }}
						anchorEl={notificationsOpen}
						anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={!!notificationsOpen}
						onClose={() => setNotificationsOpen(null)}
						PaperProps={{
							style: {
								maxHeight: 400,
							},
						}}
					>
						<div style={{ padding: 15, background: '#eee' }}>{i18n('appcommon.menutop.myTasks')}</div>
						<div style={{ margin: '0 10px' }}>
							<FormGroup style={{ padding: '0.5rem' }}>
								<div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
									<Button variant="contained" color="primary" onClick={filterTask}>
										{i18n("search")}
									</Button>

									<TextField
										variant="outlined"
										fullWidth
										size="small"
										value={taskSearch}
										onChange={(event) => {
											event.preventDefault();
											event.stopPropagation();
											setTaskSearch(event.target.value);
										}}
									/>

									<TextField
										select
										size="small"
										variant="outlined"
										value={taskPeriod}
										style={{ width: '40%' }}
										onClick={(event) => setTaskPeriod(event.target.value)}
									>
										<MenuItem value="today">Hoje</MenuItem>
										<MenuItem value="week">Esta semana</MenuItem>
										<MenuItem value="month">Este mês</MenuItem>
										<MenuItem value="six_month">Últimos 6 meses</MenuItem>
										<MenuItem value="year">Este ano</MenuItem>
									</TextField>
								</div>
								<FormControlLabel
									control={
										<Checkbox
											checked={taskDocumentFilter}
											value={taskDocumentFilter}
											onChange={(e, value) => {
												setTaskDocumentFilter(!taskDocumentFilter);
											}}
											name={`check-document`}
										/>
									}
									label={i18n(`task.documentInput.type`)}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={taskFormFilter}
											value={taskFormFilter}
											onChange={(e, value) => {
												setTaskFormFilter(!taskFormFilter);
											}}
											name={`check-form`}
										/>
									}
									label={i18n(`task.formInput.type`)}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={taskApprovalFilter}
											value={taskApprovalFilter}
											onChange={(e, value) => {
												setTaskApprovalFilter(!taskApprovalFilter);
											}}
											name={`check-approval`}
										/>
									}
									label={i18n(`task.approval.type`)}
								/>
							</FormGroup>
						</div>
						{newTasks.length ? (
							newTasks.sort(sortByUrgency).map((task, i) => {
								return renderTaskMenuItem(task, i, () => setNotificationsOpen(null));
							})
						) : (
							<h4 style={{ margin: '0 20px' }}>Nenhuma tarefa correspondente os filtros!</h4>
						)}
					</Popover>,
				]}
			/>
			{auth.isAuthenticated && (
				<Drawer
					style={{
						color: whitelabel ? whitelabel.color : false,
						background: whitelabel && whitelabel?.background ? whitelabel.background : false,
						backgroundImage:
							!whitelabel && !whitelabel?.background ? 'linear-gradient(-40deg, #5e5e5e, #626ac9 60%)' : false,
					}}
					variant="permanent"
					id="app-drawer"
					className={clsx(classes.drawer, {
						['opened']: drawerOpen,
						['closed']: !drawerOpen,
					})}
					classes={{
						paper: clsx('drawer-content', {
							['opened']: drawerOpen,
							['closed']: !drawerOpen,
						}),
					}}
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
					onKeyDown={() => setDrawerOpen(false)}
				>
					{!!whitelabel && !!whitelabel.logo && (
						<div
							style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, cursor: 'pointer' }}
							onClick={() => setDrawerOpen(!drawerOpen)}
						>
							<img
								src={whitelabel.logo}
								style={{ width: 50, marginLeft: 5, borderRadius: '50%' }}
								alt="Logo da Empresa"
								loading="lazy"
							/>
						</div>
					)}
					<div role="presentation" onClick={() => setDrawerOpen(false)}>
						{drawerOpen && (
							<div className='language-selection'>
								<TextField value={currentLang.language} label={i18n('language')} select>
									<MenuItem
										value="pt_BR"
										onClick={(e) => {
											changeLanguage('pt_BR');
										}}
									>
										Português (BR)
									</MenuItem>
									<MenuItem
										value="en_US"
										onClick={(e) => {
											changeLanguage('en_US');
										}}
									>
										English (US)
									</MenuItem>
									<MenuItem
										value="pt_PT"
										onClick={(e) => {
											changeLanguage('pt_PT');
										}}
									>
										Português (PT)
									</MenuItem>
								</TextField>
							</div>
						)}

						{modules.map((module, i) => {
							return (
								<div key={i}>
									{!!module.name && (
										<List>
											<ListItem button key={i} onClick={module.onClick}>
												{!!module.onClick && (
													<ListItemIcon
														style={{
															paddingLeft: 25,
														}}
													>
														<Icon icon={module.icon ? module.icon : 'faChevronRight'} />
													</ListItemIcon>
												)}
												<ListItemText primary={module.name} secondary={module.description} />
											</ListItem>
										</List>
									)}

									<List>
										{module.items.map((item, a) => (
											<Tooltip title={item.name} placement="right">
												<ListItem button key={a} onClick={item.onClick} style={{ paddingLeft: 25 }}>
													<ListItemIcon>
														<Icon icon={item.icon} />
													</ListItemIcon>
													<ListItemText
														primary={item.name}
														style={{
															whiteSpace: item.wrap ? 'normal' : 'nowrap',
														}}
														secondary={item.description}
													/>
												</ListItem>
											</Tooltip>
										))}
									</List>
								</div>
							);
						})}
					</div>
				</Drawer>
			)}
			<main>
				<Routes />
			</main>
			{spinner.show && <GlobalLoader app="workflux" spin={true} content={spinner.content} />}
			{message.visible && (
				<Message
					visible={message.visible}
					title={message.title}
					description={message.description}
					buttons={message.buttons}
					hide={message.hide || services.message.hide}
				/>
			)}
		</div>
	);
};

export default () => {
	const { auth } = useSelector(state => state);

	return (
		<CustomerCareProvider
			app="workflux"
			auth={auth.user.uid}
		>
			<AppCommon />
		</CustomerCareProvider>
	);
};
