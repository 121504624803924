import React, { useState, useEffect } from 'react';
import firebase from '@firebase-config';
import i18n, { format } from '@i18n';
import { store, services } from '@redux';
import { useSelector, connect } from 'react-redux';
import {
	ExecutionFlow,
	Task,
	ExecutedDocument,
	Absense,
	Signing,
	WorkflowPhaseAction as WorkflowPhaseActionShape,
} from '@taugor/taugor-bpmn-models';
import { generateUid } from '@custom/common';
import Icon from '@custom/Icon';
import Input from '@custom/Input';
import Message from '@custom/Message';
import { TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Company, Group, User } from '@taugor/taugor-appcenter-sdk-js';
import '@assets/sass/task.absent.scss';
import Api from '../../Api';

const oTaskService = new Task().getService(firebase, store, 'taskDocumentInputView');
const oExecutionFlowService = new ExecutionFlow().getService(firebase);
const oAbsenseService = new Absense().getService(firebase);

const createAction = () => {
	let oAction = new WorkflowPhaseActionShape();
	oAction.instructions = '';
	oAction.formFields = '';
	oAction.formTitle = '';
	oAction.isSigningRequired = false;
	oAction.doesExpire = false;
	oAction.daysToExpiration = 0;
	oAction.daysToFirstWarning = 0;
	oAction.daysToLastWarning = 0;
	oAction.canDelegate = false;
	oAction.expirationConsequence = 'ignore';
	oAction.isApprovalRequired = false;
	oAction.onReprovalGoesBack = false;
	oAction.onReprovalCancel = false;
	oAction.group = '';
	oAction.type = {
		company: '',
		description: '',
		name: '',
		type: '',
	};
	return oAction;
};

let oBaseTask = createAction();

const TaskOwnerAbsent = ({ auth, executionFlowView, task }) => {
	const [action, setAction] = useState(oBaseTask);
	const [users, setUsers] = useState([]);
	const [group, setGroup] = useState('');
	const [groups, setGroups] = useState([]);
	const [member, setMember] = useState('');
	const [memberList, setMemberList] = useState([]);
	const [showRequestComplimentaryTaskWindow, setShowRequestComplimentaryTaskWindow] = useState(false);

	useEffect(() => {
		getGroups(auth.company.uid).then(setGroups);
	}, []);
	if (!task || !task.uid) return <div></div>;

	const onActionChange = (prop) => (e) => {
		setAction({
			...action,
			[prop]: e.target.value,
		});
	};

	const saveComplementaryTask = async () => {
		services.spinner.show();

		try {
			let phaseToSend;

			delete group.$$index;

			action.group = group;
			action.agent = member.uid;
			action.agentName = member.firstName + ' ' + member.lastName;
			action.uid = generateUid();
			action.complementary = true;

			const oTask = new Task();

			oTask.uid = generateUid();
			action.task = oTask.uid;
			oTask.company = auth.company.uid;
			oTask.agent = member.uid;
			oTask.workflow = task.workflow;
			oTask.executionFlow = task.executionFlow;
			oTask.workflowObject = task.workflowObject;
			oTask.phase = task.phase;
			oTask.action = action;
			oTask.status = 'pending';
			oTask.conversation = '';
			oTask.expirationDateString = '';
			oTask.firstWarningDateString = '';
			oTask.secondWarningDateString = '';
			oTask.expirationSent = false;
			oTask.firstWarningSent = false;
			oTask.secondWarningSent = false;
			oTask.expired = false;
			oTask.canDelegate = false;
			await oTaskService.save(oTask);

			const executionFlow = await oExecutionFlowService.get(task.executionFlow);
			const workflowObject = executionFlow.workflowObject;

			console.log('executionFlow', executionFlow);
			console.log('executionFlow.history', executionFlow.history);

			const history = executionFlow.history || [];
			history.push({
				type: 'task.complementary.created',
				agent: auth.user.uid,
				agentName: auth.user.firstName + ' ' + auth.user.lastName,
				agentEmail: auth.user.email,
				actionType: action.type.type,
				description: action.instructions || action.formTitle,
				date: new Date(),
			});

			let phase = workflowObject.phases.find((phase) => {
				return phase.uid === oTask.phase;
			});

			if (phase) {
				phase.actions.push(action);
				phaseToSend = phase;
			}

			const participants = Array.from(new Set([...executionFlow.participants, member.uid]));

			await oExecutionFlowService.patch(executionFlow.uid, {
				workflowObject,
				history,
				participants,
			});

			await Api.executionFlow.task.complementary.create(
				executionFlow,
				executionFlow.workflowObject.phases.find((phase) => {
					return phase.uid === oTask.phase;
				}),
				oTask,
				`${auth.user.firstName} ${auth.user.lastName}`,
				member.email
			);

			setShowRequestComplimentaryTaskWindow(false);
		} catch (err) {
			console.error(err);
			services.message.show('Erro ao criar tarefa complementar');
		} finally {
			services.spinner.hide();
		}
	};

	const renderTaskType = () => {
		let actionTypeContent = '';
		let canSave = !!action.type.type;
		switch (action.type.type) {
			case 'approval':
				actionTypeContent = (
					<div>
						<Input
							label={i18n('workflow.form.actions.instructions')}
							mode="outlined top-label"
							value={action.instructions}
							onChange={onActionChange('instructions')}
						/>
					</div>
				);
				action.type.name = i18n('workflow.form.actionTypes.approval.name');
				canSave = canSave && !!action.instructions;
				break;
			case 'documentInput':
				actionTypeContent = (
					<div>
						<Input
							label={i18n('workflow.form.actions.instructions')}
							mode="outlined top-label"
							value={action.instructions}
							onChange={onActionChange('instructions')}
						/>
					</div>
				);
				action.type.name = i18n('workflow.form.actionTypes.documentInput.name');
				canSave = canSave && !!action.instructions;
				break;
			case 'formInput':
				actionTypeContent = (
					<div>
						<Input
							label={i18n('workflow.form.actions.formTitle')}
							value={action.formTitle}
							mode="outlined top-label"
							onChange={onActionChange('formTitle')}
						/>
						<Input
							label={i18n('workflow.form.formInputType.formFieldsLabel')}
							value={action.formFields}
							mode="outlined top-label"
							multiline={true}
							onChange={onActionChange('formFields')}
						/>
					</div>
				);
				action.type.name = i18n('workflow.form.actionTypes.formInput.name');
				canSave = canSave && !!action.formTitle && !!action.formFields;
				break;
		}

		return (
			<div>
				<div>{actionTypeContent}</div>
				{!!canSave && (
					<div className="columns">
						<div className="simple-button" onClick={saveComplementaryTask}>
							<Icon icon="faSave" />
							{i18n('workflow.form.saveComplementaryTask')}
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<div>
			{task.action.complementary && (
				<div className="complementary-task-warning">{i18n('executionFlow.form.complementaryTask')}</div>
			)}
			<div className="complementary-task-panel task-a">
				<simple-button
					iconed
					class="task-action"
					onClick={(e) => setShowRequestComplimentaryTaskWindow(true)}
					tooltip={i18n('complementary.task.require')}
				>
					<Icon icon="faPlus" />
				</simple-button>
			</div>
			<Message
				visible={showRequestComplimentaryTaskWindow}
				hide={() => {
					setShowRequestComplimentaryTaskWindow(false);
				}}
				title={i18n('complementary.task.require')}
				description={
					<div style={{ height: '50vh', width: '45vw' }}>
						<FormControl style={{ width: '100%' }} variant="filled">
							<InputLabel>Tipo de Atividade</InputLabel>
							<Select
								value={action.type.type}
								onChange={(e) => {
									setAction({
										...action,
										type: {
											...action.type,
											type: e.target.value,
										},
									});
								}}
							>
								<MenuItem value={'approval'}>{i18n('workflow.form.actionTypes.approval.name')}</MenuItem>
								<MenuItem value={'documentInput'}>
									{i18n('workflow.form.actionTypes.documentInput.name')}
								</MenuItem>
								<MenuItem value={'formInput'}>{i18n('workflow.form.actionTypes.formInput.name')}</MenuItem>
							</Select>
						</FormControl>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<FormControl style={{ width: '100%' }} variant="filled">
								<Autocomplete
									options={groups}
									disablePortal
									getOptionLabel={(option) => option.name}
									onChange={(e, value) => {
										if (!value) {
											// setMembers([]);
											setGroup('');
											return;
										}

										setGroup(value.uid);
										setMember('');
										services.spinner.show();
										getMembers(value.uid).then((members) => {
											setMemberList(members.data);
											services.spinner.hide();
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label={i18n('workflow.form.document.group')}
											inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
											variant="outlined"
										/>
									)}
								/>
							</FormControl>
						</div>
						<div>
							<FormControl style={{ width: '100%' }} variant="filled">
								<Autocomplete
									options={memberList}
									disablePortal
									getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
									onChange={(e, value) => {
										let member = memberList.find((m) => m.uid === value.uid);
										if (!member) {
											setMember('');
											return;
										}

										setMember(member);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label={i18n('auth.form.username')}
											inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
											variant="outlined"
										/>
									)}
								/>
							</FormControl>
						</div>

						<div>{renderTaskType()}</div>
					</div>
				}
			/>
		</div>
	);
};

let oCompanyApi = new Company();
let oGroupApi = new Group();
let oUserApi = new User();
const getGroups = (company) => {
	return oCompanyApi.groups.list({ company });
};
const getMembers = (group) => {
	return Api.getMembersAndAbsentStatus(group);
};

export default connect(({ auth, executionFlowView }) => ({ auth, executionFlowView }))(TaskOwnerAbsent);
