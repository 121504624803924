import api, { customer_api } from '@redux/api';
import { uid } from '@zerobytes/firebase-basic-service';
import firebase from '@firebase-config';
import 'firebase/storage';
import { indexia_api } from './@redux/api';

const getStored = () => {
	let url = localStorage.getItem('ged17-auth-url');
	let token = localStorage.getItem('ged17-auth-token');
	return { url, token };
};

const showGedMessageToReauth = (e) => {
	// services.message.show(
	// 	'Sua autenticação do GED expirou, por favor reautentique no menu de integrações'
	// );
	// return e;
};

const Api = {
	company: {
		licenses: {
			byCompanyUid: (company) => {
				return customer_api.get(`/license/list/${company}/workflux`);
			},
			byMetaCode: (code) => {
				return customer_api.get(`/license/list/meta/${code}/workflux`);
			},
			consume: (company, type, amount, user) => {
				return customer_api.post(
					`/license/workflux/${company}/${type}/consume`,
					{ amount, user }
					// {
					// 	headers: {
					// 		Authorization: JSON.parse(localStorage.getItem('state')).appCenterToken,
					// 	},
					// }
				);
			},
		},
	},
	ged17: {
		getToken: () => {
			return localStorage.getItem('ged17-auth-token');
		},
		authenticate: (user, pass, url) => {
			localStorage.setItem('ged17-auth-url', url);
			return api.post('ged/authenticate', { user, pass, url }).then((r) => {
				localStorage.setItem('ged17-auth-token', r.data);
				return Promise.resolve();
			});
			// showGedMessageToReauth.catch(showGedMessageToReauth);
		},
		getWorkspaces: () => {
			const { token, url } = getStored();
			if (!token) return Promise.reject();
			return api
				.post('ged/workspaces/list', { token, url })
				.then((r) => {
					return r.data;
				})
				.catch(showGedMessageToReauth);
		},
		getOrganizationUnits: (workspace) => {
			const { token, url } = getStored();
			if (!token) return Promise.reject();
			return api
				.post('ged/organizations-units/list', { token, url, workspace })
				.then((r) => {
					return r.data;
				})
				.catch(showGedMessageToReauth);
		},
		getCompanies: (organizationUnit) => {
			const { token, url } = getStored();
			if (!token) return Promise.reject();
			return api
				.post('ged/company/list', { token, url, organizationUnit })
				.then((r) => {
					return r.data;
				})
				.catch(showGedMessageToReauth);
		},
		getClassifications: (company) => {
			const { token, url } = getStored();
			if (!token) return Promise.reject();
			return api
				.post('ged/classifications/list', { token, url, company })
				.then((r) => {
					return r.data;
				})
				.catch(showGedMessageToReauth);
		},
		getFields: (classification, company) => {
			const { token, url } = getStored();
			if (!token) return Promise.reject();
			return api
				.post('ged/fields/list', { token, url, classification, company })
				.then((r) => {
					return r.data;
				})
				.catch(showGedMessageToReauth);
		},
		getClassification: (classification) => {
			const { token, url } = getStored();
			if (!token) return Promise.reject();
			return api
				.post('ged/classification/specific', { token, url, classification })
				.then((r) => {
					return r.data;
				})
				.catch(showGedMessageToReauth);
		},
	},
	getMembersAndAbsentStatus: (group) => {
		return api.post(`/workflow/group/${group}/members/absent`, {});
	},
	updateTaskOwner: (task, agent) => {
		return api.post(`/task/${task}/update/responsible`, { agent });
	},
	executionFlow: {
		cancel: ({ id, cancelMessage }) => {
			return api.post(`/workflow/execution/${id}/cancel`, {
				id,
				cancelMessage,
			});
		},
		remove: ({ id }) => {
			return api.delete(`/workflow/execution/${id}/remove`, {
				id,
			});
		},
		archive: ({ id }) => {
			return api.delete(`/workflow/execution/${id}/archive`, {
				id,
			});
		},
		unarchive: ({ id }) => {
			return api.delete(`/workflow/execution/${id}/unarchive`, {
				id,
			});
		},
		create: ({ workflow }) => {
			return api.post('workflow/execution', {
				workflow,
			});
			// .then((r) => {
			// 	//(r);
			// });
		},
		updateExecutionFlow: (data) => {
			return api.post(`/workflow/execution/updateExecutionFlow`, { data });
		},
		createDossier: (data) => {
			return api.post('/create-pdf', { executionFlowStore: data });
		},
		share: (uid, { users, groups }) => {
			return api.post(`/share/executionFlow/${uid}`, { users, groups });
		},
		document: {
			share: (document, { users, groups }) => {
				return api.post('/share/document', { document, users, groups });
			},
			open: (documentUid) => {
				return api.post(`/open/document/${documentUid}`);
			}
		},

		task: {
			approve: (id, approved, reason, signingUid) => {
				//('signingUid', signingUid);
				return api.post(`/workflow/execution/task/approval/${id}`, {
					approved,
					reason,
					signingUid,
				});
			},
			formInput: ({ task, form, signingUid }) => {
				return api.post(`/workflow/execution/task/formInput/${task.uid}`, {
					form,
					signingUid,
				});
			},

			signedDocumentInput: {
				start: ({ fileBase64 }) => {
					return api.post(`/signature/start`, {
						file: fileBase64,
					});
				},
				complete: ({ token }) => {
					return api.post(`/signature/complete`, {
						token,
					});
				},
				saveReference: ({ task, location, ged17Fields }) => {
					return api.post(`/workflow/execution/task/documentInput/${task.uid}`, {
						task,
						location,
						ged17Fields,
					});
				},
			},
			documentInput: ({ task, file, ged17Fields }) => {
				var formData = new FormData();
				formData.append('document', file);

				let rootRef = firebase.storage().ref();
				let docRef = rootRef.child(
					`executionFlow/${task.executionFlow}/task/${task.uid}/${uid()}.${file.name.split('.').pop()}`
				);

				return docRef.put(file).then((snapshot) => {
					return snapshot.ref.getDownloadURL().then((location) => {
						return api.post(`/workflow/execution/task/documentInput/${task.uid}`, {
							task,
							location,
							file: {
								name: file.name,
								size: file.size,
							},
							ged17Fields,
						});
					});
				});
			},
			confirmReview: ({ task }) => {
				return api.post(`/workflow/execution/task/confirmReview/${task}`);
			},
			complementary: {
				create: (executionFlow, phase, task, userName, email) => {
					return api.post('/workflow/complementary/task/create', { executionFlow, phase, task, userName, email });
				},
			},
		},
	},

	indexia: {
		convertedPDFtoPNG: (executedDocumentUid) => {
			return indexia_api.post(`/process/ocrmypdf/${executedDocumentUid}`);
		},
	},
	updateGroups: (workflow) => {
		return api.post(`/workflow/${workflow}/update/groups`);
	},
	getUser: (uid) => {
		return customer_api.get(`/user/${uid}`);
	},
	hsm: {
		sendHsm: (data) => {
			return api.post('/hsm/send', { data });
		},
	},
	cvs: {
		cvsWrite: (data) => {
			return api.post('/csv', { data });
		},
	},
	generateReportExecutionFlow: {
		generateReportExecutionFlowWrite: (data) => {
			return api.post('/generateReportExecutionFlow', { data });
		},
	},
	mail: {
		mailWrite: (data) => {
			return api.post('/mail', { data });
		},
	},
};

export default Api;
