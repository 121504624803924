import styles from './form.module.css';
import React, { useState } from "react";

import i18n from "@i18n";
import Input from '@custom/Input';
import { useSelector } from 'react-redux';

import { workfluxFirestore, workfluxStorage } from '@firebase-config';

import {
  Button,
  TextField,
  MenuItem
} from '@material-ui/core';
import QuillRichTextEditor from '@custom/QuillRichTextEditor';

export function FormNews({ closeModal, addCategory, listCategory, titleForm, action, dataNews }) {
  const auth = useSelector((s) => s.auth);
  const newsCollection = workfluxFirestore.collection('news');

  const [loading, setLoading] = useState(false);

  const [news, setNews] = useState({
    category: action === "edit" ? dataNews.category : "",
    title: action === "edit" ? dataNews.title : "",
    summary: action === "edit" ? dataNews.summary : "",
    text: action === "edit" ? dataNews.text : "",
    urlLocal: action === "edit" ? dataNews.bannerUrl : null,
    bannerUrl: action === "edit" ? dataNews.bannerUrl : null
  })
  const [fileImg, setFileImg] = useState(null);

  const [errors, setErros] = useState({
    errorCategory: false,
    errorTitle: false,
    errorSummary: false,
    errorText: false,
    errorImg: false
  })

  const { category, title, summary, text, urlLocal, bannerUrl } = news;

  const listCategoryActive = listCategory.filter((item) => !item.delete)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!auth.user.companyadmin) {
      closeModal();
      return;
    }

    setLoading(true);

    const listErros = {
      errorCategory: false,
      errorTitle: false,
      errorSummary: false,
      errorText: false,
      errorImg: false
    }

    if (category === "") {
      listErros.errorCategory = true
    }
    if (title === "") {
      listErros.errorTitle = true
    }
    if (summary === "") {
      listErros.errorSummary = true
    }
    
    const div = document.createElement("div");
    div.innerHTML = text;
    const textValue = div.innerText.replace(/\s+/g, " ").trim();
    if (textValue === "") {
      listErros.errorText = true
    }
    if (!fileImg && action !== "edit") {
      listErros.errorImg = true
    }

    if (listErros.errorCategory || listErros.errorTitle || listErros.errorSummary || listErros.errorText || listErros.img) {
      setErros({
        errorCategory: listErros.errorCategory,
        errorTitle: listErros.errorTitle,
        errorSummary: listErros.errorSummary,
        errorText: listErros.errorText,
        errorImg: listErros.errorImg
      })
      setLoading(false);

      return;
    }

    action === "edit" ? await editNews({ actionDelet: false }) : await addNews();

    setLoading(false);

    setNews({
      category: "",
      title: "",
      summary: "",
      text: ""
    })
    closeModal();
  }

  const editNews = async ({ actionDelet }) => {
    const refDoc = newsCollection.doc(dataNews.id);
    try {
      let urlDownload = null

      if (!!fileImg) {
        //não trocou a imagen
        const newsStorage = workfluxStorage.ref(`news/${auth.company.uid}/${category}/${dataNews.id}/banner.png`);
        await newsStorage.put(fileImg);
        urlDownload = await newsStorage.getDownloadURL();
      }
      const response = await refDoc.update({
        bannerUrl: !!urlDownload ? urlDownload : dataNews.bannerUrl,
        category,
        title,
        summary,
        text,
        delete: actionDelet
      });
    } catch (error) {
    }
  }

  const handleDelete = async () => {
    await editNews({ actionDelet: true })
    closeModal()
  }

  const addNews = async () => {
    try {
      const refDoc = newsCollection.doc();
      const newsStorage = workfluxStorage.ref(`news/${auth.company.uid}/${category}/${refDoc.id}/banner.png`);

      await newsStorage.put(fileImg);

      const urlDownload = await newsStorage.getDownloadURL();

      const response = await refDoc.set({
        uid: refDoc.id,
        bannerUrl: urlDownload,
        category,
        title,
        summary,
        text,
        createdBy: auth.user.uid,
        user: {
          uid: auth.user.uid,
          name: `${auth.user.firstName} ${auth.user.lastName}`,
          email: auth.user.email
        },
        company: auth.company.uid,
        createdAt: new Date(),
        delete: false
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = ({ prop, value }) => {
    /*if (action === "edit" && (prop === "summary" || prop === "text")) {
      const div = document.createElement("div");
      div.innerHTML = value;
      value = div.innerText.replace(/\s+/g, " ").trim();
    }*/

    setNews({ ...news, [prop]: value })
  }

  const handleChangeFile = (e) => {
    const files = e.target.files[0]
    if (files) {
      setFileImg(files);
      setNews({ ...news, urlLocal: URL.createObjectURL(files) });
    }
  }

  return (
    <section className={styles.containerForm}>
      <h1>{titleForm}</h1>
      <form onSubmit={handleSubmit} className={styles.form}>

        <label className={styles.files} style={{
          borderColor: errors.errorImg ? "red" : ""
        }}>
          <section className={styles.areaImg}>
            {urlLocal ? (
              <img src={urlLocal} alt="Imagen" className={styles.image} />
            ) : (
              <p>Selecione uma imagen</p>
            )}
          </section>
          <input type='file' onChange={(e) => handleChangeFile(e)} accept=".png, .jpeg, .jpg" />
        </label>

        <Input
          leftIcon="faTextHeight"
          mode="label-from-left"
          label={i18n("title")}
          value={title}
          onChange={(e) => handleChange({ prop: "title", value: e.target.value })}
          message={errors.errorTitle && i18n("enterATitle")}
        />

        <section className={styles.areaSelect}>
          <TextField
            select
            size="small"
            variant="outlined"
            label="Categoria"
            value={category}
            style={{ width: '100%', maxWidth: 400 }}
            error={errors.errorCategory && "erro"}

            onClick={(event) => handleChange({ prop: "category", value: event.target.value })}
          >
            {listCategoryActive.length === 0 && (
              <MenuItem value="">Cadastre um categoria</MenuItem>
            )}
            {listCategoryActive.map((category) =>
              !category.delete && (<MenuItem value={category.id}>{category.category}</MenuItem>)
            )}
          </TextField>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={addCategory}
          >
            Adicionar Categoria
          </Button>
        </section>
        <section className={styles.ritch} style={{
          borderColor: errors.errorSummary ? "red" : "",
          marginTop: 16
        }}>
          <p>{i18n("summary")}</p>
          <QuillRichTextEditor
            preserveWhitespace
            theme="snow"
            onChange={(e) => handleChange({ prop: "summary", value: e })}
            value={summary}
            placeholder="Clique duas vezes sobre algum texto para usar o editor"
            toolbar={[
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'image'],

              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],

              ['clean']
            ]}
          />
        </section>
        <section className={styles.ritch}
          style={{
            borderColor: errors.errorText ? "red" : "",
          }}
        >
          <p>{i18n("text")}</p>
          <QuillRichTextEditor
            preserveWhitespace
            theme="snow"
            onChange={(e) => handleChange({ prop: "text", value: e })}
            value={text}
            placeholder="Clique duas vezes sobre algum texto para usar o editor"
            toolbar={[
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'image'],

              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],

              ['clean']
            ]}
          />
        </section>
        <section className={styles.areaButtons}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={closeModal}
          >
            {i18n("cancel")}
          </Button>
          {action === "edit" && (
            <Button
              type="button"
              variant="contained"
              style={{
                background: "#F44336",
                color: "#FFF"
              }}
              disabled={loading}
              onClick={handleDelete}
            >
              Excluir
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {action === "edit" ? "Editar" : "Enviar"}
          </Button>
        </section>
      </form>
    </section>
  )
}