export function formatDate({ date = new Date(), separator = '-', orderDirection = 'left' }) {
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = String(date.getFullYear());

	if (orderDirection === 'left') {
		return day + separator + month + separator + year;
	}

	if (orderDirection === 'right') {
		return year + separator + month + separator + day;
	}
}

export function getDiffDaysWeek(date = new Date()) {
	const dateDay = date.getDay();
	let diffDays = dateDay - 1;

	if (diffDays === 0) {
		diffDays = 0;
	}
	if (diffDays === -1) {
		diffDays = 6;
	}

	return diffDays;
}

export function getLastMonthDate(date = new Date()) {
	const lastMonthMiliseconds = date.setDate(date.getDate() - 30);
	const lastMonthDate = new Date(lastMonthMiliseconds);
	return {
		year: lastMonthDate.getFullYear(),
		month: lastMonthDate.getMonth(),
		days: lastMonthDate.getDate(),
		dayOfWeek: lastMonthDate.getDay(),
	};
}

export const convertTimestampDateAndHours = ({ timestamp }) => {

	const date = timestamp.toDate();
	const day = ("0" + date.getDate()).slice(-2);
	const month = ("0" + (date.getMonth() + 1)).slice(-2);
	const year = date.getFullYear();

	const hor = ("0" + date.getHours()).slice(-2);
	const min = ("0" + date.getMinutes()).slice(-2);

	const dateFormatted = day + "/" + month + "/" + year;
	const foursFormatted = hor + ":" + min;

	return {
		date: dateFormatted,
		hours: foursFormatted
	};
} 

export const fixTimezone = (d) => {
	let date = new Date(d.getTime());
	date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
	return date;
}

/**
 * @param {any} value 
 * @returns {Date}
 */
export const toDate = (value) => {
    if (value && typeof value === 'object' && value.toDate) {
        const date = value.toDate();
        return date;
    }
    if (value && typeof value === 'object' && value._seconds && value._nanoseconds !== undefined) {
        const date = new Date(parseInt(value._seconds + '' + ('000' + value._nanoseconds).slice(-3).substr(0, 3)));
        return date;
    }
    if (value && typeof value === 'object' && value.seconds && value.nanoseconds !== undefined) {
        const date = new Date(parseInt(value.seconds + '' + ('000' + value.nanoseconds).slice(-3).substr(0, 3)));
        return date;
    }
    if (value instanceof Date) {
        return value;
    }
    if (typeof value === 'string' && value !== '') {
        if (value.match(/\d{2}\/\d{2}\/\d+/)) {
            const [day, month, yearAndTime] = value.split("/");
            value = `${month}/${day}/${yearAndTime}`;
        }

        return fixTimezone(new Date(value));
    }
    return value;
};
