import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill-fixed';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './styles.scss';

/**
 * @param {{
 *      theme?: 'bubble' | 'snow';
 *      toolbar?: ReactQuillProps['modules']['toolbar'];
 *      value?: string;
 * } & ReactQuillProps} props
 */
function QuillRichTextEditor({
	toolbar = [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
		['link', 'image'],
		['clean'],
	],
	theme = 'bubble',
	onChange = () => {},
	value = '',
	...rest
}) {
	const modules = { toolbar };

	const formats = [
		'background',
		'bold',
		'color',
		'font',
		'code',
		'italic',
		'link',
		'size',
		'strike',
		'script',
		'underline',
		'blockquote',
		'header',
		'indent',
		'list',
		'align',
		'direction',
		'code-block',
		'formula',
		'image',
		'video',
	];

	value = replaceTags(value);

	const handleChange = (html, ...args) => {
		html = replaceTags(html);
		if (html !== value) onChange(html, ...args);
	};

	return (
		<ReactQuill theme={theme} modules={modules} formats={formats} onChange={handleChange} defaultValue={value} {...rest} />
	);
}

export default QuillRichTextEditor;

/**
 * @param {string} html
 * @returns {string}
 */
function replaceTags(html) {
	const p = document.createElement('p');
	p.innerHTML = html;

	p.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach((el) => {
		el.innerHTML = `<span>${el.innerHTML}</span>`;
	});

	return p.innerHTML;
}
