import React, { useEffect, useState } from 'react';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { dokia } from 'zerocode';

const RenderField = ({ field, handleFieldChange }) => {
	const [value, setValue] = useState('');
	const [helperText, setHelperText] = useState('');

	const updateState = ({ target }) => {
		const newValue = target.value;
		setValue(newValue);
		setHelperText(field.required && !newValue ? 'Campo obrigatório' : '');
		handleFieldChange(field.uid, newValue);
	};

	useEffect(()=> {
		setValue(field.value)
	}, [])
	

	const singleLineField = () => (
		<TextField
			style={{ marginBottom: '10px', width: '220px' }}
			variant="outlined"
			label={field.title}
			value={value}
			onChange={updateState}
			helperText={helperText}
			error={field.required && !field.value }
			required={field.required}
		/>
	);

	const multipleLineField = () => (
		<TextField
			style={{ marginBottom: '10px', width: '220px' }}
			variant="outlined"
			label={field.title}
			value={value}
			onChange={updateState}
			helperText={helperText}
			error={field.required && !field.value}
			required={field.required}
			multiline
		/>
	);

	const numberField = () => (
		<TextField
			style={{ marginBottom: '10px', width: '220px' }}
			variant="outlined"
			type="number"
			label={field.title}
			value={value}
			onChange={updateState}
			helperText={helperText}
			error={field.required && !field.value}
			required={field.required}
		/>
	);

	const currencyField = () => (
		<TextField
			style={{ marginBottom: '10px', width: '220px' }}
			variant="outlined"
			type="number"
			label={field.title}
			value={value}
			onChange={updateState}
			helperText={helperText}
			error={field.required && !field.value}
			required={field.required}
		/>
	);

	const dateField = () => (
		<TextField
			style={{ marginBottom: '10px', width: '220px' }}
			variant="outlined"
			type="date"
			label={field.title}
			InputLabelProps={{ shrink: true }}
			value={value}
			onChange={updateState}
			helperText={helperText}
			error={field.required && !field.value}
			required={field.required}
		/>
	);

	const datetimeField = () => (
		<TextField
			style={{ marginBottom: '10px', width: '220px' }}
			variant="outlined"
			type="datetime-local"
			label={field.title}
			InputLabelProps={{ shrink: true }}
			value={value}
			onChange={updateState}
			helperText={helperText}
			error={field.required && !field.value}
			required={field.required}
		/>
	);

	const checkboxField = () => (
		<div>
			<FormControlLabel
				control={<Checkbox checked={value} onChange={(e) => updateState({ target: { value: e.target.checked } })} />}
				label={field.title}
				InputLabelProps={{ shrink: true }}
			/>
		</div>
	);

	const singleSelectionField = () => (
		<Autocomplete
			id="tags-standard"
			options={field.options}
			getOptionLabel={(option) => option.name || ''}
			defaultValue={field.defaultValue}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					label={field.title}
					className="autocomplete"
					placeholder={field.title}
					InputLabelProps={{ shrink: true }}
				/>
			)}
			onChange={(e, newValue) => {
				updateState({ target: { value: newValue } });
			}}
		/>
	);

	const multiSelectionField = () => (
		<Autocomplete
			multiple
			id="tags-standard"
			options={field.options}
			getOptionLabel={(option) => option.name || ''}
			defaultValue={field.defaultValue ? [{ name: field.defaultValue }] : []}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					label={field.title}
					placeholder={field.title}
					className="autocomplete"
				/>
			)}
			onChange={(e, newValue) => {
				updateState({ target: { value: newValue } });
			}}
		/>
	);

	const renderFieldByType = () => {
		switch (field.type) {
			case 'entity.form.field.types.singleLine':
				return singleLineField();
			case 'entity.form.field.types.multipleLine':
				return multipleLineField();
			case 'entity.form.field.types.number':
				return numberField();
			case 'entity.form.field.types.currency':
				return currencyField();
			case 'entity.form.field.types.date':
				return dateField();
			case 'entity.form.field.types.datetime':
				return datetimeField();
			case 'entity.form.field.types.checkbox':
				return checkboxField();
			case 'entity.form.field.types.singleSelection':
				return singleSelectionField();
			case 'entity.form.field.types.multiSelection':
				return multiSelectionField();
			default:
				return null;
		}
	};

	return (
		<>
			<div>{renderFieldByType()}</div>
		</>
	);
};

const EntityFieldsForm = ({ entity, document, onChange, validatedRequired }) => {
	const [fieldschange, setFieldsChange] = useState();

	const fieldsDocumentDokia = document.meta.fields;

	let idsEntitys = [];
	for (const key in fieldsDocumentDokia) {
		idsEntitys.push(key);
	}

	useEffect(() => {
		//No newFields eu estou pegando os valores dos fields das entidades e setando em seus ids
		const newFieldValue = {};

		Object.values(entity.fields).forEach((field) => {
			newFieldValue[field.uid] = field;
		});
		setFieldsChange(newFieldValue);

		dokia.document.validate(document);
	}, []);



	const handleFieldChange = (fieldId, value) => {
		setFieldsChange((prevState) => {
			const updatedArray = Object.keys(prevState).map((key) => {
				const obj = prevState[key];
				if (obj.uid === fieldId) {
					return {
						...obj,
						value: value,
					};
				}
				return obj;
			});
			const updatedFields = Object.fromEntries(updatedArray.map((obj) => [obj.uid, obj]));
			entity.fields = fieldschange;
			return updatedFields;
		});
	};


	const allFieldsSorted = Object.values(entity.fields).sort((a, b) => a.order - b.order);

	return (
		<div className="entity-form-fields-content">
			<h3>{entity.name}</h3>
			<div className="fields">
				{allFieldsSorted.map((field, i) => (
					<div key={i} order={field.order} className="field-content">
						<RenderField field={field} handleFieldChange={handleFieldChange} />
					</div>
				))}
			</div>
		</div>
	);
};

export default EntityFieldsForm;
