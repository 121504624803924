import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useInterval } from '@hooks';
import Authentication from '../screens/Authentication';

const ProtectedComponent = (props) => {
	const { auth } = useSelector((state) => state);

	/** @type {HTMLDivElement} */
	const ultrabar = document.querySelector("#ultra-bar");
	/** @type {HTMLElement} */
	const main = document.querySelector("main");

	useInterval(
		(id) => {
			if (ultrabar && main) {
				ultrabar.style.display =
					props.hideUltrabar || !auth.isAuthenticated
						? "none"
						: "inherit";
				main.style.marginTop =
					props.hideUltrabar || !auth.isAuthenticated ? "0" : "40px";
				clearInterval(id);
			}
		},
		0,
		[props.hideUltrabar, ultrabar, auth.isAuthenticated]
	);

	/** @type {HTMLDivElement} */
	const appdrawer = document.querySelector(".AppDrawer");

	useInterval(
		(id) => {
			if (appdrawer) {
				appdrawer.style.display =
					props.hideAppdrawer || !auth.isAuthenticated
						? "none"
						: "inherit";
				clearInterval(id);
			}
		},
		0,
		[props.hideAppdrawer, appdrawer, main, auth.isAuthenticated]
	);

	return (
		<Fragment>
			{auth.isAuthenticated || !props.isProtected ? <props.Screen {...props} /> : <Authentication {...props} />}
		</Fragment>
	);
};

export default ProtectedComponent;
