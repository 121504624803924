import React, { useState } from 'react';
import { format } from '@i18n';
import firebase, { workfluxFirestore } from '@firebase-config';
import { services } from '@redux';
import { connect } from 'react-redux';
import { TextField, MenuItem, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Absense } from '@taugor/taugor-bpmn-models';
import Icon from '@custom/Icon';
import { store as zerocodeStore } from 'zerocode';
import '@assets/sass/profile.scss';
import i18n from '../@i18n';
const oAbsenseService = new Absense().getService(firebase);
const absenseCollection = workfluxFirestore.collection('absense');
const Profile = ({ auth, absenseStore }) => {
	const [absenses, setAbsenses] = useState([]);
	const [currentUser, setCurrentUser] = useState({
		firstName: '',
		lastName: '',
	});
	const [currentGroup, setCurrentGroup] = useState({ uid: '', name: '' });
	const [absensesUser, setAbsensesUser] = useState([]);
	let startDate, endDate;
	let appcenterStore = zerocodeStore.getState().appcenter;

	const save = () => {
		let showWarning = false;
		absenses.map((a) => {
			if (!a.dateStart || !a.dateEnd) {
				return null;
			}
			let deny = false;
			let oAbsense = new Absense(a);
			oAbsense.user = auth.user.uid;

			let dsParts = a.dateStart.split('-');
			oAbsense.dateStart = new Date(dsParts[0], dsParts[1] - 1, dsParts[2], 0, 0, 0);

			let deParts = a.dateEnd.split('-');
			oAbsense.dateEnd = new Date(deParts[0], deParts[1] - 1, deParts[2], 23, 59, 59);

			absenseStore.list.map((abs) => {
				if (abs.dateStart.toDate() >= oAbsense.dateStart && abs.dateEnd.toDate() <= oAbsense.dateEnd) {
					deny = true;
					showWarning = true;
				}
			});

			if (!deny) {
				oAbsenseService.save(oAbsense);
			}
		});
		if (showWarning) {
			services.message.show('Datas criadas entre períodos já salvos, não serão criadas.');
		}
		setAbsenses([]);
	};

	const getAbsensesByUser = async (user) => {
		if (user) {
			const data = await absenseCollection.where('user', '==', user.uid).where('deleted', '==', false).get();
			const results = data.docs.map((d) => d.data());
			setAbsensesUser(results);
			setCurrentUser(user);
			services.spinner.hide();
		} else {
			services.spinner.hide();
			setAbsenses([]);
			setCurrentUser({
				firstName: '',
				lastName: '',
			});
		}
	};

	const addAbsensesToUser = async (start, end, user) => {
		let dsParts = startDate.split('-');
		let dateStart = new Date(dsParts[0], dsParts[1] - 1, dsParts[2], 0, 0, 0);

		let deParts = endDate.split('-');
		let dateEnd = new Date(deParts[0], deParts[1] - 1, deParts[2], 23, 59, 59);

		let doc = absenseCollection.doc();
		let uid = doc.id;
		await doc.set({
			createdAt: new Date(),
			createdBy: auth.user.uid,
			dateStart,
			dateEnd,
			deleted: false,
			uid,
			user: user.uid,
		});
		services.message.show(i18n("saveSucess"));
		getAbsensesByUser(user);
	};
	//(auth.user);
	return (
		<content id="profile">
			<header
				style={{
					width: "fit-content",
				}}
			>
				<div style={{ flex: 1, display: "flex", gap: 10 }}>
					<div className="icon-user">
						<Icon icon="faUser" />
					</div>
					<div className="title"

					>
						<div>
							{auth.user.firstName} {auth.user.lastName}
						</div>
						<div style={{ fontSize: 12 }}>
							<b>UID:</b> {auth.user.uid}
						</div>
						<div style={{ fontSize: 12 }}>{auth.user.email}</div>
					</div>
				</div>
				<div
					className="header-buttons"
					style={{
						minWidth: 50
					}}
				>
					{!!absenses.length && (
						<div className="header-button" onClick={save}>
							<Icon icon="faSave" size="lg" />
						</div>
					)}
				</div>
			</header>
			{/* {!!auth.user.companies.length && (
				<div className="profile-content">
					<div className="subtitle">Empresas que participa</div>
					{auth.user.companies.map((company, i) => {
						console.log(company);
						return <div>{company.name}</div>;
					})}
				</div>
			)} */}
			<div className="absenses">
				<Tooltip title={i18n("workflow.dashboard.titleHelp")}>
					<div className="help">
						<Icon icon="faQuestionCircle" />
					</div>
				</Tooltip>
				<div className="subtitle">{i18n("workflow.profile.titleMyRecords")}</div>

				<simple-button
					save
					style={{ width: 'fit-content' }}
					onClick={() => {
						absenses.push({ dateStart: '', dateEnd: '' });
						setAbsenses([...absenses]);
					}}
				>
					{i18n("workflow.profile.buttonAddAbsence")}
				</simple-button>
				<div className="list">
					{absenses.map((absense, i) => {
						let dateStart = absense.dateStart;
						if (absense.dateStart.toDate) {
							dateStart = absense.dateStart.toDate();
						}
						return (
							<div className="simple-list-item with-columns" key={i}>
								<div style={{
									display: "grid",
									gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr",
									gap: 16,
									width: "100%",
									maxWidth: 316
								}}>
									<TextField
										label="Início"
										value={absense.dateStart}
										type="date"
										onChange={(e) => {
											absense.dateStart = e.target.value;
											//(absense.dateStart);
											setAbsenses([...absenses]);
										}}
										InputLabelProps={{ shrink: true }}
									/>
									<TextField
										label="Fim"
										value={absense.dateEnd}
										type="date"
										onChange={(e) => {
											absense.dateEnd = e.target.value;
											setAbsenses([...absenses]);
										}}
										InputLabelProps={{ shrink: true }}
									/>
								</div>
								<simple-button
									onClick={() => {
										absenses.splice(i, 1);
										setAbsenses([...absenses]);
									}}
								>
									Remove
								</simple-button>
							</div>
						);
					})}
				</div>
				<div className="list" style={{ marginTop: 15 }}>
					{absenseStore.list
						.sort((a, b) => {
							if (a.dateStart.toDate() > b.dateStart.toDate()) {
								return -1;
							}
							if (a.dateStart.toDate() < b.dateStart.toDate()) {
								return 1;
							}
							if (a.dateStart.toDate() === b.dateStart.toDate()) {
								if (a.dateEnd.toDate() > b.dateEnd.toDate()) {
									return -1;
								}
								if (a.dateEnd.toDate() < b.dateEnd.toDate()) {
									return 1;
								}
							}
							return 0;
						})
						.map((absense, i) => {
							let dateStart = absense.dateStart.toDate();
							let dateEnd = absense.dateEnd.toDate();
							return (
								<div className="absense" key={i}>
									<div className="date">{format.date(dateStart)}</div>
									{/* <div style={{ marginLeft: 10, marginRight: 10 }}>-</div> */}
									<div className="divider"></div>
									<div className="date">{format.date(dateEnd)}</div>
									{(!dateStart || new Date(dateStart) <= new Date()) && <div className="divider"></div>}
									{(!dateStart || new Date(dateStart) <= new Date()) && (
										<div>
											<div
												className="remove-button"
												onClick={() => {
													oAbsenseService.patch(absense.uid, {
														deleted: true,
													});
												}}
											>
												<Icon icon="faTrash" />
											</div>
										</div>
									)}
								</div>
							);
						})}
				</div>
			</div>
			<div className="absenses">
				<div className="subtitle">{i18n("workflow.profile.titleVerifyUserAbsence")}</div>
				<div style={{
					display: "grid",
					gridTemplateColumns: "repeat(auto-fit, minmax(190px, 1fr))",
					width: "100%",
					marginTop: 16,
					gap: 15,
					maxWidth: 450,
				}}>
					{auth.user.companyadmin && (
						<>
							<Autocomplete
								clearOnBlur
								openOnFocus
								selectOnFocus
								handleHomeEndKeys
								disableCloseOnSelect
								variant="filled"
								style={{ width: "100%", marginRight: 8, maxWidth: 220 }}
								className="groups"
								value={currentGroup}
								options={appcenterStore.groups}
								getOptionLabel={(option) => {
									return option.name || '';
								}}
								renderOption={(option) => <div>{option.name}</div>}
								renderInput={(params) => (
									<TextField
										{...params}
										label={i18n("groupsOptional")}
										placeholder={i18n("group")}
										variant="outlined"
										className="autocomplete"
									/>
								)}
								onChange={(e, value) => {
									setCurrentGroup(value);
									// services.spinner.show();
								}}
							/>
							<Autocomplete
								clearOnBlur
								openOnFocus
								selectOnFocus
								handleHomeEndKeys
								disableCloseOnSelect
								variant="filled"
								style={{ width: "100%", marginRight: 4, maxWidth: 220 }}
								className="users"
								value={currentUser}
								options={appcenterStore.users.filter((user) => user.groups.indexOf(currentGroup?.uid) !== -1)}
								getOptionLabel={(option) => {
									return option.firstName + ' ' + option.lastName || '';
								}}
								// filterOptions={(options, state) => {
								// 	return options.filter((user) => {
								// 		if (currentGroup && currentGroup.uid) {
								// 			if (user.groups.indexOf(currentGroup.uid) == -1) return false;
								// 		}
								// 		if (!user.active) return false;
								// 		if (user.active.indexOf(auth.user.currentCompany.uid) == -1) return false;
								// 		return true;
								// 	});
								// }}
								renderOption={(user) => (
									<div>
										{user.firstName} {user.lastName}
									</div>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label={i18n("user")}
										placeholder={i18n("user")}
										variant="outlined"
										className="autocomplete"
									/>
								)}
								onChange={(e, value) => {
									services.spinner.show();
									getAbsensesByUser(value);
								}}
							/>
						</>
					)}
					{currentUser.uid && (
						<Tooltip placement="right" title="Adicione uma ausência programada para o usuário selecionado">
							<simple-button
								style={{ marginLeft: 10, height: 'max-content' }}
								save
								iconed
								onClick={() => {
									services.message.show(
										i18n("workflow.profile.titleModalRegisterAbsence"),
										<div>
											<div>
												{currentUser.firstName} {currentUser.lastName}
											</div>
											<div style={{ marginRight: 10 }}>
												<TextField
													label={i18n("start")}
													type="date"
													onChange={(e) => {
														startDate = e.target.value;
													}}
													InputLabelProps={{ shrink: true }}
												/>
											</div>
											<div style={{ marginRight: 10 }}>
												<TextField
													label={i18n("end")}
													type="date"
													onChange={(e) => {
														endDate = e.target.value;
													}}
													InputLabelProps={{ shrink: true }}
												/>
											</div>
										</div>,
										[
											{
												text: i18n("save"),
												onClick: () => {
													addAbsensesToUser(startDate, endDate, currentUser);
												},
											},
										]
									);
								}}
							>
								<Icon icon="faPlus" />
							</simple-button>
						</Tooltip>
					)}
				</div>
				{absensesUser
					.sort((a, b) => {
						if (a.dateStart.toDate() > b.dateStart.toDate()) {
							return -1;
						}
						if (a.dateStart.toDate() < b.dateStart.toDate()) {
							return 1;
						}
						if (a.dateStart.toDate() === b.dateStart.toDate()) {
							if (a.dateEnd.toDate() > b.dateEnd.toDate()) {
								return -1;
							}
							if (a.dateEnd.toDate() < b.dateEnd.toDate()) {
								return 1;
							}
						}
						return 0;
					})
					.map((absense, i) => {
						let dateStart = absense.dateStart.toDate();
						let dateEnd = absense.dateEnd.toDate();
						return (
							<div className="absense" key={i}>
								<div className="date">{format.date(dateStart)}</div>
								<div className="divider"></div>
								<div className="date">{format.date(dateEnd)}</div>
								{(!dateStart || new Date(dateStart) <= new Date()) && <div className="divider"></div>}
								<div>
									<div
										className="remove-button"
										onClick={() => {
											oAbsenseService
												.patch(absense.uid, {
													deleted: true,
												})
												.then(getAbsensesByUser(currentUser));
										}}
									>
										<Icon icon="faTrash" />
									</div>
								</div>
							</div>
						);
					})}
			</div>
		</content>
	);
};
// const sortAbsenseFunction = (a, b) => {
// 	if (!a.dateStart || !b.dateStart) {
// 		return -1;
// 	}
// 	if (!a.dateStart && !b.dateStart && !a.dateEnd && !b.dateEnd) {
// 		return -1;
// 	}
// 	if (a.dateStart.toDate && b.dateStart.toDate) {
// 		if (a.dateStart.toDate() > b.dateStart.toDate()) return 1;
// 		if (a.dateStart.toDate() < b.dateStart.toDate()) return -1;
// 	}
// 	if (!a.dateStart.toDate && !b.dateStart.toDate && a.dateStart.toISOString && b.dateStart.toISOString) {
// 		if (a.dateStart > b.dateStart) return 1;
// 		if (a.dateStart < b.dateStart) return -1;
// 	}
// 	if (a.dateStart.toDate && !b.dateStart.toDate && b.dateStart.toISOString) {
// 		if (a.dateStart > b.dateStart.toDate()) return 1;
// 		if (a.dateStart < b.dateStart.toDate()) return -1;
// 	}
// 	if (!a.dateStart.toDate && b.dateStart.toDate && a.dateStart.toISOString) {
// 		if (a.dateStart.toDate() > b.dateStart) return 1;
// 		if (a.dateStart.toDate() < b.dateStart) return -1;
// 	}
// 	return 0;
// };
export default connect(({ auth, absenseStore }) => ({ auth, absenseStore }))(Profile);
